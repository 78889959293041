import { Dispatch } from "redux";
import { AdminApiClient } from "../../utils/http-client";
import { IPaginationParams, IBlogPostParams } from "../types";

const adminApiClient = AdminApiClient.getInstance();

export const blogPosts = (queryParams?: IPaginationParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "BLOG_POSTS_START" });
  await adminApiClient
    .blogPosts(queryParams)
    .then((response) => {
      dispatch({ type: "BLOG_POSTS_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "BLOG_POSTS_FAILURE", payload: error }));
};

export const blogPost = (id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: "BLOG_POST_START" });
  await adminApiClient
    .blogPost(id)
    .then((response) => {
      dispatch({ type: "BLOG_POST_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "BLOG_POST_FAILURE", payload: error }));
};

export const createBlogPost = (params: IBlogPostParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "CREATE_BLOG_POST_START" });
  await adminApiClient
    .createBlogPost(params)
    .then((response) => {
      dispatch({ type: "CREATE_BLOG_POST_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "CREATE_BLOG_POST_FAILURE", payload: error }));
};

export const updateBlogPost = (id: string, params: IBlogPostParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "UPDATE_BLOG_POST_START" });
  await adminApiClient
    .updateBlogPost(id, params)
    .then((response) => {
      dispatch({ type: "UPDATE_BLOG_POST_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "UPDATE_BLOG_POST_FAILURE", payload: error }));
};

export const deleteBlogPost = (id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: "DELETE_BLOG_POST_START" });
  await adminApiClient
    .deleteBlogPost(id)
    .then((response) => {
      dispatch({ type: "DELETE_BLOG_POST_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "DELETE_BLOG_POST_FAILURE", payload: error }));
};
