import { Reducer } from "react";
import {
  IContractsState,
  ContractsActionType,
  IContractState,
  ContractActionType,
  CreateContractActionType,
  UpdateContractActionType,
  DeleteContractActionType,
  IDefaultState,
} from "../types";

// Contracts
const contractsInitialState: IContractsState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const contractsReducer: Reducer<IContractsState, ContractsActionType> = (
  state = contractsInitialState,
  action
): IContractsState => {
  switch (action.type) {
    case "CONTRACTS_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "CONTRACTS_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "CONTRACTS_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "CONTRACTS_RESET":
      return contractsInitialState;
    default:
      return state;
  }
};

// Contract
const contractInitialState: IContractState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const contractReducer: Reducer<IContractState, ContractActionType> = (
  state = contractInitialState,
  action
): IContractState => {
  switch (action.type) {
    case "CONTRACT_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "CONTRACT_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "CONTRACT_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "CONTRACT_RESET":
      return contractInitialState;
    default:
      return state;
  }
};

/**
 * @param state
 * @param action
 */
export const createContractReducer: Reducer<IContractState, CreateContractActionType> = (
  state = contractInitialState,
  action
): IContractState => {
  switch (action.type) {
    case "CREATE_CONTRACT_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "CREATE_CONTRACT_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "CREATE_CONTRACT_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "CREATE_CONTRACT_RESET":
      return contractInitialState;
    default:
      return state;
  }
};

/**
 * @param state
 * @param action
 */
export const updateContractReducer: Reducer<IContractState, UpdateContractActionType> = (
  state = contractInitialState,
  action
): IContractState => {
  switch (action.type) {
    case "UPDATE_CONTRACT_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "UPDATE_CONTRACT_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "UPDATE_CONTRACT_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "UPDATE_CONTRACT_RESET":
      return contractInitialState;
    default:
      return state;
  }
};

const contractDeleteInitialState: IDefaultState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const deleteContractReducer: Reducer<IDefaultState, DeleteContractActionType> = (
  state = contractDeleteInitialState,
  action
): IContractState => {
  switch (action.type) {
    case "DELETE_CONTRACT_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "DELETE_CONTRACT_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "DELETE_CONTRACT_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "DELETE_CONTRACT_RESET":
      return contractDeleteInitialState;
    default:
      return state;
  }
};
