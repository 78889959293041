import { useCallback, useEffect, useRef, useState,useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { Card, Row, Col, Button, Form, Table, ButtonGroup } from "react-bootstrap";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { RiAddBoxLine, RiEyeLine, RiDeleteBin6Line } from "react-icons/all";
import { RootState } from "../../../store/reducers";
import { DeleteConfirmModal, LoadingIndicator, Pagination, RecordNotFound } from "../../partials";
import { IMentoringProgramsPaginationParams, IMentoringProgramsState } from "../../../store/types";
import { MainLayout } from "../../layouts";
import actions from "../../../store/actions";
import { DEFAULT_PER_PAGE } from "../../../types";
import qs from "query-string";
export const ListPrograms = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const { search } = useLocation();
    const filterQRef = useRef<HTMLInputElement>(null);
    const { isLoading, response, error } = useSelector<RootState, IMentoringProgramsState>(state => state.mentoringPrograms);
    const { response: deleteResponse } = useSelector<RootState, IMentoringProgramsState>(state => state.deleteMentoringProgram);
    const queryString = new URLSearchParams(useLocation().search);
    const page: number = queryString.get("page") ? Number(queryString.get("page")) : 1;
    const mentor_program: string | null = queryString.get("mentor_program") ? queryString.get("mentor_program") : null;
    
  const [queryParams, setQueryParams] = useState<IMentoringProgramsPaginationParams>({
    page: page,
    per: DEFAULT_PER_PAGE,
});

    const getMentorPrograms = useCallback(
        (queryParameters?): void => {
            dispatch(actions.mentoringPrograms(queryParameters));
        },
        [dispatch]
    );
    const [deleteProgram, setDeleteProgram] = useState<string | null>(null);
    const onDeleteConfirm = async () => {
        if (deleteProgram) {
            dispatch(await actions.deleteMentoringProgram(deleteProgram));
            setDeleteProgram(null);
        }
    }


    const handleSubmitFilter = (): void => {
        let url: string = `/mentoring_programs?page=1`;
        const searchParams: Array<string> = [];
        if (filterQRef.current!.value) {
            searchParams.push(`q=${filterQRef.current!.value}`);
            queryParams.q = filterQRef.current!.value;
          }
        if (searchParams.length) {
            url += "&" + searchParams.join("&");
          }
          history.push(url);
    }
    const qParams: IMentoringProgramsPaginationParams = useMemo<IMentoringProgramsPaginationParams>(() => qs.parse(search), [search]);
    useEffect(() => {
        const params: IMentoringProgramsPaginationParams = qParams;
        params.per = DEFAULT_PER_PAGE;
        setQueryParams(params);
        getMentorPrograms(params);
    }, [qParams, getMentorPrograms]);

    useEffect((): void => {
        if (deleteResponse) {
            addToast(t("deleted_with_param", { param: t("mentoring_program") }), {
                appearance: "success",
                autoDismiss: true,
            });
            getMentorPrograms({
                page: page,
                mentor_program: mentor_program,
            });
            dispatch({ type: "DELETE_MENTORING_PROGRAM_RESET" });
        }
    }, [deleteResponse, addToast, t, getMentorPrograms, dispatch, page, mentor_program]);

    useEffect((): void => {
        if (error?.response) {
            dispatch({ type: "MENTORING_PROGRAM_RESET" });

            if (error.response.status >= 400) {
                history.push("/mentoring_programs");
            }
        } else {
            getMentorPrograms({ page, per: DEFAULT_PER_PAGE });
        }
    }, [getMentorPrograms, page, error, history, dispatch]);
    return (
        <MainLayout>
            <LoadingIndicator show={isLoading} />
            {response && (
                <Row>
                    <Col md="12">
                        <Row>
                            <Col md="12" className="order-first order-md-last">
                                <Card >
                                    <Card.Body style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Form onSubmit={handleSubmitFilter} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                            <Form.Group controlId="filter-mentorProgram" style={{ marginRight: '1rem', marginBottom: '1rem', width: '18%' }}>
                                                <Form.Label>{t("mentoring_programs")}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="q"
                                                    ref={filterQRef}
                                                    placeholder={t("mentoring_programs")}
                                                    onKeyPress={(e: any) => e.code === "Enter" && handleSubmitFilter()}
                                                    defaultValue={mentor_program ? mentor_program : ""}
                                                />
                                            </Form.Group>
                                            <div style={{ display: 'flex', justifyContent: 'end', width: '94%' }}>
                                                <Button style={{ width: '20%' }} variant="outline-primary" onClick={handleSubmitFilter}>
                                                    {t("filter")}
                                                </Button>
                                            </div>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Card>
                            <Card.Header>
                                <Row>
                                    <Col as="h6" xs="4" className="mb-0">
                                        {t("mentoring_programs")}
                                    </Col>
                                    <Col xs="4" className="mb-0 text-right">
                                        <strong className="mr-1">{t("total")}:</strong>
                                        <span>{response.data.pagination.total}</span>
                                    </Col>
                                    <Col xs="4" className="text-right">
                                        <Button as={NavLink} to="/mentoring_programs/create" variant="success" size="sm" className="with-icon">
                                            <RiAddBoxLine />
                                            <span>{t("create")}</span>
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body className="p-0 m-0">
                                {response.data.items.length > 0 ? (
                                    <>
                                        <DeleteConfirmModal
                                            show={deleteProgram !== null}
                                            onClose={() => setDeleteProgram(null)}
                                            onConfirm={onDeleteConfirm}
                                        />
                                        <Table responsive>
                                            <thead>

                                                <tr>
                                                    <th >{t("program_name")}</th>
                                                    {/* <th>{t("session")}</th> */}
                                                    <th>{t("actions")}</th>
                                                </tr>

                                            </thead>
                                            <tbody>
                                                {response.data.items.map((program, key,) => (

                                                    <tr key={key}>
                                                        <td>
                                                            <NavLink to={`/mentoring_programs/${program.id}`}>
                                                                {program.title}
                                                            </NavLink>
                                                        </td>
                                                        {/* <td>
                                                            <div>
                                                                
                                                                {program.sessions ? (
                                                                    <Button
                                                                        variant="outline-success"
                                                                        size="sm"
                                                                        className="with-icon"
                                                                        title={t("add")}
                                                                        
                                                                        onClick={() =>
                                                                            OpenmentorProgramsDetailModal(program.sessions.map((session: any) => ({
                                                                                title: session.title,
                                                                                goals: session.goals,
                                                                            })))
                                                                        }
                                                                    >
                                                                        {countObjects(program.sessions)}
                                                                    </Button>
                                                                ) : (
                                                                    "-"
                                                                )}
                                                            </div>
                                                        </td> */}
                                                        <td className="actions">
                                                            <ButtonGroup>
                                                                <Button
                                                                    as={NavLink}
                                                                    to={`/mentoring_programs/${program.id}`}
                                                                    variant="outline-primary"
                                                                    size="sm"
                                                                    title={t("show")}
                                                                >
                                                                    <RiEyeLine />
                                                                </Button>
                                                                <Button
                                                                    variant="outline-danger"
                                                                    size="sm"
                                                                    title={t("delete")}
                                                                    onClick={() => setDeleteProgram(program.id)}
                                                                >
                                                                    <RiDeleteBin6Line />
                                                                </Button>
                                                            </ButtonGroup>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </>
                                ) : (
                                    <RecordNotFound />
                                )}
                            </Card.Body>

                            <Card.Footer>
                                <Row>
                                    <Col xs="6">
                                        <Pagination pagination={response.data.pagination} extraParams={["role", "q", "q2"]} />
                                    </Col>
                                    
                                </Row>
                            </Card.Footer>
                        </Card>

                    </Col>
                </Row>
            )}
        </MainLayout>

    );

};
export default ListPrograms;