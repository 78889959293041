export const translation = {
  email: "E-posta",
  enter_email: "E-posta girin",
  password: "Şifre",
  password_confirmation: "Şifre (tekrar)",
  enter_password: "Şifre girin",
  remember_me: "Beni hatırla",
  login: "Giriş",
  lost_password: "Şifremi unuttum",
  lost_password_notice: "Şifrenizi yenileyebilmek için sisteme kayıtlı e-posta adresinizi yazarak formu gönderin.",
  lost_password_update: "Şifre güncelle",
  lost_password_update_notice: "Kullanmak istediğiniz yeni şifrenizi yazarak formu gönderin.",
  lost_password_update_invalid_request: "Şifre yenileme isteği geçersiz.",
  login_with_new_password: "Yeni şifreniz ile giriş yapabilirsiniz.",
  send: "Gönder",
  cancel: "İptal et",
  bad_credentials: "Bilgileriniz hatalı, lütfen tekrar deneyin.",
  password_reset_request_success:
    "Şifrenizi yenilemek için gerekli bağlantı e-posta adresinize gönderildi. Gelen bağlantıya tıklayarak şifrenizi güncelleyebilirsiniz.",
  role_error: "You are not authorized to use this interface",
  unknown_error: "Bir hata oluştu, lütfen tekrar deneyin.",
  error_404_message: "Aradığınız içeriğe ulaşılamıyor.",
  profile: "Profil",
  logout: "Çıkış",
  dashboard: "Başlangıç",
  goto_dashboard: "Başlangıca dön",
  page: "Sayfa",
  pages: "Sayfalar",
  category: "Uzmanlık",
  categories: "Uzmanlıklar",
  select_category: "Uzmanlık seçin",
  expertise_management: "Uzmanlık yönetimi",
  expertise: "Uzmanlık",
  expertises: "Uzmanlıklar",
  select_expertise: "Uzmanlık seçin",
  expertise_not_selected: "Uzmanlık seçilmedi",
  sector_management: "Sektör yönetimi",
  sector: "Sektör",
  sectors: "Sektörler",
  select_sector: "Sektör seçin",
  select_sub_sector: "Alt sektör seçin",
  sub_sector: "Alt sektör",
  sub_sectors: "Alt sektörler",
  sector_not_selected: "Sektör seçilmedi",
  business_information: "İşletme bilgileri",
  business_type: "İşletme tipi",
  business_types: {
    individual_company: "Şahıs Şirketi",
    limited_company: "Limited Şirket",
    incorporated_company: "Anonim Şirket",
    cooperative: "Kooperatif",
    company_at_idea_stage: "Şirket Fikir Aşamasında",
    individual_application: "Şirket Bulunmuyor/Bireysel Başvuru",
  },
  business_name: "İşletme adı",
  business_role: "İşletmedeki görev",
  business_sectors: "İşletme sektörleri",
  business_working_time: "İşletmedeki çalışma süresi",
  business_sector: "İşletme sektörü",
  business_website: "İşletme websitesi",
  business_group: "İşletme grubu",
  business_address: "İşletme adresi",
  business_phone: "İşletme telefonu",
  business_general: "İşletme genel",
  business_city: "İşletme şehir",
  business_roles: {
    MANAGER: "Yönetici",
    USER: "Çalışan",
  },
  contract: "Sözleşme",
  contracts: "Sözleşmeler",
  blog_post: "Blog yazısı",
  blog_posts: "Blog yazıları",
  news_singular: "Haber",
  news: "Haberler",
  faq: "Sık sorulan soru",
  faqs: "Sık sorulan sorular",
  feedback: "Yorum",
  feedbacks: "Yorumlar",
  general: "Genel",
  latitude: "Enlem",
  longitude: "Boylam",
  url: "URL",
  mentoring_programs: "Rehberlik Programları",
  mentoring_program: "Rehberlik Programı",
  mentoring_program_title: "Rehberlik Programı Başlığı",
  Please_add_at_least_one_session: "Lütfen en az bir oturum ekleyin",
  mentoring_procedures:"Rehberlik işlemleri",
  update_mentoring_program: "Rehberlik programını güncelle",
  session_topics: "Oturum konuları",
  session_decisions: "Oturum kararları",
  session_assesments: "Oturum değerlendirmeleri",
  session_recording_link: "Oturum kayıt linki",
  session_link: "Oturum linki",
  session_date: "Oturum tarihi",
  matches: "Eşleştirme işlemleri",
  match:"Eşleşme",
  program_name: "Program adı",
  session: "Oturum",
  sessions_title_and_sessions_topics: "Oturum başlıkları ve konular",
  session_title: "Oturum başlığı",
  program_title: "Program başlığı",
  followed_task_title :"Görev başlığı",
  followed_task_task_order :"Görev sırası",
  followed_task_status :"Görev durumu",
  followed_task_authorized_person :"Yetkili Kişi",
  followed_task_start_date :"Başlangıç tarihi",
  followed_task_completion_date :"Bitiş tarihi",
  select: "Seçiniz",
  followed_task_status_not_choose :"Görev durumu seçilmedi",
  You_can_enter_multiple_links_by_separating_them_with_a_space :"Birden fazla link girebilirsiniz, aralarına boşluk bırakın",
  New_Followed_Task :"Yeni Takip Edilen Görev",
  goals: "Hedefler",
  preparations: "Hazırlıklar",
  agenda: "Gündem",
  documents: "Dökümanlar",
  followed_tasks: "Takip edilen görevler",
  remove_followed_task: "Takip edilen görevi kaldır",
  remove_session: "Oturumu kaldır",
  session_location: "Oturum lokasyonu",
  session_documents: "Oturum dökümanları",
  session_goal: "Oturum hedefi",
  session_goals : "Oturum hedefleri",
  steps: "Adımlar",
  session_preparations : "Oturum hazırlıkları",
  session_agenda : "Oturum gündemi",
  session_delete: "Oturumu sil",
  session_update: "Oturumu güncelle",
  create_new_session: "Yeni oturum oluştur",
  contact_information: "İletişim bilgileri",
  contact_information_updated: "İletişim bilgileri güncellendi",
  appointments: "Görüşmeler",
  appointment_records: "Görüşme kayıtları",
  only_recorded: "Sadece kayıt yapılmışlar",
  appointment_content_types: {
    MEET: "Tanışma",
    LESSON: "Ders",
    SET_BY_MENTOR_APPOINTMENT: "Etkinlik/Toplantı",
  },
  appointment_status_types: {
    WAITING: "Bekliyor",
    NOT_YET_START: "Henüz başmaladı",
    PAST: "Zamanı geçmiş",
    AVAILABLE: "Görüşme aktif",
    UNAVAILABLE: "Kullanımda değil",
    DONE: "Tamamlandı",
  },
  records: "Kayıtlar",
  duration: "Süre",
  file: "Dosya",
  download: "İndir",
  room_id: "Toplantı ID",
  record_count: "Kayıt sayısı",
  start_date: "Başlangıç tarihi",
  end_date: "Bitiş tarihi",
  event_place: "Görüşme yeri",
  appointment_message: "Görüşme notu",
  is_appointment_completed: "Görüşme tamamlandı mı?",
  mentor_evaluation: "Rehber değerlendirmesi",
  mentor_score: "Rehber puanı",
  mentor_teams: "Rehber Takımları",
  mentor_team: "Rehber Takımı",
  select_mentor_team:"Takım seçin",
  select_mentoring_program:"Rehberlik programı seçin",
  select_client_s:"Danışan(lar) seçin",
  team_lead: "Takım Lideri",
  intern: "Stajyer",
  interns: "Stajyerler",
  select_interns: "Stajyerler seçin",
  team_interns: "Takım Stajyerleri",
  task_order: "Görev Sırası",
  team_process_manager: "Takım Süreç Yöneticisi",
  team_project_manager: "Takım Proje Yöneticisi",
  team_mentors: "Takım Rehberleri",
  team_expertises: "Takım Uzmanlıkları",
  team_name: "Takım Adı",
  mentor_score_count: "Rehber değerlendirme sayısı",
  user_evaluation: "Girişimci değerlendirmesi",
  user_score: "Girişimci puanı",
  user_score_count: "Girişimci değerlendirme sayısı",
  applications: "Başvurular",
  mentor_application: "Rehber başvurusu",
  mentor_applications: "Rehber başvuruları",
  user_application: "Girişimci başvurusu",
  user_applications: "Girişimci başvuruları",
  user: "Kullanıcı",
  users: "Kullanıcılar",
  user_detail: "Kullanıcı detayı",
  role: "Rol",
  roles: "Roller",
  role_names: {
    ADMIN: "Yönetici",
    MENTOR: "Rehber",
    USER: "Girişimci",
  },
  mentors_count: "Rehberler",
  users_count: "Girişimciler",
  logs: "Loglar",
  application_status_type: {
    waiting: "Bekliyor",
    approved: "Onaylandı",
    rejected: "Reddedildi",
  },
  approved_at: "Onaylanma tarihi",
  rejected_at: "Reddedilme tarihi",
  reject_description: "Reddedilme nedeni",
  user_notifications: {
    mentor_applications: "Onay bekleyen {{count}} rehber başvurusu var.",
    user_applications: "Onay bekleyen {{count}} girişimci başvurusu var.",
    contact_form_messages: "8 okunmamış iletişim formu mesajı var.",
  },
  list: "Liste",
  create: "Oluştur",
  create_mentor: "Rehber oluştur",
  create_user: "Girişimci oluştur",
  export: "Dışarı Aktar",
  export_as_excel: "Excel olarak dışarı aktar",
  new: "Yeni",
  order: "Sıra",
  edit: "Düzenle",
  show: "Görüntüle",
  detail: "Detay",
  details: "Detaylar",
  change_status: "Durumu değiştir",
  total: "Toplam",
  save: "Kaydet",
  created: "Oluşturuldu",
  created_with_param: "{{param}} oluşturuldu.",
  update: "Güncelle",
  updated: "Güncellendi",
  updated_with_param: "{{param}} güncellendi.",
  delete: "Sil",
  deleted: "Silindi",
  deleted_with_param: "{{param}} silindi.",
  update_my_profile: "Bilgilerimi güncelle",
  your_profile_updated: "Bilgileriniz güncellendi.",
  name: "İsim",
  lastname: "Soyisim",
  city: "Şehir",
  select_city: "Şehir seçin",
  district: "İlçe",
  select_district: "İlçe seçin",
  address: "Adres",
  about: "Hakkında",
  gender: "Cinsiyet",
  select_gender: "Cinsiyet seçin",
  genders: {
    male: "Erkek",
    female: "Kadın",
    other: "Belirtilmemiş",
  },
  disability: "Engel durumu",
  select_disability: "Engel durumu seçin",
  disabilities: {
    yes: "Var",
    no: "Yok",
    other: "Belirtilmemiş",
  },
  select_role: "Görev seçin",
  add: "Ekle",
  phone: "Telefon",
  fax: "Faks",
  location: "Lokasyon",
  social_media: "Sosyal medya",
  facebook: "Facebook",
  twitter: "Twitter",
  instagram: "Instagram",
  linkedin: "LinkedIn",
  youtube: "YouTube",
  avatar: "Profil resmi",
  select_file: "Dosya seçin",
  browse: "Seç",
  browse_or_drop: "Dosya seçin ya da bu alana sürükleyin",
  id: "ID",
  title: "Başlık",
  slug: "Slug",
  type: "Tip",
  select_type: "Tip seçin",
  status: "Durum",
  select_status: "Durum seçin",
  content: "İçerik",
  picture: "Resim",
  pictures: "Resimler",
  only_new_added_notice: "Sadece yenileri eklenecekse kullanın",
  description: "Tanım",
  summary: "Giriş Metni",
  keywords: "Anahtar kelimeler",
  actions: "Eylemler",
  update_password_notice: "Şifre güncellenmeyecekse bu alanları boş bırakın",
  leave_blank_if_not_updated: "Güncellenmeyecekse boş bırakın",
  created_at: "Oluşturma tarihi",
  updated_at: "Güncellenme tarihi",
  activated_at: "Doğrulama tarihi",
  all: "Tümü",
  faqs_type: {
    user: "Girişimciler",
    mentor: "Rehberler",
    help: "Yardım",
  },
  experience: "Deneyim",
  experience_years: "{{param}} yıl",
  education_status: "Eğitim durumu",
  select_education_status: "Eğitim durumu seçin",
  education_items: {
    high_school: "Lise",
    college: "Yüksekokul",
    bachelor_degree: "Lisans",
    master_degree: "Yüksek Lisans",
    phd: "Doktora",
    other: "Diğer",
  },
  education_detail: "Eğitim detayı",
  languages: "Diller",
  language_items: {
    tr: "Türkçe",
    en: "İngilizce",
    de: "Almanca",
    fr: "Fransızca",
    ar: "Arapça",
  },
  evaluate: "Değerlendir",
  email_address_is_not_valid: "E-posta adresi doğrulanmamış",
  evaluate_success: "Değerlendirme başarılı şekilde yapıldı",
  date_range: "Tarih aralığı",
  filter: "Filtrele",
  not_found: "Bulunamadı",
  content_not_found: "İçerik bulunamadı.",
  record_not_found: "Kayıt bulunmuyor",
  not_authorized_this_area: "Bu alanı kullanma yetkiniz bulunmuyor!",
  confirmation_required: "Onay gerekli",
  delete_confirmation_message: "Silmek istediğinize emin misiniz?",
  yes: "Evet",
  no: "Hayır",
  ok: "Tamam",
  validation: {
    invalid_url:" Geçerli bir link girmelisiniz",
    you_must_enter: "{{name}} girmelisiniz",
    you_must_choose: "{{name}} seçmelisiniz",
    invalid: "{{name}} geçersiz",
    passwords_must_match: "Şifreler eşleşmiyor",
    min: "En az {{min}} karakter olmalı",
    max: "En fazla {{max}} karakter olmalı",
    max_choose: "En fazla {{max}} adet {{name}} seçebilirsiniz",
    min_and_max: "En az {{min}}, en fazla {{max}} karakter olmalı",
  },
  This_user_belongs_to_an_active_team: "Bu kullanıcının aktif bir takımı bulunmaktadır",
  featured_mentors: "Rehber Listeleme",
  featured_on: "Ana Sayfa Göster",
  featured_off: "Ana Sayfada Gösterme",
  year: "Yıl",
  ip: "Ip",
  service: "Servis",
  method: "Method",
  nace: "Nace kodu",
  code: "Kod",
  endpoint: "Endpoint",
  methods_type: {
    post: "Post",
    get: "Get",
    patch: "Patch",
    delete: "Delete",
    put: "Put",
  },
  logs_types: {
    info: "Bilgi",
    debug: "Hata Ayıklama (Debug)",
    error: "Hata",
  },
  reset: "Sıfırla",
  support: "Destek",
  message: "Mesaj",
  support_is_read: {
    true: "Okunmuş",
    false: "Okunmamış",
  },
  sender: "Gönderen",
  enter_name: "İsim girin",
  is_read_status: "Okunmu Durumu",
  work_title: "Kıdem",
  full_name: "Ad Soyad",
  active: "Aktif",
  passive: "Pasif",
  add_user_modal_title_mentor: "Rehber Ekle",
  add_user_modal_title_user: "Danışan Ekle",
  clients: "Danışanlar",
  client_s:"Danışan(lar)",
  select_client: "Danışanlar",
  mentors: "Rehberler",
  process_manager: "Süreç Yöneticisi",
  project_manager: "Proje Yöneticisi",
  classification: "Sınıflandırma",
  you_should_process_manager_or_project_manager: "Proje yöneticisi veya Süreç yöneticisini giriş yapmalısınız",
};

export default translation;
