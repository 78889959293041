import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Form, InputGroup } from "react-bootstrap";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/all";
import { PaginationType } from "../../store/types";

type PaginationPropsType = {
  pagination: PaginationType;
  extraParams?: Array<string>;
};

export const Pagination = (props: PaginationPropsType) => {
  const { pages, prev, next } = props.pagination;
  const location = useLocation();
  const history = useHistory();

  const queryString = new URLSearchParams(useLocation().search);
  const page = Number(queryString.get("page") ? queryString.get("page") : 1);

  let extraParams: string = "";
  if (props.extraParams) {
    const params: Array<any> = [];
    props.extraParams.forEach((param) => {
      if (queryString.get(param)) {
        params.push(param + "=" + String(queryString.get(param)));
      }
    });

    extraParams = params.length > 0 ? "&" + params.join("&") : "";
  }

  const handlePrev = () => {
    history.push(`${location.pathname}?page=${String(page - 1) + extraParams}`);
  };

  const handleNext = () => {
    history.push(`${location.pathname}?page=${String(page + 1) + extraParams}`);
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    history.push(`${location.pathname}?page=${String(event.target.value) + extraParams}`);
  };

  return (
    <InputGroup className="content-pagination">
      <InputGroup.Prepend>
        <Button
          variant="outline-secondary"
          disabled={prev === null}
          onClick={handlePrev}
          size="sm"
          className="with-icon"
        >
          <RiArrowLeftSLine />
        </Button>
      </InputGroup.Prepend>
      <Form.Control
        as="select"
        size="sm"
        custom
        defaultValue={page}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => handleChange(event)}
      >
        {[...Array(pages)].map((_, key) => (
          <option value={key + 1} key={key}>
            {key + 1}
          </option>
        ))}
      </Form.Control>
      <InputGroup.Append>
        <Button
          variant="outline-secondary"
          disabled={next === null}
          onClick={handleNext}
          size="sm"
          className="with-icon"
        >
          <RiArrowRightSLine />
        </Button>
      </InputGroup.Append>
    </InputGroup>
  );
};
