import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { MainLayout } from "../../layouts";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { RiArrowLeftSLine, RiCheckFill } from "react-icons/all";
import { useForm, Controller } from "react-hook-form";
import { RootState } from "../../../store/reducers";
import { IContractParams, IContractState } from "../../../store/types";
import { AlertDefaultState, AlertStateType } from "../../../types";
import { FormAlert } from "../../partials";
import actions from "../../../store/actions";
import { WysiwygEditor } from "../../partials";
import history from "../../../utils/history";

export const Create = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const { register, control, handleSubmit, errors, reset } = useForm<IContractParams>();
  const onSubmit = async (data: IContractParams) => {
    dispatch(await actions.createContract(data));
  };

  const { isLoading, response, error } = useSelector<RootState, IContractState>(
    (state: RootState) => state.createContract
  );

  const [formDisable, setFormDisable] = useState<boolean>(false);
  const [formAlert, setFormAlert] = useState<AlertStateType>(AlertDefaultState);

  const wysiwygRef = useRef();

  useEffect(() => {
    setFormDisable(isLoading);

    if (error !== null) {
      if (error.response.status === 400) {
        setFormAlert({
          variant: "danger",
          show: true,
          messages: error.response.data.messages,
        });
      } else {
        addToast(t("unknown_error"), {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }

    if (response) {
      addToast(t("created_with_param", { param: t("contract") }), {
        appearance: "success",
        autoDismiss: true,
      });

      reset();

      dispatch({ type: "CREATE_CONTRACT_RESET" });
      history.push(`/contracts/${response.data.id}`);
    }
  }, [isLoading, response, addToast, t, error, reset, dispatch]);

  return (
    <MainLayout>
      <FormAlert variant={formAlert.variant} show={formAlert.show} to={formAlert.to}>
        {formAlert.messages.map((message, key) => {
          return <p key={key}>{message}</p>;
        })}
      </FormAlert>

      <Card className={formDisable ? "loading-block" : ""}>
        <Card.Header as="h6">{t("create")}</Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
              <Form.Group as={Col} md="12" controlId="contract-title">
                <Form.Label>{t("title")}:</Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  ref={register({
                    required: {
                      value: true,
                      message: t("validation.you_must_enter", { name: t("title") }),
                    },
                  })}
                  placeholder={t("title")}
                  defaultValue=""
                  autoFocus
                  isInvalid={!!errors.title}
                />
                <Form.Control.Feedback type="invalid">{errors.title?.message}</Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <hr />
            <Form.Row>
              <Form.Group as={Col} md="12" controlId="contract-content">
                <Form.Label>{t("content")}:</Form.Label>
                <Controller
                  control={control}
                  name="content"
                  ref={register({
                    required: {
                      value: true,
                      message: t("validation.you_must_enter", { name: t("content") }),
                    },
                  })}
                  placeholder={t("content")}
                  defaultValue=""
                  render={({ onChange }) => {
                    return (
                      <WysiwygEditor
                        defaultValue=""
                        onChange={(content: string) => onChange(content)}
                        ref={wysiwygRef}
                      />
                    );
                  }}
                  isInvalid={!!errors.content}
                />
                <Form.Control.Feedback type="invalid">{errors.content?.message}</Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <hr />
            <Form.Row>
              <Form.Group as={Col} md="4" controlId="contract-description">
                <Form.Label>{t("description")}:</Form.Label>
                <Form.Control
                  type="text"
                  as="textarea"
                  name="description"
                  ref={register}
                  placeholder={t("description")}
                  defaultValue=""
                />
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="contract-keywords">
                <Form.Label>{t("keywords")}:</Form.Label>
                <Form.Control
                  type="text"
                  as="textarea"
                  name="keywords"
                  ref={register}
                  placeholder={t("keywords")}
                  defaultValue=""
                />
              </Form.Group>
            </Form.Row>
          </Form>
        </Card.Body>
        <Card.Footer>
          <Row>
            <Col xs="6" className="text-left">
              <Button as={NavLink} to="/contracts" variant="secondary" size="sm" className="with-icon">
                <RiArrowLeftSLine />
                <span>{t("list")}</span>
              </Button>
            </Col>
            <Col xs="6" className="text-right">
              <Button variant="primary" size="sm" className="with-icon" onClick={handleSubmit(onSubmit)}>
                <RiCheckFill />
                <span>{t("save")}</span>
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </MainLayout>
  );
};

export default Create;
