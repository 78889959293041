import React, { useState, useEffect, useCallback } from 'react';
import { Button, Card, Col, Form, Row, Accordion, Modal, ButtonGroup } from 'react-bootstrap';
import { RiArrowLeftSLine, RiCheckFill, RiDeleteBin6Line, RiAddBoxLine, RiEyeLine, RiEditBoxLine } from 'react-icons/ri';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import actions from "../../../store/actions";
import { useToasts } from "react-toast-notifications";
import { DeleteConfirmModal } from "../../partials";
import { AlertDefaultState, AlertStateType } from "../../../types";
import { FormAlert } from "../../partials";
import history from "../../../utils/history";
import { FollowedTaskModal, FollowedTaskDetailModalType, FollowedTaskModalDefaultState } from "../followed-tasks/FollowedTaskModal";
import {
    SessionType,
    SessionFormType,
    ISessionParams,
    ISessionUpdateParams,
    IFollowedTaskCreateParams,
    ISessionsState,
    FollowedTaskType,
    IDefaultState,
    IFollowedTasksState,
    IMentoringProgramState,
} from '../../../store/types';
import { RootState } from '../../../store/reducers';
export type OptionType = {
    value: string;
    label: string;
};
type FollowedTaskAccordionType = {
    [key: string]: boolean;
};

type SessionUpdateModalPropsType = {
    modal: { show: boolean; session: string | null };
    onClose: () => void;
};
export const SessionUpdateModalDefaultState: SessionUpdateDetailModalType = {
    show: false,
    session: null,
};
type RouterParamsType = {
    id: string;
};

export type SessionUpdateDetailModalType = {
    show: boolean;
    session: string | null;
};

export const SessionUpdate = (props: SessionUpdateModalPropsType) => {
    const [show, setShow] = useState<boolean>(props.modal.show);
    const { t } = useTranslation();
    const { id } = useParams<RouterParamsType>();
    const dispatch = useDispatch();// eslint-disable-next-line
    const [def_title, setTitle] = useState<string>("");// eslint-disable-next-line
    const [def_location, setLocation] = useState<string>("");// eslint-disable-next-line
    const [def_goals, setGoals] = useState<string>("");// eslint-disable-next-line
    const [def_preparations, setPreparations] = useState<string>("");// eslint-disable-next-line
    const [def_agenda, setAgenda] = useState<string>("");// eslint-disable-next-line
    const [def_documents, setDocuments] = useState<string>("");// eslint-disable-next-line
    const [followedTaskModal, setFollowedTaskModal] = useState<FollowedTaskDetailModalType>(FollowedTaskModalDefaultState);
    const { addToast } = useToasts();
    const [formDisable, setFormDisable] = useState<boolean>(false);
    const [formAlert, setFormAlert] = useState<AlertStateType>(AlertDefaultState);
    const [taskIndex, setTaskIndex] = useState<number | null>(null);
    const { modal } = props;
    const [sessions, setSessions] = useState(modal.session);
    const [session, setSession] = useState<SessionFormType>({
        title: "",
        location: "",
        goals: "",
        preparations: "",
        agenda: "",
        documents: "",
        followed_tasks: [],
    });

    const [formData, setFormData] = useState({
        title: '',
        steps: ''
    });
    const { response: ProgramResponse, isLoading: ProgramLoading, error: ProgramError } = useSelector<RootState, IMentoringProgramState>((state: RootState) => state.mentoringProgram);
    const { response, isLoading, error } = useSelector<RootState, ISessionsState>((state: RootState) => state.updateSession);
    const [sessionProps, setSessionProps] = useState<string | null>(props.modal.session);

    const [newTaskData, setNewTaskData] = useState<{ title: string; steps: string }>({ title: '', steps: '' });
    const [sessionData, setSessionData] = useState<SessionType | null>(null);
    const [deleteItemTask, setDeleteItemTask] = useState<string | null>(null);
    useEffect(() => {
        if (ProgramResponse && modal.session) {
            const { sessions } = ProgramResponse.data;
            const flattenedSessions = Array.isArray(sessions[0]) ? sessions.flat() : sessions;
            const matchedSession = flattenedSessions.find(session => session && session.id === modal.session);
            if (matchedSession) {
                const updatedSession: SessionType = {
                    id: matchedSession.id,
                    title: matchedSession.title,
                    location: matchedSession.location,
                    goals: matchedSession.goals,
                    preparations: matchedSession.preparations,
                    agenda: matchedSession.agenda,
                    documents: matchedSession.documents,
                    followed_tasks: matchedSession.followed_tasks,
                    created_at: matchedSession.created_at,
                    updated_at: matchedSession.updated_at,
                    deleted_at: matchedSession.deleted_at,
                };
                setSessionData(updatedSession);
            } else {
            }
        } else {
        }
    }, [modal.session, ProgramResponse]);

    useEffect(() => {
        if (deleteItemTask !== null) {
            const index = parseInt(deleteItemTask, 10);
            setTaskIndex(index);
        }
    }, [deleteItemTask]);
    const onDeleteConfirmTasks = async () => {
        const taskId = deleteItemTask;
        const sid = sessionData?.id;
        const followedTasks = sessionData?.followed_tasks;

        if (!sid || !followedTasks) {
            console.error('Invalid session ID (sid) or Followed Tasks.');
            return;
        }

        const task = followedTasks.find(task => task.id.toString() === taskId);
        if (!task) {
            console.error('Followed Task or its ID (fid) is missing.');
            return;
        }

        const fid = task.id;

        try {
            dispatch(actions.deleteFollowedTask(id, sid, fid));
        } catch (error) {
            console.error('Error deleting Followed Task:', error);
        }
    };



    const onSubmitFollowedTask = async (followed_task_index: number) => {
        if (!sessionData || !sessionData.id) {
            console.error('Session data is invalid.');
            return;
        }

        const newData: IFollowedTaskCreateParams = {
            title: newTaskData.title,
            steps: newTaskData.steps,
        };

        try {
            dispatch(actions.createFollowedTask(id, sessionData.id, newData));
            removeFollowedTaskItem(followed_task_index);
        } catch (error) {
            console.error('Error creating followed task:', error);
        }
    };


    const onUpdateSubmit = useCallback(async (taskId: string, formData: { title: string, steps: string }) => {
        const sid = sessionData?.id;

        if (sid) {
            const followedTaskUpdate: Partial<FollowedTaskType> = {};

            if (formData.title.trim() !== "") {
                followedTaskUpdate.title = formData.title;
            }
            if (formData.steps.trim() !== "") {
                followedTaskUpdate.steps = formData.steps;
            }
            try {
                dispatch(actions.updateFollowedTask(id, sid, taskId, followedTaskUpdate));
                setFollowedTaskAccordion({});
                resetFormData();
            } catch (error) {
                console.error('Error updating followed task:', error);
            }
        } else {
            console.error('Session ID (sid) or Followed Task ID (fid) is missing.');
        }
    }, [dispatch, id, sessionProps]);

    const resetFormData = () => {
        setFormData({
            title: '',
            steps: '',
        });
    }

    const onSubmit = async (session: SessionFormType) => {
        if (!props.modal.session) {
            console.error("Modal data is null or undefined.");
            return;
        }
        const sessionData: ISessionUpdateParams = {
            title: session.title || '',
            location: session.location !== null ? session.location : null,
            goals: session.goals || '',
            preparations: session.preparations !== null ? session.preparations : null,
            agenda: session.agenda !== null ? session.agenda : null,
            documents: session.documents !== null ? session.documents : null,
        };

        const sid = props.modal.session;
        if (!sid) {
            console.error("Session ID is null or undefined.");
            return;
        }
        await dispatch(actions.updateSession(id, sid, sessionData));
        props.onClose();
        history.push(`/mentoring_programs/${id}`);

    };
    useEffect(() => {
        setShow(props.modal.show);
    }, [props.modal.show]);


    const { register, handleSubmit, errors } = useForm<ISessionParams>();
    type MentorSessionID = { mentorID: string, sessionID: string, }
    const [deleteItem, setDeleteItem] = useState<MentorSessionID | null>(null);
    const onDeleteConfirm = async () => {
        if (deleteItem) {
            dispatch(await actions.deleteSession(deleteItem.mentorID, deleteItem.sessionID));
        }
    };

    useEffect(() => {
        setFormDisable(isLoading);

        if (error !== null) {
            if (error.response.status === 400) {
                setFormAlert({
                    variant: "danger",
                    show: true,
                    messages: error.response.data.messages,
                });
            } else {
                addToast(t("unknown_error"), {
                    appearance: "error",
                    autoDismiss: true,
                });
            }
        }

        if (response) {
            addToast(t("updated_with_param", { param: t("session") }), {
                appearance: "success",
                autoDismiss: true,
            });

            dispatch({ type: "UPDATE_SESSION_RESET" });
            history.push(`/mentoring_programs/${id}`);
        }
    }, [isLoading, addToast, t, id, response, error, dispatch]);
    const addFollowedTaskItem = () => {
        const newFollowedTask = {
            title: "",
            steps: "",
        };

        const updatedFollowedTasks = session.followed_tasks ? [...session.followed_tasks, newFollowedTask] : [newFollowedTask];

        setSession({
            ...session,
            followed_tasks: updatedFollowedTasks,
        });
    };
    const removeFollowedTaskItem = (taskIndex: number) => {
        const followedTasks = session.followed_tasks ? [...session.followed_tasks] : [];

        if (taskIndex > -1 && taskIndex < followedTasks.length) {
            followedTasks.splice(taskIndex, 1);

            setSession((prevSession) => ({
                ...prevSession,
                followed_tasks: followedTasks,
            }));
        } else {
            console.error("Invalid task index provided or followed_tasks is null.");
        }
    };

    useEffect(() => {
        setSessionProps(props.modal.session);
    }, [props.modal.session]);
    const handleHide = () => {
        setShow(false);
    };
    const handleClose = (): void => {
        handleHide();
        props.onClose();
    };

    const openFollowedTaskModal = (followedTask: FollowedTaskType) => {
        setFollowedTaskModal({
            show: true,
            session: followedTask,
        });
    };

    const closeFollowedTaskModal = () => {
        setFollowedTaskModal({ show: false, session: null });
    };





    const [followedTaskAccordion, setFollowedTaskAccordion] = useState<FollowedTaskAccordionType>({});
    const toggleAccordion = (key: string) => {
        setFollowedTaskAccordion((prevState) => ({
            ...prevState,
            [key]: !prevState[key],
        }));
    };
    const handleDeleteClick = (taskId: string) => {
        setDeleteItemTask(taskId);
    };

    return (
        <Modal centered
            show={show}
            onHide={handleClose}
            animation={false}
            dialogClassName="ydc"
        >
            <FormAlert variant={formAlert.variant} show={formAlert.show} to={formAlert.to}>
                {formAlert.messages.map((message, key: number) => (
                    <p key={key}>{message}</p>
                ))}
            </FormAlert>
            <Modal.Header closeButton>
                <Modal.Title as="h6">{sessionData?.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={formDisable ? "loading-block" : ""} style={{ border: '4px solid #ced4da', borderRadius: '1rem', padding: 10 }}>
                    <div>
                        <Card style={{ marginBottom: 40, border: '4px solid #ced4da', borderRadius: '1rem' }}>
                            <Card.Header as="h6">
                            </Card.Header>
                            <Card.Body>
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <Form.Row>
                                        <DeleteConfirmModal
                                            show={deleteItem !== null}
                                            onClose={() => setDeleteItem(null)}
                                            onConfirm={onDeleteConfirm}
                                        />
                                        <Col xs="12" className="text-right">
                                            <Button variant="primary" size="sm" className="with-icon" onClick={handleSubmit(onSubmit)}>
                                                <RiCheckFill />
                                                <span>{t("save")}</span>
                                            </Button>

                                        </Col>
                                    </Form.Row>
                                    <hr />
                                    <Form.Row>
                                        <Form.Group as={Col} sm="6" md="3" lg="3" controlId="program-sessions">
                                            <Form.Label>{t("session_title")}:</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="title"
                                                ref={register({
                                                    required: {
                                                        value: true,
                                                        message: t("validation.you_must_enter", { name: t("sessions") }),
                                                    },
                                                })}
                                                placeholder={t("sessions")}
                                                defaultValue={sessionData?.title}
                                                onChange={(event) => {
                                                    setTitle(event.target.value);
                                                }}
                                                isInvalid={!!errors.title}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.title && errors.title.message}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} sm="6" md="3" lg="3">
                                            <Form.Label>{t("session_location")}:</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="location"
                                                ref={register}
                                                placeholder={t("session_location")}
                                                defaultValue={sessionData?.location || ''}
                                                onChange={(event) => {
                                                    setLocation(event.target.value);
                                                }}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} sm="6" md="4" lg="4" controlId="program-documents">
                                            <Form.Label>{t("session_documents")}:</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={5}
                                                type="text"
                                                name="documents"
                                                ref={register}
                                                placeholder={t("session_documents")}
                                                defaultValue={sessionData?.documents || ''}
                                                onChange={(event) => {
                                                    setDocuments(event.target.value);
                                                }}

                                            />

                                        </Form.Group>
                                    </Form.Row>
                                    <hr />
                                    <Form.Row>
                                        <Form.Group as={Col} sm="6" md="4" lg="4" controlId="program-Goals">
                                            <Form.Label>{t("session_goals")}:</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={5}
                                                type="text"
                                                name="goals"
                                                ref={register({
                                                    required: {
                                                        value: true,
                                                        message: t("validation.you_must_enter", { name: t("goals") }),
                                                    },
                                                })}
                                                placeholder={t("session_goals")}
                                                defaultValue={sessionData?.goals}
                                                onChange={(event) => {
                                                    setGoals(event.target.value);
                                                }}
                                                isInvalid={!!errors.goals}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.goals && errors.goals.message}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} sm="6" md="4" lg="4" controlId="program-preparations">
                                            <Form.Label>{t("session_preparations")}:</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={5}
                                                type="text"
                                                name="preparations"
                                                ref={register}
                                                placeholder={t("session_preparations")}
                                                defaultValue={sessionData?.preparations || ''}
                                                onChange={(event) => {
                                                    setPreparations(event.target.value);
                                                }}

                                            />

                                        </Form.Group>
                                        <Form.Group as={Col} sm="6" md="4" lg="4" controlId="program-agenda">
                                            <Form.Label>{t("session_agenda")}:</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={5}
                                                type="text"
                                                name="agenda"
                                                ref={register}
                                                placeholder={t("session_agenda")}
                                                defaultValue={sessionData?.agenda || ''}
                                                onChange={(event) => {
                                                    setAgenda(event.target.value);
                                                }}

                                            />

                                        </Form.Group>
                                    </Form.Row>
                                    <hr />

                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-12">
                                            {sessionData?.followed_tasks?.map((followedTask, taskIndex) => (
                                                <div key={taskIndex} className="row mb-2">
                                                    <div className="col-8">
                                                        <a href="##" onClick={() => openFollowedTaskModal(followedTask)}>
                                                            {followedTask.title}
                                                        </a>
                                                    </div>
                                                    <DeleteConfirmModal
                                                        show={deleteItemTask !== null}
                                                        onClose={() => setDeleteItemTask(null)}
                                                        onConfirm={() => onDeleteConfirmTasks()}
                                                    />
                                                    <div className="col-4" style={{ display: "flex", justifyContent: "end" }}>
                                                        <ButtonGroup className="float-end">
                                                            <Button
                                                                onClick={() => openFollowedTaskModal(followedTask)}
                                                                variant="outline-primary"
                                                                size="sm"
                                                                title="Show"
                                                            >
                                                                <RiEyeLine />
                                                            </Button>
                                                            <Button
                                                                onClick={() => toggleAccordion(`accordion-${taskIndex}`)}
                                                                variant="outline-primary"
                                                                size="sm"
                                                                title="Edit"
                                                            >
                                                                <RiEditBoxLine />
                                                            </Button>
                                                            <Button
                                                                variant="danger"
                                                                size="sm"
                                                                className="with-icon"
                                                                title="Delete"
                                                                onClick={() => handleDeleteClick(followedTask.id)}
                                                            >
                                                                <RiDeleteBin6Line />
                                                            </Button>
                                                        </ButtonGroup>
                                                    </div>
                                                    {followedTaskAccordion[`accordion-${taskIndex}`] && (
                                                        <div className="col-12 mt-2">
                                                            <hr />
                                                            <Form.Row>
                                                                <Form.Group as={Col} sm="2" md="2" lg="2" controlId={`formFollowedTaskSteps_${taskIndex}`}>
                                                                    <Form.Label>{t("task_order")} :</Form.Label>
                                                                    <Form.Control
                                                                        type="number"
                                                                        name={`followed_tasks[${taskIndex}].steps`}
                                                                        ref={register({
                                                                            required: {
                                                                                value: true,
                                                                                message: t("validation.you_must_enter", { name: t("task_order") }),
                                                                            },
                                                                        })}
                                                                        placeholder={t("followed_task_task_order")}
                                                                        onChange={(e) => {
                                                                            const newSteps = e.target.value;
                                                                            setFormData((prevFormData) => ({
                                                                                ...prevFormData,
                                                                                steps: newSteps
                                                                            }));
                                                                        }}
                                                                        defaultValue={followedTask.steps}
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group as={Col} sm="4" md="4" lg="4" controlId={`formFollowedTaskTitle_${taskIndex}`}>
                                                                    <Form.Label>{t("followed_task_title")} :</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name={`followed_tasks[${taskIndex}].title`}
                                                                        ref={register({
                                                                            required: {
                                                                                value: true,
                                                                                message: t("validation.you_must_enter", { name: t("title") }),
                                                                            },
                                                                        })}
                                                                        placeholder={t("followed_task_title")}
                                                                        onChange={(e) => {
                                                                            const newTitle = e.target.value;
                                                                            setFormData((prevFormData) => ({
                                                                                ...prevFormData,
                                                                                title: newTitle
                                                                            }));
                                                                        }}
                                                                        defaultValue={followedTask.title}
                                                                        isInvalid={!!error?.followed_tasks?.[taskIndex]?.title}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {error?.followed_tasks?.[taskIndex]?.title?.message}
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                                <Form.Group as={Col} sm="4" md="4" lg="4" className="d-flex justify-content-end align-items-center" controlId={`formFollowedTaskSubmit_${taskIndex}`}>
                                                                    <Button
                                                                        variant="primary"
                                                                        size="sm"
                                                                        className="with-icon"
                                                                        onClick={() => onUpdateSubmit(followedTask.id, formData)}
                                                                        style={{ height: '30px' }}
                                                                    >
                                                                        <RiCheckFill />
                                                                        <span>{t("save")}</span>
                                                                    </Button>
                                                                </Form.Group>
                                                            </Form.Row>
                                                            <hr />
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                            <Form.Row>
                                                <Form.Group as={Col} md="12">
                                                    <Form.Label htmlFor="followedTask-selector">
                                                        {t("followed_tasks")}:
                                                        <Button
                                                            variant="outline-success"
                                                            size="sm"
                                                            className="with-icon ml-1"
                                                            title={t("add")}
                                                            onClick={() => addFollowedTaskItem()}
                                                        >
                                                            <RiAddBoxLine />
                                                            <span>{t("add")}</span>
                                                        </Button>
                                                    </Form.Label>
                                                </Form.Group>
                                            </Form.Row>
                                            {session.followed_tasks && session.followed_tasks.map((followed_task, followed_task_index) => (
                                                <div key={followed_task_index}>
                                                    <Accordion defaultActiveKey="0" style={{ border: '2px solid #ced4da', borderRadius: '1rem' }}>
                                                        <Accordion.Toggle as={Card.Header} eventKey={followed_task_index.toString()}>
                                                            {t("New_Followed_Task")}
                                                        </Accordion.Toggle>
                                                        <Accordion.Collapse eventKey={followed_task_index.toString()}>
                                                            <Card.Body>
                                                                <Form.Row>
                                                                    <Form.Group as={Col} sm="2" md="2" lg="2">
                                                                        <Form.Label>{t("task_order")}:</Form.Label>
                                                                        <Form.Control
                                                                            type="number"
                                                                            name={`followed_tasks.${followed_task_index}.steps`}
                                                                            ref={register({
                                                                                required: {
                                                                                    value: true,
                                                                                    message: t("validation.you_must_enter", { name: t("task_order") }),
                                                                                },
                                                                            })}
                                                                            placeholder={t("task_order")}
                                                                            defaultValue=""
                                                                            onChange={(e) => setNewTaskData({ ...newTaskData, steps: e.target.value })}
                                                                        />
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} sm="6" md="4" lg="4">
                                                                        <Form.Label htmlFor={`title-${followed_task_index}`}>{t("followed_task_title")}:</Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            id={`title-${followed_task_index}`}
                                                                            name={`followed_tasks.${followed_task_index}.title`}
                                                                            ref={register({
                                                                                required: {
                                                                                    value: true,
                                                                                    message: t("validation.you_must_enter", { name: t("title") }),
                                                                                },
                                                                            })}
                                                                            placeholder={t("title")}
                                                                            defaultValue="" autoFocus
                                                                            onChange={(e) => setNewTaskData({ ...newTaskData, title: e.target.value })}
                                                                        />
                                                                    </Form.Group>
                                                                </Form.Row>
                                                                <hr />
                                                                <Form.Row>
                                                                    <Form.Group as={Col} sm="4" md="4" lg="4" className="d-flex justify-content-start align-items-center">
                                                                        <Button
                                                                            variant="outline-danger"
                                                                            size="sm"
                                                                            className="with-icon ml-1"
                                                                            title={t("remove")}
                                                                            onClick={() => removeFollowedTaskItem(followed_task_index)}
                                                                        >
                                                                            <RiDeleteBin6Line />
                                                                            <span>{t("remove_followed_task")}</span>
                                                                        </Button>
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} sm="4" md="4" lg="4" className="d-flex justify-content-end align-items-center">
                                                                        <Button
                                                                            variant="primary"
                                                                            size="sm"
                                                                            className="with-icon"
                                                                            onClick={() => handleSubmit(() => onSubmitFollowedTask(followed_task_index))()}
                                                                            style={{ height: '30px' }}
                                                                        >
                                                                            <RiCheckFill />
                                                                            <span>{t("save")}</span>
                                                                        </Button>
                                                                    </Form.Group>
                                                                </Form.Row>
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Accordion>
                                                    <hr />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Form>
                            </Card.Body>
                            <FollowedTaskModal modal={followedTaskModal} onClose={closeFollowedTaskModal} />
                            <Card.Footer>
                                <Row>
                                    <Col xs="6" className="text-left">
                                        <Button as={NavLink} to="/mentoring_programs" variant="secondary" size="sm" className="with-icon">
                                            <RiArrowLeftSLine />
                                            <span>{t("list")}</span>
                                        </Button>
                                    </Col>

                                </Row>
                            </Card.Footer>
                        </Card>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t("Close")}
                </Button>
            </Modal.Footer>
        </Modal>
    );

};
