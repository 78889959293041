import { Dispatch } from "redux";
import { AdminApiClient } from "../../utils/http-client";
import { IUserApplicationPaginationParams, IUserApplicationEvaluateParams } from "../types";

const adminApiClient = AdminApiClient.getInstance();

export const userApplications = (queryParams?: IUserApplicationPaginationParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "USER_APPLICATIONS_START" });
  await adminApiClient
    .userApplications(queryParams)
    .then((response) => {
      dispatch({ type: "USER_APPLICATIONS_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "USER_APPLICATIONS_FAILURE", payload: error }));
};

export const userApplication = (id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: "USER_APPLICATION_START" });
  await adminApiClient
    .userApplication(id)
    .then((response) => {
      dispatch({ type: "USER_APPLICATION_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "USER_APPLICATION_FAILURE", payload: error }));
};

export const evaluateUserApplication =
  (id: string, params: IUserApplicationEvaluateParams) => async (dispatch: Dispatch) => {
    dispatch({ type: "EVALUATE_USER_APPLICATION_START" });
    await adminApiClient
      .evaluateUserApplication(id, params)
      .then((response) => {
        dispatch({ type: "EVALUATE_USER_APPLICATION_SUCCESS", payload: response });
      })
      .catch((error) => dispatch({ type: "EVALUATE_USER_APPLICATION_FAILURE", payload: error }));
  };

export const deleteUserApplication = (id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: "DELETE_USER_APPLICATION_START" });
  await adminApiClient
    .deleteUserApplication(id)
    .then((response) => {
      dispatch({ type: "DELETE_USER_APPLICATION_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "DELETE_USER_APPLICATION_FAILURE", payload: error }));
};
