import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { MainLayout } from "../../layouts";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { NavLink, useParams } from "react-router-dom";
import { RiArrowLeftSLine, RiCheckFill } from "react-icons/all";
import { useForm } from "react-hook-form";
import { RootState } from "../../../store/reducers";
import { ICategoryParams, ICategoryState } from "../../../store/types";
import { AlertDefaultState, AlertStateType } from "../../../types";
import { FormAlert, LoadingIndicator } from "../../partials";
import actions from "../../../store/actions";
import history from "../../../utils/history";

type RouterParamsType = {
  id: string;
};

export const Edit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { id } = useParams<RouterParamsType>();

  const { register, handleSubmit, errors, reset } = useForm<ICategoryParams>();
  const onSubmit = async (data: ICategoryParams) => {
    dispatch(await actions.updateCategory(id, data));
  };

  const {
    isLoading: categoryIsLoading,
    response: categoryResponse,
    error: categoryError,
  } = useSelector<RootState, ICategoryState>((state: RootState) => state.category);

  const { isLoading, response, error } = useSelector<RootState, ICategoryState>(
    (state: RootState) => state.updateCategory
  );

  const [formDisable, setFormDisable] = useState<boolean>(false);
  const [formAlert, setFormAlert] = useState<AlertStateType>(AlertDefaultState);

  useEffect(() => {
    if (categoryError?.response) {
      if (categoryError.response.status === 400 || categoryError.response.status === 404) {
        dispatch({ type: "CATEGORY_RESET" });
        history.push("/404");
      }
    } else {
      dispatch(actions.category(id));
    }
  }, [dispatch, id, categoryError]);

  useEffect(() => {
    setFormDisable(isLoading);

    if (error !== null) {
      if (error.response.status === 400) {
        setFormAlert({
          variant: "danger",
          show: true,
          messages: error.response.data.messages,
        });
      } else {
        addToast(t("unknown_error"), {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }

    if (response) {
      addToast(t("updated_with_param", { param: t("category") }), {
        appearance: "success",
        autoDismiss: true,
      });

      dispatch({ type: "UPDATE_CATEGORY_RESET" });
      history.push(`/sector-management/categories/${id}`);
    }
  }, [isLoading, addToast, t, id, response, error, reset, dispatch]);

  return (
    <MainLayout>
      <LoadingIndicator show={categoryIsLoading} />

      {categoryResponse && (
        <>
          <FormAlert variant={formAlert.variant} show={formAlert.show} to={formAlert.to}>
            {formAlert.messages.map((message, key) => {
              return <p key={key}>{message}</p>;
            })}
          </FormAlert>

          <Card className={formDisable ? "loading-block" : ""}>
            <Card.Header as="h6">{t("edit")}</Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Row>
                  <Form.Group as={Col} md="4" controlId="category-title">
                    <Form.Label>{t("title")}:</Form.Label>
                    <Form.Control
                      type="text"
                      name="title"
                      ref={register({
                        required: {
                          value: true,
                          message: t("validation.you_must_enter", { name: t("title") }),
                        },
                      })}
                      placeholder={t("title")}
                      defaultValue={categoryResponse.data.title}
                      autoFocus
                      isInvalid={!!errors.title}
                    />
                    <Form.Control.Feedback type="invalid">{errors.title?.message}</Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <hr />
                <Form.Row>
                  <Form.Group as={Col} md="4" controlId="category-description">
                    <Form.Label>{t("description")}:</Form.Label>
                    <Form.Control
                      type="text"
                      as="textarea"
                      name="description"
                      ref={register}
                      placeholder={t("description")}
                      defaultValue={categoryResponse.data.description ? categoryResponse.data.description : ""}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="4" controlId="category-keywords">
                    <Form.Label>{t("keywords")}:</Form.Label>
                    <Form.Control
                      type="text"
                      as="textarea"
                      name="keywords"
                      ref={register}
                      placeholder={t("keywords")}
                      defaultValue={categoryResponse.data.keywords ? categoryResponse.data.keywords : ""}
                    />
                  </Form.Group>
                </Form.Row>
              </Form>
            </Card.Body>
            <Card.Footer>
              <Row>
                <Col xs="6" className="text-left">
                  <Button
                    as={NavLink}
                    to="/sector-management/categories"
                    variant="secondary"
                    size="sm"
                    className="with-icon"
                  >
                    <RiArrowLeftSLine />
                    <span>{t("list")}</span>
                  </Button>
                </Col>
                <Col xs="6" className="text-right">
                  <Button variant="primary" size="sm" className="with-icon" onClick={handleSubmit(onSubmit)}>
                    <RiCheckFill />
                    <span>{t("save")}</span>
                  </Button>
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        </>
      )}
    </MainLayout>
  );
};

export default Edit;
