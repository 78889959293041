import { Dispatch } from "redux";
import { AdminApiClient } from "../../utils/http-client";
import { IFaqPaginationParams, IFaqParams } from "../types";

const adminApiClient = AdminApiClient.getInstance();

export const faqs = (queryParams?: IFaqPaginationParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "FAQS_START" });
  await adminApiClient
    .faqs(queryParams)
    .then((response) => {
      dispatch({ type: "FAQS_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "FAQS_FAILURE", payload: error }));
};

export const faq = (id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: "FAQ_START" });
  await adminApiClient
    .faq(id)
    .then((response) => {
      dispatch({ type: "FAQ_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "FAQ_FAILURE", payload: error }));
};

export const createFaq = (params: IFaqParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "CREATE_FAQ_START" });
  await adminApiClient
    .createFaq(params)
    .then((response) => {
      dispatch({ type: "CREATE_FAQ_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "CREATE_FAQ_FAILURE", payload: error }));
};

export const updateFaq = (id: string, params: IFaqParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "UPDATE_FAQ_START" });
  await adminApiClient
    .updateFaq(id, params)
    .then((response) => {
      dispatch({ type: "UPDATE_FAQ_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "UPDATE_FAQ_FAILURE", payload: error }));
};

export const deleteFaq = (id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: "DELETE_FAQ_START" });
  await adminApiClient
    .deleteFaq(id)
    .then((response) => {
      dispatch({ type: "DELETE_FAQ_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "DELETE_FAQ_FAILURE", payload: error }));
};
