import React, { useEffect, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RiEyeLine } from "react-icons/all";

type FormAlertPropsType = {
  variant: string;
  show: boolean;
  dismissible?: boolean;
  heading?: string | null;
  children?: React.ReactNode;
  to?: string | null;
  onClose?: Function;
};

const defaultProps: FormAlertPropsType = {
  variant: "",
  show: false,
  dismissible: true,
  heading: null,
  children: null,
  to: null,
};

export const FormAlert = (props: FormAlertPropsType) => {
  const { t } = useTranslation();
  const { variant, show, dismissible, heading, children, to, onClose } = { ...defaultProps, ...props };
  const [showAlert, setShowAlert] = useState<boolean>(true);

  useEffect(() => {
    setShowAlert(show);
  }, [show]);

  const handleOnClose = () => {
    setShowAlert(false);

    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      {showAlert && (
        <Alert className="form-alert" variant={variant} onClose={() => handleOnClose()} dismissible={dismissible}>
          {heading && <Alert.Heading>{heading}</Alert.Heading>}
          {children}
          {to && (
            <>
              <hr />
              <div className="d-flex justify-content-start">
                <Button as={NavLink} to={to} variant="outline-success with-icon">
                  <RiEyeLine />
                  <span>{t("show")}</span>
                </Button>
              </div>
            </>
          )}
        </Alert>
      )}
    </>
  );
};
