import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { MainLayout } from "../../layouts";
import { Button, Card, Col, Row } from "react-bootstrap";
import { RiArrowLeftSLine, RiDeleteBin6Line, RiEditBoxLine } from "react-icons/all";
import { RootState } from "../../../store/reducers";
import { IDefaultState, IFaqState } from "../../../store/types";
import { LoadingIndicator, DeleteConfirmModal } from "../../partials";
import Moment from "react-moment";
import actions from "../../../store/actions";

type RouterParamsType = {
  id: string;
};

export const Show = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { addToast } = useToasts();
  const { id } = useParams<RouterParamsType>();

  const { isLoading, response, error } = useSelector<RootState, IFaqState>((state: RootState) => state.faq);

  const {
    isLoading: deleteIsLoading,
    response: deleteResponse,
    error: deleteError,
  } = useSelector<RootState, IDefaultState>((state: RootState) => state.deleteFaq);

  const [deleteItem, setDeleteItem] = useState<string | null>(null);
  const onDeleteConfirm = async () => {
    if (deleteItem) {
      dispatch(await actions.deleteFaq(deleteItem));
    }
  };

  useEffect(() => {
    if (error?.response) {
      if (error.response.status === 400 || error.response.status === 404) {
        dispatch({ type: "FAQ_RESET" });
        history.push("/404");
      }
    } else {
      dispatch(actions.faq(id));
    }
  }, [dispatch, id, error, history]);

  useEffect(() => {
    if (deleteError) {
      addToast(t("unknown_error"), {
        appearance: "error",
        autoDismiss: true,
      });
    }

    if (deleteResponse) {
      addToast(t("deleted_with_param", { param: t("faq") }), {
        appearance: "success",
        autoDismiss: true,
      });

      dispatch({ type: "DELETE_FAQ_RESET" });

      history.push("/faqs");
    }
  }, [deleteResponse, deleteError, addToast, t, dispatch, history]);

  return (
    <MainLayout>
      <LoadingIndicator show={isLoading} />
      {response && (
        <Card className={deleteIsLoading ? "loading-block" : ""}>
          <Card.Header as="h6">{t("show")}</Card.Header>
          <Card.Body>
            <div className="content-view">
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("id")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.id}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("type")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{t("faqs_type." + response.data.type)}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("title")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.title}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("slug")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.slug}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("content")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {response.data.content ? <div dangerouslySetInnerHTML={{ __html: response.data.content }} /> : "-"}
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("description")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {response.data.description ? response.data.description : "-"}
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("keywords")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.keywords ? response.data.keywords : "-"}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("created_at")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  <Moment format="DD/MM/YYYY HH:mm:ss">{response.data.created_at}</Moment>
                </dd>
              </dl>
              {response.data.updated_at && (
                <>
                  <hr />
                  <dl className="row">
                    <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("updated_at")}:</dt>
                    <dd className="col-12 col-sm-9 col-md-10">
                      <Moment format="DD/MM/YYYY HH:mm:ss">{response.data.updated_at}</Moment>
                    </dd>
                  </dl>
                </>
              )}
            </div>
          </Card.Body>
          <Card.Footer>
            <Row>
              <Col xs="6" className="text-left">
                <Button as={NavLink} to="/faqs" variant="secondary" size="sm" className="with-icon">
                  <RiArrowLeftSLine />
                  <span>{t("list")}</span>
                </Button>
              </Col>
              <Col xs="6" className="text-right">
                <Button as={NavLink} to={`/faqs/${id}/edit`} variant="warning" size="sm" className="with-icon mr-2">
                  <RiEditBoxLine />
                  <span>{t("edit")}</span>
                </Button>
                <DeleteConfirmModal
                  show={deleteItem !== null}
                  onClose={() => setDeleteItem(null)}
                  onConfirm={onDeleteConfirm}
                />
                <Button variant="danger" size="sm" className="with-icon" onClick={() => setDeleteItem(id)}>
                  <RiDeleteBin6Line />
                  <span>{t("delete")}</span>
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      )}
    </MainLayout>
  );
};

export default Show;
