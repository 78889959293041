import CreateSesion from '../sessions/CreateSesion';

export const UpdateMentoringProgram = () => {

  return (
      <CreateSesion/>
    
  );
};

export default UpdateMentoringProgram;
