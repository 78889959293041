import { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FollowedTaskType, MatchFollowedTaskType, MatchSessionType } from "../../../store/types";
import moment from "moment";
import { MatchFollowedTaskModal, MatchFollowedTaskDetailModalType, MatchFollowedTaskModalDefaultState } from "../followed-tasks/MatchFollowedTaskModal";
type SessionDetailModalPropsType = {
    modal: SessionDetailModalType;
    onClose: () => void;
};


export type SessionDetailModalType = {
    show: boolean;
    data: MatchSessionType | null;
};

export const SessionModalDefaultState: SessionDetailModalType = {
    show: false,
    data: null,
};

export const MatchSessionListModal = (props: SessionDetailModalPropsType) => {
    const { t } = useTranslation();
    const [show, setShow] = useState<boolean>(props.modal.show);
    const [FollowedTaskModal, setFollowedTaskModal] = useState<MatchFollowedTaskDetailModalType>(MatchFollowedTaskModalDefaultState);
    const handleClose = (): void => {
        props.onClose();
    };
    useEffect(() => {
        setShow(props.modal.show);
    }, [props.modal.show]);

    const openFollowedTaskModal = (followedTask: MatchFollowedTaskType) => {
        setFollowedTaskModal({
            show: true,
            session: followedTask,
        });
    };
    const closeFollowedTaskModal = () => {
        setFollowedTaskModal({ show: false, session: null });
    };
    return (
        <Modal centered show={show} onHide={handleClose} animation={false} dialogClassName="ydc">
            <Modal.Header closeButton>
                <Modal.Title as="h6">{props.modal.data?.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <h6>{t("session_title")}</h6>
                    <p>{props.modal.data?.title}</p>
                </div>
                <div>
                    <h6>{t("session_location")}:</h6>
                    <p>{props.modal.data?.location}</p>
                </div>
                <hr />
                <div>
                    <h6>{t("session_goals")}:</h6>
                    <p>{props.modal.data?.goals}</p>
                </div>
                <hr />
                <div>
                    <h6>{t("session_preparations")}:</h6>
                    <p>{props.modal.data?.preparations}</p>
                </div>
                <hr />
                <div>
                    <h6>{t("session_agenda")}:</h6>
                    <p>{props.modal.data?.agenda}</p>
                </div>
                <hr />
                <div>
                    <h6>{t("session_documents")}:</h6>
                    <div>
                        {props.modal.data?.documents ? (
                            props.modal.data.documents.split(' ').map((document, index) => (
                                <p key={index}>
                                    <a href={document} target="_blank" rel="noopener noreferrer">
                                        {document}
                                    </a>
                                </p>
                            ))
                        ) : (
                            <span>{t("")}</span>
                        )}
                    </div>
                </div>
                <hr />
                <div>
                    <h6>{t("session_link")}:</h6>
                    <div>
                        {props.modal.data?.link ? (
                            props.modal.data.link.split(' ').map((link, index) => (
                                <p key={index}>
                                    <a href={link} target="_blank" rel="noopener noreferrer">
                                        {link}
                                    </a>
                                </p>
                            ))
                        ) : (
                            <span>{t("")}</span>
                        )}
                    </div>
                </div>
                <hr />
                <div>
                    <h6>{t("session_recording_link")};</h6>
                    <div>
                        {props.modal.data?.recording_link ? (
                            props.modal.data.recording_link.split(' ').map((link, index) => (
                                <p key={index}>
                                    <a href={link} target="_blank" rel="noopener noreferrer">
                                        {link}
                                    </a>
                                </p>
                            ))
                        ) : (
                            <span>{t("")}</span>
                        )}
                    </div>
                </div>


                <hr />
                <div>
                    <h6>{t("session_assesments")}:</h6>
                    <p>{props.modal.data?.assessment}</p>
                </div>
                <hr />
                <div>
                    <h6>{t("session_topics")}:</h6>
                    <p>{props.modal.data?.topics}</p>
                </div>
                <hr />
                <div>
                    <h6>{t("session_decisions")}:</h6>
                    <p>{props.modal.data?.decisions}</p>
                </div>
                <hr />
                <div>
                <h6>{t("session_date")}:</h6>
                    {props.modal.data?.date ? (
                        <>
                            <p>{moment(props.modal.data?.date).format("DD/MM/YYYY")}</p>
                        </>
                    ) : " "}
                </div>
                <hr />
                <h6>{t("followed_tasks")}:</h6>
                {props.modal.data?.followed_tasks?.map((followedTask, index) => (
                    <div key={index} className="row mb-2">
                        <div className="col-8">
                            <button
                                style={{ color: '#007bff', textDecoration: 'underline', cursor: 'pointer', border: 'none', background: 'none', padding: '0' }}
                                onClick={() => openFollowedTaskModal(followedTask)}
                            >
                                {followedTask.title}
                            </button>

                        </div>
                    </div>
                ))}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t("Close")}
                </Button>
            </Modal.Footer>
            <MatchFollowedTaskModal modal={FollowedTaskModal} onClose={closeFollowedTaskModal} />
        </Modal>
    );
};
