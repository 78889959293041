import { Reducer } from "react";
import {
    ISessionsState,
    SessionsActionType,
    ISessionState,
    SessionActionType,
    CreateSessionActionType,
    UpdateSessionActionType,
    DeleteSessionActionType,
    IDefaultState,
    CreateMatchSessionType,
    IMatchSessionState
} from "../types";

// Sessions
const sessionsInitialState: ISessionsState = {
    isLoading: false,
    response: null,
    error: null,
};

/**
 * @param state
 * @param action
 */
export const sessionsReducer: Reducer<ISessionsState, SessionsActionType> = (
    state = sessionsInitialState,
    action
): ISessionsState => {
    switch (action.type) {
        case "SESSIONS_START":
            return { ...state, isLoading: true, response: null, error: null };
        case "SESSIONS_SUCCESS":
            return { ...state, isLoading: false, response: action.payload.data };
        case "SESSIONS_FAILURE":
            return { ...state, isLoading: false, error: action.payload };
        case "SESSIONS_RESET":
            return sessionsInitialState;
        default:
            return state;
    }
};

// Session
const sessionInitialState: ISessionState = {
    isLoading: false,
    response: null,
    error: null,
};

const matchSessionInitialState: IMatchSessionState = {
    isLoading: false,
    response: null,
    error: null,
};

/**
 * @param state
 * @param action
 */
export const sessionReducer: Reducer<ISessionState, SessionActionType> = (
    state = sessionInitialState,
    action
): ISessionState => {
    switch (action.type) {
        case "SESSION_START":
            return { ...state, isLoading: true, response: null, error: null };
        case "SESSION_SUCCESS":
            return { ...state, isLoading: false, response: action.payload.data };
        case "SESSION_FAILURE":
            return { ...state, isLoading: false, error: action.payload };
        case "SESSION_RESET":
            return sessionInitialState;
        default:
            return state;
    }
};

/**
 * @param state
 * @param action
 */
export const createSessionReducer: Reducer<ISessionState, CreateSessionActionType> = (
    state = sessionInitialState,
    action
): ISessionState => {
    switch (action.type) {
        case "CREATE_SESSION_START":
            return { ...state, isLoading: true, response: null, error: null };
        case "CREATE_SESSION_SUCCESS":
            return { ...state, isLoading: false, response: action.payload.data };
        case "CREATE_SESSION_FAILURE":
            return { ...state, isLoading: false, error: action.payload };
        case "CREATE_SESSION_RESET":
            return sessionInitialState;
        default:
            return state;
    }
};
export const createMatchSessionReducer: Reducer<IMatchSessionState, CreateMatchSessionType> = (
    state = matchSessionInitialState,
    action
): IMatchSessionState => {
    switch (action.type) {
        case "CREATE_SESSION_START":
            return { ...state, isLoading: true, response: null, error: null };
        case "CREATE_SESSION_SUCCESS":
            return { ...state, isLoading: false, response: action.payload.data };
        case "CREATE_SESSION_FAILURE":
            return { ...state, isLoading: false, error: action.payload };
        case "CREATE_SESSION_RESET":
            return matchSessionInitialState;
        default:
            return state;
    }
};
/**
* @param state
* @param action
*/
export const updateSessionReducer: Reducer<ISessionState, UpdateSessionActionType> = (
    state = sessionInitialState,
    action
): ISessionState => {
    switch (action.type) {
        case "UPDATE_SESSION_START":
            return { ...state, isLoading: true, response: null, error: null };
        case "UPDATE_SESSION_SUCCESS":
            return { ...state, isLoading: false, response: action.payload.data };
        case "UPDATE_SESSION_FAILURE":
            return { ...state, isLoading: false, error: action.payload };
        case "UPDATE_SESSION_RESET":
            return sessionInitialState;
        default:
            return state;
    }
};

const deleteSessionInitialState: IDefaultState = {
    isLoading: false,
    response: null,
    error: null,
};

/**
* @param state
* @param action
*/
export const deleteSessionReducer: Reducer<IDefaultState, DeleteSessionActionType> = (
    state = deleteSessionInitialState,
    action
): ISessionsState => {
    switch (action.type) {
        case "DELETE_SESSION_START":
            return { ...state, isLoading: true, response: null, error: null };
        case "DELETE_SESSION_SUCCESS":
            return { ...state, isLoading: false, response: action.payload ? action.payload.data : null };
        case "DELETE_SESSION_FAILURE":
            return { ...state, isLoading: false, error: action.payload };
        case "DELETE_SESSION_RESET":
            return deleteSessionInitialState;
        default:
            return state;
    }
}

