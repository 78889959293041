import { Reducer } from "react";
import {
    IFollowedTasksState,
    FollowedTasksActionType,
    IFollowedTaskState,
    FollowedTaskActionType,
    CreateFollowedTaskActionType,
    UpdateFollowedTaskActionType,
    DeleteFollowedTaskActionType,
} from "../types";

// Followed Tasks
const followedTasksInitialState: IFollowedTasksState = {
    isLoading: false,
    response: null,
    error: null,
};

/**
 * @param state
 * @param action
 */
export const followedTasksReducer: Reducer<IFollowedTasksState, FollowedTasksActionType> = (
    state = followedTasksInitialState,
    action
): IFollowedTasksState => {
    switch (action.type) {
        case "FOLLOWED_TASKS_START":
            return { ...state, isLoading: true, response: null, error: null };
        case "FOLLOWED_TASKS_SUCCESS":
            return { ...state, isLoading: false, response: action.payload.data };
        case "FOLLOWED_TASKS_FAILURE":
            return { ...state, isLoading: false, error: action.payload };
        case "FOLLOWED_TASKS_RESET":
            return followedTasksInitialState;
        default:
            return state;
    }
}

// Followed Task
const followedTaskInitialState: IFollowedTaskState = {
    isLoading: false,
    response: null,
    error: null,
};

/**
 * @param state
 * @param action
 */
export const followedTaskReducer: Reducer<IFollowedTaskState, FollowedTaskActionType> = (
    state = followedTaskInitialState,
    action
): IFollowedTaskState => {
    switch (action.type) {
        case "FOLLOWED_TASK_START":
            return { ...state, isLoading: true, response: null, error: null };
        case "FOLLOWED_TASK_SUCCESS":
            return { ...state, isLoading: false, response: action.payload.data };
        case "FOLLOWED_TASK_FAILURE":
            return { ...state, isLoading: false, error: action.payload };
        case "FOLLOWED_TASK_RESET":
            return followedTaskInitialState;
        default:
            return state;
    }
}

/**
 * @param state
 * @param action
 */

export const createFollowedTaskReducer: Reducer<IFollowedTaskState, CreateFollowedTaskActionType> = (
    state = followedTaskInitialState,
    action
): IFollowedTaskState => {
    switch (action.type) {
        case "CREATE_FOLLOWED_TASK_START":
            return { ...state, isLoading: true, response: null, error: null };
        case "CREATE_FOLLOWED_TASK_SUCCESS":
            return { ...state, isLoading: false, response: action.payload.data };
        case "CREATE_FOLLOWED_TASK_FAILURE":
            return { ...state, isLoading: false, error: action.payload };
        case "CREATE_FOLLOWED_TASK_RESET":
            return followedTaskInitialState;
        default:
            return state;
    }
}

/**
 * @param state
 * @param action
 */
export const updateFollowedTaskReducer: Reducer<IFollowedTaskState, UpdateFollowedTaskActionType> = (
    state = followedTaskInitialState,
    action
): IFollowedTaskState => {
    switch (action.type) {
        case "UPDATE_FOLLOWED_TASK_START":
            return { ...state, isLoading: true, response: null, error: null };
        case "UPDATE_FOLLOWED_TASK_SUCCESS":
            return { ...state, isLoading: false, response: action.payload.data };
        case "UPDATE_FOLLOWED_TASK_FAILURE":
            return { ...state, isLoading: false, error: action.payload };
        case "UPDATE_FOLLOWED_TASK_RESET":
            return followedTaskInitialState;
        default:
            return state;
    }
}

/**
 * @param state
 * @param action
 */
export const deleteFollowedTaskReducer: Reducer<IFollowedTaskState, DeleteFollowedTaskActionType> = (
    state = followedTaskInitialState,
    action
): IFollowedTaskState => {
    switch (action.type) {
        case "DELETE_FOLLOWED_TASK_START":
            return { ...state, isLoading: true, response: null, error: null };
        case "DELETE_FOLLOWED_TASK_SUCCESS":
            return { ...state, isLoading: false, response: action.payload ? action.payload.data : null};
        case "DELETE_FOLLOWED_TASK_FAILURE":
            return { ...state, isLoading: false, error: action.payload };
        case "DELETE_FOLLOWED_TASK_RESET":
            return followedTaskInitialState;
        default:
            return state;
    }
}
