import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { MainLayout } from "../../layouts";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import { NavLink, useParams } from "react-router-dom";
import { RiAddBoxLine, RiArrowLeftSLine, RiCheckFill, RiDeleteBin6Line } from "react-icons/all";
import { Controller, useForm } from "react-hook-form";
import { RootState } from "../../../store/reducers";
import { ICategoriesState, ICitiesState, IDistrictsState, IUserParams, IUserState } from "../../../store/types";
import {
  AlertDefaultState,
  AlertStateType,
  BusinessTypes,
  DisabilityTypes,
  EducationStatusTypes,
  EMAIL_REGEX_PATTERN,
  GenderTypes,
  LanguageTypes,
  UserRoleTypes,
} from "../../../types";
import { CustomFileInput, FormAlert, LoadingIndicator } from "../../partials";
import actions from "../../../store/actions";
import history from "../../../utils/history";
import SectorSelectorModal, {
  SectorSelectedType,
  SectorSelectOptionType,
  SectorSelectorModalDefaultState,
  SectorSelectorModalType,
} from "./SectorSelectorModal";
import Select from "react-select";
import { fileObjectToBase64 } from "../../../utils/mixins";

type RouterParamsType = {
  id: string;
};

type CategorySelectOptionType = {
  value: string;
  label: string;
};

export const Edit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { id } = useParams<RouterParamsType>();

  const [categories, setCategories] = useState<Array<CategorySelectOptionType>>([]);
  const [selectedUserSectors, setSelectedUserSectors] = useState<Array<SectorSelectedType>>([]);
  const [selectedBusinessSectors, setSelectedBusinessSectors] = useState<Array<SectorSelectedType>>([]);

  const [sectorSelectorModal, setSectorSelectorModal] = useState<SectorSelectorModalType>(
    SectorSelectorModalDefaultState
  );

  const { register, watch, control, handleSubmit, errors, reset } = useForm<IUserParams>();
  const onSubmit = async (data: IUserParams) => {
    if (data.avatar instanceof FileList && data.avatar.length > 0) {
      data.avatar = await fileObjectToBase64(data.avatar[0]);
    } else {
      delete data.avatar;
    }

    if (data.password === "") {
      delete data.password;
      delete data.password_confirmation;
    }

    if (data.categories.length) {
      data.categories = data.categories.map((category: any) => {
        return category.value;
      });
    }

    data.user_sectors = [];
    if (selectedUserSectors.length) {
      selectedUserSectors.forEach((item) => {
        const subSectorBag: Array<string> = [];
        if (item.sub_sector.length) {
          item.sub_sector.forEach((sub) => {
            subSectorBag.push(sub.value);
          });
        }

        data.user_sectors.push({
          sector: item.sector.value,
          sub_sector: subSectorBag,
          sector_experience: item.sector_experience,
        });
      });
    }

    data.business_sectors = [];
    if (selectedBusinessSectors.length) {
      selectedBusinessSectors.forEach((item) => {
        const subSectorBag: Array<string> = [];
        if (item.sub_sector.length) {
          item.sub_sector.forEach((sub) => {
            subSectorBag.push(sub.value);
          });
        }

        data.business_sectors.push({
          sector: item.sector.value,
          sub_sector: subSectorBag,
        });
      });
    }
    if (data.business_type === undefined || data.business_type === '')
      delete data.business_type

    if (data.education === undefined || data.education === '')
      delete data.education

    dispatch(await actions.updateUser(id, data));
  };

  const {
    isLoading: userIsLoading,
    response: userResponse,
    error: userError,
  } = useSelector<RootState, IUserState>((state: RootState) => state.user);

  const { isLoading, response, error } = useSelector<RootState, IUserState>((state: RootState) => state.updateUser);

  const { isLoading: citiesIsLoading, response: citiesResponse } = useSelector<RootState, ICitiesState>(
    (state: RootState) => state.cities
  );

  const { isLoading: districtsIsLoading, response: districtsResponse } = useSelector<RootState, IDistrictsState>(
    (state: RootState) => state.districts
  );

  const { isLoading: categoriesIsLoading, response: categoriesResponse } = useSelector<RootState, ICategoriesState>(
    (state: RootState) => state.categories
  );

  const [defaultCategories, setDefaultCategories] = useState<Array<CategorySelectOptionType>>([]);

  const [formDisable, setFormDisable] = useState<boolean>(false);
  const [formAlert, setFormAlert] = useState<AlertStateType>(AlertDefaultState);

  const getCityDistricts = useCallback(
    (cityId: string) => {
      dispatch(actions.districts(cityId));
    },
    [dispatch]
  );

  useEffect(() => {
    if (citiesResponse === null) {
      dispatch(actions.cities());
    }
  }, [citiesResponse, dispatch]);

  useEffect(() => {
    const cityId = userResponse?.data.city?.id;
    if (cityId) {
      getCityDistricts(cityId);
    }
  }, [userResponse, getCityDistricts]);

  useEffect(() => {
    dispatch(actions.categories({ page: 1, per: 9999 }));
  }, [dispatch]);

  useEffect(() => {
    if (categoriesResponse) {
      if (categoriesResponse.data.items.length > 0) {
        const categoryOptions: Array<CategorySelectOptionType> = categoriesResponse.data.items.map((category) => {
          return {
            value: category.id,
            label: category.title,
          };
        });
        setCategories(categoryOptions);
      }
    } else {
      dispatch(actions.categories({ page: 1, per: 9999 }));
    }
  }, [categoriesResponse, dispatch]);

  useEffect(() => {
    if (userResponse) {
      const categories = userResponse.data.categories.map((category) => {
        return {
          value: category.id,
          label: category.title,
        };
      });

      setDefaultCategories(categories);
    }
  }, [userResponse]);

  useEffect(() => {
    setFormDisable(citiesIsLoading || districtsIsLoading || categoriesIsLoading || isLoading);
  }, [citiesIsLoading, districtsIsLoading, categoriesIsLoading, isLoading]);

  useEffect(() => {
    if (userError?.response) {
      if (userError.response.status === 400 || userError.response.status === 404) {
        dispatch({ type: "USER_RESET" });
        history.push("/404");
      }
    } else {
      dispatch(actions.user(id));
    }
  }, [dispatch, id, userError]);

  useEffect(() => {
    setFormDisable(isLoading);

    if (error !== null) {
      if (error.response.status === 400) {
        setFormAlert({
          variant: "danger",
          show: true,
          messages: error.response.data.messages,
        });
      } else {
        addToast(t("unknown_error"), {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }

    if (response) {
      addToast(t("updated_with_param", { param: t("user") }), {
        appearance: "success",
        autoDismiss: true,
      });

      dispatch({ type: "UPDATE_USER_RESET" });
      history.push(`/users/${id}`);
    }
  }, [isLoading, response, addToast, t, id, error, reset, dispatch]);

  useEffect(() => {
    if (userResponse) {
      if (userResponse.data.user_sectors && userResponse.data.user_sectors.length) {
        const userSectors: Array<SectorSelectedType> = [];
        userResponse.data.user_sectors.forEach((item) => {
          const subSectors: Array<SectorSelectOptionType> = [];
          if (item.sub_sector.length) {
            item.sub_sector.forEach((sub) => {
              subSectors.push({
                label: `${sub.name} / ${sub.code}`,
                value: sub.id,
              });
            });
          }

          userSectors.push({
            type: "user",
            sector: {
              label: `${item.sector.title} / ${item.sector.nace}`,
              value: item.sector.id,
            },
            sub_sector: subSectors,
            sector_experience: Number(item.sector_experience),
          });
        });

        setSelectedUserSectors(userSectors);
      }

      if (userResponse.data.business_sectors && userResponse.data.business_sectors.length) {
        const businessSectors: Array<SectorSelectedType> = [];
        userResponse.data.business_sectors.forEach((item) => {
          const subSectors: Array<SectorSelectOptionType> = [];
          if (item.sub_sector.length) {
            item.sub_sector.forEach((sub) => {
              subSectors.push({
                label: `${sub.name} / ${sub.code}`,
                value: sub.id,
              });
            });
          }

          businessSectors.push({
            type: "business",
            sector: {
              label: `${item.sector.title} / ${item.sector.nace}`,
              value: item.sector.id,
            },
            sub_sector: subSectors,
          });
        });

        setSelectedBusinessSectors(businessSectors);
      }
    }
  }, [userResponse]);

  const onHandleSectorSelect = (data: SectorSelectedType) => {
    if (data.type === "user") {
      setSelectedUserSectors(selectedUserSectors.filter((item) => item.sector.value !== data.sector.value));
      setSelectedUserSectors((selectedUserSectors) => [...selectedUserSectors, data]);
    } else if (data.type === "business") {
      setSelectedBusinessSectors(selectedBusinessSectors.filter((item) => item.sector.value !== data.sector.value));
      setSelectedBusinessSectors((selectedBusinessSectors) => [...selectedBusinessSectors, data]);
    }
  };

  const handleDeleteUserSector = (data: SectorSelectedType) => {
    setSelectedUserSectors(selectedUserSectors.filter((item) => item.sector.value !== data.sector.value));
  };

  const handleDeleteBusinessSector = (data: SectorSelectedType) => {
    setSelectedBusinessSectors(selectedBusinessSectors.filter((item) => item.sector.value !== data.sector.value));
  };

  return (
    <MainLayout>
      <LoadingIndicator show={userIsLoading} />

      {userResponse && (
        <>
          <FormAlert variant={formAlert.variant} show={formAlert.show} to={formAlert.to}>
            {formAlert.messages.map((message, key) => {
              return <p key={key}>{message}</p>;
            })}
          </FormAlert>

          <Card className={formDisable ? "loading-block" : ""}>
            <Card.Header as="h6">{t("edit")}</Card.Header>
            <Card.Body>
              <SectorSelectorModal
                modal={sectorSelectorModal}
                onClose={() => setSectorSelectorModal(SectorSelectorModalDefaultState)}
                onSubmit={onHandleSectorSelect}
              />
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Row>
                  <Form.Group as={Col} sm="12">
                    <Form.Label className="form-label d-block">{t("roles")}:</Form.Label>
                    <>
                      {Object.values(UserRoleTypes).map((value, key) => {
                        return (
                          <Form.Check
                            inline
                            custom
                            type="checkbox"
                            key={key}
                            label={t(`role_names.${value}`)}
                            name="roles"
                            id={`role-${value}`}
                            value={value}
                            defaultChecked={userResponse.data.roles.includes(value)}
                            ref={register({
                              required: {
                                value: true,
                                message: t("validation.you_must_enter", { name: t("role") }),
                              },
                            })}
                            isInvalid={!!errors.roles}
                          />
                        );
                      })}
                    </>
                  </Form.Group>
                </Form.Row>
                <hr />
                <Form.Row>
                  <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-name">
                    <Form.Label>{t("name")}:</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      ref={register({
                        required: {
                          value: true,
                          message: t("validation.you_must_enter", { name: t("name") }),
                        },
                      })}
                      placeholder={t("name")}
                      defaultValue={userResponse.data.name}
                      autoFocus
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-lastname">
                    <Form.Label>{t("lastname")}:</Form.Label>
                    <Form.Control
                      type="text"
                      name="lastname"
                      ref={register({
                        required: {
                          value: true,
                          message: t("validation.you_must_enter", { name: t("lastname") }),
                        },
                      })}
                      placeholder={t("lastname")}
                      defaultValue={userResponse.data.lastname}
                      isInvalid={!!errors.lastname}
                    />
                    <Form.Control.Feedback type="invalid">{errors.lastname?.message}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-email">
                    <Form.Label>{t("email")}:</Form.Label>
                    <Form.Control
                      type="text"
                      name="email"
                      ref={register({
                        required: {
                          value: true,
                          message: t("validation.you_must_enter", { name: t("email") }),
                        },
                        pattern: {
                          value: EMAIL_REGEX_PATTERN,
                          message: t("validation.invalid", { name: t("email") }),
                        },
                      })}
                      placeholder={t("email")}
                      defaultValue={userResponse.data.email}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Row>
                  <Col md="6">
                    <Alert variant="secondary">{t("update_password_notice")}</Alert>
                  </Col>
                </Row>
                <Form.Row>
                  <Form.Group as={Col} md="3" controlId="user-password">
                    <Form.Label>{t("password")}:</Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      ref={register({
                        minLength: {
                          value: 6,
                          message: t("validation.min", { min: 6 }),
                        },
                        maxLength: {
                          value: 100,
                          message: t("validation.max", { max: 100 }),
                        },
                      })}
                      placeholder={t("password")}
                      isInvalid={!!errors.password}
                    />
                    <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="3" controlId="user-password-confirmation">
                    <Form.Label>{t("password_confirmation")}:</Form.Label>
                    <Form.Control
                      type="password"
                      name="password_confirmation"
                      ref={register({
                        validate: (value) =>
                          value === watch("password") || t("validation.passwords_must_match").toString(),
                      })}
                      placeholder={t("password_confirmation")}
                      isInvalid={!!errors.password_confirmation}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password_confirmation?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <hr />
                <Form.Row>
                  <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-phone">
                    <Form.Label>{t("phone")}:</Form.Label>
                    <Form.Control
                      type="number"
                      name="phone"
                      ref={register({
                        required: {
                          value: false,
                          message: t("validation.you_must_enter", { name: t("phone") }),
                        },
                        min: {
                          value: 11,
                          message: t("validation.min", { min: 11 }),
                        },
                      })}
                      maxLength={11}
                      placeholder={t("phone")}
                      defaultValue={userResponse.data.phone}
                      isInvalid={!!errors.phone}
                    />
                    <Form.Control.Feedback type="invalid">{errors.phone?.message}</Form.Control.Feedback>
                  </Form.Group>
                  {citiesResponse && (
                    <Form.Group as={Col} md="3" controlId="user-city">
                      <Form.Label>{t("city")}:</Form.Label>
                      <Form.Control
                        as="select"
                        custom
                        name="city"
                        ref={register({
                          required: {
                            value: true,
                            message: t("validation.you_must_choose", { name: t("city") }),
                          },
                        })}
                        defaultValue={userResponse.data.city?.id}
                        onChange={(event) => getCityDistricts(event.target.value)}
                        isInvalid={!!errors.city}
                      >
                        <option value="">{t("select_city")}</option>
                        {citiesResponse?.data.items.map((city, key) => {
                          return (
                            <option value={city.id} key={key}>
                              {city.name}
                            </option>
                          );
                        })}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">{errors.city?.message}</Form.Control.Feedback>
                    </Form.Group>
                  )}
                  {districtsResponse && (
                    <Form.Group as={Col} md="3" controlId="user-district">
                      <Form.Label>{t("district")}:</Form.Label>
                      <Form.Control
                        as="select"
                        custom
                        name="district"
                        ref={register({
                          required: {
                            value: true,
                            message: t("validation.you_must_choose", { name: t("district") }),
                          },
                        })}
                        defaultValue={userResponse.data.district?.id}
                        isInvalid={!!errors.district}
                      >
                        <option value="">{t("select_district")}</option>
                        {districtsResponse?.data.items.map((district, key) => {
                          return (
                            <option value={district.id} key={key}>
                              {district.name}
                            </option>
                          );
                        })}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">{errors.district?.message}</Form.Control.Feedback>
                    </Form.Group>
                  )}
                  <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-address">
                    <Form.Label>{t("address")}:</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="address"
                      rows={1}
                      ref={register({
                        required: {
                          value: false,
                          message: t("validation.you_must_enter", { name: t("address") }),
                        },
                      })}
                      placeholder={t("address")}
                      defaultValue={userResponse.data.address ? userResponse.data.address : ""}
                      isInvalid={!!errors.address}
                    />
                    <Form.Control.Feedback type="invalid">{errors.address?.message}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-linkedin">
                    <Form.Label>{t("linkedin")}:</Form.Label>
                    <Form.Control
                      type="text"
                      name="linkedin"
                      ref={register({
                        required: {
                          value: false,
                          message: t("validation.you_must_enter", { name: t("linkedin") }),
                        },
                      })}
                      placeholder={t("linkedin")}
                      defaultValue={userResponse.data.linkedin ? userResponse.data.linkedin : ""}
                      isInvalid={!!errors.linkedin}
                    />
                    <Form.Control.Feedback type="invalid">{errors.linkedin?.message}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-avatar">
                    <Form.Label>
                      {t("avatar")}: <em className="text-muted">{t("leave_blank_if_not_updated")}</em>
                    </Form.Label>
                    <CustomFileInput
                      name="avatar"
                      accept="image/jpeg,image/jpg,image/png"
                      ref={register}
                      isInvalid={!!errors.avatar}
                    />
                    <Form.Control.Feedback type="invalid">{errors.avatar?.message}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-gender">
                    <Form.Label>{t("gender")}:</Form.Label>
                    <Form.Control
                      as="select"
                      custom
                      name="gender"
                      ref={register({
                        required: {
                          value: false,
                          message: t("validation.you_must_choose", { name: t("gender") }),
                        },
                      })}
                      defaultValue={userResponse.data.gender ? userResponse.data.gender : ""}
                      isInvalid={!!errors.gender}
                    >
                      <option value="">{t("select_gender")}</option>
                      {[GenderTypes.male, GenderTypes.female].map((gender, key) => {
                        return (
                          <option value={gender} key={key}>
                            {t(`genders.${gender}`)}
                          </option>
                        );
                      })}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">{errors.gender?.message}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-disability">
                    <Form.Label>{t("disability")}:</Form.Label>
                    <Form.Control
                      as="select"
                      custom
                      name="disability"
                      ref={register({
                        required: {
                          value: false,
                          message: t("validation.you_must_choose", { name: t("disability") }),
                        },
                      })}
                      defaultValue={userResponse.data.disability ? userResponse.data.disability : ""}
                      isInvalid={!!errors.disability}
                    >
                      <option value="">{t("select_disability")}</option>
                      {[DisabilityTypes.yes, DisabilityTypes.no].map((disability, key) => {
                        return (
                          <option value={disability} key={key}>
                            {t(`disabilities.${disability}`)}
                          </option>
                        );
                      })}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">{errors.disability?.message}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-about">
                    <Form.Label>{t("about")}:</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="about"
                      rows={1}
                      ref={register({
                        required: {
                          value: false,
                          message: t("validation.you_must_enter", { name: t("about") }),
                        },
                      })}
                      placeholder={t("about")}
                      defaultValue={userResponse.data.about ? userResponse.data.about : ""}
                      isInvalid={!!errors.about}
                    />
                    <Form.Control.Feedback type="invalid">{errors.about?.message}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-education">
                    <Form.Label>{t("education_status")}:</Form.Label>
                    <Form.Control
                      as="select"
                      custom
                      name="education"
                      ref={register({
                        required: {
                          value: false,
                          message: t("validation.you_must_choose", { name: t("education_status") }),
                        },
                      })}
                      defaultValue={userResponse.data.education ? userResponse.data.education : ""}
                      isInvalid={!!errors.education}
                    >
                      <option value="">{t("select_education_status")}</option>
                      {Object.values(EducationStatusTypes).map((education, key) => {
                        return (
                          <option value={education} key={key}>
                            {t(`education_items.${education}`)}
                          </option>
                        );
                      })}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">{errors.education?.message}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-education-detail">
                    <Form.Label>{t("education_detail")}:</Form.Label>
                    <Form.Control
                      type="text"
                      name="education_detail"
                      ref={register({
                        required: {
                          value: false,
                          message: t("validation.you_must_enter", { name: t("education_detail") }),
                        },
                      })}
                      placeholder={t("education_detail")}
                      defaultValue={userResponse.data.education_detail ? userResponse.data.education_detail : ""}
                      isInvalid={!!errors.education_detail}
                    />
                    <Form.Control.Feedback type="invalid">{errors.education_detail?.message}</Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <hr />
                <Form.Row>
                  <Form.Group as={Col} lg="6" controlId="user-categories" style={{ zIndex: 2 }}>
                    <Form.Label>{t("categories")}:</Form.Label>
                    <Controller
                      control={control}
                      name="categories"
                      defaultValue={defaultCategories}
                      render={({ onChange, ref }) => (
                        <Select
                          isMulti
                          isClearable
                          inputRef={ref}
                          placeholder={t("select_category")}
                          noOptionsMessage={() => t("not_found")}
                          options={categories}
                          defaultValue={defaultCategories}
                          onChange={(value: any) => onChange(value)}
                        />
                      )}
                      ref={register}
                      rules={{
                        required: {
                          value: true,
                          message: t("validation.you_must_choose", { name: t("category") }),
                        },
                      }}
                      isInvalid={!!errors.categories}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label className="form-label d-block">{t("languages")}:</Form.Label>
                    <div className="pt-2">
                      {Object.values(LanguageTypes).map((value, key) => {
                        return (
                          <Form.Check
                            inline
                            custom
                            type="checkbox"
                            key={key}
                            label={t(`language_items.${value}`)}
                            name="language"
                            id={`language-${value}`}
                            value={value}
                            defaultChecked={userResponse.data.language.includes(value)}
                            ref={register({
                              required: {
                                value: false,
                                message: t("validation.you_must_enter", { name: t("language") }),
                              },
                            })}
                            isInvalid={!!errors.language}
                          />
                        );
                      })}
                    </div>
                  </Form.Group>
                </Form.Row>
                <hr />
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label className="form-label d-block">{t("sectors")}:</Form.Label>
                    {selectedUserSectors.length ? (
                      <div className="table-responsive">
                        <table className="table table-bordered table-hover">
                          <thead>
                            <tr>
                              <th>{t("sector")}</th>
                              <th>{t("sub_sector")}</th>
                              <th className="text-center">{t("experience")}</th>
                              <th className="text-center">{t("actions")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {selectedUserSectors.map((item, key) => {
                              return (
                                <tr key={key}>
                                  <td>{item.sector.label}</td>
                                  <td>
                                    {item.sub_sector.length ? (
                                      <>
                                        {item.sub_sector.map((sub, key) => {
                                          return (
                                            <div key={key} className="d-block mb-2">
                                              - {sub.label}
                                            </div>
                                          );
                                        })}
                                      </>
                                    ) : (
                                      <>-</>
                                    )}
                                  </td>
                                  <td className="text-center">
                                    {item.sector_experience} {t("year")}
                                  </td>
                                  <td className="text-center">
                                    <Button
                                      variant="outline-danger"
                                      size={"sm"}
                                      className="btn-icon"
                                      title={t("delete")}
                                      onClick={() => handleDeleteUserSector(item)}
                                    >
                                      <RiDeleteBin6Line />
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className="d-block border p-3 mb-3">{t("sector_not_selected")}</div>
                    )}
                    <div className="text-right">
                      <Button
                        variant="outline-success"
                        size="sm"
                        className="with-icon"
                        title={t("add")}
                        onClick={() => setSectorSelectorModal({ show: true, type: "user" })}
                      >
                        <RiAddBoxLine />
                        <span>{t("add")}</span>
                      </Button>
                    </div>
                  </Form.Group>
                </Form.Row>
                <hr />
                <h6 className="mb-4">{t("business_information")}:</h6>
                <Form.Row>
                  <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-business-type">
                    <Form.Label>{t("business_type")}:</Form.Label>
                    <Form.Control
                      as="select"
                      custom
                      name="business_type"
                      ref={register({
                        required: {
                          value: false,
                          message: t("validation.you_must_choose", { name: t("business_type") }),
                        },
                      })}
                      defaultValue={userResponse.data.business_type ? userResponse.data.business_type : ""}
                      isInvalid={!!errors.business_type}
                    >
                      <option value="">{t("business_type")}</option>
                      {Object.values(BusinessTypes).map((type, key) => {
                        return (
                          <option value={type} key={key}>
                            {t(`business_types.${type}`)}
                          </option>
                        );
                      })}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">{errors.business_type?.message}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-business-group">
                    <Form.Label>{t("business_group")}:</Form.Label>
                    <Form.Control
                      type="text"
                      name="business_group"
                      ref={register({
                        required: {
                          value: false,
                          message: t("validation.you_must_enter", { name: t("business_group") }),
                        },
                      })}
                      placeholder={t("business_group")}
                      defaultValue={userResponse.data.business_group ? userResponse.data.business_group : ""}
                      isInvalid={!!errors.business_group}
                    />
                    <Form.Control.Feedback type="invalid">{errors.business_group?.message}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-business-name">
                    <Form.Label>{t("business_name")}:</Form.Label>
                    <Form.Control
                      type="text"
                      name="business_name"
                      ref={register({
                        required: {
                          value: false,
                          message: t("validation.you_must_enter", { name: t("business_name") }),
                        },
                      })}
                      placeholder={t("business_name")}
                      defaultValue={userResponse.data.business_name ? userResponse.data.business_name : ""}
                      isInvalid={!!errors.business_name}
                    />
                    <Form.Control.Feedback type="invalid">{errors.business_name?.message}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-business-role">
                    <Form.Label>{t("business_role")}:</Form.Label>
                    <Form.Control
                      as="select"
                      custom
                      name="business_role"
                      ref={register({
                        required: {
                          value: false,
                          message: t("validation.you_must_choose", { name: t("business_role") }),
                        },
                      })}
                      defaultValue={userResponse.data.business_role ? userResponse.data.business_role : ""}
                      isInvalid={!!errors.business_role}
                    >
                      <option value="">{t("select_role")}</option>
                      <option value="MANAGER">{t("business_roles.MANAGER")}</option>
                      <option value="USER">{t("business_roles.USER")}</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">{errors.business_role?.message}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-business-working-time">
                    <Form.Label>{t("business_working_time")}:</Form.Label>
                    <Form.Control
                      type="number"
                      name="business_working_time"
                      ref={register({
                        required: {
                          value: false,
                          message: t("validation.you_must_enter", { name: t("business_working_time") }),
                        },
                      })}
                      placeholder={t("business_working_time")}
                      defaultValue={
                        userResponse.data.business_working_time ? userResponse.data.business_working_time : ""
                      }
                      isInvalid={!!errors.business_working_time}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.business_working_time?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-business-website">
                    <Form.Label>{t("business_website")}:</Form.Label>
                    <Form.Control
                      type="text"
                      name="business_website"
                      ref={register({
                        required: {
                          value: false,
                          message: t("validation.you_must_enter", { name: t("business_website") }),
                        },
                      })}
                      placeholder={t("business_website")}
                      defaultValue={userResponse.data.business_website ? userResponse.data.business_website : ""}
                      isInvalid={!!errors.business_website}
                    />
                    <Form.Control.Feedback type="invalid">{errors.business_website?.message}</Form.Control.Feedback>
                  </Form.Group>
                  {citiesResponse && (
                    <Form.Group as={Col} md="3" controlId="user-business-city">
                      <Form.Label>{t("business_city")}:</Form.Label>
                      <Form.Control
                        as="select"
                        custom
                        name="business_city"
                        ref={register({
                          required: {
                            value: false,
                            message: t("validation.you_must_choose", { name: t("business_city") }),
                          },
                        })}
                        defaultValue={userResponse.data.business_city?.id}
                        isInvalid={!!errors.business_city}
                      >
                        <option value="">{t("select_city")}</option>
                        {citiesResponse?.data.items.map((city, key) => {
                          return (
                            <option value={city.id} key={key}>
                              {city.name}
                            </option>
                          );
                        })}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">{errors.city?.message}</Form.Control.Feedback>
                    </Form.Group>
                  )}
                  <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-business-address">
                    <Form.Label>{t("business_address")}:</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="business_adress"
                      rows={1}
                      ref={register({
                        required: {
                          value: false,
                          message: t("validation.you_must_enter", { name: t("business_address") }),
                        },
                      })}
                      placeholder={t("business_address")}
                      defaultValue={userResponse.data.business_adress ? userResponse.data.business_adress : ""}
                      isInvalid={!!errors.business_adress}
                    />
                    <Form.Control.Feedback type="invalid">{errors.business_adress?.message}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-business-phone">
                    <Form.Label>{t("business_phone")}:</Form.Label>
                    <Form.Control
                      type="text"
                      name="business_phone"
                      ref={register({
                        required: {
                          value: false,
                          message: t("validation.you_must_enter", { name: t("business_phone") }),
                        },
                      })}
                      placeholder={t("business_phone")}
                      defaultValue={userResponse.data.business_phone ? userResponse.data.business_phone : ""}
                      isInvalid={!!errors.business_phone}
                    />
                    <Form.Control.Feedback type="invalid">{errors.business_phone?.message}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-business-general">
                    <Form.Label>{t("business_general")}:</Form.Label>
                    <Form.Control
                      type="text"
                      name="business_general"
                      ref={register({
                        required: {
                          value: false,
                          message: t("validation.you_must_enter", { name: t("business_general") }),
                        },
                      })}
                      placeholder={t("business_general")}
                      defaultValue={userResponse.data.business_general ? userResponse.data.business_general : ""}
                      isInvalid={!!errors.business_general}
                    />
                    <Form.Control.Feedback type="invalid">{errors.business_general?.message}</Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} sm="12">
                    <Form.Label className="form-label d-block">{t("business_sectors")}:</Form.Label>
                    {selectedBusinessSectors.length ? (
                      <div className="table-responsive">
                        <table className="table table-bordered table-hover">
                          <thead>
                            <tr>
                              <th>{t("sector")}</th>
                              <th>{t("sub_sector")}</th>
                              <th className="text-center">{t("actions")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {selectedBusinessSectors.map((item, key) => {
                              return (
                                <tr key={key}>
                                  <td>{item.sector.label}</td>
                                  <td>
                                    {item.sub_sector.length ? (
                                      <>
                                        {item.sub_sector.map((sub, key) => {
                                          return (
                                            <div key={key} className="d-block mb-2">
                                              - {sub.label}
                                            </div>
                                          );
                                        })}
                                      </>
                                    ) : (
                                      <>-</>
                                    )}
                                  </td>
                                  <td className="text-center">
                                    <Button
                                      variant="outline-danger"
                                      size={"sm"}
                                      className="btn-icon"
                                      title={t("delete")}
                                      onClick={() => handleDeleteBusinessSector(item)}
                                    >
                                      <RiDeleteBin6Line />
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className="d-block border p-3 mb-3">{t("sector_not_selected")}</div>
                    )}
                    <div className="text-right">
                      <Button
                        variant="outline-success"
                        size="sm"
                        className="with-icon"
                        title={t("add")}
                        onClick={() => setSectorSelectorModal({ show: true, type: "business" })}
                      >
                        <RiAddBoxLine />
                        <span>{t("add")}</span>
                      </Button>
                    </div>
                  </Form.Group>
                </Form.Row>
              </Form>
            </Card.Body>
            <Card.Footer>
              <Row>
                <Col xs="6" className="text-left">
                  <Button as={NavLink} to="/users" variant="secondary" size="sm" className="with-icon">
                    <RiArrowLeftSLine />
                    <span>{t("list")}</span>
                  </Button>
                </Col>
                <Col xs="6" className="text-right">
                  <Button variant="primary" size="sm" className="with-icon" onClick={handleSubmit(onSubmit)}>
                    <RiCheckFill />
                    <span>{t("save")}</span>
                  </Button>
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        </>
      )}
    </MainLayout>
  );
};

export default Edit;
