import React from "react";
import ILayoutProps from "../layouts/ILayoutProps";
import logo from "../../assets/img/logo-dark.svg";
import { NavLink } from "react-router-dom";

export const AuthLayout = (props: ILayoutProps) => {
  const { children } = props;

  return (
    <div className="auth-container">
      <NavLink to="/" className="logo">
        <img src={logo} alt="" />
      </NavLink>
      <hr />
      {children}
    </div>
  );
};

export default AuthLayout;
