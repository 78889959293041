import { Reducer } from "react";
import { IDefaultState, DeletePictureActionType } from "../types";

const pictureDeleteInitialState: IDefaultState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const deletePictureReducer: Reducer<IDefaultState, DeletePictureActionType> = (
  state = pictureDeleteInitialState,
  action
): IDefaultState => {
  switch (action.type) {
    case "DELETE_PICTURE_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "DELETE_PICTURE_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "DELETE_PICTURE_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "DELETE_PICTURE_RESET":
      return pictureDeleteInitialState;
    default:
      return state;
  }
};
