import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { MainLayout } from "../../layouts";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { NavLink, useParams } from "react-router-dom";
import { RiArrowLeftSLine, RiCheckFill } from "react-icons/all";
import { Controller, useForm } from "react-hook-form";
import { RootState } from "../../../store/reducers";
import { IContractParams, IContractState } from "../../../store/types";
import { AlertDefaultState, AlertStateType } from "../../../types";
import { FormAlert, LoadingIndicator, WysiwygEditor } from "../../partials";
import actions from "../../../store/actions";
import history from "../../../utils/history";

type RouterParamsType = {
  id: string;
};

export const Edit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { id } = useParams<RouterParamsType>();

  const { register, handleSubmit, errors, reset, control } = useForm<IContractParams>();
  const onSubmit = async (data: IContractParams) => {
    dispatch(await actions.updateContract(id, data));
  };

  const {
    isLoading: contractIsLoading,
    response: contractResponse,
    error: contractError,
  } = useSelector<RootState, IContractState>((state: RootState) => state.contract);

  const { isLoading, response, error } = useSelector<RootState, IContractState>(
    (state: RootState) => state.updateContract
  );

  const [formDisable, setFormDisable] = useState<boolean>(false);
  const [formAlert, setFormAlert] = useState<AlertStateType>(AlertDefaultState);

  const wysiwygRef = useRef();

  useEffect(() => {
    if (contractError?.response) {
      if (contractError.response.status === 400 || contractError.response.status === 404) {
        dispatch({ type: "CONTRACT_RESET" });
        history.push("/404");
      }
    } else {
      dispatch(actions.contract(id));
    }
  }, [dispatch, id, contractError]);

  useEffect(() => {
    setFormDisable(isLoading);

    if (error !== null) {
      if (error.response.status === 400) {
        setFormAlert({
          variant: "danger",
          show: true,
          messages: error.response.data.messages,
        });
      } else {
        addToast(t("unknown_error"), {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }

    if (response) {
      addToast(t("updated_with_param", { param: t("contract") }), {
        appearance: "success",
        autoDismiss: true,
      });

      dispatch({ type: "UPDATE_CONTRACT_RESET" });
      history.push(`/contracts/${id}`);
    }
  }, [isLoading, response, addToast, t, error, reset, dispatch, id]);

  return (
    <MainLayout>
      <LoadingIndicator show={contractIsLoading} />

      {contractResponse && (
        <>
          <FormAlert variant={formAlert.variant} show={formAlert.show} to={formAlert.to}>
            {formAlert.messages.map((message, key) => {
              return <p key={key}>{message}</p>;
            })}
          </FormAlert>

          <Card className={formDisable ? "loading-block" : ""}>
            <Card.Header as="h6">{t("edit")}</Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Row>
                  <Form.Group as={Col} md="4" controlId="contract-title">
                    <Form.Label>{t("title")}:</Form.Label>
                    <Form.Control
                      type="text"
                      name="title"
                      ref={register({
                        required: {
                          value: true,
                          message: t("validation.you_must_enter", { name: t("title") }),
                        },
                      })}
                      placeholder={t("title")}
                      defaultValue={contractResponse.data.title}
                      autoFocus
                      isInvalid={!!errors.title}
                    />
                    <Form.Control.Feedback type="invalid">{errors.title?.message}</Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <hr />
                <Form.Row>
                  <Form.Group as={Col} md="12" controlId="contract-content">
                    <Form.Label>{t("content")}:</Form.Label>
                    <Controller
                      control={control}
                      name="content"
                      ref={register({
                        required: {
                          value: true,
                          message: t("validation.you_must_enter", { name: t("content") }),
                        },
                      })}
                      placeholder={t("content")}
                      defaultValue={contractResponse.data.content ? contractResponse.data.content : ""}
                      render={({ onChange }) => {
                        return (
                          <WysiwygEditor
                            defaultValue={contractResponse.data.content ? contractResponse.data.content : ""}
                            onChange={(content: string) => onChange(content)}
                            ref={wysiwygRef}
                          />
                        );
                      }}
                      isInvalid={!!errors.content}
                    />
                    <Form.Control.Feedback type="invalid">{errors.content?.message}</Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <hr />
                <Form.Row>
                  <Form.Group as={Col} md="4" controlId="contract-description">
                    <Form.Label>{t("description")}:</Form.Label>
                    <Form.Control
                      type="text"
                      as="textarea"
                      name="description"
                      ref={register}
                      placeholder={t("description")}
                      defaultValue={contractResponse.data.description ? contractResponse.data.description : ""}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="4" controlId="contract-keywords">
                    <Form.Label>{t("keywords")}:</Form.Label>
                    <Form.Control
                      type="text"
                      as="textarea"
                      name="keywords"
                      ref={register}
                      placeholder={t("keywords")}
                      defaultValue={contractResponse.data.keywords ? contractResponse.data.keywords : ""}
                    />
                  </Form.Group>
                </Form.Row>
              </Form>
            </Card.Body>
            <Card.Footer>
              <Row>
                <Col xs="6" className="text-left">
                  <Button as={NavLink} to="/contracts" variant="secondary" size="sm" className="with-icon">
                    <RiArrowLeftSLine />
                    <span>{t("list")}</span>
                  </Button>
                </Col>
                <Col xs="6" className="text-right">
                  <Button variant="primary" size="sm" className="with-icon" onClick={handleSubmit(onSubmit)}>
                    <RiCheckFill />
                    <span>{t("save")}</span>
                  </Button>
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        </>
      )}
    </MainLayout>
  );
};

export default Edit;
