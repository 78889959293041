import React, { useCallback, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { MainLayout } from "../../layouts";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import { RiArrowLeftSLine, RiCheckFill } from "react-icons/all";
import { RootState } from "../../../store/reducers";
import {
  ICitiesState,
  IDistrictsState,
  IMeState,
  IUpdateProfileParams,
  IUpdateProfileState,
} from "../../../store/types";
import { LoadingIndicator, CustomFileInput, FormAlert } from "../../partials";
import { fileObjectToBase64 } from "../../../utils/mixins";
import { AlertStateType, AlertDefaultState, EMAIL_REGEX_PATTERN } from "../../../types";
import actions from "../../../store/actions";
import history from "../../../utils/history";

type FormDataType = {
  name: string;
  lastname: string;
  phone: string;
  city: string;
  district: string;
  email: string;
  avatar: FileList;
  password?: string;
  password_confirmation?: string;
};

export const Edit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const [formDisable, setFormDisable] = useState<boolean>(false);

  const { isLoading, response } = useSelector<RootState, IMeState>((state: RootState) => state.me);

  const { isLoading: citiesIsLoading, response: citiesResponse } = useSelector<RootState, ICitiesState>(
    (state: RootState) => state.cities
  );

  const { isLoading: districtsIsLoading, response: districtsResponse } = useSelector<RootState, IDistrictsState>(
    (state: RootState) => state.districts
  );

  const {
    isLoading: updateProfileIsLoading,
    response: updateProfileResponse,
    error: updateProfileError,
  } = useSelector<RootState, IUpdateProfileState>((state: RootState) => state.updateProfile);

  const getCityDistricts = useCallback(
    (cityId: string) => {
      dispatch(actions.districts(cityId));
    },
    [dispatch]
  );

  const [formAlert, setFormAlert] = useState<AlertStateType>(AlertDefaultState);

  useEffect(() => {
    if (citiesResponse === null) {
      dispatch(actions.cities());
    }
  }, [citiesResponse, dispatch]);

  useEffect(() => {
    const cityId = response?.data.city?.id;
    if (cityId) {
      getCityDistricts(cityId);
    }
  }, [response, getCityDistricts]);

  useEffect(() => {
    setFormDisable(citiesIsLoading || districtsIsLoading || updateProfileIsLoading);
  }, [citiesIsLoading, districtsIsLoading, updateProfileIsLoading]);

  const { register, handleSubmit, watch, errors } = useForm<FormDataType>();
  const onSubmit = async (data: FormDataType) => {
    setFormAlert(AlertDefaultState);

    const params: IUpdateProfileParams = {
      name: data.name,
      lastname: data.lastname,
      phone: data.phone,
      city: data.city,
      district: data.district,
      email: data.email,
    };

    if (data.avatar.length > 0) {
      params.avatar = await fileObjectToBase64(data.avatar[0]);
    }

    if (data.password !== "") {
      params.password = data.password;
      params.password_confirmation = data.password_confirmation;
    }

    dispatch(await actions.updateProfile(params));
  };

  useEffect(() => {
    if (updateProfileError !== null) {
      if (updateProfileError.response.status === 400) {
        setFormAlert({
          variant: "danger",
          show: true,
          messages: updateProfileError.response.data.messages,
        });
      } else {
        addToast(t("unknown_error"), {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }

    if (updateProfileResponse) {
      addToast(t("your_profile_updated"), {
        appearance: "success",
        autoDismiss: true,
      });

      dispatch({ type: "UPDATE_PROFILE_RESET" });
      history.push("/profile");
    }
  }, [updateProfileResponse, addToast, t, updateProfileError, dispatch]);

  return (
    <MainLayout>
      <FormAlert variant={formAlert.variant} show={formAlert.show}>
        {formAlert.messages.map((message, key) => {
          return <p key={key}>{message}</p>;
        })}
      </FormAlert>

      <LoadingIndicator show={isLoading} />

      {response && (
        <Card className={formDisable ? "loading-block" : ""}>
          <Card.Header as="h6">{t("profile")}</Card.Header>
          <Card.Body>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Row>
                <Form.Group as={Col} md="4" controlId="profile-name">
                  <Form.Label>{t("name")}:</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    ref={register({
                      required: {
                        value: true,
                        message: t("validation.you_must_enter", { name: t("name") }),
                      },
                    })}
                    placeholder={t("name")}
                    defaultValue={response.data.name}
                    autoFocus
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="profile-lastname">
                  <Form.Label>{t("lastname")}:</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastname"
                    ref={register({
                      required: {
                        value: true,
                        message: t("validation.you_must_enter", { name: t("lastname") }),
                      },
                    })}
                    placeholder={t("lastname")}
                    defaultValue={response.data.lastname}
                    isInvalid={!!errors.lastname}
                  />
                  <Form.Control.Feedback type="invalid">{errors.lastname?.message}</Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              <hr />
              <Form.Row>
                <Form.Group as={Col} sm="6" md="4" lg="3" controlId="profile-phone">
                  <Form.Label>{t("phone")}:</Form.Label>
                  <Form.Control
                    type="number"
                    name="phone"
                    ref={register({
                      required: {
                        value: true,
                        message: t("validation.you_must_enter", { name: t("phone") }),
                      },
                      min: {
                        value: 11,
                        message: t("validation.min", { min: 11 }),
                      },
                    })}
                    maxLength={11}
                    placeholder={t("phone")}
                    defaultValue={response.data.phone}
                    isInvalid={!!errors.phone}
                  />
                  <Form.Control.Feedback type="invalid">{errors.phone?.message}</Form.Control.Feedback>
                </Form.Group>
                {citiesResponse && (
                  <Form.Group as={Col} md="3" controlId="profile-city">
                    <Form.Label>{t("city")}:</Form.Label>
                    <Form.Control
                      as="select"
                      custom
                      name="city"
                      ref={register({
                        required: {
                          value: true,
                          message: t("validation.you_must_choose", { name: t("city") }),
                        },
                      })}
                      defaultValue={response.data.city?.id}
                      onChange={(event) => getCityDistricts(event.target.value)}
                      isInvalid={!!errors.city}
                    >
                      <option value="">{t("select_city")}</option>
                      {citiesResponse?.data.items.map((city, key) => {
                        return (
                          <option value={city.id} key={key}>
                            {city.name}
                          </option>
                        );
                      })}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">{errors.city?.message}</Form.Control.Feedback>
                  </Form.Group>
                )}
                {districtsResponse && (
                  <Form.Group as={Col} md="3" controlId="profile-district">
                    <Form.Label>{t("district")}:</Form.Label>
                    <Form.Control
                      as="select"
                      custom
                      name="district"
                      ref={register({
                        required: {
                          value: true,
                          message: t("validation.you_must_choose", { name: t("district") }),
                        },
                      })}
                      defaultValue={response.data.district?.id}
                      isInvalid={!!errors.district}
                    >
                      <option value="">{t("select_district")}</option>
                      {districtsResponse?.data.items.map((district, key) => {
                        return (
                          <option value={district.id} key={key}>
                            {district.name}
                          </option>
                        );
                      })}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">{errors.district?.message}</Form.Control.Feedback>
                  </Form.Group>
                )}
              </Form.Row>
              <hr />
              <Form.Row>
                <Form.Group as={Col} md="4" controlId="profile-email">
                  <Form.Label>{t("email")}:</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    ref={register({
                      required: {
                        value: true,
                        message: t("validation.you_must_enter", { name: t("email") }),
                      },
                      pattern: {
                        value: EMAIL_REGEX_PATTERN,
                        message: t("validation.invalid", { name: t("email") }),
                      },
                    })}
                    placeholder={t("email")}
                    defaultValue={response.data.email}
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              <hr />
              <Form.Row>
                <Form.Group as={Col} md="4" controlId="profile-avatar">
                  <Form.Label>{t("avatar")}:</Form.Label>
                  <CustomFileInput
                    name="avatar"
                    accept="image/jpeg,image/jpg,image/png"
                    ref={register}
                    isInvalid={!!errors.avatar}
                  />
                  <Form.Control.Feedback type="invalid">{errors.avatar?.message}</Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              <hr />
              <Row>
                <Col md="6">
                  <Alert variant="secondary">{t("update_password_notice")}</Alert>
                </Col>
              </Row>
              <Form.Row>
                <Form.Group as={Col} md="3" controlId="profile-password">
                  <Form.Label>{t("password")}:</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    ref={register({
                      minLength: {
                        value: 6,
                        message: t("validation.min", { min: 6 }),
                      },
                      maxLength: {
                        value: 100,
                        message: t("validation.max", { max: 100 }),
                      },
                    })}
                    placeholder={t("password")}
                    isInvalid={!!errors.password}
                  />
                  <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="profile-password-confirmation">
                  <Form.Label>{t("password_confirmation")}:</Form.Label>
                  <Form.Control
                    type="password"
                    name="password_confirmation"
                    ref={register({
                      validate: (value) =>
                        value === watch("password") || t("validation.passwords_must_match").toString(),
                    })}
                    placeholder={t("password_confirmation")}
                    isInvalid={!!errors.password_confirmation}
                  />
                  <Form.Control.Feedback type="invalid">{errors.password_confirmation?.message}</Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
            </Form>
          </Card.Body>
          <Card.Footer className="text-right">
            <Row>
              <Col xs="6" className="text-left">
                <Button as={NavLink} to="/profile" variant="secondary" size="sm" className="with-icon">
                  <RiArrowLeftSLine />
                  <span>{t("profile")}</span>
                </Button>
              </Col>
              <Col xs="6" className="text-right">
                <Button variant="primary" size="sm" className="with-icon" onClick={handleSubmit(onSubmit)}>
                  <RiCheckFill />
                  <span>{t("update")}</span>
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      )}
    </MainLayout>
  );
};

export default Edit;
