import { Reducer } from "react";
import {
  ITeamsState,
  TeamsActionType,
  ITeamState,
  TeamActionType,
  CreateTeamActionType,
  UpdateTeamActionType,
  DeleteTeamActionType,
  IDefaultState,
} from "../types";

// Teams
const teamsInitialState: ITeamsState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const teamsReducer: Reducer<ITeamsState, TeamsActionType> = (state = teamsInitialState, action): ITeamsState => {
  switch (action.type) {
    case "TEAMS_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "TEAMS_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "TEAMS_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "TEAMS_RESET":
      return teamsInitialState;
    default:
      return state;
  }
};

// Team
const teamInitialState: ITeamState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const teamReducer: Reducer<ITeamState, TeamActionType> = (state = teamInitialState, action): ITeamState => {
  switch (action.type) {
    case "TEAM_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "TEAM_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "TEAM_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "TEAM_RESET":
      return teamInitialState;
    default:
      return state;
  }
};

/**
 * @param state
 * @param action
 */
export const createTeamReducer: Reducer<ITeamState, CreateTeamActionType> = (
  state = teamInitialState,
  action
): ITeamState => {
  switch (action.type) {
    case "CREATE_TEAM_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "CREATE_TEAM_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "CREATE_TEAM_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "CREATE_TEAM_RESET":
      return teamInitialState;
    default:
      return state;
  }
};

/**
 * @param state
 * @param action
 */
export const updateTeamReducer: Reducer<ITeamState, UpdateTeamActionType> = (
  state = teamInitialState,
  action
): ITeamState => {
  switch (action.type) {
    case "UPDATE_TEAM_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "UPDATE_TEAM_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "UPDATE_TEAM_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "UPDATE_TEAM_RESET":
      return teamInitialState;
    default:
      return state;
  }
};

const teamDeleteInitialState: IDefaultState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const deleteTeamReducer: Reducer<IDefaultState, DeleteTeamActionType> = (
  state = teamDeleteInitialState,
  action
): ITeamState => {
  switch (action.type) {
    case "DELETE_TEAM_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "DELETE_TEAM_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "DELETE_TEAM_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "DELETE_TEAM_RESET":
      return teamDeleteInitialState;
    default:
      return state;
  }
};
