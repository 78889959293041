import { Dispatch } from "redux";
import { AdminApiClient } from "../../utils/http-client";
import { INewsParams, IPaginationParams } from "../types";

const adminApiClient = AdminApiClient.getInstance();

export const newsList = (queryParams?: IPaginationParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "NEWS_LIST_START" });
  await adminApiClient
    .newsList(queryParams)
    .then((response) => {
      dispatch({ type: "NEWS_LIST_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "NEWS_LIST_FAILURE", payload: error }));
};

export const news = (id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: "NEWS_START" });
  await adminApiClient
    .news(id)
    .then((response) => {
      dispatch({ type: "NEWS_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "NEWS_FAILURE", payload: error }));
};

export const createNews = (params: INewsParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "CREATE_NEWS_START" });
  await adminApiClient
    .createNews(params)
    .then((response) => {
      dispatch({ type: "CREATE_NEWS_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "CREATE_NEWS_FAILURE", payload: error }));
};

export const updateNews = (id: string, params: INewsParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "UPDATE_NEWS_START" });
  await adminApiClient
    .updateNews(id, params)
    .then((response) => {
      dispatch({ type: "UPDATE_NEWS_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "UPDATE_NEWS_FAILURE", payload: error }));
};

export const deleteNews = (id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: "DELETE_NEWS_START" });
  await adminApiClient
    .deleteNews(id)
    .then((response) => {
      dispatch({ type: "DELETE_NEWS_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "DELETE_NEWS_FAILURE", payload: error }));
};
