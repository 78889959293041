import { Dispatch } from "redux";
import { AdminApiClient } from "../../utils/http-client";
import { IAppointmentRecordsPaginationParams } from "../types";

const adminApiClient = AdminApiClient.getInstance();

export const appointmentRecords = (queryParams?: IAppointmentRecordsPaginationParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "APPOINTMENT_RECORDS_START" });
  await adminApiClient
    .appointmentRecords(queryParams)
    .then((response) => {
      dispatch({ type: "APPOINTMENT_RECORDS_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "APPOINTMENT_RECORDS_FAILURE", payload: error }));
};

export const appointmentRecord = (id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: "APPOINTMENT_RECORD_START" });
  await adminApiClient
    .appointmentRecord(id)
    .then((response) => {
      dispatch({ type: "APPOINTMENT_RECORD_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "APPOINTMENT_RECORD_FAILURE", payload: error }));
};
