import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { MainLayout } from "../../../layouts";
import { Button, Card, Col, Row } from "react-bootstrap";
import { RiArrowLeftSLine, RiDeleteBin6Line, RiEditBoxLine, RiEyeLine } from "react-icons/all";
import { RootState } from "../../../../store/reducers";
import { ISectorState, IDefaultState } from "../../../../store/types";
import { LoadingIndicator, DeleteConfirmModal } from "../../../partials";
import actions from "../../../../store/actions";

type RouterParamsType = {
  id: string;
};

export const Show = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { addToast } = useToasts();
  const { id } = useParams<RouterParamsType>();

  const { isLoading, response, error } = useSelector<RootState, ISectorState>((state: RootState) => state.sector);

  const {
    isLoading: deleteIsLoading,
    response: deleteResponse,
    error: deleteError,
  } = useSelector<RootState, IDefaultState>((state: RootState) => state.deleteSector);

  const [deleteItem, setDeleteItem] = useState<string | null>(null);
  const onDeleteConfirm = async () => {
    if (deleteItem) {
      dispatch(await actions.deleteSector(deleteItem));
    }
  };

  useEffect(() => {
    if (error?.response) {
      if (error.response.status === 400 || error.response.status === 404) {
        dispatch({ type: "SECTOR_RESET" });
        history.push("/404");
      }
    } else {
      dispatch(actions.sector(id, { subsector: true }));
    }
  }, [dispatch, id, error, history]);

  useEffect(() => {
    if (deleteError) {
      addToast(t("unknown_error"), {
        appearance: "error",
        autoDismiss: true,
      });
    }

    if (deleteResponse) {
      addToast(t("deleted_with_param", { param: t("sector") }), {
        appearance: "success",
        autoDismiss: true,
      });

      dispatch({ type: "DELETE_SECTOR_RESET" });

      history.push("/sector-management/sectors");
    }
  }, [deleteResponse, deleteError, addToast, t, dispatch, history]);

  return (
    <MainLayout>
      <LoadingIndicator show={isLoading} />
      {response && (
        <Card className={deleteIsLoading ? "loading-block" : ""}>
          <Card.Header as="h6">{t("show")}</Card.Header>
          <Card.Body>
            <div className="content-view">
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("id")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.id}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("title")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.title}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("slug")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.slug}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("nace")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.nace}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("code")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.code}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("sub_sectors")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {response.data.subsector && response.data.subsector.length ? (
                    <div className="table-responsive">
                      <table className="table table-bordered table-hover table-striped">
                        <thead>
                          <tr>
                            <th>{t("name")}</th>
                            <th>{t("code")}</th>
                            <th className="text-center">{t("details")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {response.data.subsector.map((sub) => {
                            return (
                              <tr>
                                <td>{sub.name}</td>
                                <td>{sub.code}</td>
                                <td className="text-center">
                                  <Button
                                    as={NavLink}
                                    to={`/sector-management/sub-sectors/${sub.id}`}
                                    variant="outline-info"
                                    size={"sm"}
                                    className="btn-icon"
                                    title={t("show")}
                                  >
                                    <RiEyeLine />
                                  </Button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <>-</>
                  )}
                </dd>
              </dl>
            </div>
          </Card.Body>
          <Card.Footer>
            <Row>
              <Col xs="6" className="text-left">
                <Button
                  as={NavLink}
                  to="/sector-management/sectors"
                  variant="secondary"
                  size="sm"
                  className="with-icon"
                >
                  <RiArrowLeftSLine />
                  <span>{t("list")}</span>
                </Button>
              </Col>
              <Col xs="6" className="text-right">
                <Button
                  as={NavLink}
                  to={`/sector-management/sectors/${id}/edit`}
                  variant="warning"
                  size="sm"
                  className="with-icon mr-2"
                >
                  <RiEditBoxLine />
                  <span>{t("edit")}</span>
                </Button>
                <DeleteConfirmModal
                  show={deleteItem !== null}
                  onClose={() => setDeleteItem(null)}
                  onConfirm={onDeleteConfirm}
                />
                <Button variant="danger" size="sm" className="with-icon" onClick={() => setDeleteItem(id)}>
                  <RiDeleteBin6Line />
                  <span>{t("delete")}</span>
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      )}
    </MainLayout>
  );
};

export default Show;
