import { Reducer } from "react";
import {
  IAppointmentRecordsState,
  AppointmentRecordsActionType,
  IAppointmentRecordState,
  AppointmentRecordActionType,
} from "../types";

// AppointmentRecords
const appointmentRecordsInitialState: IAppointmentRecordsState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const appointmentRecordsReducer: Reducer<IAppointmentRecordsState, AppointmentRecordsActionType> = (
  state = appointmentRecordsInitialState,
  action
): IAppointmentRecordsState => {
  switch (action.type) {
    case "APPOINTMENT_RECORDS_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "APPOINTMENT_RECORDS_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "APPOINTMENT_RECORDS_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "APPOINTMENT_RECORDS_RESET":
      return appointmentRecordsInitialState;
    default:
      return state;
  }
};

// AppointmentRecord
const appointmentRecordInitialState: IAppointmentRecordState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const appointmentRecordReducer: Reducer<IAppointmentRecordState, AppointmentRecordActionType> = (
  state = appointmentRecordInitialState,
  action
): IAppointmentRecordState => {
  switch (action.type) {
    case "APPOINTMENT_RECORD_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "APPOINTMENT_RECORD_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "APPOINTMENT_RECORD_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "APPOINTMENT_RECORD_RESET":
      return appointmentRecordInitialState;
    default:
      return state;
  }
};
