import { Reducer } from "react";
import { IContactState, IUpdateContactState, ContactActionType, UpdateContactActionType } from "../types";

// Contact
const contactInitialState: IContactState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const contactReducer: Reducer<IContactState, ContactActionType> = (
  state: IContactState = contactInitialState,
  action: ContactActionType
): IContactState => {
  switch (action.type) {
    case "CONTACT_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "CONTACT_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "CONTACT_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "CONTACT_RESET":
      return { ...state, isLoading: false, response: null, error: action.payload };
    default:
      return state;
  }
};

// Update contact
const updateContactInitialState: IUpdateContactState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const updateContactReducer: Reducer<IUpdateContactState, UpdateContactActionType> = (
  state: IUpdateContactState = updateContactInitialState,
  action: UpdateContactActionType
): IContactState => {
  switch (action.type) {
    case "UPDATE_CONTACT_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "UPDATE_CONTACT_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "UPDATE_CONTACT_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "UPDATE_CONTACT_RESET":
      return updateContactInitialState;
    default:
      return state;
  }
};
