import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { MainLayout } from "../../../layouts";
import { Button, Card, Col, Row } from "react-bootstrap";
import { RiArrowLeftSLine, RiDeleteBin6Line, RiEditBoxLine } from "react-icons/all";
import { RootState } from "../../../../store/reducers";
import { ISubSectorState, IDefaultState } from "../../../../store/types";
import { LoadingIndicator, DeleteConfirmModal } from "../../../partials";
import actions from "../../../../store/actions";

type RouterParamsType = {
  id: string;
};

export const Show = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { addToast } = useToasts();
  const { id } = useParams<RouterParamsType>();

  const { isLoading, response, error } = useSelector<RootState, ISubSectorState>((state: RootState) => state.subSector);

  const {
    isLoading: deleteIsLoading,
    response: deleteResponse,
    error: deleteError,
  } = useSelector<RootState, IDefaultState>((state: RootState) => state.deleteSubSector);

  const [deleteItem, setDeleteItem] = useState<string | null>(null);
  const onDeleteConfirm = async () => {
    if (deleteItem) {
      dispatch(await actions.deleteSubSector(deleteItem));
    }
  };

  useEffect(() => {
    if (error?.response) {
      if (error.response.status === 400 || error.response.status === 404) {
        dispatch({ type: "SUB_SECTOR_RESET" });
        history.push("/404");
      }
    } else {
      dispatch(actions.subSector(id));
    }
  }, [dispatch, id, error, history]);

  useEffect(() => {
    if (deleteError) {
      addToast(t("unknown_error"), {
        appearance: "error",
        autoDismiss: true,
      });
    }

    if (deleteResponse) {
      addToast(t("deleted_with_param", { param: t("sub_sector") }), {
        appearance: "success",
        autoDismiss: true,
      });

      dispatch({ type: "DELETE_SUB_SECTOR_RESET" });

      history.push("/sector-management/sub-sectors");
    }
  }, [deleteResponse, deleteError, addToast, t, dispatch, history]);

  return (
    <MainLayout>
      <LoadingIndicator show={isLoading} />
      {response && (
        <Card className={deleteIsLoading ? "loading-block" : ""}>
          <Card.Header as="h6">{t("show")}</Card.Header>
          <Card.Body>
            <div className="content-view">
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("id")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.id}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("sector")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  <Button
                    as={NavLink}
                    size="sm"
                    to={`/sector-management/sectors/${response.data.sector.id}`}
                    variant="outline-secondary"
                    className="mr-2 mb-2"
                  >
                    {response.data.sector.title}
                  </Button>
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("title")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.name}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("code")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.code}</dd>
              </dl>
            </div>
          </Card.Body>
          <Card.Footer>
            <Row>
              <Col xs="6" className="text-left">
                <Button
                  as={NavLink}
                  to="/sector-management/sub-sectors"
                  variant="secondary"
                  size="sm"
                  className="with-icon"
                >
                  <RiArrowLeftSLine />
                  <span>{t("list")}</span>
                </Button>
              </Col>
              <Col xs="6" className="text-right">
                <Button
                  as={NavLink}
                  to={`/sector-management/sub-sectors/${id}/edit`}
                  variant="warning"
                  size="sm"
                  className="with-icon mr-2"
                >
                  <RiEditBoxLine />
                  <span>{t("edit")}</span>
                </Button>
                <DeleteConfirmModal
                  show={deleteItem !== null}
                  onClose={() => setDeleteItem(null)}
                  onConfirm={onDeleteConfirm}
                />
                <Button variant="danger" size="sm" className="with-icon" onClick={() => setDeleteItem(id)}>
                  <RiDeleteBin6Line />
                  <span>{t("delete")}</span>
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      )}
    </MainLayout>
  );
};

export default Show;
