export const FaqTypes = {
  user: "user",
  mentor: "mentors",
  help: "help",
};

export const UserRoleTypes = {
  admin: "ADMIN",
  mentor: "MENTOR",
  user: "USER",
};

export const GenderTypes = {
  male: "male",
  female: "female",
  other: "other",
};

export const EducationStatusTypes = {
  high_school: "high_school",
  college: "college",
  bachelor_degree: "bachelor_degree",
  master_degree: "master_degree",
  phd: "phd",
  other: "other",
};

export const BusinessTypes = {
  individual_company: "individual_company",
  limited_company: "limited_company",
  incorporated_company: "incorporated_company",
  cooperative: "cooperative",
  company_at_idea_stage: "company_at_idea_stage",
  individual_application: "individual_application",
};

export const LanguageTypes = {
  tr: "tr",
  en: "en",
  de: "de",
  fr: "fr",
  ar: "ar",
};

export const DisabilityTypes = {
  yes: "yes",
  no: "no",
  other: "other",
};

export const ApplicationStatusTypes = {
  waiting: "waiting",
  approved: "approved",
  rejected: "rejected",
};

export const AppointmentTypes = {
  meet: "MEET",
  lesson: "LESSON",
  set_by_mentor: "SET_BY_MENTOR_APPOINTMENT",
};

export const AppointmentStatusTypes = {
  waiting: "WAITING",
  not_yet_start: "NOT_YET_START",
  past: "PAST",
  available: "AVAILABLE",
  unavailable: "UNAVAILABLE",
};

export const AppointmentStatusFilterTypes = {
  waiting: "WAITING",
  not_yet_start: "NOT_YET_START",
  past: "PAST",
  available: "AVAILABLE",
  done: "DONE",
};

export const LogsMethodTypes = {
  post: "post",
  patch: "patch",
  delete: "delete",
  get: "get",
  put: "put",
};

export const LogTypes = {
  info: "info",
  debug: "debug",
  error: "error",
};

export const LogStatusTypes = {
  200: 200,
  201: 201,
  400: 400,
  401: 401,
  403: 403,
  404: 404,
};

export const SupportReadTypes = {
  true: "read",
  false: "unread",
};
