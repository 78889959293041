import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { MainLayout } from "../../layouts";
import { Button, ButtonGroup, Card, Col, Row, Table, Form } from "react-bootstrap";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { RiAddBoxLine, RiDeleteBin6Line, RiEditBoxLine, RiEyeLine } from "react-icons/all";
import { RootState } from "../../../store/reducers";
import { CategoryType, ICategoriesState, ITeamsPaginationParams, ITeamsState, UserFilterSelectType } from "../../../store/types";
import { TeamDetailModal, TeamDetailModalType, TeamDetailModalDefaultState } from "./TeamDetailModal";
import { DeleteConfirmModal, LoadingIndicator, Pagination, RecordNotFound } from "../../partials";
import actions from "../../../store/actions";
import { DEFAULT_PER_PAGE } from "../../../types";
import qs from "query-string";
import Select from "react-select";

export const ListTeams = () => {
  const [teamDetailModal, setTeamModal] = useState<TeamDetailModalType>(TeamDetailModalDefaultState);
  const countObjects = (param: any) => {
    return param.length;
  };
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const filterQRef = useRef<HTMLInputElement>(null);
  const filterTeamLeadRef = useRef<HTMLInputElement>(null);
  const filterProcessManagerRef = useRef<HTMLInputElement>(null);
  const filterProjectManagerRef = useRef<HTMLInputElement>(null);
  const [selectedCategories, setSelectedCategories] = useState<UserFilterSelectType[]>([]);
  const { isLoading, response, error } = useSelector<RootState, ITeamsState>((state: RootState) => state.teams);
  const { response: deleteResponse } = useSelector<RootState, ITeamsState>((state: RootState) => state.deleteTeam);

  const queryString = new URLSearchParams(useLocation().search);
  const page: number = queryString.get("page") ? Number(queryString.get("page")) : 1;
  const role: string | null = queryString.get("role") ? queryString.get("role") : null;
  const q: string | null = queryString.get("q") ? queryString.get("q") : null;
  const team_lead: string | null = queryString.get("team_lead") ? queryString.get("team_lead") : null;
  const process_manager: string | null = queryString.get("process_manager") ? queryString.get("process_manager") : null;
  const project_manager: string | null = queryString.get("project_manager") ? queryString.get("project_manager") : null;
  const categories: string | null = queryString.get("categories") ? queryString.get("categories") : null;

  const { response: categoriesResponse } = useSelector<RootState, ICategoriesState>(
    (state: RootState) => state.categories
  );

  useEffect((): void => {
    if (categories && categoriesResponse) {
      const selectedCategoriesBag: Array<UserFilterSelectType> = [];

      categories.split(",").forEach((id: string): void => {
        const find: CategoryType | undefined = categoriesResponse.data.items.find((category: CategoryType) => category.id === id);
        if (find) {
          selectedCategoriesBag.push({
            label: find.title,
            value: find.id,
          });
        }
      });
      setSelectedCategories(selectedCategoriesBag);
    } else {
      setSelectedCategories([]);
    }
  }, [categories, categoriesResponse]);

  const [queryParams, setQueryParams] = useState<ITeamsPaginationParams>({
    page: page,
    per: DEFAULT_PER_PAGE,
    team_lead: "",
    process_manager: "",
    project_managers: [],
    categories: [],
  });
  const qParams: ITeamsPaginationParams = useMemo<ITeamsPaginationParams>(() => qs.parse(search), [search]);

  const getTeams = useCallback(
    (queryParams?): void => {
      dispatch(actions.teams(queryParams));
    },
    [dispatch]
  );

  const [deleteItem, setDeleteItem] = useState<string | null>(null);
  const onDeleteConfirm = async () => {
    if (deleteItem) {
      dispatch(await actions.deleteTeam(deleteItem));
      setDeleteItem(null);
    }
  };

  useEffect(() => {
    const params: ITeamsPaginationParams = qParams;
    params.per = DEFAULT_PER_PAGE;

    if (categories) {
      params.categories = categories.split(",");
    }
    setQueryParams(params);
    if (!categoriesResponse) {
      dispatch(actions.categories());
    }
    getTeams(params);
  }, [qParams, categories, categoriesResponse, getTeams, dispatch]);

  const handleSubmitFilter = (): void => {
    let url: string = `/teams?page=1`;
    const searchParams: Array<string> = [];

    if (filterTeamLeadRef.current!.value) {
      searchParams.push(`team_lead=${filterTeamLeadRef.current!.value}`);
      queryParams.team_lead = filterTeamLeadRef.current!.value;
    }

    if (filterProcessManagerRef.current!.value) {
      searchParams.push(`process_manager=${filterProcessManagerRef.current!.value}`);
      queryParams.team_lead = filterProcessManagerRef.current!.value;
    }

    // if (filterProjectManagerRef.current!.value) {
    //   searchParams.push(`project_manager=${filterProjectManagerRef.current!.value}`);
    //   queryParams.team_lead = filterProjectManagerRef.current!.value;
    // }

    if (selectedCategories.length > 0) {
      searchParams.push(`categories=${selectedCategories.map((category: UserFilterSelectType) => category.value).join(",")}`);
      queryParams.categories = selectedCategories.map((category: UserFilterSelectType) => category.value);
    } else {
      queryParams.categories = [];
    }

    if (filterQRef.current!.value) {
      searchParams.push(`q=${filterQRef.current!.value}`);
      queryParams.q = filterQRef.current!.value;
    }

    if (searchParams.length) {
      url += "&" + searchParams.join("&");
    }

    history.push(url);
  };

  useEffect((): void => {
    if (deleteResponse) {
      addToast(t("deleted_with_param", { param: t("team") }), {
        appearance: "success",
        autoDismiss: true,
      });

      getTeams({
        page: page,
        per: DEFAULT_PER_PAGE,
        role,
        q,
        categories: selectedCategories.map((category) => category.value),
      });
      dispatch({ type: "DELETE_TEAM_RESET" });
    }
  }, [deleteResponse, addToast, t, getTeams, page, role, q, selectedCategories, dispatch]);

  useEffect((): void => {
    if (error?.response) {
      dispatch({ type: "TEAMS_RESET" });

      if (error.response.status >= 400) {
        history.push("/teams");
      }
    } else {
      getTeams({ page, per: DEFAULT_PER_PAGE });
    }
  }, [getTeams, page, error, history, dispatch]);

  return (
    <MainLayout>
      <LoadingIndicator show={isLoading} />
      {response && (
        <Row>
          <Col md="12">
            <Row>
              <Col md="12" className="order-first order-md-last">
                <Card >
                  <Card.Body style={{ display: 'flex', flexDirection: 'column' }}>
                    <Form onSubmit={handleSubmitFilter} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                      <Form.Group controlId="filter-teamLead" style={{ marginRight: '1rem', marginBottom: '1rem', width: '18%' }}>
                        <Form.Label>{t("team_lead")}</Form.Label>
                        <Form.Control
                          type="text"
                          name="q"
                          ref={filterTeamLeadRef}
                          placeholder={t("team_lead")}
                          onKeyPress={(e: any) => e.code === "Enter" && handleSubmitFilter()}
                          defaultValue={team_lead ? team_lead : ""}
                        />
                      </Form.Group>
                      <Form.Group controlId="filter-process_manager" style={{ marginRight: '1rem', marginBottom: '1rem', width: '18%' }}>
                        <Form.Label>{t("process_manager")}</Form.Label>
                        <Form.Control
                          type="text"
                          name="q"
                          ref={filterProcessManagerRef}
                          placeholder={t("process_manager")}
                          onKeyPress={(e: any) => e.code === "Enter" && handleSubmitFilter()}
                          defaultValue={process_manager ? process_manager : ""}
                        />
                      </Form.Group>
                      {/* <Form.Group controlId="filter-project_manager" style={{ marginRight: '1rem', marginBottom: '1rem', width: '18%' }}>
                        <Form.Label>{t("interns")}</Form.Label>
                        <Form.Control
                          type="text"
                          name="q"
                          ref={filterProjectManagerRef}
                          placeholder={t("interns")}
                          onKeyPress={(e: any) => e.code === "Enter" && handleSubmitFilter()}
                          defaultValue={project_manager ? project_manager : ""}
                        />
                      </Form.Group> */}
                      <Form.Group controlId="filter-q" style={{ marginRight: '1.1rem', marginBottom: '1rem', width: '18%' }}>
                        <Form.Label>{t("mentor_teams")}</Form.Label>
                        <Form.Control
                          type="text"
                          name="q"
                          ref={filterQRef}
                          placeholder={t("mentor_teams")}
                          onKeyPress={(e: any) => e.code === "Enter" && handleSubmitFilter()}
                          defaultValue={q ? q : ""}
                        />
                      </Form.Group>
                      <Form.Group controlId="filter-categories" style={{ marginRight: '1rem', marginBottom: '1rem', width: '18%' }}>
                        <Form.Label>{t("categories")}</Form.Label>
                        {categoriesResponse && (
                          <Select
                            isMulti
                            isClearable
                            placeholder={t("select_category")}
                            hideSelectedOptions={false}
                            onChange={(items) => setSelectedCategories([...items])}
                            defaultValue={selectedCategories}
                            options={categoriesResponse.data.items.map((category) => ({
                              value: category.id,
                              label: category.title,
                            }))}
                          />
                        )}
                      </Form.Group>
                      <hr />
                      <div style={{ display: 'flex', justifyContent: 'end', width: '94%' }}>
                        <Button style={{ width: '20%' }} variant="outline-secondary" onClick={handleSubmitFilter}>
                          {t("filter")}
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>

              </Col>
            </Row>

            <Card>
              <Card.Header>
                <Row>
                  <Col as="h6" xs="4" className="mb-0">
                    {t("mentor_teams")}
                  </Col>
                  <Col xs="4" className="mb-0 text-right">
                    <strong className="mr-1">{t("total")}:</strong>
                    <span>{response.data.pagination.total}</span>
                  </Col>
                  <Col xs="4" className="text-right">
                    <Button as={NavLink} to="/teams/create" variant="success" size="sm" className="with-icon">
                      <RiAddBoxLine />
                      <span>{t("create")}</span>
                    </Button>
                  </Col>
                </Row>
              </Card.Header>

              <Card.Body className="p-0 m-0">
                <TeamDetailModal modal={teamDetailModal} />
                {response.data.items.length > 0 ? (
                  <>
                    <DeleteConfirmModal
                      show={deleteItem !== null}
                      onClose={() => setDeleteItem(null)}
                      onConfirm={onDeleteConfirm}
                    />
                    <Table responsive striped hover className="table-list m-0">
                      <thead>
                        <tr>
                          <th>{t("team_name")}</th>
                          <th>{t("team_lead")}</th>
                          <th>{t("team_process_manager")}</th>
                          <th>{t("team_interns")}</th>
                          <th>{t("team_mentors")}</th>
                          <th>{t("categories")}</th>
                          <th className="actions">{t("actions")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {response.data.items.map((item, key) => {
                          return (
                            <tr key={key}>
                              <td>
                                <NavLink to={`/teams/${item.id}`} className="text-primary">
                                  {item.name}
                                </NavLink>
                              </td>
                              <td>
                                <div>
                                  <NavLink to={`/users/${item.team_lead.id}`} className="text-success">
                                    {item.team_lead.name}
                                  </NavLink>{" "}
                                  <NavLink to={`/users/${item.team_lead.id}`} className="text-success">
                                    {item.team_lead.lastname}
                                  </NavLink>
                                </div>
                                <NavLink to={`/users/${item.team_lead.id}`} className="text-success">
                                  {item.team_lead.email}
                                </NavLink>
                              </td>
                              <td>
                                <div>
                                  {item.process_manager ? (
                                    <td>
                                      <div>
                                        <NavLink to={`/users/${item.process_manager.id}`}>
                                          {item.process_manager.name}
                                        </NavLink>{" "}
                                        <NavLink to={`/users/${item.process_manager.id}`}>
                                          {item.process_manager.lastname}
                                        </NavLink>
                                      </div>
                                      <NavLink to={`/users/${item.process_manager.id}`}>
                                        {item.process_manager.email}
                                      </NavLink>
                                    </td>
                                  ) : (
                                    "-"
                                  )}
                                </div>
                              </td>
                              <td>
                                <div>
                                  {item.project_managers?.map((d: any, key: number) => (
                                    <div key={key}>
                                      <span>• </span>
                                      <NavLink to={`/users/${d.id}`} className="text-success">
                                        {d.name} {d.lastname}
                                      </NavLink>{" "}
                                      <br />
                                    </div>
                                  ))}
                                </div>

                              </td>
                              <td>
                                <div>
                                  {item.mentors?.map((d: any, key: number) => (
                                    <div key={key}>
                                      <span>• </span>
                                      <NavLink to={`/users/${d.id}`} className="text-success">
                                        {d.name} {d.lastname}
                                      </NavLink>{" "}
                                      <br />

                                    </div>
                                  ))}
                                </div>
                              </td>
                              <td>
                                <div>
                                  {
                                    <ul>
                                      {item.categories.map((exp_valu: CategoryType, exp_key: number) => (
                                        <li key={exp_key}>
                                          <NavLink to={`/sector-management/categories/${exp_valu.id}`}>
                                            {exp_valu.title}
                                          </NavLink>
                                        </li>
                                      ))}
                                    </ul>
                                  }
                                </div>
                              </td>
                              <td className="actions">
                                <ButtonGroup>
                                  <Button
                                    as={NavLink}
                                    to={`/teams/${item.id}`}
                                    variant="outline-info"
                                    size="sm"
                                    className="btn-icon"
                                    title={t("show")}
                                  >
                                    <RiEyeLine />
                                  </Button>
                                  <Button
                                    as={NavLink}
                                    to={`/teams/${item.id}/edit`}
                                    variant="outline-secondary"
                                    size="sm"
                                    className="btn-icon"
                                    title={t("edit")}
                                  >
                                    <RiEditBoxLine />
                                  </Button>
                                  <Button
                                    variant="outline-danger"
                                    size="sm"
                                    className="btn-icon"
                                    title={t("delete")}
                                    onClick={() => setDeleteItem(item.id)}
                                  >
                                    <RiDeleteBin6Line />
                                  </Button>
                                </ButtonGroup>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </>
                ) : (
                  <RecordNotFound />
                )}
              </Card.Body>
              <Card.Footer>
                <Row>
                  <Col xs="12">
                    <Pagination pagination={response.data.pagination} extraParams={["role", "q", "q2"]} />
                  </Col>

                </Row>
              </Card.Footer>
            </Card>

          </Col>
        </Row>
      )}
    </MainLayout>
  );
};

export default ListTeams;
