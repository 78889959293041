import { Reducer } from "react";
import { ISupportListState, SupportActionType, SupportListActionType, ISupportState } from "../types";

// Support list
const supportListInitialState: ISupportListState = {
  isLoading: false,
  response: null,
  error: null,
};

// Support
const supportInitialState: ISupportState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const supportListReducer: Reducer<ISupportListState, SupportListActionType> = (
  state = supportListInitialState,
  action
): ISupportListState => {
  switch (action.type) {
    case "SUPPORT_LIST_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "SUPPORT_LIST_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "SUPPORT_LIST_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "SUPPORT_LIST_RESET":
      return supportListInitialState;
    default:
      return state;
  }
};

/**
 * @param state
 * @param action
 */
export const supportReducer: Reducer<ISupportState, SupportActionType> = (
  state = supportInitialState,
  action
): ISupportState => {
  switch (action.type) {
    case "SUPPORT_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "SUPPORT_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "SUPPORT_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "SUPPORT_RESET":
      return supportInitialState;
    default:
      return state;
  }
};
