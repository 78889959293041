import { Dispatch } from "redux";
import { AdminApiClient } from "../../utils/http-client";
import { ISectorParams, ISectorQueryParams, ISectorsParams } from "../types";

const adminApiClient = AdminApiClient.getInstance();

export const sectors = (queryParams?: ISectorsParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "SECTORS_START" });
  await adminApiClient
    .sectors(queryParams)
    .then((response) => {
      dispatch({ type: "SECTORS_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "SECTORS_FAILURE", payload: error }));
};

export const sector = (id: string, queryParams?: ISectorQueryParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "SECTOR_START" });
  await adminApiClient
    .sector(id, queryParams)
    .then((response) => {
      dispatch({ type: "SECTOR_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "SECTOR_FAILURE", payload: error }));
};

export const createSector = (params: ISectorParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "CREATE_SECTOR_START" });
  await adminApiClient
    .createSector(params)
    .then((response) => {
      dispatch({ type: "CREATE_SECTOR_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "CREATE_SECTOR_FAILURE", payload: error }));
};

export const updateSector = (id: string, params: ISectorParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "UPDATE_SECTOR_START" });
  await adminApiClient
    .updateSector(id, params)
    .then((response) => {
      dispatch({ type: "UPDATE_SECTOR_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "UPDATE_SECTOR_FAILURE", payload: error }));
};

export const deleteSector = (id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: "DELETE_SECTOR_START" });
  await adminApiClient
    .deleteSector(id)
    .then((response) => {
      dispatch({ type: "DELETE_SECTOR_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "DELETE_SECTOR_FAILURE", payload: error }));
};
