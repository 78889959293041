import { Dispatch } from "redux";
import { AdminApiClient } from "../../utils/http-client";
import { IUserParams, IUserCreateParams, IPaginationParams, IUsersExportParams } from "../types";

const adminApiClient = AdminApiClient.getInstance();

export const users = (queryParams?: IPaginationParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "USERS_START" });
  await adminApiClient
    .users(queryParams)
    .then((response) => {
      dispatch({ type: "USERS_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "USERS_FAILURE", payload: error }));
};

export const usersExport = (queryParams?: IUsersExportParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "USERS_EXPORT_START" });
  await adminApiClient
    .usersExport(queryParams)
    .then((response) => {
      dispatch({ type: "USERS_EXPORT_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "USERS_EXPORT_FAILURE", payload: error }));
};

export const user = (id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: "USER_START" });
  await adminApiClient
    .user(id)
    .then((response) => {
      dispatch({ type: "USER_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "USER_FAILURE", payload: error }));
};

export const createUser = (params: IUserCreateParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "CREATE_USER_START" });
  await adminApiClient
    .createUser(params)
    .then((response) => {
      dispatch({ type: "CREATE_USER_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "CREATE_USER_FAILURE", payload: error }));
};

export const createFastUser = (params: IUserCreateParams) => async (dispatch: Dispatch) => {
    dispatch({ type: "CREATE_USER_START" });
    await adminApiClient
        .createFastUser(params)
        .then((response) => {
            dispatch({ type: "CREATE_USER_SUCCESS", payload: response });
        })
        .catch((error) => dispatch({ type: "CREATE_USER_FAILURE", payload: error }));
};

export const updateUser = (id: string, params: IUserParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "UPDATE_USER_START" });
  await adminApiClient
    .updateUser(id, params)
    .then((response) => {
      dispatch({ type: "UPDATE_USER_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "UPDATE_USER_FAILURE", payload: error }));
};

export const deleteUser = (id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: "DELETE_USER_START" });
  await adminApiClient
    .deleteUser(id)
    .then((response) => {
      dispatch({ type: "DELETE_USER_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "DELETE_USER_FAILURE", payload: error }));
};
