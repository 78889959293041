import { Reducer } from "react";
import { 
  IMentoringProgramsState,
  MentoringProgramsActionType,
  MentoringProgramActionType,
  CreateMentoringProgramActionType,
  UpdateMentoringProgramActionType,
  DeleteMentoringProgramActionType,
  IMentoringProgramState,
  IDefaultState,
} from "../types";

// Mentoring Programs
const mentoringProgramsInitialState: IMentoringProgramsState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */

export const mentoringProgramsReducer: Reducer<IMentoringProgramsState, MentoringProgramsActionType> = (state = mentoringProgramsInitialState, action): IMentoringProgramsState => {
  switch (action.type) {
    case "MENTORING_PROGRAMS_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "MENTORING_PROGRAMS_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "MENTORING_PROGRAMS_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "MENTORING_PROGRAMS_RESET":
      return mentoringProgramsInitialState;
    default:
      return state;
  }
};

// Mentoring Program
const mentoringProgramInitialState: IMentoringProgramState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */

export const mentoringProgramReducer: Reducer<IMentoringProgramState, MentoringProgramActionType> = (state = mentoringProgramInitialState, action): IMentoringProgramState => {
  switch (action.type) {
    case "MENTORING_PROGRAM_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "MENTORING_PROGRAM_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "MENTORING_PROGRAM_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "MENTORING_PROGRAM_RESET":
      return mentoringProgramInitialState;
    default:
      return state;
  }
};

/**
 * @param state
 * @param action
 */

export const createMentoringProgramReducer: Reducer<IMentoringProgramState, CreateMentoringProgramActionType> = (state = mentoringProgramInitialState, action): IMentoringProgramState => {
  switch (action.type) {
    case "CREATE_MENTORING_PROGRAM_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "CREATE_MENTORING_PROGRAM_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "CREATE_MENTORING_PROGRAM_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "CREATE_MENTORING_PROGRAM_RESET":
      return mentoringProgramInitialState;
    default:
      return state;
  }
};

/**
 * @param state
 * @param action
 */

export const updateMentoringProgramReducer: Reducer<IMentoringProgramState, UpdateMentoringProgramActionType> = (state = mentoringProgramInitialState, action): IMentoringProgramState => {
  switch (action.type) {
    case "UPDATE_MENTORING_PROGRAM_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "UPDATE_MENTORING_PROGRAM_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "UPDATE_MENTORING_PROGRAM_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "UPDATE_MENTORING_PROGRAM_RESET":
      return mentoringProgramInitialState;
    default:
      return state;
  }
};

const MentoringProgramDeleteInitialState: IDefaultState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */

export const deleteMentoringProgramReducer: Reducer<IDefaultState, DeleteMentoringProgramActionType> = (
  state = MentoringProgramDeleteInitialState,
  action
  ): IMentoringProgramState => {
    switch (action.type) {
      case "DELETE_MENTORING_PROGRAM_START":
        return { ...state, isLoading: true, response: null, error: null };
      case "DELETE_MENTORING_PROGRAM_SUCCESS":
        return { ...state, isLoading: false, response: action.payload.data };
      case "DELETE_MENTORING_PROGRAM_FAILURE":
        return { ...state, isLoading: false, error: action.payload };
      case "DELETE_MENTORING_PROGRAM_RESET":
        return MentoringProgramDeleteInitialState;
      default:
        return state;
    }
};
