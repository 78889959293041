import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { MainLayout } from "../../layouts";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { RiArrowLeftSLine, RiCheckFill } from "react-icons/all";
import { RootState } from "../../../store/reducers";
import { IContactState, IUpdateContactParams, IUpdateContactState } from "../../../store/types";
import { LoadingIndicator, FormAlert } from "../../partials";
import { AlertStateType, AlertDefaultState, EMAIL_REGEX_PATTERN, URL_REGEX_PATTERN } from "../../../types";
import actions from "../../../store/actions";
import history from "../../../utils/history";

export const Edit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const { isLoading, response } = useSelector<RootState, IContactState>((state: RootState) => state.contact);

  const {
    isLoading: updateContactIsLoading,
    response: updateContactResponse,
    error: updateContactError,
  } = useSelector<RootState, IUpdateContactState>((state: RootState) => state.updateContact);

  const [formAlert, setFormAlert] = useState<AlertStateType>(AlertDefaultState);

  const { register, handleSubmit, errors } = useForm<IUpdateContactParams>();
  const onSubmit = async (data: IUpdateContactParams) => {
    setFormAlert(AlertDefaultState);

    dispatch(await actions.updateContact(data));
  };

  useEffect(() => {
    if (response === null) {
      dispatch(actions.contact());
    }
  }, [response, dispatch]);

  useEffect(() => {
    if (updateContactError !== null) {
      if (updateContactError.response.status === 400) {
        setFormAlert({
          variant: "danger",
          show: true,
          messages: updateContactError.response.data.messages,
        });
      } else {
        addToast(t("unknown_error"), {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }

    if (updateContactResponse) {
      addToast(t("contact_information_updated"), {
        appearance: "success",
        autoDismiss: true,
      });

      dispatch({ type: "UPDATE_CONTACT_RESET" });
      dispatch(actions.contact());
      history.push("/contact");
    }
  }, [updateContactResponse, addToast, t, updateContactError, dispatch]);

  return (
    <MainLayout>
      <FormAlert variant={formAlert.variant} show={formAlert.show}>
        {formAlert.messages.map((message, key) => {
          return <p key={key}>{message}</p>;
        })}
      </FormAlert>

      <LoadingIndicator show={isLoading} />

      {response && (
        <Card className={updateContactIsLoading ? "loading-block" : ""}>
          <Card.Header as="h6">{t("edit")}</Card.Header>
          <Card.Body>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <h6 className="text-muted">{t("general")}</h6>
              <hr />
              <Form.Row>
                <Form.Group as={Col} md="6" controlId="contact-address">
                  <Form.Label>{t("address")}:</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="address"
                    autoFocus
                    ref={register({
                      required: {
                        value: true,
                        message: t("validation.you_must_enter", { name: t("address") }),
                      },
                    })}
                    placeholder={t("address")}
                    defaultValue={response.data.address}
                    isInvalid={!!errors.address}
                  />
                  <Form.Control.Feedback type="invalid">{errors.address?.message}</Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md="3" controlId="contact-phone">
                  <Form.Label>{t("phone")}:</Form.Label>
                  <Form.Control
                    type="text"
                    name="phone"
                    ref={register({
                      required: {
                        value: true,
                        message: t("validation.you_must_enter", { name: t("phone") }),
                      },
                    })}
                    placeholder={t("phone")}
                    defaultValue={response.data.phone}
                    isInvalid={!!errors.phone}
                  />
                  <Form.Control.Feedback type="invalid">{errors.phone?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="contact-fax">
                  <Form.Label>{t("fax")}:</Form.Label>
                  <Form.Control
                    type="text"
                    name="fax"
                    ref={register}
                    placeholder={t("fax")}
                    defaultValue={response.data.fax ?? ""}
                    isInvalid={!!errors.fax}
                  />
                  <Form.Control.Feedback type="invalid">{errors.fax?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="contact-email">
                  <Form.Label>{t("email")}:</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    ref={register({
                      required: {
                        value: true,
                        message: t("validation.you_must_enter", { name: t("email") }),
                      },
                      pattern: {
                        value: EMAIL_REGEX_PATTERN,
                        message: t("validation.invalid", { name: t("email") }),
                      },
                    })}
                    placeholder={t("email")}
                    defaultValue={response.data.email}
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              <h6 className="mt-3 text-muted">{t("location")}</h6>
              <hr />
              <Form.Row>
                <Form.Group as={Col} md="3" controlId="contact-latitude">
                  <Form.Label>{t("latitude")}:</Form.Label>
                  <Form.Control
                    type="number"
                    name="latitude"
                    ref={register}
                    placeholder={t("latitude")}
                    defaultValue={response.data.latitude ?? ""}
                    isInvalid={!!errors.latitude}
                  />
                  <Form.Control.Feedback type="invalid">{errors.latitude?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="contact-longitude">
                  <Form.Label>{t("longitude")}:</Form.Label>
                  <Form.Control
                    type="number"
                    name="longitude"
                    ref={register}
                    placeholder={t("email")}
                    defaultValue={response.data.longitude ?? ""}
                    isInvalid={!!errors.longitude}
                  />
                  <Form.Control.Feedback type="invalid">{errors.longitude?.message}</Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              <h6 className="mt-3 text-muted">{t("social_media")}</h6>
              <hr />
              <Form.Row>
                <Form.Group as={Col} sm="6" md="4" lg="3" controlId="contact-facebook">
                  <Form.Label>{t("facebook")}:</Form.Label>
                  <Form.Control
                    type="text"
                    name="facebook"
                    ref={register({
                      pattern: {
                        value: URL_REGEX_PATTERN,
                        message: t("validation.invalid", { name: t("url") }),
                      },
                    })}
                    placeholder={t("facebook")}
                    defaultValue={response.data.facebook ?? ""}
                    isInvalid={!!errors.facebook}
                  />
                  <Form.Control.Feedback type="invalid">{errors.facebook?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm="6" md="4" lg="3" controlId="contact-twitter">
                  <Form.Label>{t("twitter")}:</Form.Label>
                  <Form.Control
                    type="text"
                    name="twitter"
                    ref={register({
                      pattern: {
                        value: URL_REGEX_PATTERN,
                        message: t("validation.invalid", { name: t("url") }),
                      },
                    })}
                    placeholder={t("twitter")}
                    defaultValue={response.data.twitter ?? ""}
                    isInvalid={!!errors.twitter}
                  />
                  <Form.Control.Feedback type="invalid">{errors.twitter?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm="6" md="4" lg="3" controlId="contact-instagram">
                  <Form.Label>{t("instagram")}:</Form.Label>
                  <Form.Control
                    type="text"
                    name="instagram"
                    ref={register({
                      pattern: {
                        value: URL_REGEX_PATTERN,
                        message: t("validation.invalid", { name: t("url") }),
                      },
                    })}
                    placeholder={t("instagram")}
                    defaultValue={response.data.instagram ?? ""}
                    isInvalid={!!errors.instagram}
                  />
                  <Form.Control.Feedback type="invalid">{errors.instagram?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm="6" md="4" lg="3" controlId="contact-linkedin">
                  <Form.Label>{t("linkedin")}:</Form.Label>
                  <Form.Control
                    type="text"
                    name="linkedin"
                    ref={register({
                      pattern: {
                        value: URL_REGEX_PATTERN,
                        message: t("validation.invalid", { name: t("url") }),
                      },
                    })}
                    placeholder={t("linkedin")}
                    defaultValue={response.data.linkedin ?? ""}
                    isInvalid={!!errors.linkedin}
                  />
                  <Form.Control.Feedback type="invalid">{errors.linkedin?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm="6" md="4" lg="3" controlId="contact-youtube">
                  <Form.Label>{t("youtube")}:</Form.Label>
                  <Form.Control
                    type="text"
                    name="youtube"
                    ref={register({
                      pattern: {
                        value: URL_REGEX_PATTERN,
                        message: t("validation.invalid", { name: t("url") }),
                      },
                    })}
                    placeholder={t("youtube")}
                    defaultValue={response.data.youtube ?? ""}
                    isInvalid={!!errors.youtube}
                  />
                  <Form.Control.Feedback type="invalid">{errors.youtube?.message}</Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
            </Form>
          </Card.Body>
          <Card.Footer className="text-right">
            <Row>
              <Col xs="6" className="text-left">
                <Button as={NavLink} to="/contact" variant="secondary" size="sm" className="with-icon">
                  <RiArrowLeftSLine />
                  <span>{t("contact_information")}</span>
                </Button>
              </Col>
              <Col xs="6" className="text-right">
                <Button variant="primary" size="sm" className="with-icon" onClick={handleSubmit(onSubmit)}>
                  <RiCheckFill />
                  <span>{t("update")}</span>
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      )}
    </MainLayout>
  );
};

export default Edit;
