import { Dispatch } from "redux";
import { AdminApiClient } from "../../utils/http-client";
import { ITeamParams, ITeamCreateParams, IPaginationParams, ITeamsExportParams } from "../types";

const adminApiClient = AdminApiClient.getInstance();

export const teams = (queryParams?: IPaginationParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "TEAMS_START" });
  await adminApiClient
    .teams(queryParams)
    .then((response) => {
      dispatch({ type: "TEAMS_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "TEAMS_FAILURE", payload: error }));
};

export const teamsExport = (queryParams?: ITeamsExportParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "TEAMS_EXPORT_START" });
  await adminApiClient
    .teamsExport(queryParams)
    .then((response) => {
      dispatch({ type: "TEAMS_EXPORT_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "TEAMS_EXPORT_FAILURE", payload: error }));
};

export const team = (id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: "TEAM_START" });
  await adminApiClient
    .team(id)
    .then((response) => {
      dispatch({ type: "TEAM_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "TEAM_FAILURE", payload: error }));
};

export const createTeam = (params: ITeamCreateParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "CREATE_TEAM_START" });
  await adminApiClient
    .createTeam(params)
    .then((response) => {
      dispatch({ type: "CREATE_TEAM_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "CREATE_TEAM_FAILURE", payload: error }));
};

export const updateTeam = (id: string, params: ITeamParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "UPDATE_TEAM_START" });
  await adminApiClient
    .updateTeam(id, params)
    .then((response) => {
      dispatch({ type: "UPDATE_TEAM_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "UPDATE_TEAM_FAILURE", payload: error }));
};

export const deleteTeam = (id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: "DELETE_TEAM_START" });
  await adminApiClient
    .deleteTeam(id)
    .then((response) => {
      dispatch({ type: "DELETE_TEAM_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "DELETE_TEAM_FAILURE", payload: error }));
};
