import { AbstractHttpClient } from "./AbstractHttpClient";
import { AxiosError, AxiosRequestConfig } from "axios";
import { ILoginParams, IPasswordParams, IUpdatePasswordParams } from "../../store/types";
import { app as config } from "../../config";
import AppStorage from "../storage";
import history from "../history";
import store from "../../store";
import actions from "../../store/actions";

export default class AuthApiClient extends AbstractHttpClient {
  /**
   * @private classInstance
   */
  private static classInstance?: AuthApiClient;

  /**
   * @private constructor
   */
  private constructor() {
    super(config.API_URL.replace(/^\/|\/$/g, "").concat("/auth"));

    this._initializeResponseInterceptor();
  }

  /**
   * @param config
   */
  private _setAuthorizationWithRefreshToken = (config: AxiosRequestConfig) => {
    const refreshToken = AppStorage.getRefreshToken();
    if (refreshToken) {
      config.headers.Authorization = `Bearer ${refreshToken}`;
    }

    return config;
  };

  /**
   * Remove tokens from storage.
   */
  private _removeTokensFromStorage = (): void => {
    AppStorage.removeTokens();
  };

  /**
   * @private _initializeResponseInterceptor
   */
  protected _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(this._handleResponse, this._handleError);
  };

  /**
   * @protected _handleError
   * @param error
   */
  protected _handleError = (error: AxiosError) => {
    const statusCode = Number(error.response?.status);
    const originalRequest = error.config;

    if (originalRequest.headers.Authorization && (401 === statusCode || 422 === statusCode)) {
      this._removeTokensFromStorage();
      store.dispatch(actions.loginReset);
      history.push("/auth/login");
      window.location.href = "/auth/login";
    }

    return Promise.reject(error);
  };

  /**
   * @public getInstance
   */
  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new this();
    }

    return this.classInstance;
  }

  /**
   * User login.
   */
  public login = async (params: ILoginParams) => await this.instance.post("/login", params);

  /**
   * Refresh token.
   */
  public refresh = async () => {
    this.instance.interceptors.request.use(this._setAuthorizationWithRefreshToken);

    return await this.instance.patch("/refresh");
  };

  /**
   * Password.
   */
  public password = async (params: IPasswordParams) => await this.instance.post("/password", params);

  /**
   * Update password.
   */
  public updatePassword = async (token: string, params: IUpdatePasswordParams) => {
    return await this.instance.patch(`/password/${token}`, params);
  };
}
