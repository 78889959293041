import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import { NavLink } from "react-router-dom";
import { AuthLayout } from "../../layouts";
import { RootState } from "../../../store/reducers";
import { Alert, Button, Form } from "react-bootstrap";
import { FormAlert, LoadingIndicator } from "../../partials";
import { IPasswordParams, IPasswordState } from "../../../store/types";
import { AlertDefaultState, AlertStateType, EMAIL_REGEX_PATTERN } from "../../../types";
import actions from "../../../store/actions";

type FormDataType = {
  email: string;
};

export const Password = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const [requestSuccess, setRequestSuccess] = useState<boolean>(false);

  const { isLoading, response, error } = useSelector<RootState, IPasswordState>((state: RootState) => state.password);

  const [formAlert, setFormAlert] = useState<AlertStateType>(AlertDefaultState);

  const { register, handleSubmit, watch, reset, errors } = useForm<FormDataType>();
  const onSubmit = async (data: FormDataType) => {
    const params: IPasswordParams = {
      email: data.email,
    };

    setFormAlert(AlertDefaultState);
    dispatch(await actions.password(params));
  };

  useEffect(() => {
    if (error !== null) {
      if (error.response.status > 400) {
        setFormAlert({
          variant: "danger",
          show: true,
          messages: [t("bad_credentials")],
        });
      } else {
        addToast(t("unknown_error"), {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }

    if (response !== null) {
      setRequestSuccess(true);
    }
  }, [isLoading, response, error, addToast, t]);

  useEffect(() => {
    if (requestSuccess) {
      reset();

      setFormAlert({
        variant: "success",
        show: true,
        messages: [t("password_reset_request_success")],
      });

      dispatch({ type: "PASSWORD_RESET" });
    }
  }, [requestSuccess, reset, dispatch, t]);

  return (
    <>
      <AuthLayout>
        <Alert variant="secondary">{t("lost_password_notice")}</Alert>

        <FormAlert variant={formAlert.variant} show={formAlert.show}>
          {formAlert.messages.map((message, key) => {
            return <p key={key}>{message}</p>;
          })}
        </FormAlert>

        <LoadingIndicator show={isLoading} absolute={true} />

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group controlId="password-email">
            <Form.Label>{t("email")}:</Form.Label>
            <Form.Control
              type="email"
              name="email"
              ref={register({
                required: {
                  value: true,
                  message: t("validation.you_must_enter", { name: t("email") }),
                },
                pattern: {
                  value: EMAIL_REGEX_PATTERN,
                  message: t("validation.invalid", { name: t("email") }),
                },
              })}
              autoFocus
              placeholder={t("enter_email")}
              isInvalid={!!errors.email}
            />
            <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
          </Form.Group>

          <Button type="submit" variant="secondary" block disabled={!watch("email")}>
            {t("send")}
          </Button>

          <hr />

          <div className="text-center">
            <NavLink to="/auth/login" className="text-muted">
              {t("login")}
            </NavLink>
          </div>
        </Form>
      </AuthLayout>
    </>
  );
};

export default Password;
