import { Dispatch } from "redux";
import { AdminApiClient } from "../../utils/http-client";
import { IFeaturedMentorParams, IPaginationParams } from "../types";

const adminApiClient = AdminApiClient.getInstance();

export const featuredMentors = (queryParams?: IPaginationParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "FEATURED_MENTORS_START" });
  await adminApiClient
    .featuredMentors(queryParams)
    .then((response) => {
      dispatch({ type: "FEATURED_MENTORS_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "FEATURED_MENTORS_FAILURE", payload: error }));
};

export const updateFeaturedMentor = (id: string, params: IFeaturedMentorParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "UPDATE_FEATURED_MENTOR_START" });
  await adminApiClient
    .updateFeaturedMentor(id, params)
    .then((response) => {
      dispatch({ type: "UPDATE_FEATURED_MENTOR_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "UPDATE_FEATURED_MENTOR_FAILURE", payload: error }));
};
