import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";
import { AuthLayout } from "../../layouts";
import { Form, Button } from "react-bootstrap";
import { NavLink, useHistory } from "react-router-dom";
import { ILoginParams, ILoginState, ILogoutState } from "../../../store/types";
import { RootState } from "../../../store/reducers";
import { LoadingIndicator, FormAlert } from "../../partials";
import { AlertStateType, AlertDefaultState, EMAIL_REGEX_PATTERN } from "../../../types";
import { getRouterFlashState, clearRouterFlashState } from "../../../utils/mixins";
import actions from "../../../store/actions";

type FormDataType = {
  email: string;
  password: string;
  remember_me?: boolean;
};

export const Login = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const history = useHistory();

  const { isLoading, response, error } = useSelector<RootState, ILoginState>((state: RootState) => state.login);

  const { response: logoutResponse } = useSelector<RootState, ILogoutState>((state: RootState) => state.logout);

  const [formAlert, setFormAlert] = useState<AlertStateType>(AlertDefaultState);

  const { register, handleSubmit, watch, errors } = useForm<FormDataType>();
  const onSubmit = async (data: FormDataType) => {
    const params: ILoginParams = {
      email: data.email,
      password: data.password,
      remember_me: data.remember_me,
    };

    setFormAlert(AlertDefaultState);
    dispatch(await actions.login(params));
  };

  useEffect(() => {
    if (error !== null) {
      if (error.response.status > 400) {
        setFormAlert({
          variant: "danger",
          show: true,
          messages: [t("bad_credentials")],
        });
      } else {
        addToast(t("unknown_error"), {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }

    if (response !== null) {
      history.push("/");
    }
  }, [isLoading, response, error, addToast, t, history]);

  useEffect(() => {
    if (logoutResponse === true) {
      dispatch(actions.logout(false));
    }
  }, [logoutResponse, dispatch]);

  useEffect(() => {
    const flashState = getRouterFlashState();
    if (flashState) {
      setFormAlert(flashState);
      clearRouterFlashState();
    }
  }, []);

  return (
    <>
      <AuthLayout>
        <FormAlert variant={formAlert.variant} show={formAlert.show}>
          {formAlert.messages.map((message, key) => {
            return <p key={key}>{message}</p>;
          })}
        </FormAlert>

        <LoadingIndicator show={isLoading} absolute={true} />

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group controlId="login-email">
            <Form.Label>{t("email")}:</Form.Label>
            <Form.Control
              type="email"
              name="email"
              ref={register({
                required: {
                  value: true,
                  message: t("validation.you_must_enter", { name: t("email") }),
                },
                pattern: {
                  value: EMAIL_REGEX_PATTERN,
                  message: t("validation.invalid", { name: t("email") }),
                },
              })}
              autoFocus
              placeholder={t("enter_email")}
              isInvalid={!!errors.email}
            />
            <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="login-password">
            <Form.Label>{t("password")}:</Form.Label>
            <Form.Control
              type="password"
              name="password"
              ref={register({
                required: {
                  value: true,
                  message: t("validation.you_must_enter", { name: t("password") }),
                },
              })}
              placeholder={t("enter_password")}
              isInvalid={!!errors.password}
            />
            <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="login-remember-me" className="text-right">
            <Form.Check type="switch" name="remember_me" ref={register} label={t("remember_me")} />
          </Form.Group>

          <Button type="submit" variant="secondary" block disabled={!(watch("email") && watch("password"))}>
            {t("login")}
          </Button>

          <hr />

          <div className="text-center">
            <NavLink to="/auth/password" className="text-muted">
              {t("lost_password")}
            </NavLink>
          </div>
        </Form>
      </AuthLayout>
    </>
  );
};

export default Login;
