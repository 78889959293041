import { Dispatch } from "redux";
import { AdminApiClient } from "../../utils/http-client";
import { IOrderParams, IPageParams, IPaginationParams } from "../types";

const adminApiClient = AdminApiClient.getInstance();

export const pages = (queryParams?: IPaginationParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "PAGES_START" });
  await adminApiClient
    .pages(queryParams)
    .then((response) => {
      dispatch({ type: "PAGES_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "PAGES_FAILURE", payload: error }));
};

export const orderPage = (params: IOrderParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "ORDER_PAGE_START" });
  await adminApiClient
    .orderPage(params)
    .then((response) => {
      dispatch({ type: "ORDER_PAGE_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "ORDER_PAGE_FAILURE", payload: error }));
};

export const page = (id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: "PAGE_START" });
  await adminApiClient
    .page(id)
    .then((response) => {
      dispatch({ type: "PAGE_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "PAGE_FAILURE", payload: error }));
};

export const createPage = (params: IPageParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "CREATE_PAGE_START" });
  await adminApiClient
    .createPage(params)
    .then((response) => {
      dispatch({ type: "CREATE_PAGE_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "CREATE_PAGE_FAILURE", payload: error }));
};

export const updatePage = (id: string, params: IPageParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "UPDATE_PAGE_START" });
  await adminApiClient
    .updatePage(id, params)
    .then((response) => {
      dispatch({ type: "UPDATE_PAGE_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "UPDATE_PAGE_FAILURE", payload: error }));
};

export const deletePage = (id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: "DELETE_PAGE_START" });
  await adminApiClient
    .deletePage(id)
    .then((response) => {
      dispatch({ type: "DELETE_PAGE_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "DELETE_PAGE_FAILURE", payload: error }));
};
