import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { MainLayout } from "../../layouts";
import { Badge, Button, Card, Col, Image, Row } from "react-bootstrap";
import { RiArrowLeftSLine, RiDeleteBin6Line, RiEditBoxLine } from "react-icons/all";
import { RootState } from "../../../store/reducers";
import { IUserState, IDefaultState } from "../../../store/types";
import { LoadingIndicator, DeleteConfirmModal } from "../../partials";
import Moment from "react-moment";
import actions from "../../../store/actions";
import { nl2br, pictureUrl } from "../../../utils/mixins";
import ToggleSector from "../../partials/ToggleSector";

type RouterParamsType = {
  id: string;
};

export const Show = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { addToast } = useToasts();
  const { id } = useParams<RouterParamsType>();

  const { isLoading, response, error } = useSelector<RootState, IUserState>((state: RootState) => state.user);

  const {
    isLoading: deleteIsLoading,
    response: deleteResponse,
    error: deleteError,
  } = useSelector<RootState, IDefaultState>((state: RootState) => state.deleteUser);

  const [deleteItem, setDeleteItem] = useState<string | null>(null);
  const onDeleteConfirm = async () => {
    if (deleteItem) {
      dispatch(await actions.deleteUser(deleteItem));
    }
  };

  useEffect(() => {
    if (error?.response) {
      if (error.response.status === 400 || error.response.status === 404) {
        dispatch({ type: "USER_RESET" });
        history.push("/404");
      }
    } else {
      dispatch(actions.user(id));
    }
  }, [dispatch, id, error, history]);

  useEffect(() => {
    if (deleteError) {
      addToast(t("unknown_error"), {
        appearance: "error",
        autoDismiss: true,
      });
    }

    if (deleteResponse) {
      addToast(t("deleted_with_param", { param: t("user") }), {
        appearance: "success",
        autoDismiss: true,
      });

      dispatch({ type: "DELETE_USER_RESET" });

      history.push("/users");
    }
  }, [deleteResponse, deleteError, addToast, t, dispatch, history]);

  return (
    <MainLayout>
      <LoadingIndicator show={isLoading} />
      {response && (
        <Card className={deleteIsLoading ? "loading-block" : ""}>
          <Card.Header as="h6">{t("show")}</Card.Header>
          <Card.Body>
            <div className="content-view">
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("roles")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {response.data.roles.length > 0 ? (
                    <>
                      {response.data.roles.map((role, key) => {
                        return (
                          <Badge variant="secondary" key={key} className="m-1">
                            {t(`role_names.${role}`)}
                          </Badge>
                        );
                      })}
                    </>
                  ) : (
                    <>-</>
                  )}
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("name")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.name}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("lastname")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.lastname}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("email")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  <a href={"mailto:" + response.data.email} className="text-muted">
                    {response.data.email}
                  </a>
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("phone")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  <a href={"tel:" + response.data.phone} className="text-muted">
                    {response.data.phone}
                  </a>
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("city")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.city ? response.data.city.name : "-"}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("district")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {response.data.district ? response.data.district.name : "-"}
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("address")}:</dt>
                <dd
                  className="col-12 col-sm-9 col-md-10"
                  dangerouslySetInnerHTML={{ __html: response.data.address ? nl2br(response.data.address) : "-" }}
                />
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">LinkedIn:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {response.data.linkedin ? (
                    <a href={response.data.linkedin} target="_blank" rel="noreferrer" className="text-muted">
                      {response.data.linkedin}
                    </a>
                  ) : (
                    <>-</>
                  )}
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("avatar")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {response.data.avatar ? (
                    <div className="profile-picture">
                      <Image src={pictureUrl(response.data.avatar)} rounded thumbnail />
                    </div>
                  ) : (
                    "-"
                  )}
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("gender")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {response.data.gender ? <>{t(`genders.${response.data.gender}`)}</> : <>-</>}
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("disability")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {response.data.disability ? <>{t(`disabilities.${response.data.disability}`)}</> : <>-</>}
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("about")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.about ? nl2br(response.data.about) : "-"}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("experience")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {response.data.experience ? (
                    <>{t("experience_years", { param: response.data.experience })}</>
                  ) : (
                    <>-</>
                  )}
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("education_status")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {response.data.education ? <>{t(`education_items.${response.data.education}`)}</> : <>-</>}
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("education_detail")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {response.data.education_detail ? <>{response.data.education_detail}</> : <>-</>}
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("languages")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {response.data.language.length > 0 ? (
                    <>
                      {response.data.language.map((item, key) => {
                        return (
                          <Badge variant="secondary" key={key} className="m-1">
                            {t(`language_items.${item}`)}
                          </Badge>
                        );
                      })}
                    </>
                  ) : (
                    <>-</>
                  )}
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("sectors")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {response.data.user_sectors && response.data.user_sectors.length > 0 ? (
                    <>
                      {response.data.user_sectors.map((item, key) => (
                        <>
                          <ToggleSector {...item} key={key} />
                        </>
                      ))}
                    </>
                  ) : (
                    "-"
                  )}
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("business_type")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {response.data.business_type ? <>{t(`business_types.${response.data.business_type}`)}</> : <>-</>}
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("business_name")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {response.data.business_name ? <>{response.data.business_name}</> : <>-</>}
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("business_role")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {response.data.business_role ? <>{response.data.business_role}</> : <>-</>}
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("business_working_time")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {response.data.business_working_time ? <>{response.data.business_working_time}</> : <>-</>}
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("business_website")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {response.data.business_website ? <>{response.data.business_website}</> : <>-</>}
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("business_group")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {response.data.business_group ? <>{response.data.business_group}</> : <>-</>}
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("business_address")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {response.data.business_adress ? <>{response.data.business_adress}</> : <>-</>}
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("business_phone")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {response.data.business_phone ? <>{response.data.business_phone}</> : <>-</>}
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("business_general")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {response.data.business_general ? <>{response.data.business_general}</> : <>-</>}
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("business_city")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {response.data.business_city ? <>{response.data.business_city.name}</> : <>-</>}
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("business_role")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {response.data.business_role ? <>{t(`business_roles.${response.data.business_role}`)}</> : <>-</>}
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("business_sectors")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {response.data.business_sectors && response.data.business_sectors.length > 0 ? (
                    <>
                      {response.data.business_sectors.map((item, key) => (
                        <>
                          <ToggleSector {...item} key={key} />
                        </>
                      ))}
                    </>
                  ) : (
                    "-"
                  )}
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("categories")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {response.data.categories.length > 0 ? (
                    <>
                      {response.data.categories.map((category, key) => {
                        return (
                          <Button
                            as={NavLink}
                            size="sm"
                            to={`/categories/${category.id}`}
                            key={key}
                            variant="outline-secondary"
                            className="mr-2 mb-2"
                          >
                            {category.title}
                          </Button>
                        );
                      })}
                    </>
                  ) : (
                    "-"
                  )}
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("mentor_score")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.mentor_score}/5</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("mentor_score_count")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.mentor_score_count}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("user_score")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.user_score}/5</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("user_score_count")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.user_score_count}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("created_at")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  <Moment format="DD/MM/YYYY HH:mm:ss">{response.data.created_at}</Moment>
                </dd>
              </dl>
              {response.data.updated_at && (
                <>
                  <hr />
                  <dl className="row">
                    <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("updated_at")}:</dt>
                    <dd className="col-12 col-sm-9 col-md-10">
                      <Moment format="DD/MM/YYYY HH:mm:ss">{response.data.updated_at}</Moment>
                    </dd>
                  </dl>
                </>
              )}
            </div>
          </Card.Body>
          <Card.Footer>
            <Row>
              <Col xs="6" className="text-left">
                <Button as={NavLink} to="/users" variant="secondary" size="sm" className="with-icon">
                  <RiArrowLeftSLine />
                  <span>{t("list")}</span>
                </Button>
              </Col>
              <Col xs="6" className="text-right">
                <Button as={NavLink} to={`/users/${id}/edit`} variant="warning" size="sm" className="with-icon mr-2">
                  <RiEditBoxLine />
                  <span>{t("edit")}</span>
                </Button>
                <DeleteConfirmModal
                  show={deleteItem !== null}
                  onClose={() => setDeleteItem(null)}
                  onConfirm={onDeleteConfirm}
                />
                <Button variant="danger" size="sm" className="with-icon" onClick={() => setDeleteItem(id)}>
                  <RiDeleteBin6Line />
                  <span>{t("delete")}</span>
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      )}
    </MainLayout>
  );
};

export default Show;
