import { Reducer } from "react";
import {
  IAppointmentsState,
  AppointmentsActionType,
  IAppointmentState,
  AppointmentActionType,
  AppointmentsExportActionType,
} from "../types";

// Appointments
const appointmentsInitialState: IAppointmentsState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const appointmentsReducer: Reducer<IAppointmentsState, AppointmentsActionType> = (
  state = appointmentsInitialState,
  action
): IAppointmentsState => {
  switch (action.type) {
    case "APPOINTMENTS_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "APPOINTMENTS_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "APPOINTMENTS_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "APPOINTMENTS_RESET":
      return appointmentsInitialState;
    default:
      return state;
  }
};

/**
 * @param state
 * @param action
 */
export const appointmentsExportReducer: Reducer<IAppointmentsState, AppointmentsExportActionType> = (
  state = appointmentsInitialState,
  action
): IAppointmentsState => {
  switch (action.type) {
    case "APPOINTMENTS_EXPORT_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "APPOINTMENTS_EXPORT_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "APPOINTMENTS_EXPORT_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "APPOINTMENTS_EXPORT_RESET":
      return appointmentsInitialState;
    default:
      return state;
  }
};

// Appointment
const appointmentInitialState: IAppointmentState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const appointmentReducer: Reducer<IAppointmentState, AppointmentActionType> = (
  state = appointmentInitialState,
  action
): IAppointmentState => {
  switch (action.type) {
    case "APPOINTMENT_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "APPOINTMENT_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "APPOINTMENT_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "APPOINTMENT_RESET":
      return appointmentInitialState;
    default:
      return state;
  }
};
