import { Reducer } from "react";
import {
  IDefaultState,
  IMentorApplicationsState,
  MentorApplicationsActionType,
  IMentorApplicationState,
  MentorApplicationActionType,
  EvaluateMentorApplicationActionType,
  DeleteMentorApplicationActionType,
} from "../types";

// MentorApplications
const mentorApplicationsInitialState: IMentorApplicationsState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const mentorApplicationsReducer: Reducer<IMentorApplicationsState, MentorApplicationsActionType> = (
  state = mentorApplicationsInitialState,
  action
): IMentorApplicationsState => {
  switch (action.type) {
    case "MENTOR_APPLICATIONS_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "MENTOR_APPLICATIONS_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "MENTOR_APPLICATIONS_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "MENTOR_APPLICATIONS_RESET":
      return mentorApplicationsInitialState;
    default:
      return state;
  }
};

// MentorApplication
const mentorApplicationInitialState: IMentorApplicationState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const mentorApplicationReducer: Reducer<IMentorApplicationState, MentorApplicationActionType> = (
  state = mentorApplicationInitialState,
  action
): IMentorApplicationState => {
  switch (action.type) {
    case "MENTOR_APPLICATION_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "MENTOR_APPLICATION_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "MENTOR_APPLICATION_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "MENTOR_APPLICATION_RESET":
      return mentorApplicationInitialState;
    default:
      return state;
  }
};

/**
 * @param state
 * @param action
 */
export const evaluateMentorApplicationReducer: Reducer<IMentorApplicationState, EvaluateMentorApplicationActionType> = (
  state = mentorApplicationInitialState,
  action
): IMentorApplicationState => {
  switch (action.type) {
    case "EVALUATE_MENTOR_APPLICATION_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "EVALUATE_MENTOR_APPLICATION_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "EVALUATE_MENTOR_APPLICATION_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "EVALUATE_MENTOR_APPLICATION_RESET":
      return mentorApplicationInitialState;
    default:
      return state;
  }
};

const faqDeleteInitialState: IDefaultState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const deleteMentorApplicationReducer: Reducer<IDefaultState, DeleteMentorApplicationActionType> = (
  state = faqDeleteInitialState,
  action
): IMentorApplicationState => {
  switch (action.type) {
    case "DELETE_MENTOR_APPLICATION_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "DELETE_MENTOR_APPLICATION_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "DELETE_MENTOR_APPLICATION_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "DELETE_MENTOR_APPLICATION_RESET":
      return faqDeleteInitialState;
    default:
      return state;
  }
};
