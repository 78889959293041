import { Reducer } from "react";
import {
  IUsersState,
  UsersActionType,
  IUserState,
  UserActionType,
  CreateUserActionType,
  UpdateUserActionType,
  DeleteUserActionType,
  IDefaultState,
  UsersExportActionType,
  IUsersExportState,
} from "../types";

// Users
const usersInitialState: IUsersState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const usersReducer: Reducer<IUsersExportState, UsersActionType> = (
  state = usersInitialState,
  action
): IUsersState => {
  switch (action.type) {
    case "USERS_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "USERS_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "USERS_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "USERS_RESET":
      return usersInitialState;
    default:
      return state;
  }
};

/**
 * @param state
 * @param action
 */
export const usersExportReducer: Reducer<IUsersState, UsersExportActionType> = (
  state = usersInitialState,
  action
): IUsersState => {
  switch (action.type) {
    case "USERS_EXPORT_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "USERS_EXPORT_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "USERS_EXPORT_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "USERS_EXPORT_RESET":
      return usersInitialState;
    default:
      return state;
  }
};

// User
const userInitialState: IUserState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const userReducer: Reducer<IUserState, UserActionType> = (state = userInitialState, action): IUserState => {
  switch (action.type) {
    case "USER_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "USER_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "USER_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "USER_RESET":
      return userInitialState;
    default:
      return state;
  }
};

/**
 * @param state
 * @param action
 */
export const createUserReducer: Reducer<IUserState, CreateUserActionType> = (
  state = userInitialState,
  action
): IUserState => {
  switch (action.type) {
    case "CREATE_USER_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "CREATE_USER_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "CREATE_USER_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "CREATE_USER_RESET":
      return userInitialState;
    case "CREATE_FAST_USER_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "CREATE_FAST_USER_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "CREATE_FAST_USER_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "CREATE_FAST_USER_RESET":
      return userInitialState;
    default:
      return state;
  }
};

/**
 * @param state
 * @param action
 */
export const updateUserReducer: Reducer<IUserState, UpdateUserActionType> = (
  state = userInitialState,
  action
): IUserState => {
  switch (action.type) {
    case "UPDATE_USER_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "UPDATE_USER_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "UPDATE_USER_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "UPDATE_USER_RESET":
      return userInitialState;
    default:
      return state;
  }
};

const userDeleteInitialState: IDefaultState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const deleteUserReducer: Reducer<IDefaultState, DeleteUserActionType> = (
  state = userDeleteInitialState,
  action
): IUserState => {
  switch (action.type) {
    case "DELETE_USER_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "DELETE_USER_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "DELETE_USER_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "DELETE_USER_RESET":
      return userDeleteInitialState;
    default:
      return state;
  }
};
