import { Dispatch } from "redux";
import { AdminApiClient } from "../../utils/http-client";

const adminApiClient = AdminApiClient.getInstance();

export const dashboard = () => async (dispatch: Dispatch) => {
  dispatch({ type: "DASHBOARD_START" });
  await adminApiClient
    .dashboard()
    .then((response) => {
      dispatch({ type: "DASHBOARD_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "DASHBOARD_FAILURE", payload: error }));
};
