import { Dispatch } from "redux";
import { AdminApiClient } from "../../utils/http-client";
import { IUpdateContactParams } from "../types";

const adminApiClient = AdminApiClient.getInstance();

export const contact = () => async (dispatch: Dispatch) => {
  dispatch({ type: "CONTACT_START" });
  await adminApiClient
    .contact()
    .then((response) => {
      dispatch({ type: "CONTACT_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "CONTACT_FAILURE", payload: error }));
};

export const updateContact = (params: IUpdateContactParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "UPDATE_CONTACT_START" });
  await adminApiClient
    .updateContact(params)
    .then(async (response) => {
      dispatch({ type: "UPDATE_CONTACT_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "UPDATE_CONTACT_FAILURE", payload: error }));
};
