import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { MainLayout } from "../../layouts";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { NavLink, useParams } from "react-router-dom";
import { RiArrowLeftSLine, RiCheckFill } from "react-icons/all";
import { useForm, Controller } from "react-hook-form";
import { RootState } from "../../../store/reducers";
import { IBlogPostParams, IBlogPostState, ICategoriesState } from "../../../store/types";
import { AlertDefaultState, AlertStateType } from "../../../types";
import { CustomFileInput, FormAlert, LoadingIndicator } from "../../partials";
import Select from "react-select";
import actions from "../../../store/actions";
import { WysiwygEditor } from "../../partials";
import { fileObjectToBase64 } from "../../../utils/mixins";
import history from "../../../utils/history";

export type CategorySelectOptionType = {
  value: string;
  label: string;
};

type FormDataType = {
  title: string;
  summary: string;
  content: string;
  pictures: FileList;
  categories: Array<CategorySelectOptionType>;
  status: string;
  description?: string | null;
  keywords?: string | null;
};

type RouterParamsType = {
  id: string;
};

export const Edit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { id } = useParams<RouterParamsType>();

  const [categories, setCategories] = useState<Array<CategorySelectOptionType>>([]);
  const [defaultCategories, setDefaultCategories] = useState<Array<CategorySelectOptionType>>([]);

  const { response: categoriesResponse } = useSelector<RootState, ICategoriesState>(
    (state: RootState) => state.categories
  );

  useEffect(() => {
    if (categoriesResponse) {
      setFormDisable(false);

      if (categoriesResponse.data.items.length > 0) {
        const categoryOptions: Array<CategorySelectOptionType> = categoriesResponse.data.items.map((category) => {
          return {
            value: category.id,
            label: category.title,
          };
        });
        setCategories(categoryOptions);
      }
    } else {
      setFormDisable(true);
      dispatch({ type: "CATEGORIES_RESET" });
      dispatch(actions.categories({ page: 1, per: 9999 }));
    }
  }, [categoriesResponse, dispatch]);

  const { register, control, handleSubmit, errors, reset } = useForm<FormDataType>();
  const onSubmit = async (data: FormDataType) => {
    setFormDisable(true);
    setFormAlert(AlertDefaultState);

    const params: IBlogPostParams = {
      title: data.title,
      summary: data.summary,
      content: data.content,
      pictures: [],
      categories: [],
      is_active: data.status === "active",
      description: data.description,
      keywords: data.keywords,
    };

    let pictures: Promise<string>[] = [];
    if (data.pictures.length > 0) {
      pictures = Array.from(data.pictures).map(async (picture) => {
        return await fileObjectToBase64(picture);
      });
    }

    if (data.categories.length > 0) {
      params.categories = data.categories.map((category) => {
        return category.value;
      });
    }

    Promise.all(pictures).then(async (values) => {
      params.pictures = values;
      dispatch(await actions.updateBlogPost(id, params));
    });
  };

  const {
    isLoading: blogPostIsLoading,
    response: blogPostResponse,
    error: blogPostError,
  } = useSelector<RootState, IBlogPostState>((state: RootState) => state.blogPost);

  const { isLoading, response, error } = useSelector<RootState, IBlogPostState>(
    (state: RootState) => state.updateBlogPost
  );

  const [formDisable, setFormDisable] = useState<boolean>(false);
  const [formAlert, setFormAlert] = useState<AlertStateType>(AlertDefaultState);

  useEffect(() => {
    if (blogPostError?.response) {
      if (blogPostError.response.status === 400 || blogPostError.response.status === 404) {
        dispatch({ type: "BLOG_POST_RESET" });
        history.push("/404");
      }
    } else {
      dispatch(actions.blogPost(id));
    }
  }, [dispatch, id, blogPostError]);

  useEffect(() => {
    if (blogPostResponse) {
      const categories = blogPostResponse.data.categories.map((category) => {
        return {
          value: category.id,
          label: category.title,
        };
      });

      setDefaultCategories(categories);
    }
  }, [blogPostResponse]);

  useEffect(() => {
    setFormDisable(isLoading);

    if (error !== null) {
      if (error.response.status === 400) {
        setFormAlert({
          variant: "danger",
          show: true,
          messages: error.response.data.messages,
        });
      } else {
        addToast(t("unknown_error"), {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }

    if (response) {
      addToast(t("updated_with_param", { param: t("blog_post") }), {
        appearance: "success",
        autoDismiss: true,
      });

      reset();

      dispatch({ type: "UPDATE_BLOG_POST_RESET" });
      history.push(`/blog-posts/${response.data.id}`);
    }
  }, [isLoading, response, error, addToast, reset, t, dispatch]);

  return (
    <MainLayout>
      <LoadingIndicator show={blogPostIsLoading} />

      {blogPostResponse && (
        <>
          <FormAlert variant={formAlert.variant} show={formAlert.show} to={formAlert.to}>
            {formAlert.messages.map((message, key) => {
              return <p key={key}>{message}</p>;
            })}
          </FormAlert>

          <Card className={formDisable ? "loading-block" : ""}>
            <Card.Header as="h6">{t("edit")}</Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit(onSubmit)}>
                {categoriesResponse && (
                  <>
                    <Form.Row>
                      <Form.Group as={Col} md="12" controlId="blog-post-categories">
                        <Form.Label>{t("categories")}:</Form.Label>
                        <Controller
                          control={control}
                          name="categories"
                          defaultValue={defaultCategories}
                          render={({ onChange, ref }) => (
                            <Select
                              isMulti
                              isClearable
                              inputRef={ref}
                              placeholder={t("select_category")}
                              noOptionsMessage={() => t("not_found")}
                              options={categories}
                              defaultValue={defaultCategories}
                              onChange={(value: any) => {
                                setDefaultCategories(value);
                                onChange(value);
                              }}
                            />
                          )}
                          ref={register}
                          rules={{
                            required: {
                              value: true,
                              message: t("validation.you_must_choose", { name: t("category") }),
                            },
                          }}
                          isInvalid={!!errors.categories}
                        />
                      </Form.Group>
                    </Form.Row>
                    <hr />
                  </>
                )}
                <Form.Row>
                  <Form.Group as={Col} md="12" controlId="blogPost-title">
                    <Form.Label>{t("title")}:</Form.Label>
                    <Form.Control
                      type="text"
                      name="title"
                      ref={register({
                        required: {
                          value: true,
                          message: t("validation.you_must_enter", { name: t("title") }),
                        },
                      })}
                      placeholder={t("title")}
                      defaultValue={blogPostResponse.data.title}
                      autoFocus
                      isInvalid={!!errors.title}
                    />
                    <Form.Control.Feedback type="invalid">{errors.title?.message}</Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <hr />
                <Form.Row>
                  <Form.Group as={Col} md="12" controlId="news-summary">
                    <Form.Label>{t("summary")}:</Form.Label>
                    <Form.Control
                      type="text"
                      as="textarea"
                      name="summary"
                      ref={register({
                        required: {
                          value: true,
                          message: t("validation.you_must_enter", { name: t("summary") }),
                        },
                      })}
                      placeholder={t("summary")}
                      defaultValue={blogPostResponse.data.summary}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md="12" controlId="blogPost-content">
                    <Form.Label>{t("content")}:</Form.Label>
                    <Controller
                      control={control}
                      name="content"
                      ref={register({
                        required: {
                          value: true,
                          message: t("validation.you_must_enter", { name: t("content") }),
                        },
                      })}
                      placeholder={t("content")}
                      defaultValue={blogPostResponse.data.content ? blogPostResponse.data.content : ""}
                      render={({ onChange, ref }) => {
                        return (
                          <WysiwygEditor
                            defaultValue={blogPostResponse.data.content ? blogPostResponse.data.content : ""}
                            onChange={(content: string) => onChange(content)}
                            ref={ref}
                          />
                        );
                      }}
                      isInvalid={!!errors.content}
                    />
                    <Form.Control.Feedback type="invalid">{errors.content?.message}</Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <hr />
                <Form.Row>
                  <Form.Group as={Col} md="12" controlId="blogPost-content">
                    <Form.Label>{t("pictures")}:</Form.Label>
                    <span className="text-muted ml-2">{t("only_new_added_notice")}</span>
                    <CustomFileInput
                      name="pictures"
                      accept="image/jpeg,image/jpg,image/png"
                      multiple
                      dropzone
                      ref={register}
                      isInvalid={!!errors.pictures}
                    />
                    <Form.Control.Feedback type="invalid">{errors.pictures?.message}</Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <hr />
                <hr />
                <Form.Row>
                  <Form.Group as={Col} md="4" controlId="blogPost-description">
                    <Form.Label>{t("description")}:</Form.Label>
                    <Form.Control
                      type="text"
                      as="textarea"
                      name="description"
                      ref={register}
                      placeholder={t("description")}
                      defaultValue={blogPostResponse.data.description ? blogPostResponse.data.description : ""}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="4" controlId="blogPost-keywords">
                    <Form.Label>{t("keywords")}:</Form.Label>
                    <Form.Control
                      type="text"
                      as="textarea"
                      name="keywords"
                      ref={register}
                      placeholder={t("keywords")}
                      defaultValue={blogPostResponse.data.keywords ? blogPostResponse.data.keywords : ""}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md="3" controlId="blogPost-status">
                    <Form.Label>{t("status")}</Form.Label>
                    <Form.Control
                      as="select"
                      custom
                      name="status"
                      ref={register({
                        required: {
                          value: true,
                          message: t("validation.you_must_choose", { name: t("status") }),
                        },
                      })}
                      placeholder={t("status")}
                      defaultValue={blogPostResponse.data.is_active ? "active" : "passive"}
                      isInvalid={!!errors.status}
                    >
                      <option value="">{t("select_status")}</option>
                      <option value="active">{t("active")}</option>
                      <option value="passive">{t("passive")}</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">{errors.status?.message}</Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
              </Form>
            </Card.Body>
            <Card.Footer>
              <Row>
                <Col xs="6" className="text-left">
                  <Button as={NavLink} to="/blog-posts" variant="secondary" size="sm" className="with-icon">
                    <RiArrowLeftSLine />
                    <span>{t("list")}</span>
                  </Button>
                </Col>
                <Col xs="6" className="text-right">
                  <Button variant="primary" size="sm" className="with-icon" onClick={handleSubmit(onSubmit)}>
                    <RiCheckFill />
                    <span>{t("save")}</span>
                  </Button>
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        </>
      )}
    </MainLayout>
  );
};

export default Edit;
