import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { MainLayout } from "../../../layouts";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { NavLink, useParams } from "react-router-dom";
import { RiArrowLeftSLine, RiCheckFill } from "react-icons/all";
import { useForm } from "react-hook-form";
import { RootState } from "../../../../store/reducers";
import { ISectorParams, ISectorState } from "../../../../store/types";
import { AlertDefaultState, AlertStateType } from "../../../../types";
import { FormAlert, LoadingIndicator } from "../../../partials";
import actions from "../../../../store/actions";
import history from "../../../../utils/history";

type RouterParamsType = {
  id: string;
};

export const Edit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { id } = useParams<RouterParamsType>();

  const { register, handleSubmit, errors, reset } = useForm<ISectorParams>();
  const onSubmit = async (data: ISectorParams) => {
    dispatch(await actions.updateSector(id, data));
  };

  const {
    isLoading: sectorIsLoading,
    response: sectorResponse,
    error: sectorError,
  } = useSelector<RootState, ISectorState>((state: RootState) => state.sector);

  const { isLoading, response, error } = useSelector<RootState, ISectorState>((state: RootState) => state.updateSector);

  const [formDisable, setFormDisable] = useState<boolean>(false);
  const [formAlert, setFormAlert] = useState<AlertStateType>(AlertDefaultState);

  useEffect(() => {
    if (sectorError?.response) {
      if (sectorError.response.status === 400 || sectorError.response.status === 404) {
        dispatch({ type: "CATEGORY_RESET" });
        history.push("/404");
      }
    } else {
      dispatch(actions.sector(id));
    }
  }, [dispatch, id, sectorError]);

  useEffect(() => {
    setFormDisable(isLoading);

    if (error !== null) {
      if (error.response.status === 400) {
        setFormAlert({
          variant: "danger",
          show: true,
          messages: error.response.data.messages,
        });
      } else {
        addToast(t("unknown_error"), {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }

    if (response) {
      addToast(t("updated_with_param", { param: t("sector") }), {
        appearance: "success",
        autoDismiss: true,
      });

      dispatch({ type: "UPDATE_CATEGORY_RESET" });
      history.push(`/sector-management/sectors/${id}`);
    }
  }, [isLoading, addToast, t, id, response, error, reset, dispatch]);

  return (
    <MainLayout>
      <LoadingIndicator show={sectorIsLoading} />

      {sectorResponse && (
        <>
          <FormAlert variant={formAlert.variant} show={formAlert.show} to={formAlert.to}>
            {formAlert.messages.map((message, key) => {
              return <p key={key}>{message}</p>;
            })}
          </FormAlert>

          <Card className={formDisable ? "loading-block" : ""}>
            <Card.Header as="h6">{t("edit")}</Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Row>
                  <Form.Group as={Col} sm="6" md="4" controlId="sector-title">
                    <Form.Label>{t("title")}:</Form.Label>
                    <Form.Control
                      type="text"
                      name="title"
                      ref={register({
                        required: {
                          value: true,
                          message: t("validation.you_must_enter", { name: t("title") }),
                        },
                      })}
                      placeholder={t("title")}
                      defaultValue={sectorResponse.data.title}
                      autoFocus
                      isInvalid={!!errors.title}
                    />
                    <Form.Control.Feedback type="invalid">{errors.title?.message}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm="6" md="4" controlId="sector-nace">
                    <Form.Label>{t("nace")}:</Form.Label>
                    <Form.Control
                      type="text"
                      name="nace"
                      ref={register({
                        required: {
                          value: true,
                          message: t("validation.you_must_enter", { name: t("nace") }),
                        },
                        maxLength: {
                          value: 1,
                          message: t("validation.max", { max: 1 }),
                        },
                      })}
                      maxLength={1}
                      placeholder={t("nace")}
                      defaultValue={sectorResponse.data.nace}
                      isInvalid={!!errors.nace}
                    />
                    <Form.Control.Feedback type="invalid">{errors.nace?.message}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm="6" md="4" controlId="sector-code">
                    <Form.Label>{t("code")}:</Form.Label>
                    <Form.Control
                      type="text"
                      name="code"
                      ref={register({
                        required: {
                          value: true,
                          message: t("validation.you_must_enter", { name: t("code") }),
                        },
                        maxLength: {
                          value: 1,
                          message: t("validation.max", { max: 1 }),
                        },
                      })}
                      maxLength={1}
                      placeholder={t("nace")}
                      defaultValue={sectorResponse.data.code}
                      isInvalid={!!errors.code}
                    />
                    <Form.Control.Feedback type="invalid">{errors.code?.message}</Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
              </Form>
            </Card.Body>
            <Card.Footer>
              <Row>
                <Col xs="6" className="text-left">
                  <Button
                    as={NavLink}
                    to="/sector-management/sectors"
                    variant="secondary"
                    size="sm"
                    className="with-icon"
                  >
                    <RiArrowLeftSLine />
                    <span>{t("list")}</span>
                  </Button>
                </Col>
                <Col xs="6" className="text-right">
                  <Button variant="primary" size="sm" className="with-icon" onClick={handleSubmit(onSubmit)}>
                    <RiCheckFill />
                    <span>{t("save")}</span>
                  </Button>
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        </>
      )}
    </MainLayout>
  );
};

export default Edit;
