import { Reducer } from "react";
import {
  ILogoutState,
  IMeState,
  IUpdateProfileState,
  LogoutActionType,
  MeActionType,
  UpdateProfileActionType,
} from "../types";
import AppStorage from "../../utils/storage";

// Me
const meInitialState: IMeState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const meReducer: Reducer<IMeState, MeActionType> = (
  state: IMeState = meInitialState,
  action: MeActionType
): IMeState => {
  switch (action.type) {
    case "ME_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "ME_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "ME_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "ME_RESET":
      return { ...state, isLoading: false, response: null, error: action.payload };
    default:
      return state;
  }
};

// Update profile
const updateProfileInitialState: IUpdateProfileState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const updateProfileReducer: Reducer<IUpdateProfileState, UpdateProfileActionType> = (
  state: IUpdateProfileState = updateProfileInitialState,
  action: UpdateProfileActionType
): IMeState => {
  switch (action.type) {
    case "UPDATE_PROFILE_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "UPDATE_PROFILE_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "UPDATE_PROFILE_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "UPDATE_PROFILE_RESET":
      return updateProfileInitialState;
    default:
      return state;
  }
};

// Logout
const logoutInitialState: ILogoutState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const logoutReducer: Reducer<ILogoutState, LogoutActionType> = (
  state: ILogoutState = logoutInitialState,
  action: LogoutActionType
): IMeState => {
  switch (action.type) {
    case "LOGOUT_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "LOGOUT_SUCCESS":
      AppStorage.removeTokens();
      return { ...state, isLoading: false, response: action.payload.response };
    case "LOGOUT_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    default:
      return state;
  }
};
