import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { tr } from "../locales";
import app from "../config/app";

i18n
  .use(initReactI18next)
  .init({
    resources: {
      tr: {
        translation: tr,
      },
    },
    lng: app.DEFAULT_LOCALE,
    fallbackLng: app.DEFAULT_LOCALE,
    interpolation: {
      escapeValue: false,
    },
  })
  .then((_) => {});

export default i18n;
