import { login, loginUpdate, loginReset, refresh, password, updatePassword } from "./auth";
import { me, meReset, updateProfile, logout } from "./userOperations";
import { dashboard } from "./dashboard";
import { deletePicture } from "./general";
import { cities, districts } from "./cityAndDistricts";
import { categories, orderCategory, category, createCategory, updateCategory, deleteCategory } from "./categories";
import { sectors, sector, createSector, updateSector, deleteSector } from "./sectors";
import { subSectors, createSubSector, subSector, updateSubSector, deleteSubSector } from "./subSectors";
import { pages, orderPage, page, createPage, updatePage, deletePage } from "./pages";
import { contracts, contract, createContract, updateContract, deleteContract } from "./contracts";
import { newsList, news, createNews, updateNews, deleteNews } from "./news";
import { deleteSession,updateSession,createSession, createMatchSession } from "./sessions";
import {createFollowedTask,updateFollowedTask,deleteFollowedTask} from "./followedTasks";
import { blogPosts, blogPost, createBlogPost, updateBlogPost, deleteBlogPost } from "./blogPosts";
import { faqs, faq, createFaq, updateFaq, deleteFaq } from "./faqs";
import { contact, updateContact } from "./contact";
import { Match,updateMatch,deleteMatch, createMatch } from "./matches";
import {
  mentorApplications,
  mentorApplication,
  evaluateMentorApplication,
  deleteMentorApplication,
} from "./mentorApplications";
import {users, usersExport, user, createUser, updateUser, deleteUser, createFastUser} from "./users";
import { teams, teamsExport, team, createTeam, updateTeam, deleteTeam } from "./teams";
import { mentoringPrograms,mentoringProgramsExport, mentoringProgram, createMentoringProgram, updateMentoringProgram, deleteMentoringProgram } from "./mentoringPrograms";
import { userApplications, userApplication, evaluateUserApplication, deleteUserApplication } from "./userApplications";
import { featuredMentors, updateFeaturedMentor } from "./featured";
import { appointments, appointmentsExport, appointment } from "./appointments";
import { appointmentRecords, appointmentRecord } from "./appointmentRecords";
import { logs } from "./logs";
import { supportList, support } from "./support";
import { createFeedback, deleteFeedback, feedback, feedbacks, updateFeedback } from "./feedbacks";

const actions = {
  login,
  loginUpdate,
  loginReset,
  refresh,
  password,
  updatePassword,
  me,
  meReset,
  updateProfile,
  logout,
  dashboard,
  deletePicture,
  cities,
  districts,
  categories,
  orderCategory,
  category,
  createCategory,
  updateCategory,
  deleteCategory,
  sectors,
  sector,
  createSector,
  updateSector,
  deleteSector,
  subSectors,
  subSector,
  createSubSector,
  updateSubSector,
  deleteSubSector,
  pages,
  orderPage,
  page,
  createPage,
  updatePage,
  deletePage,
  contracts,
  contract,
  createContract,
  updateContract,
  deleteContract,
  newsList,
  news,
  createNews,
  updateNews,
  deleteNews,
  blogPosts,
  blogPost,
  createBlogPost,
  updateBlogPost,
  deleteBlogPost,
  faqs,
  faq,
  createFaq,
  updateFaq,
  deleteFaq,
  contact,
  updateContact,
  mentorApplications,
  mentorApplication,
  evaluateMentorApplication,
  deleteMentorApplication,
  userApplications,
  userApplication,
  evaluateUserApplication,
  deleteUserApplication,
  users,
  usersExport,
  user,
  createUser,
  createFastUser,
  updateUser,
  deleteUser,
  teams,
  teamsExport,
  team,
  createTeam,
  updateTeam,
  deleteTeam,
  featuredMentors,
  feedbacks,
  createFeedback,
  feedback,
  updateFeedback,
  deleteFeedback,
  updateFeaturedMentor,
  appointments,
  appointmentsExport,
  appointment,
  appointmentRecords,
  appointmentRecord,
  logs,
  supportList,
  support,
  mentoringPrograms,
  mentoringProgramsExport,
  mentoringProgram,
  createMentoringProgram,
  updateMentoringProgram,
  deleteMentoringProgram,
  createSession,
  createMatchSession,
  deleteSession,
  updateSession,
  createFollowedTask,
  updateFollowedTask,
  deleteFollowedTask,
  Match,
  updateMatch,
  deleteMatch,
  createMatch,

};

export default actions;
