export const app = {
  APP_NAME: String(process.env.REACT_APP_NAME),
  ENV: String(process.env.REACT_APP_ENV),
  API_URL: String(process.env.REACT_APP_API_URL),
  BASE_URL: String(process.env.REACT_APP_BASE_URL),
  WEBRTC_URL: String(process.env.REACT_APP_WEBRTC_URL),
  MEETING_URL: String(process.env.REACT_APP_MEETING_URL),
  STORAGE_URL: String(process.env.REACT_APP_STORAGE_URL),
  TIMEZONE: String(process.env.REACT_APP_TIMEZONE),
  DEFAULT_LOCALE: String(process.env.REACT_APP_DEFAULT_LOCALE),
};

export default app;
