import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { MainLayout } from "../../layouts";
import { Accordion, Button, Card, Col, Form, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { RiAddBoxLine, RiArrowLeftSLine, RiCheckFill, RiDeleteBin6Line } from "react-icons/all";
import { useForm } from "react-hook-form";
import { RootState } from "../../../store/reducers";
import {
    IMentoringProgramCreateParams,
    IMentoringProgramState,
    SessionFormType
}
    from "../../../store/types";
import { AlertDefaultState, AlertStateType } from "../../../types";
import { FormAlert } from "../../partials";
import actions from "../../../store/actions";
import history from "../../../utils/history";
export type OptionType = {
    value: string;
    label: string;
};
const CreatePrograms = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const { register, handleSubmit, errors, reset } = useForm<IMentoringProgramCreateParams>();
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const [formAlert, setFormAlert] = useState<AlertStateType>(AlertDefaultState);
    const [formDisable, setFormDisable] = useState<boolean>(false);
    const [sessionItems, setSessionItems] = useState<Array<SessionFormType>>([]);
    useEffect(() => {
        dispatch({ type: "USERS_RESET" })
    }, [dispatch]);
    const addSessionItem = () => {
        setSessionItems([...sessionItems, {
            title: "",
            location: "",
            goals: "",
            preparations: "",
            agenda: "",
            documents: "",
            followed_tasks: [],
        }]);
    };
    const removeSessionItem = (index: number) => {
        const updatedSessionItems = [...sessionItems];
        updatedSessionItems.splice(index, 1);
        setSessionItems(updatedSessionItems);
    };
    const addFollowedTaskItem = (index: number) => {
        const sessionItem = sessionItems[index];
        if (sessionItem) {
            if (sessionItem.followed_tasks === undefined) {
                sessionItem.followed_tasks = [];
            }
            sessionItem.followed_tasks = [...sessionItem.followed_tasks!, {
                title: "",
                steps: "",
            }];
        }
        setSessionItems([...sessionItems]);
    }
    const removeFollowedTaskItem = (sessionIndex: number, taskIndex: number) => {
        const updatedSessionItems = [...sessionItems];
        const followedTasks = updatedSessionItems[sessionIndex]?.followed_tasks;

        if (followedTasks && followedTasks.length > taskIndex) {
            followedTasks.splice(taskIndex, 1);
            setSessionItems(updatedSessionItems);
        }
    };
    const { isLoading, response, error } = useSelector<RootState, IMentoringProgramState>((state: RootState) => state.createMentoringProgram);
    useEffect((): void => {
        setFormDisable(isLoading);
        if (error !== null) {
            if (error.response.status === 404) {
                setFormAlert({
                    variant: "danger",
                    show: true,
                    messages: error.response.data.messages,
                });
            } else {
                addToast(t("unknown_error"), {
                    appearance: "error",
                    autoDismiss: true,
                });
            }
        }
        if (errors !== null) {
            console.log(errors);
        }
        if (response) {
            addToast(t("created_with_param", { param: t("mentoring_program") }), {
                appearance: "success",
                autoDismiss: true,
            });
            reset();
            dispatch({ type: "CREATE_MENTORING_PROGRAM_RESET" });
            history.push(`/mentoring_programs/${response.data.id}`);
        }
    }, [isLoading, response, error, addToast, reset, t, dispatch, errors]);

    const onSubmit = async (data: IMentoringProgramCreateParams) => {
        if (sessionItems.length === 0) {
            addSessionItem();
            setFormAlert({
                variant: "danger",
                show: true,
                messages: [t("Please_add_at_least_one_session")],
            });
            return;
        } else {
            setSessionItems([...sessionItems, {
                title: "",
                location: "",
                goals: "",
                preparations: "",
                agenda: "",
                documents: "",
                followed_tasks: [],
            }]);
            dispatch(actions.createMentoringProgram(data));
        }
    };
    
    return (
        <MainLayout>
            <FormAlert variant={formAlert.variant} show={formAlert.show} to={formAlert.to}>
                {formAlert.messages.map((message, key: number) => (
                    <p key={key}>{message}</p>
                ))}
            </FormAlert>
            <Card className={formDisable ? "loading-block" : ""} style={{ marginBottom: 180 }}>
                <Card.Header as="h6">{t("create")}</Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Row>
                            <Form.Group as={Col} md="8" controlId="mentor-program-name">
                                <Form.Label>{t("title")}:</Form.Label>
                                <Form.Control
                                    type="text"
                                    title="title"
                                    name="title"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message: t("validation.you_must_enter", { name: t("mentoring_program_title") }),
                                        }
                                    })}
                                    placeholder={t("title")}
                                    autoFocus
                                    isInvalid={!!errors.title}
                                />
                                <Form.Control.Feedback type="invalid">{errors.title?.message}</Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <hr />
                        <Form.Row>
                            <Form.Group as={Col} md="12">
                                <Form.Label htmlFor="selector-session">
                                    {t("session")}:
                                    <Button
                                        variant="outline-success"
                                        size="sm"
                                        className="with-icon ml-1"
                                        title={t("add")}
                                        onClick={() => addSessionItem()}
                                    >
                                        <RiAddBoxLine />
                                        <span>{t("add")}</span>
                                    </Button>
                                </Form.Label>
                            </Form.Group>
                        </Form.Row>
                            {sessionItems.map((sessionItem, index) => {
                                return (
                                    <Accordion defaultActiveKey="0" style={{ border: '4px solid #ced4da', borderRadius: '1rem' }}>
                                        <Accordion.Toggle as={Card.Header} eventKey={index.toString()} className="d-flex justify-content-between align-items-center">
                                            <span>{`Session ${index + 1}`}</span>
                                            <Button
                                                variant="outline-danger"
                                                size="sm"
                                                className="with-icon"
                                                title={t("remove")}
                                                onClick={() => removeSessionItem(index)}
                                            >
                                                <RiDeleteBin6Line />
                                                <span>{t("remove_session")}</span>
                                            </Button>
                                        </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={`${index}`}>
                                        <Card.Body>
                                            <Form.Row>
                                                <Form.Group as={Col} sm="6" md="3" lg="3">
                                                    <Form.Label>{t("title")}:</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name={`sessions.${index}.title`}
                                                        ref={register({
                                                            required: {
                                                                value: true,
                                                                message: t("validation.you_must_enter", { name: t("session_title") }),
                                                            },
                                                        })}
                                                        placeholder={t("title")}
                                                        defaultValue=""
                                                        autoFocus
                                                        isInvalid={!!errors.sessions?.[index]?.title}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{errors.sessions?.[index]?.title?.message}</Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} sm="6" md="3" lg="3">
                                                    <Form.Label>{t("location")}:</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name={`sessions.${index}.location`}
                                                        ref={register}
                                                        placeholder={t("location")}
                                                        defaultValue=""
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} sm="6" md="3" lg="3">
                                                    <Form.Label>{t("documents")}</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name={`sessions.${index}.documents`}
                                                        ref={register}
                                                        placeholder={t("documents")}
                                                        defaultValue=""
                                                    />
                                                </Form.Group>
                                            </Form.Row>
                                            <hr />
                                            <Form.Row>
                                                <Form.Group as={Col} sm="6" md="4" lg="4">
                                                    <Form.Label>{t("goals")}</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        style={{ resize: "none" }}
                                                        rows={4}
                                                        type="text"
                                                        name={`sessions.${index}.goals`}
                                                        ref={register({
                                                            required: {
                                                                value: true,
                                                                message: t("validation.you_must_enter", { name: t("session_goal") }),
                                                            },
                                                        })}
                                                        placeholder={t("goals")}
                                                        defaultValue=""
                                                        isInvalid={!!errors.sessions?.[index]?.goals}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{errors.sessions?.[index]?.goals?.message}</Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} sm="6" md="4" lg="4">
                                                    <Form.Label>{t("preparations")}</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        style={{ resize: "none" }}
                                                        rows={4}
                                                        type="text"
                                                        name={`sessions.${index}.preparations`}
                                                        ref={register}
                                                        placeholder={t("preparations")}
                                                        defaultValue=""
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} sm="6" md="4" lg="4">
                                                    <Form.Label>{t("agenda")}</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        style={{ resize: "none" }}
                                                        rows={4}
                                                        type="text"
                                                        name={`sessions.${index}.agenda`}
                                                        ref={register}
                                                        placeholder={t("agenda")}
                                                        defaultValue=""
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="12">
                                                    <Form.Label htmlFor="followedTask-selector">
                                                        {t("followed_tasks")}:
                                                        <Button
                                                            variant="outline-success"
                                                            size="sm"
                                                            className="with-icon ml-1"
                                                            title={t("add")}
                                                            onClick={() => addFollowedTaskItem(index)}
                                                        >
                                                            <RiAddBoxLine />
                                                            <span>{t("add")}</span>
                                                        </Button>
                                                    </Form.Label>
                                                </Form.Group>
                                            </Form.Row>
                                            {sessionItem.followed_tasks?.map((followed_task, followed_task_index) => {
                                                return (
                                                    <div>
                                                        <Accordion key={followed_task_index} defaultActiveKey="0" style={{ border: '2px solid #ced4da', borderRadius: '1rem' }}>
                                                            <Accordion.Toggle as={Card.Header} eventKey={followed_task_index.toString()} className="d-flex justify-content-between align-items-center">
                                                                <span>{`Followed Task ${followed_task_index + 1}`}</span>
                                                                <Button
                                                                    variant="outline-danger"
                                                                    size="sm"
                                                                    className="with-icon"
                                                                    title={t("remove")}
                                                                    onClick={() => removeFollowedTaskItem(index, followed_task_index)}
                                                                >
                                                                    <RiDeleteBin6Line />
                                                                    <span>{t("remove_followed_task")}</span>
                                                                </Button>
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse eventKey={`${followed_task_index}`}>
                                                                <Card.Body>
                                                                    <Form.Row>
                                                                        <Form.Group as={Col} sm="6" md="4" lg="4">
                                                                            <Form.Label>{t("title")}:</Form.Label>
                                                                            <Form.Control
                                                                                type="text"
                                                                                name={`sessions.${index}.followed_tasks.${followed_task_index}.title`}
                                                                                ref={register({
                                                                                    required: {
                                                                                        value: true,
                                                                                        message: t("validation.you_must_enter", { name: t("title") }),
                                                                                    },
                                                                                })}
                                                                                placeholder={t("title")}
                                                                                defaultValue=""
                                                                                autoFocus
                                                                            />
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} sm="6" md="4" lg="4">
                                                                            <Form.Label>{t("task_order")}:</Form.Label>
                                                                            <Form.Control
                                                                                type="number"
                                                                                name={`sessions.${index}.followed_tasks.${followed_task_index}.steps`}
                                                                                ref={register({
                                                                                    required: {
                                                                                        value: true,
                                                                                        message: t("validation.you_must_enter", { name: t("task_order") }),
                                                                                    },
                                                                                })}
                                                                                placeholder={t("task_order")}
                                                                                defaultValue=""
                                                                            />
                                                                        </Form.Group>
                                                                    </Form.Row>
                                                                    <hr />
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Accordion>
                                                        <hr />
                                                    </div>
                                                )
                                            })}
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Accordion>
                            )
                        })}
                    </Form>
                </Card.Body>
                <Card.Footer>
                    <Row>
                        <Col xs="6" className="text-left">
                            <Button as={NavLink} to="/mentoring_programs" variant="secondary" size="sm" className="with-icon">
                                <RiArrowLeftSLine />
                                <span>{t("list")}</span>
                            </Button>
                        </Col>
                        <Col xs="6" className="text-right">
                            <Button variant="primary" size="sm" className="with-icon" onClick={handleSubmit(onSubmit)}>
                                <RiCheckFill />
                                <span>{t("save")}</span>
                            </Button>
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
        </MainLayout>
    );
});
export default CreatePrograms;
