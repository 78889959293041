import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { MainLayout } from "../../layouts";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { RiArrowLeftSLine, RiCheckFill } from "react-icons/all";
import { useForm } from "react-hook-form";
import { RootState } from "../../../store/reducers";
import { IFeedbackParams, IFeedbackState } from "../../../store/types";
import { AlertDefaultState, AlertStateType } from "../../../types";
import { CustomFileInput, FormAlert } from "../../partials";
import history from "../../../utils/history";
import { fileObjectToBase64 } from "../../../utils/mixins";
import actions from "../../../store/actions";

type FormDataType = {
  name: string;
  position: string;
  description?: string;
  picture: FileList;
};

export const Create = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const { register, handleSubmit, errors, reset } = useForm<FormDataType>();
  const onSubmit = async (data: FormDataType) => {
    setFormDisable(true);
    setFormAlert(AlertDefaultState);

    const params: IFeedbackParams = {
      name: data.name,
      position: data.position,
      picture: [],
      description: data.description,
    };

    let pictures: Promise<string>[] = [];
    if (data.picture.length > 0) {
      pictures = Array.from(data.picture).map(async (picture) => {
        return await fileObjectToBase64(picture);
      });
    }

    Promise.all(pictures).then(async (values) => {
      params.picture = values;
      dispatch(await actions.createFeedback(params));
    });
  };

  const { isLoading, response, error } = useSelector<RootState, IFeedbackState>(
    (state: RootState) => state.createFeedback
  );

  const [formDisable, setFormDisable] = useState<boolean>(false);
  const [formAlert, setFormAlert] = useState<AlertStateType>(AlertDefaultState);

  useEffect(() => {
    setFormDisable(isLoading);

    if (error !== null) {
      if (error.response.status === 400) {
        setFormAlert({
          variant: "danger",
          show: true,
          messages: error.response.data.messages,
        });
      } else {
        addToast(t("unknown_error"), {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }

    if (response) {
      addToast(t("created_with_param", { param: t("news_singular") }), {
        appearance: "success",
        autoDismiss: true,
      });

      reset();

      dispatch({ type: "CREATE_FEEDBACK_RESET" });
      history.push(`/feedbacks/${response.data.id}`);
    }
  }, [isLoading, response, error, addToast, reset, t, dispatch]);

  return (
    <MainLayout>
      <FormAlert variant={formAlert.variant} show={formAlert.show} to={formAlert.to}>
        {formAlert.messages.map((message, key) => {
          return <p key={key}>{message}</p>;
        })}
      </FormAlert>

      <Card className={formDisable ? "loading-block" : ""}>
        <Card.Header as="h6">{t("create")}</Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
              <Form.Group as={Col} md="6" controlId="news-title">
                <Form.Label>{t("full_name")}:</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  ref={register({
                    required: {
                      value: true,
                      message: t("validation.you_must_enter", { name: t("full_name") }),
                    },
                  })}
                  placeholder={t("full_name")}
                  defaultValue=""
                  autoFocus
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="news-title">
                <Form.Label>{t("work_title")}:</Form.Label>
                <Form.Control
                  type="text"
                  name="position"
                  ref={register({
                    required: {
                      value: true,
                      message: t("validation.you_must_enter", { name: t("work_title") }),
                    },
                  })}
                  placeholder={t("work_title")}
                  defaultValue=""
                  autoFocus
                  isInvalid={!!errors.position}
                />
                <Form.Control.Feedback type="invalid">{errors.position?.message}</Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <hr />
            <Form.Row>
              <Form.Group as={Col} md="12" controlId="news-description">
                <Form.Label>{t("description")}:</Form.Label>
                <Form.Control
                  type="text"
                  as="textarea"
                  name="description"
                  style={{ height: 120 }}
                  ref={register}
                  placeholder={t("description")}
                  defaultValue=""
                />
              </Form.Group>
            </Form.Row>
            <hr />
            <Form.Row>
              <Form.Group as={Col} md="8" controlId="news-content">
                <Form.Label>{t("pictures")}:</Form.Label>
                <CustomFileInput
                  name="picture"
                  accept="image/jpeg,image/jpg,image/png"
                  multiple
                  dropzone
                  ref={register}
                  isInvalid={!!errors.picture}
                />
                <Form.Control.Feedback type="invalid">{errors.picture?.message}</Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <hr />
          </Form>
        </Card.Body>
        <Card.Footer>
          <Row>
            <Col xs="6" className="text-left">
              <Button as={NavLink} to="/feedbacks" variant="secondary" size="sm" className="with-icon">
                <RiArrowLeftSLine />
                <span>{t("list")}</span>
              </Button>
            </Col>
            <Col xs="6" className="text-right">
              <Button variant="primary" size="sm" className="with-icon" onClick={handleSubmit(onSubmit)}>
                <RiCheckFill />
                <span>{t("save")}</span>
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </MainLayout>
  );
};

export default Create;
