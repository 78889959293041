import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/reducers";
import { ISectorsState, SectorType } from "../../../store/types";
import actions from "../../../store/actions";
import Select from "react-select";
import { AlertDefaultState, AlertStateType } from "../../../types";
import { FormAlert } from "../../partials";

export type SectorSelectorModalType = {
  show: boolean;
  type: "user" | "business";
};

export const SectorSelectorModalDefaultState: SectorSelectorModalType = {
  show: false,
  type: "user",
};

type SectorSelectorModalPropsType = {
  modal: SectorSelectorModalType;
  onClose: Function;
  onSubmit: Function;
};

export type SectorSelectOptionType = {
  value: string;
  label: string;
};

export type SectorSelectedType = {
  type: "user" | "business";
  sector: SectorSelectOptionType;
  sub_sector: Array<SectorSelectOptionType>;
  sector_experience?: number;
};

export const SectorSelectorModal = (props: SectorSelectorModalPropsType) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [show, setShow] = useState<boolean>(false);
  const [formDisable, setFormDisable] = useState<boolean>(false);
  const [sectors, setSectors] = useState<Array<SectorType>>([]);
  const [subSectorOptions, setSubSectorOptions] = useState<Array<SectorSelectOptionType>>([]);
  const [selectedSector, setSelectedSector] = useState<SectorSelectOptionType | null>(null);
  const [selectedSubSectors, setSelectedSubSectors] = useState<Array<SectorSelectOptionType>>([]);
  const sectorRef = useRef<HTMLSelectElement>(null);
  const subSectorRef = useRef<any>(null);
  const experienceRef = useRef<HTMLInputElement>(null);
  const [formAlert, setFormAlert] = useState<AlertStateType>(AlertDefaultState);

  const { isLoading: sectorsIsLoading, response: sectorsResponse } = useSelector<RootState, ISectorsState>(
    (state: RootState) => state.sectors
  );

  useEffect(() => {
    if (!sectorsResponse) {
      dispatch(actions.sectors({ subsector: true }));
    } else {
      setSectors(sectorsResponse.data.items);
    }
  }, [sectorsResponse, dispatch]);

  useEffect(() => {
    if (sectorsIsLoading) {
      setFormDisable(true);
    } else {
      setFormDisable(false);
    }
  }, [sectorsIsLoading]);

  const handleSectorChange = () => {
    if (!sectorRef.current!.value) {
      setSelectedSector(null);
      setSelectedSubSectors([]);
      return;
    }

    const selectedSectorMatched = sectors.filter((sector) => sector.id === sectorRef.current!.value);
    if (selectedSectorMatched.length) {
      if (selectedSectorMatched[0].subsector) {
        setSelectedSector({
          label: `${selectedSectorMatched[0].title} / ${selectedSectorMatched[0].nace}`,
          value: selectedSectorMatched[0].id,
        });

        const subSectorsBag: Array<SectorSelectOptionType> = [];

        selectedSectorMatched[0].subsector.forEach((subSector) => {
          subSectorsBag.push({
            value: subSector.id,
            label: subSector.name + " / " + subSector.code,
          });
        });

        setSubSectorOptions(subSectorsBag);
      }
    } else {
      setSelectedSector(null);
    }

    setSelectedSubSectors([]);
  };

  const handleSubSectorChange = (options: any) => {
    const subSectorsBag: Array<SectorSelectOptionType> = [];

    if (options.length) {
      options.forEach((option: SectorSelectOptionType) => {
        subSectorsBag.push(option);
      });
    }

    setSelectedSubSectors(subSectorsBag);
  };

  const handleHide = () => {
    setShow(false);
    dispatch({ type: "SECTORS_RESET" });
  };

  const handleClose = () => {
    handleHide();
    props.onClose();
  };

  const handleSubmit = () => {
    const errors: Array<string> = [];

    if (!selectedSector) {
      errors.push(t("validation.you_must_choose", { name: t("sector") }));
    }

    if (!selectedSubSectors.length) {
      errors.push(t("validation.you_must_choose", { name: t("sub_sector") }));
    }

    if (selectedSubSectors.length > 3) {
      errors.push(t("validation.max_choose", { name: t("sub_sector"), max: 3 }));
    }

    if (props.modal.type === "user" && !experienceRef.current!.value) {
      errors.push(t("validation.you_must_enter", { name: t("experience") }));
    }

    if (errors.length) {
      setFormAlert({
        variant: "danger",
        show: true,
        messages: errors,
      });
    } else {
      const data = {
        type: props.modal.type,
        sector: selectedSector,
        sub_sector: selectedSubSectors,
        sector_experience: props.modal.type === "user" ? Number(experienceRef.current!.value) : null,
      };

      setFormAlert(AlertDefaultState);
      sectorRef.current!.value = "";
      setSelectedSector(null);
      setSelectedSubSectors([]);
      if (props.modal.type === "user") {
        experienceRef.current!.value = "";
      }

      props.onSubmit(data);
    }
  };

  useEffect(() => {
    setShow(props.modal.show);
  }, [props.modal.show]);

  return (
    <Modal centered show={show} onHide={handleClose} animation={false} className={formDisable ? "loading-block" : ""}>
      <Modal.Header closeButton>
        <Modal.Title as="h6">{t("select_sector")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <FormAlert variant={formAlert.variant} show={formAlert.show} to={formAlert.to}>
            {formAlert.messages.map((message, key) => {
              return <p key={key}>{message}</p>;
            })}
          </FormAlert>
          <Form.Group>
            <Form.Label htmlFor="selector-sector">{t("sector")}</Form.Label>
            <Form.Control
              as="select"
              custom
              id="selector-sector"
              autoFocus
              ref={sectorRef}
              onChange={handleSectorChange}
            >
              <option value="">{t("select_sector")}</option>
              {sectors.length > 0 && (
                <>
                  {sectors.map((sector, key) => {
                    return (
                      <option key={key} value={sector.id}>
                        {sector.title} / {sector.nace}
                      </option>
                    );
                  })}
                </>
              )}
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="selector-subsector">{t("sub_sector")}</Form.Label>
            <Select
              isMulti
              isClearable
              id="selector-subsector"
              inputRef={subSectorRef}
              placeholder={t("select_sub_sector")}
              noOptionsMessage={() => t("not_found")}
              defaultValue={selectedSubSectors}
              value={selectedSubSectors}
              options={subSectorOptions}
              onChange={(option) => handleSubSectorChange(option)}
            />
          </Form.Group>
          {props.modal.type === "user" && (
            <Form.Group>
              <Form.Label htmlFor="selector-experience">{t("experience")}</Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  name="experience"
                  id="selector-experience"
                  min="1"
                  aria-describedby="experience-addon"
                  placeholder={t("experience")}
                  defaultValue=""
                  ref={experienceRef}
                />
                <InputGroup.Text id="experience-addon">{t("year")}</InputGroup.Text>
              </InputGroup>
            </Form.Group>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleSubmit}>
          {t("add")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SectorSelectorModal;
