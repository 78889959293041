import React, { useEffect, useState } from "react";
import ILayoutPartialPropType from "./ILayoutPartialPropType";
import { Navbar, Dropdown, Badge, Button } from "react-bootstrap";
import {  NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RiLogoutBoxRLine, RiMenuLine, RiNotification3Line, RiProfileLine, RiShieldUserLine } from "react-icons/all";
import { RootState } from "../../store/reducers";
import { IMeState, IDashboardState } from "../../store/types";
import actions from "../../store/actions";
import ICMPD from "../../assets/img/logo/ICMPD_logo_transparent_v2.jpg";
import ExampleSTB from "../../assets/img/logo/STB-ÖrnekLogo.png";
import EUTR from "../../assets/img/logo/EU_TR flag.png";
import YDKM from "../../assets/img/logo/YDKM_TR Yatay Renkli.png";
import uretken from "../../assets/img/logo/uretkeniz biz LOGO FINAL_LOGO VE LOGOTYPE beyaz-turkuvaz.png";
type DropdownToggleRefPropsType = {
  children: React.ReactNode;
  onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {};
};

export const Header = ({ asideToggleHandler }: ILayoutPartialPropType) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [totalNotifications, setTotalNotifications] = useState<number>(0);
  const [mentorApplications, setMentorApplications] = useState<number>(0);
  const [userApplications, setUserApplications] = useState<number>(0);

  const { response: meResponse } = useSelector<RootState, IMeState>((state: RootState) => state.me);

  const { response: dashboardResponse } = useSelector<RootState, IDashboardState>(
    (state: RootState) => state.dashboard
  );

  const UserToggle = React.forwardRef((props: DropdownToggleRefPropsType, ref: React.Ref<HTMLAnchorElement>) => (
    <a
      href="/#"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        props.onClick(e);
      }}
    >
      {props.children}
    </a>
  ));

  const NotificationsToggle = React.forwardRef(
    (props: DropdownToggleRefPropsType, ref: React.Ref<HTMLAnchorElement>) => (
      <a
        href="/#"
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          props.onClick(e);
        }}
      >
        {props.children}
      </a>
    )
  );

  const handleLogout = () => {
    dispatch(actions.logout());
  };

  useEffect(() => {
    if (dashboardResponse) {
      setMentorApplications(dashboardResponse.data.applications.mentor);
      setUserApplications(dashboardResponse.data.applications.user);
      setTotalNotifications(dashboardResponse.data.applications.mentor + dashboardResponse.data.applications.user);
    } else {
      dispatch(actions.dashboard());
    }
  }, [dashboardResponse, dispatch]);

  return (
    <header>
      <Navbar collapseOnSelect expand="lg">

        <Button className="aside-toggle" onClick={() => asideToggleHandler()}>
          <RiMenuLine />
        </Button>
        <div className="menu-items">
          <div className="d-flex justify-content-center align-items-center" style={{flexDirection:"column"}}>
          <img src={ICMPD} className="hide-on-phone" style={{ padding: '2px 0px', objectFit: 'fill', height: 69 }} alt="" />
          <img src={uretken} className="hide-on-phone" style={{ padding: '2px 0px', objectFit: 'fill', height: 75,width:75 }} alt="" />
          </div>
          <img src={EUTR} className="hide-on-phone" style={{ padding: '2px 0px', objectFit: 'fill', height: 150 }} alt="" />
          <div className="d-flex justify-content-end" style={{flexDirection:"column"}}> 
          <img src={ExampleSTB} className="hide-on-phone" style={{ padding: '2px 0px', objectFit: 'fill', height: 75 }} alt="" />
          <img src={YDKM} className="hide-on-phone" style={{ padding: '2px 0px', objectFit: 'fill', height: 75 }} alt="" />
        </div>
        </div>
        
        {meResponse && (
          <div className="user-actions">

            <Dropdown className="notifications">
              <Dropdown.Toggle as={NotificationsToggle}>
                <RiNotification3Line />
                {totalNotifications > 0 && (
                  <Badge pill variant="danger">
                    {totalNotifications > 9 ? <>9+</> : <>{totalNotifications}</>}
                  </Badge>
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-right bordered p-0">
                {mentorApplications > 0 && (
                  <Dropdown.Item
                    as={NavLink}
                    exact
                    to="/applications/mentor?status=waiting"
                    eventKey="mentor-applications-count"
                  >
                    <span className="text">
                      {t("user_notifications.mentor_applications", { count: mentorApplications })}
                    </span>
                    <Badge pill variant="danger">
                      {mentorApplications}
                    </Badge>
                  </Dropdown.Item>
                )}
                {userApplications > 0 && (
                  <Dropdown.Item
                    as={NavLink}
                    exact
                    to="/applications/user?status=waiting"
                    eventKey="user-applications-count"
                  >
                    <span className="text">
                      {t("user_notifications.user_applications", { count: userApplications })}
                    </span>
                    <Badge pill variant="danger">
                      {userApplications}
                    </Badge>
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="user">
              <Dropdown.Toggle as={UserToggle}>
                <RiShieldUserLine />
                <span className="text">{meResponse.data.name + " " + meResponse.data.lastname}</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-right bordered p-0">
                <Dropdown.Item as={NavLink} to="/profile" eventKey="1">
                  <RiProfileLine />
                  <span className="text">{t("profile")}</span>
                </Dropdown.Item>
                <Dropdown.Item eventKey="2" onClick={handleLogout}>
                  <RiLogoutBoxRLine />
                  <span className="text">{t("logout")}</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
      </Navbar>
    </header>
  );
};
