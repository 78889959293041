import { Reducer } from "react";
import { ILogsListState, LogsListActionType } from "../types";

// Logs list
const logsInitialState: ILogsListState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const logsListReducer: Reducer<ILogsListState, LogsListActionType> = (
  state = logsInitialState,
  action
): ILogsListState => {
  switch (action.type) {
    case "LOGS_LIST_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "LOGS_LIST_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "LOGS_LIST_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "LOGS_LIST_RESET":
      return logsInitialState;
    default:
      return state;
  }
};
