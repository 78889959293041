import { Reducer } from "react";
import {
  FeedbacksListActionType,
  FeedbacksCreateActionType,
  UpdateFeedbackActionType,
  DeleteFeedbacksActionType,
  FeedBackActionType,
  FeedbackState,
  IDefaultState,
  IFeedbacksListState,
  IFeedbackState,
  INewsState,
} from "../types";

// Feedbacks list
const FeedbacksInitialState: IFeedbacksListState = {
  isLoading: false,
  response: null,
  error: null,
};

const feedbacksInitialState: IFeedbackState = {
  isLoading: false,
  response: null,
  error: null,
};

const feedbackInitialState: IDefaultState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const feedbacksReducer: Reducer<IFeedbacksListState, FeedbacksListActionType> = (
  state = FeedbacksInitialState,
  action
): IFeedbacksListState => {
  switch (action.type) {
    case "FEEDBACKS_LIST_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "FEEDBACKS_LIST_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "FEEDBACKS_LIST_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "FEEDBACKS_LIST_RESET":
      return FeedbacksInitialState;
    default:
      return state;
  }
};

export const createFeedbackReducer: Reducer<IFeedbackState, FeedbacksCreateActionType> = (
  state = feedbacksInitialState,
  action
): IFeedbackState => {
  switch (action.type) {
    case "CREATE_FEEDBACK_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "CREATE_FEEDBACK_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "CREATE_FEEDBACK_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "CREATE_FEEDBACK_RESET":
      return feedbacksInitialState;
    default:
      return state;
  }
};

/**
 * @param state
 * @param action
 */
export const feedbackReducer: Reducer<FeedbackState, FeedBackActionType> = (
  state = feedbackInitialState,
  action
): FeedbackState => {
  switch (action.type) {
    case "FEEDBACK_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "FEEDBACK_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "FEEDBACK_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "FEEDBACK_RESET":
      return feedbackInitialState;
    default:
      return state;
  }
};

export const updateFeedbackReducer: Reducer<IDefaultState, UpdateFeedbackActionType> = (
  state = feedbackInitialState,
  action
): IFeedbackState => {
  switch (action.type) {
    case "UPDATE_FEEDBACK_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "UPDATE_FEEDBACK_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "UPDATE_FEEDBACK_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "UPDATE_FEEDBACK_RESET":
      return feedbackInitialState;
    default:
      return state;
  }
};

export const deleteFeedbacksReducer: Reducer<IDefaultState, DeleteFeedbacksActionType> = (
  state = feedbackInitialState,
  action
): INewsState => {
  switch (action.type) {
    case "DELETE_FEEDBACK_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "DELETE_FEEDBACK_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "DELETE_FEEDBACK_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "DELETE_FEEDBACK_RESET":
      return feedbackInitialState;
    default:
      return state;
  }
};
