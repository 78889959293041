import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { MainLayout } from "../../../layouts";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { RiArrowLeftSLine, RiCheckFill } from "react-icons/all";
import { useForm } from "react-hook-form";
import { RootState } from "../../../../store/reducers";
import { ISectorParams, ISectorState } from "../../../../store/types";
import { AlertDefaultState, AlertStateType } from "../../../../types";
import { FormAlert } from "../../../partials";
import actions from "../../../../store/actions";
import history from "../../../../utils/history";

export const Create = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const { register, handleSubmit, errors, reset } = useForm<ISectorParams>();
  const onSubmit = async (data: ISectorParams) => {
    dispatch(await actions.createSector(data));
  };

  const { isLoading, response, error } = useSelector<RootState, ISectorState>((state: RootState) => state.createSector);

  const [formDisable, setFormDisable] = useState<boolean>(false);
  const [formAlert, setFormAlert] = useState<AlertStateType>(AlertDefaultState);

  useEffect(() => {
    setFormDisable(isLoading);

    if (error !== null) {
      if (error.response.status === 400) {
        setFormAlert({
          variant: "danger",
          show: true,
          messages: error.response.data.messages,
        });
      } else {
        addToast(t("unknown_error"), {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }

    if (response) {
      addToast(t("created_with_param", { param: t("sector") }), {
        appearance: "success",
        autoDismiss: true,
      });

      reset();

      dispatch({ type: "CREATE_SECTOR_RESET" });
      history.push(`/sector-management/sectors/${response.data.id}`);
    }
  }, [isLoading, response, addToast, t, error, reset, dispatch]);

  return (
    <MainLayout>
      <FormAlert variant={formAlert.variant} show={formAlert.show} to={formAlert.to}>
        {formAlert.messages.map((message, key) => {
          return <p key={key}>{message}</p>;
        })}
      </FormAlert>

      <Card className={formDisable ? "loading-block" : ""}>
        <Card.Header as="h6">{t("create")}</Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
              <Form.Group as={Col} sm="6" md="4" controlId="sector-title">
                <Form.Label>{t("title")}:</Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  ref={register({
                    required: {
                      value: true,
                      message: t("validation.you_must_enter", { name: t("title") }),
                    },
                  })}
                  placeholder={t("title")}
                  defaultValue=""
                  autoFocus
                  isInvalid={!!errors.title}
                />
                <Form.Control.Feedback type="invalid">{errors.title?.message}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} sm="6" md="4" controlId="sector-nace">
                <Form.Label>{t("nace")}:</Form.Label>
                <Form.Control
                  type="text"
                  name="nace"
                  ref={register({
                    required: {
                      value: true,
                      message: t("validation.you_must_enter", { name: t("nace") }),
                    },
                    maxLength: {
                      value: 1,
                      message: t("validation.max", { max: 1 }),
                    },
                  })}
                  maxLength={1}
                  placeholder={t("nace")}
                  defaultValue=""
                  isInvalid={!!errors.nace}
                />
                <Form.Control.Feedback type="invalid">{errors.nace?.message}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} sm="6" md="4" controlId="sector-code">
                <Form.Label>{t("code")}:</Form.Label>
                <Form.Control
                  type="text"
                  name="code"
                  ref={register({
                    required: {
                      value: true,
                      message: t("validation.you_must_enter", { name: t("code") }),
                    },
                    maxLength: {
                      value: 1,
                      message: t("validation.max", { max: 1 }),
                    },
                  })}
                  maxLength={1}
                  placeholder={t("nace")}
                  defaultValue=""
                  isInvalid={!!errors.code}
                />
                <Form.Control.Feedback type="invalid">{errors.code?.message}</Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
          </Form>
        </Card.Body>
        <Card.Footer>
          <Row>
            <Col xs="6" className="text-left">
              <Button as={NavLink} to="/sector-management/sectors" variant="secondary" size="sm" className="with-icon">
                <RiArrowLeftSLine />
                <span>{t("list")}</span>
              </Button>
            </Col>
            <Col xs="6" className="text-right">
              <Button variant="primary" size="sm" className="with-icon" onClick={handleSubmit(onSubmit)}>
                <RiCheckFill />
                <span>{t("save")}</span>
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </MainLayout>
  );
};

export default Create;
