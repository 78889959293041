import { ErrorLayout } from "../../layouts";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { RiDashboard3Line } from "react-icons/ri";
import { NavLink } from "react-router-dom";

export const Error404 = () => {
  const { t } = useTranslation();

  return (
    <>
      <ErrorLayout>
        <div className="text-center">
          <p className="text-muted">{t("error_404_message")}</p>
          <hr />
          <Button as={NavLink} to="/" exact variant="outline-secondary with-icon">
            <RiDashboard3Line />
            <span>{t("goto_dashboard")}</span>
          </Button>
        </div>
      </ErrorLayout>
    </>
  );
};

export default Error404;
