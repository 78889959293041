import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { MainLayout } from "../../../layouts";
import { Button, ButtonGroup, Card, Col, Form, Row, Table } from "react-bootstrap";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { RiEyeLine, RiDeleteBin6Line } from "react-icons/all";
import Moment from "react-moment";
import qs from "query-string";
import { RootState } from "../../../../store/reducers";
import { IMentorApplicationPaginationParams, IMentorApplicationsState } from "../../../../store/types";
import { DeleteConfirmModal, LoadingIndicator, Pagination, RecordNotFound } from "../../../partials";
import actions from "../../../../store/actions";
import { DEFAULT_PER_PAGE, ApplicationStatusTypes } from "../../../../types";
import { getApplicationStatus, getApplicationStatusClassName } from "../../../../utils/mixins";

export const List = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const filterStatusRef = useRef<HTMLSelectElement>(null);
  const filterQRef = useRef<HTMLInputElement>(null);

  const { isLoading, response, error } = useSelector<RootState, IMentorApplicationsState>(
    (state: RootState) => state.mentorApplications
  );

  const { response: deleteResponse, error: deleteError } = useSelector<RootState, IMentorApplicationsState>(
    (state: RootState) => state.deleteMentorApplication
  );

  const getMentorApplications = useCallback(
    (queryParams?) => {
      dispatch(actions.mentorApplications(queryParams));
    },
    [dispatch]
  );

  const queryString = new URLSearchParams(useLocation().search);
  const page: number = queryString.get("page") ? Number(queryString.get("page")) : 1;
  const status: string | null = queryString.get("status") ? queryString.get("status") : null;
  const q: string | null = queryString.get("q") ? queryString.get("q") : null;

  const [queryParams, setQueryParams] = useState<IMentorApplicationPaginationParams>({
    page,
    per: DEFAULT_PER_PAGE,
    status,
    q,
  });

  const qParams = useMemo<IMentorApplicationPaginationParams>(() => qs.parse(search), [search]);

  useEffect(() => {
    const params = qParams;
    params.per = DEFAULT_PER_PAGE;
    setQueryParams(params);
    getMentorApplications(params);
  }, [getMentorApplications, qParams]);

  const [deleteItem, setDeleteItem] = useState<string | null>(null);
  const onDeleteConfirm = async () => {
    if (deleteItem) {
      dispatch(await actions.deleteMentorApplication(deleteItem));
      setDeleteItem(null);
    }
  };

  const handleSubmitFilter = () => {
    let url = `/applications/mentor?page=1`;
    const searchParams: Array<string> = [];
    if (filterStatusRef.current!.value) {
      searchParams.push(`status=${filterStatusRef.current!.value}`);
      queryParams.status = filterStatusRef.current!.value;
    }

    if (filterQRef.current!.value) {
      searchParams.push(`q=${filterQRef.current!.value}`);
      queryParams.q = filterQRef.current!.value;
    }

    if (searchParams.length) {
      url += "&" + searchParams.join("&");
    }

    getMentorApplications(queryParams);

    history.push(url);
  };

  useEffect(() => {
    if (deleteResponse) {
      addToast(t("deleted_with_param", { param: t("mentor_application") }), {
        appearance: "success",
        autoDismiss: true,
      });

      getMentorApplications(queryParams);
      dispatch({ type: "DELETE_MENTOR_APPLICATION_RESET" });
      dispatch(actions.dashboard());
    }

    if (deleteError) {
      if (deleteError.response.status === 400) {
        addToast(deleteError.response.data.messages.join(" "), {
          appearance: "error",
          autoDismiss: true,
        });
      } else {
        addToast(t("unknown_error"), {
          appearance: "error",
          autoDismiss: true,
        });
      }

      dispatch({ type: "DELETE_MENTOR_APPLICATION_RESET" });
    }
  }, [deleteResponse, deleteError, addToast, t, getMentorApplications, queryParams, page, dispatch]);

  useEffect(() => {
    if (error?.response) {
      dispatch({ type: "MENTOR_APPLICATIONS_RESET" });

      if (error.response.status >= 400) {
        history.push("/applications/mentor");
      }
    }

    if (!response && !isLoading) {
      getMentorApplications(queryParams);
    }
  }, [response, isLoading, getMentorApplications, queryParams, status, error, history, dispatch]);

  return (
    <MainLayout>
      <LoadingIndicator show={isLoading} />
      {response && (
        <Row>
          <Col md="9" className="order-last order-md-first">
            <Card>
              <Card.Header>
                <Row>
                  <Col as="h6" xs="8" className="mb-0">
                    {t("mentor_applications")}
                  </Col>
                  <Col xs="4" className="mb-0 text-right">
                    <strong className="mr-1">{t("total")}:</strong>
                    <span>{response.data.pagination.total}</span>
                  </Col>
                </Row>
              </Card.Header>

              <Card.Body className="p-0 m-0">
                {response.data.items.length > 0 ? (
                  <>
                    <DeleteConfirmModal
                      show={deleteItem !== null}
                      onClose={() => setDeleteItem(null)}
                      onConfirm={onDeleteConfirm}
                    />
                    <Table responsive striped hover className="table-list m-0">
                      <thead>
                        <tr>
                          <th>{t("name")}</th>
                          <th>{t("status")}</th>
                          <th className="date">{t("created_at")}</th>
                          <th className="actions">{t("actions")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {response.data.items.map((item, key) => {
                          return (
                            <tr key={key} className={`table-${getApplicationStatusClassName(item)}`}>
                              <td>{`${item.name} ${item.lastname}`}</td>
                              <td>{t("application_status_type." + getApplicationStatus(item))}</td>
                              <td className="date">
                                <Moment format="DD/MM/YYYY HH:mm:ss">{item.created_at}</Moment>
                              </td>
                              <td className="actions">
                                <ButtonGroup>
                                  <Button
                                    as={NavLink}
                                    to={`/applications/mentor/${item.id}`}
                                    variant="outline-info"
                                    size={"sm"}
                                    className="btn-icon"
                                    title={t("show")}
                                  >
                                    <RiEyeLine />
                                  </Button>
                                  <Button
                                    variant="outline-danger"
                                    size={"sm"}
                                    className="btn-icon"
                                    title={t("delete")}
                                    onClick={() => setDeleteItem(item.id)}
                                  >
                                    <RiDeleteBin6Line />
                                  </Button>
                                </ButtonGroup>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </>
                ) : (
                  <RecordNotFound />
                )}
              </Card.Body>

              <Card.Footer>
                <Row>
                  <Col xs="12">
                    {response.data.items.length > 0 && (
                      <Pagination pagination={response.data.pagination} extraParams={["status", "q"]} />
                    )}
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Col>
          <Col md="3" className="order-first order-md-last">
            <Card>
              <Card.Body>
                <Form onSubmit={handleSubmitFilter}>
                  <Form.Group controlId="filter-type">
                    <Form.Label>{t("status")}</Form.Label>
                    <Form.Control as="select" custom ref={filterStatusRef} defaultValue={status ? status : ""}>
                      <option value="">{t("all")}</option>
                      {Object.keys(ApplicationStatusTypes).map((key) => {
                        return (
                          <option value={key} key={key}>
                            {t("application_status_type." + key)}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="filter-q">
                    <Form.Label>{t("keywords")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="q"
                      ref={filterQRef}
                      placeholder={t("keywords")}
                      defaultValue={q ? q : ""}
                    />
                  </Form.Group>
                  <hr />
                  <Button variant="outline-secondary" block onClick={handleSubmitFilter}>
                    {t("filter")}
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </MainLayout>
  );
};

export default List;
