import { Reducer } from "react";
import {
  ISubSectorsState,
  SubSectorsActionType,
  ISubSectorState,
  SubSectorActionType,
  CreateSubSectorActionType,
  UpdateSubSectorActionType,
  DeleteSubSectorActionType,
  IDefaultState,
} from "../types";

// Sub sectors
const subSectorsInitialState: ISubSectorsState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const subSectorsReducer: Reducer<ISubSectorsState, SubSectorsActionType> = (
  state = subSectorsInitialState,
  action
): ISubSectorsState => {
  switch (action.type) {
    case "SUB_SECTORS_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "SUB_SECTORS_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "SUB_SECTORS_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "SUB_SECTORS_RESET":
      return subSectorsInitialState;
    default:
      return state;
  }
};

// Sub sector
const subSectorInitialState: ISubSectorState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const subSectorReducer: Reducer<ISubSectorState, SubSectorActionType> = (
  state = subSectorInitialState,
  action
): ISubSectorState => {
  switch (action.type) {
    case "SUB_SECTOR_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "SUB_SECTOR_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "SUB_SECTOR_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "SUB_SECTOR_RESET":
      return subSectorInitialState;
    default:
      return state;
  }
};

/**
 * @param state
 * @param action
 */
export const createSubSectorReducer: Reducer<ISubSectorState, CreateSubSectorActionType> = (
  state = subSectorInitialState,
  action
): ISubSectorState => {
  switch (action.type) {
    case "CREATE_SUB_SECTOR_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "CREATE_SUB_SECTOR_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "CREATE_SUB_SECTOR_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "CREATE_SUB_SECTOR_RESET":
      return subSectorInitialState;
    default:
      return state;
  }
};

/**
 * @param state
 * @param action
 */
export const updateSubSectorReducer: Reducer<ISubSectorState, UpdateSubSectorActionType> = (
  state = subSectorInitialState,
  action
): ISubSectorState => {
  switch (action.type) {
    case "UPDATE_SUB_SECTOR_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "UPDATE_SUB_SECTOR_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "UPDATE_SUB_SECTOR_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "UPDATE_SUB_SECTOR_RESET":
      return subSectorInitialState;
    default:
      return state;
  }
};

const subSectorDeleteInitialState: IDefaultState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const deleteSubSectorReducer: Reducer<IDefaultState, DeleteSubSectorActionType> = (
  state = subSectorDeleteInitialState,
  action
): ISubSectorState => {
  switch (action.type) {
    case "DELETE_SUB_SECTOR_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "DELETE_SUB_SECTOR_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "DELETE_SUB_SECTOR_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "DELETE_SUB_SECTOR_RESET":
      return subSectorDeleteInitialState;
    default:
      return state;
  }
};
