import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { MainLayout } from "../../layouts";
import { Button, ButtonGroup, Card, Col, Row, Table, Badge, Form } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { RiEyeLine, RiEyeOffFill } from "react-icons/all";
import { RootState } from "../../../store/reducers";
import { IFeaturedMentorsState, IUsersPaginationParams } from "../../../store/types";
import { LoadingIndicator, Pagination, RecordNotFound } from "../../partials";
import actions from "../../../store/actions";
import { DEFAULT_PER_PAGE } from "../../../types";
import qs from "query-string";

export const List = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const filterQRef = useRef<HTMLInputElement>(null);

  const { isLoading, response, error } = useSelector<RootState, IFeaturedMentorsState>(
    (state: RootState) => state.featuredUsers
  );

  const {
    isLoading: updateIsLoading,
    response: updateResponse,
    error: updateError,
  } = useSelector<RootState, IFeaturedMentorsState>((state: RootState) => state.updateFeaturedUser);

  const getFeaturedUsers = useCallback(
    (queryParams?) => {
      dispatch(actions.featuredMentors(queryParams));
    },
    [dispatch]
  );

  const queryString = new URLSearchParams(useLocation().search);
  const page: number = queryString.get("page") ? Number(queryString.get("page")) : 1;
  const q: string | null = queryString.get("q") ? queryString.get("q") : null;

  const [queryParams, setQueryParams] = useState<IUsersPaginationParams>({ page: page, per: DEFAULT_PER_PAGE });
  const qParams = useMemo<IUsersPaginationParams>(() => qs.parse(search), [search]);
  useEffect(() => {
    const params = qParams;
    params.per = DEFAULT_PER_PAGE;
    setQueryParams(params);
    getFeaturedUsers(params);
  }, [getFeaturedUsers, qParams]);

  const handleSubmitFilter = () => {
    let url = `/featured-mentors?page=1`;
    const searchParams: Array<string> = [];
    if (filterQRef.current!.value) {
      searchParams.push(`q=${filterQRef.current!.value}`);
      queryParams.q = filterQRef.current!.value;
    }

    if (searchParams.length) {
      url += "&" + searchParams.join("&");
    }

    getFeaturedUsers(queryParams);

    history.push(url);
  };

  useEffect(() => {
    if (error?.response) {
      dispatch({ type: "FEATURED_USERS_RESET" });

      if (error.response.status >= 400) {
        history.push("/featured-mentors");
      }
    } else {
      getFeaturedUsers({ page, per: DEFAULT_PER_PAGE, q });
    }
  }, [getFeaturedUsers, page, q, error, history, dispatch]);

  const handleUpdateStatus = (id: string, status: boolean) => {
    dispatch(actions.updateFeaturedMentor(id, { status }));
  };

  useEffect(() => {
    if (updateResponse) {
      getFeaturedUsers({ page, per: DEFAULT_PER_PAGE, q });

      dispatch({ type: "UPDATE_FEATURED_MENTOR_RESET" });
    }

    if (updateError) {
      addToast(t("unknown_error"), {
        appearance: "error",
        autoDismiss: true,
      });

      dispatch({ type: "UPDATE_FEATURED_MENTOR_RESET" });
    }
  }, [updateResponse, updateError, addToast, dispatch, getFeaturedUsers, page, q, t]);

  return (
    <MainLayout>
      <LoadingIndicator show={isLoading} />
      {response && (
        <Row>
          <Col md="9" className="order-last order-md-first">
            <Card className={updateIsLoading ? "loading-block" : ""}>
              <Card.Header>
                <Row>
                  <Col as="h6" xs="8" className="mb-0">
                    {t("users")}
                  </Col>
                  <Col xs="4" className="mb-0 text-right">
                    <strong className="mr-1">{t("total")}:</strong>
                    <span>{response.data.pagination.total}</span>
                  </Col>
                </Row>
              </Card.Header>

              <Card.Body className="p-0 m-0">
                {response.data.items.length > 0 ? (
                  <>
                    <Table responsive striped hover className="table-list m-0">
                      <thead>
                        <tr>
                          <th>{t("name")}</th>
                          <th>{t("lastname")}</th>
                          <th>{t("email")}</th>
                          <th className="text-center">{t("roles")}</th>
                          <th className="actions">{t("actions")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {response.data.items.map((item, key) => {
                          return (
                            <tr key={key}>
                              <td>{item.name}</td>
                              <td>{item.lastname}</td>
                              <td>{item.email}</td>
                              <td className="text-center">
                                {item.roles.length > 0 ? (
                                  <>
                                    {item.roles.map((role, key) => {
                                      return (
                                        <Badge variant="secondary" key={key} className="m-1">
                                          {t(`role_names.${role}`)}
                                        </Badge>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <>-</>
                                )}
                              </td>
                              <td className="actions">
                                <ButtonGroup>
                                  {!item.featured ? (
                                    <Button
                                      variant="outline-danger"
                                      size="sm"
                                      className="btn-icon"
                                      title={t("featured_on")}
                                      onClick={() => handleUpdateStatus(item.id, true)}
                                    >
                                      <RiEyeOffFill />
                                    </Button>
                                  ) : (
                                    <Button
                                      variant="outline-success"
                                      size="sm"
                                      className="btn-icon"
                                      title={t("featured_off")}
                                      onClick={() => handleUpdateStatus(item.id, false)}
                                    >
                                      <RiEyeLine />
                                    </Button>
                                  )}
                                </ButtonGroup>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </>
                ) : (
                  <RecordNotFound />
                )}
              </Card.Body>

              <Card.Footer>
                <Row>
                  <Col xs="6">
                    <Pagination pagination={response.data.pagination} extraParams={["q"]} />
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Col>
          <Col md="3" className="order-first order-md-last">
            <Card>
              <Card.Body>
                <Form onSubmit={handleSubmitFilter}>
                  <Form.Group controlId="filter-q">
                    <Form.Label>{t("keywords")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="q"
                      ref={filterQRef}
                      placeholder={t("keywords")}
                      defaultValue={q ? q : ""}
                    />
                  </Form.Group>
                  <hr />
                  <Button variant="outline-secondary" block onClick={handleSubmitFilter}>
                    {t("filter")}
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </MainLayout>
  );
};

export default List;
