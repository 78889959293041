import React, { useEffect, useState } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { History } from "history";
import { PublicRoute } from "./PublicRoute";
import { PrivateRoute } from "./PrivateRoute";
import AppStorage from "../utils/storage";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/reducers";
import { ILoginState, ILoginUpdateParams } from "../store/types";
import actions from "../store/actions";

interface IRouterPropItemType {
  path: string;
  component?: any;
  authRequired?: boolean | undefined;
  breadcrumb?: string;
  key?: string;
  routes?: IRouterPropItemType[];
}

interface IRouterPropType {
  routes: IRouterPropItemType[];
  history: History;
}

/**
 * Converts the router tree to a flat list.
 * @param routes
 */
export const flatRoutes = (routes: Array<IRouterPropItemType>) => {
  let _routes: Array<IRouterPropItemType> = [];

  const worker = (items: Array<IRouterPropItemType>, path?: string) => {
    if (items.length > 0) {
      items.forEach((item) => {
        const nestedPath = ((path ? path : "") + item.path).replace(/\/\/+/g, "/");

        if (item.component !== undefined) {
          _routes.push({
            path: nestedPath,
            component: item.component,
            authRequired: item.authRequired,
            breadcrumb: item.breadcrumb,
            key: item.key,
          });
        }

        if (item.routes !== undefined && item.routes.length > 0) {
          worker(item.routes, nestedPath);
        }
      });
    }
  };
  worker(routes);

  return _routes;
};

/**
 * AppRouter component.
 * @param props
 * @constructor
 */
export const AppRouter = (props: IRouterPropType) => {
  const { history } = props;
  const dispatch = useDispatch();
  const [routes, setRoutes] = useState<Array<IRouterPropItemType>>([]);
  const { response: loginResponse } = useSelector<RootState, ILoginState>((state: RootState) => state.login);
  const [isSignedIn, setIsSignedIn] = useState<boolean>(false);

  useEffect(() => {
    setRoutes(flatRoutes(props.routes));

    if (loginResponse !== null) {
      setIsSignedIn(true);
    } else {
      if (AppStorage.getAccessToken() !== null || AppStorage.getRefreshToken() !== null) {
        setIsSignedIn(true);

        const loginUpdate: ILoginUpdateParams = {};
        if (AppStorage.getAccessToken() !== null) {
          loginUpdate.token = String(AppStorage.getAccessToken());
        }
        if (AppStorage.getRefreshToken() !== null) {
          loginUpdate.token = String(AppStorage.getRefreshToken());
        }

        dispatch(actions.loginUpdate(loginUpdate));
      } else {
        setIsSignedIn(false);
      }
    }
  }, [props, loginResponse, dispatch]);

  return (
    <Router history={history}>
      <Switch>
        {routes.map((route, key) => {
          if (route.component === undefined) {
            return null;
          }

          if (route.authRequired !== undefined) {
            if (route.authRequired) {
              return (
                <PrivateRoute exact path={route.path} component={route.component} isSignedIn={isSignedIn} key={key} />
              );
            } else {
              return (
                <PublicRoute exact path={route.path} component={route.component} isSignedIn={isSignedIn} key={key} />
              );
            }
          } else {
            return <Route exact path={route.path} component={route.component} key={key} />;
          }
        })}
      </Switch>
    </Router>
  );
};

export default AppRouter;
