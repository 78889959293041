import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { MainLayout } from "../../layouts";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { RiAddBoxLine, RiArrowLeftSLine, RiCheckFill, RiDeleteBin6Line } from "react-icons/all";
import { Controller, useForm } from "react-hook-form";
import { RootState } from "../../../store/reducers";
import {
  ICategoriesState,
  ICitiesState,
  IDistrictsState,
  IUserParams,
  IUserCreateParams,
  IUserState,
} from "../../../store/types";
import {
  AlertDefaultState,
  AlertStateType,
  BusinessTypes,
  DisabilityTypes,
  EducationStatusTypes,
  EMAIL_REGEX_PATTERN,
  GenderTypes,
  LanguageTypes,
  UserRoleTypes,
} from "../../../types";
import { CustomFileInput, FormAlert } from "../../partials";
import actions from "../../../store/actions";
import history from "../../../utils/history";
import {fileObjectToBase64, generateRandomPassword} from "../../../utils/mixins";
import Select from "react-select";
import SectorSelectorModal, {
  SectorSelectedType,
  SectorSelectorModalDefaultState,
  SectorSelectorModalType,
} from "./SectorSelectorModal";

type CategorySelectOptionType = {
  value: string;
  label: string;
};

export const CreateUserUser = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const [categories, setCategories] = useState<Array<CategorySelectOptionType>>([]);
  const [selectedUserSectors, setSelectedUserSectors] = useState<Array<SectorSelectedType>>([]);
  const [selectedBusinessSectors, setSelectedBusinessSectors] = useState<Array<SectorSelectedType>>([]);

  const [sectorSelectorModal, setSectorSelectorModal] = useState<SectorSelectorModalType>(
    SectorSelectorModalDefaultState
  );

  const { register, control, watch, handleSubmit, errors, reset } = useForm<IUserParams>();

  const onSubmit = async (data: any) => {
    if (data.password === undefined || data.password === '') {
      const randomPassword = generateRandomPassword();
      data.password = randomPassword;
      data.password_confirmation = randomPassword;
    }

    if (selectedBusinessSectors.length < 1) {
      return addToast(t("validation.you_must_choose", { name: t("business_sectors") }), {
        appearance: "error",
        autoDismiss: true,
      });
    }

    if (data.avatar instanceof FileList && data.avatar.length > 0) {
      data.avatar = await fileObjectToBase64(data.avatar[0]);
    } else {
      delete data.avatar;
    }

    if (data.categories.length) {
      data.categories = data.categories.map((category: any) => {
        return category.value;
      });
    }

    data.user_sectors = [];
    if (selectedUserSectors.length) {
      selectedUserSectors.forEach((item) => {
        const subSectorBag: Array<string> = [];
        if (item.sub_sector.length) {
          item.sub_sector.forEach((sub) => {
            subSectorBag.push(sub.value);
          });
        }

        data.user_sectors.push({
          sector: item.sector.value,
          sub_sector: subSectorBag,
          sector_experience: item.sector_experience,
        });
      });
    }

    const businessData: any = {
      business_name: data.business_name,
      business_sectors: [],
      business_type: data.business_type,
      business_working_time: data.business_working_time,
      business_website: data.business_website,
      business_group: data.business_group,
      business_city: data.business_city,
      business_adress: data.business_adress,
      business_phone: data.business_phone,
      business_general: data.business_general,
      business_role: data.business_role,
    };

    if (selectedBusinessSectors.length) {
      selectedBusinessSectors.forEach((item) => {
        const subSectorBag: Array<string> = [];
        if (item.sub_sector.length) {
          item.sub_sector.forEach((sub) => {
            subSectorBag.push(sub.value);
          });
        }

        businessData.business_sectors.push({
          sector: item.sector.value,
          sub_sector: subSectorBag,
        });
      });
    }

    delete data.business_name;
    delete data.business_sectors;
    delete data.business_type;
    delete data.business_working_time;
    delete data.business_website;
    delete data.business_group;
    delete data.business_city;
    delete data.business_adress;
    delete data.business_phone;
    delete data.business_general;
    delete data.business_role;

    data.roles = [UserRoleTypes.user];
    const formattedData: IUserCreateParams = data;
    formattedData.business = businessData;

    dispatch(await actions.createUser(formattedData));
  };

  const { isLoading, response, error } = useSelector<RootState, IUserState>((state: RootState) => state.createUser);

  const { isLoading: citiesIsLoading, response: citiesResponse } = useSelector<RootState, ICitiesState>(
    (state: RootState) => state.cities
  );

  const { isLoading: districtsIsLoading, response: districtsResponse } = useSelector<RootState, IDistrictsState>(
    (state: RootState) => state.districts
  );

  const { isLoading: categoriesIsLoading, response: categoriesResponse } = useSelector<RootState, ICategoriesState>(
    (state: RootState) => state.categories
  );

  const [formDisable, setFormDisable] = useState<boolean>(false);
  const [formAlert, setFormAlert] = useState<AlertStateType>(AlertDefaultState);

  const getCityDistricts = useCallback(
    (cityId: string) => {
      dispatch(actions.districts(cityId));
    },
    [dispatch]
  );

  // Resetting the pagination to previous states so that it can be disabled
  useEffect(() => {
    dispatch({ type: 'CATEGORIES_RESET' })
  }, [dispatch]);

  useEffect(() => {
    if (citiesResponse === null) {
      dispatch(actions.cities());
    }
  }, [citiesResponse, dispatch]);

  useEffect(() => {
    const cityId = response?.data.city?.id;
    if (cityId) {
      getCityDistricts(cityId);
    }
  }, [response, getCityDistricts]);

  useEffect(() => {
    if (categoriesResponse) {
      if (categoriesResponse.data.items.length > 0) {
        const categoryOptions: Array<CategorySelectOptionType> = categoriesResponse.data.items.map((category) => {
          return {
            value: category.id,
            label: category.title,
          };
        });
        setCategories(categoryOptions);
      }
    } else {
      dispatch(actions.categories({ page: 1, per: 9999 }));
    }
  }, [categoriesResponse, dispatch]);

  useEffect(() => {
    setFormDisable(citiesIsLoading || districtsIsLoading || categoriesIsLoading || isLoading);
  }, [citiesIsLoading, districtsIsLoading, categoriesIsLoading, isLoading]);

  useEffect(() => {
    setFormDisable(isLoading);

    if (error !== null) {
      if (error.response.status === 400) {
        setFormAlert({
          variant: "danger",
          show: true,
          messages: error.response.data.messages,
        });
      } else {
        addToast(t("unknown_error"), {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }

    if (response) {
      addToast(t("created_with_param", { param: t("user") }), {
        appearance: "success",
        autoDismiss: true,
      });

      reset();

      dispatch({ type: "CREATE_USER_RESET" });
      history.push(`/users/${response.data.id}`);
    }
  }, [isLoading, response, error, addToast, reset, t, dispatch]);

  const onHandleSectorSelect = (data: SectorSelectedType) => {
    if (data.type === "user") {
      setSelectedUserSectors(selectedUserSectors.filter((item) => item.sector.value !== data.sector.value));
      setSelectedUserSectors((selectedUserSectors) => [...selectedUserSectors, data]);
    } else if (data.type === "business") {
      setSelectedBusinessSectors(selectedBusinessSectors.filter((item) => item.sector.value !== data.sector.value));
      setSelectedBusinessSectors((selectedBusinessSectors) => [...selectedBusinessSectors, data]);
    }
  };

  const handleDeleteUserSector = (data: SectorSelectedType) => {
    setSelectedUserSectors(selectedUserSectors.filter((item) => item.sector.value !== data.sector.value));
  };

  const handleDeleteBusinessSector = (data: SectorSelectedType) => {
    setSelectedBusinessSectors(selectedBusinessSectors.filter((item) => item.sector.value !== data.sector.value));
  };

  return (
    <MainLayout>
      <FormAlert variant={formAlert.variant} show={formAlert.show} to={formAlert.to}>
        {formAlert.messages.map((message, key) => {
          return <p key={key}>{message}</p>;
        })}
      </FormAlert>

      <Card className={formDisable ? "loading-block" : ""}>
        <Card.Header as="h6">
          {t("create_user")}
        </Card.Header>
        <Card.Body>
          <SectorSelectorModal
            modal={sectorSelectorModal}
            onClose={() => setSectorSelectorModal(SectorSelectorModalDefaultState)}
            onSubmit={onHandleSectorSelect}
          />
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
              <Form.Group as={Col} sm="12">
                <Form.Label className="form-label d-block">{t("role")}:</Form.Label>
                <>
                  <Form.Check
                    inline
                    custom
                    type="checkbox"
                    label={t(`role_names.${UserRoleTypes.user}`)}
                    name="roles"
                    id={`role-${UserRoleTypes.user}`}
                    value={UserRoleTypes.user}
                    ref={register({
                      required: {
                        value: true,
                        message: t("validation.you_must_enter", { name: t("role") }),
                      },
                    })}
                    isInvalid={!!errors.roles}
                    defaultChecked
                    disabled
                  />
                </>
              </Form.Group>
            </Form.Row>
            <hr />
            <Form.Row>
              <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-name">
                <Form.Label>{t("name")}:*</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  ref={register({
                    required: {
                      value: true,
                      message: t("validation.you_must_enter", { name: t("name") }),
                    },
                  })}
                  placeholder={t("name")}
                  defaultValue=""
                  autoFocus
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-lastname">
                <Form.Label>{t("lastname")}:*</Form.Label>
                <Form.Control
                  type="text"
                  name="lastname"
                  ref={register({
                    required: {
                      value: true,
                      message: t("validation.you_must_enter", { name: t("lastname") }),
                    },
                  })}
                  placeholder={t("lastname")}
                  defaultValue=""
                  isInvalid={!!errors.lastname}
                />
                <Form.Control.Feedback type="invalid">{errors.lastname?.message}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-email">
                <Form.Label>{t("email")}:*</Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  ref={register({
                    required: {
                      value: true,
                      message: t("validation.you_must_enter", { name: t("email") }),
                    },
                    pattern: {
                      value: EMAIL_REGEX_PATTERN,
                      message: t("validation.invalid", { name: t("email") }),
                    },
                  })}
                  placeholder={t("email")}
                  defaultValue=""
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="3" controlId="user-password">
                <Form.Label>{t("password")}:</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  ref={register({
                    required: {
                      value: false,
                      message: t("validation.you_must_enter", { name: t("password") }),
                    },
                    minLength: {
                      value: 6,
                      message: t("validation.min", { min: 6 }),
                    },
                    maxLength: {
                      value: 100,
                      message: t("validation.max", { max: 100 }),
                    },
                  })}
                  placeholder={t("password")}
                  isInvalid={!!errors.password}
                />
                <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="user-password-confirmation">
                <Form.Label>{t("password_confirmation")}:</Form.Label>
                <Form.Control
                  type="password"
                  name="password_confirmation"
                  ref={register({
                    required: {
                      value: false,
                      message: t("validation.you_must_enter", { name: t("password") }),
                    },
                    validate: (value) => value === watch("password") || t("validation.passwords_must_match").toString(),
                  })}
                  placeholder={t("password_confirmation")}
                  isInvalid={!!errors.password_confirmation}
                />
                <Form.Control.Feedback type="invalid">{errors.password_confirmation?.message}</Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <hr />
            <Form.Row>
              <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-phone">
                <Form.Label>{t("phone")}:*</Form.Label>
                <Form.Control
                  type="number"
                  name="phone"
                  ref={register({
                    required: {
                      value: true,
                      message: t("validation.you_must_enter", { name: t("phone") }),
                    },
                    min: {
                      value: 11,
                      message: t("validation.min", { min: 11 }),
                    },
                  })}
                  maxLength={11}
                  placeholder={t("phone")}
                  defaultValue=""
                  isInvalid={!!errors.phone}
                />
                <Form.Control.Feedback type="invalid">{errors.phone?.message}</Form.Control.Feedback>
              </Form.Group>
              {citiesResponse && (
                <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-city">
                  <Form.Label>{t("city")}:*</Form.Label>
                  <Form.Control
                    as="select"
                    custom
                    name="city"
                    ref={register({
                      required: {
                        value: true,
                        message: t("validation.you_must_choose", { name: t("city") }),
                      },
                    })}
                    defaultValue=""
                    onChange={(event) => getCityDistricts(event.target.value)}
                    isInvalid={!!errors.city}
                  >
                    <option value="">{t("select_city")}</option>
                    {citiesResponse?.data.items.map((city, key) => {
                      return (
                        <option value={city.id} key={key}>
                          {city.name}
                        </option>
                      );
                    })}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.city?.message}</Form.Control.Feedback>
                </Form.Group>
              )}
              {districtsResponse && (
                <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-district">
                  <Form.Label>{t("district")}:*</Form.Label>
                  <Form.Control
                    as="select"
                    custom
                    name="district"
                    ref={register({
                      required: {
                        value: true,
                        message: t("validation.you_must_choose", { name: t("district") }),
                      },
                    })}
                    defaultValue=""
                    isInvalid={!!errors.district}
                  >
                    <option value="">{t("select_district")}</option>
                    {districtsResponse?.data.items.map((district, key) => {
                      return (
                        <option value={district.id} key={key}>
                          {district.name}
                        </option>
                      );
                    })}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.district?.message}</Form.Control.Feedback>
                </Form.Group>
              )}
              <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-address">
                <Form.Label>{t("address")}:</Form.Label>
                <Form.Control
                  as="textarea"
                  name="address"
                  rows={1}
                  ref={register({
                    required: {
                      value: false,
                      message: t("validation.you_must_enter", { name: t("address") }),
                    },
                  })}
                  placeholder={t("address")}
                  defaultValue=""
                  isInvalid={!!errors.address}
                />
                <Form.Control.Feedback type="invalid">{errors.address?.message}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-linkedin">
                <Form.Label>{t("linkedin")}:</Form.Label>
                <Form.Control
                  type="text"
                  name="linkedin"
                  ref={register({
                    required: {
                      value: false,
                      message: t("validation.you_must_enter", { name: t("linkedin") }),
                    },
                  })}
                  placeholder={t("linkedin")}
                  defaultValue=""
                  isInvalid={!!errors.linkedin}
                />
                <Form.Control.Feedback type="invalid">{errors.linkedin?.message}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-avatar">
                <Form.Label>{t("avatar")}:</Form.Label>
                <CustomFileInput
                  name="avatar"
                  accept="image/jpeg,image/jpg,image/png"
                  ref={register({
                    required: {
                      value: false,
                      message: t("validation.you_must_choose", { name: t("avatar") }),
                    },
                  })}
                  isInvalid={!!errors.avatar}
                />
                {errors.avatar?.message && (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.avatar?.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-gender">
                <Form.Label>{t("gender")}:*</Form.Label>
                <Form.Control
                  as="select"
                  custom
                  name="gender"
                  ref={register({
                    required: {
                      value: true,
                      message: t("validation.you_must_choose", { name: t("gender") }),
                    },
                  })}
                  defaultValue=""
                  isInvalid={!!errors.gender}
                >
                  <option value="">{t("select_gender")}</option>
                  {[GenderTypes.male, GenderTypes.female].map((gender, key) => {
                    return (
                      <option value={gender} key={key}>
                        {t(`genders.${gender}`)}
                      </option>
                    );
                  })}
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.gender?.message}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-disability">
                <Form.Label>{t("disability")}:</Form.Label>
                <Form.Control
                  as="select"
                  custom
                  name="disability"
                  ref={register({
                    required: {
                      value: false,
                      message: t("validation.you_must_choose", { name: t("disability") }),
                    },
                  })}
                  defaultValue=""
                  isInvalid={!!errors.disability}
                >
                  <option value="">{t("select_disability")}</option>
                  {[DisabilityTypes.yes, DisabilityTypes.no].map((disability, key) => {
                    return (
                      <option value={disability} key={key}>
                        {t(`disabilities.${disability}`)}
                      </option>
                    );
                  })}
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.disability?.message}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-about">
                <Form.Label>{t("about")}:</Form.Label>
                <Form.Control
                  as="textarea"
                  name="about"
                  rows={1}
                  ref={register({
                    required: {
                      value: false,
                      message: t("validation.you_must_enter", { name: t("about") }),
                    },
                  })}
                  placeholder={t("about")}
                  defaultValue=""
                  isInvalid={!!errors.about}
                />
                <Form.Control.Feedback type="invalid">{errors.about?.message}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-education">
                <Form.Label>{t("education_status")}:*</Form.Label>
                <Form.Control
                  as="select"
                  custom
                  name="education"
                  ref={register({
                    required: {
                      value: true,
                      message: t("validation.you_must_choose", { name: t("education_status") }),
                    },
                  })}
                  defaultValue=""
                  isInvalid={!!errors.education}
                >
                  <option value="">{t("select_education_status")}</option>
                  {Object.values(EducationStatusTypes).map((education, key) => {
                    return (
                      <option value={education} key={key}>
                        {t(`education_items.${education}`)}
                      </option>
                    );
                  })}
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.education?.message}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-education-detail">
                <Form.Label>{t("education_detail")}:*</Form.Label>
                <Form.Control
                  type="text"
                  name="education_detail"
                  ref={register({
                    required: {
                      value: true,
                      message: t("validation.you_must_enter", { name: t("education_detail") }),
                    },
                  })}
                  placeholder={t("education_detail")}
                  defaultValue=""
                  isInvalid={!!errors.education_detail}
                />
                <Form.Control.Feedback type="invalid">{errors.education_detail?.message}</Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <hr />
            <Form.Row>
              <Form.Group as={Col} lg="6" controlId="user-categories" style={{ zIndex: 2 }}>
                <Form.Label>{t("categories")}:*</Form.Label>
                <Controller
                  control={control}
                  name="categories"
                  defaultValue={[]}
                  render={({ onChange, value, ref }) => (
                    <Select
                      isMulti
                      isClearable
                      inputRef={ref}
                      placeholder={t("select_category")}
                      noOptionsMessage={() => t("not_found")}
                      options={categories}
                      value={categories.find((category) => category.value === value)}
                      onChange={(value: any) => onChange(value)}
                    />
                  )}
                  ref={register}
                  rules={{
                    required: {
                      value: true,
                      message: t("validation.you_must_choose", { name: t("category") }),
                    },
                  }}
                  isInvalid={!!errors.categories}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className="form-label d-block">{t("languages")}:</Form.Label>
                <div className="pt-2">
                  {Object.values(LanguageTypes).map((value, key) => {
                    return (
                      <Form.Check
                        inline
                        custom
                        type="checkbox"
                        key={key}
                        label={t(`language_items.${value}`)}
                        name="language"
                        id={`language-${value}`}
                        value={value}
                        ref={register({
                          required: {
                            value: false,
                            message: t("validation.you_must_enter", { name: t("language") }),
                          },
                        })}
                        defaultChecked={value === LanguageTypes.tr}
                        isInvalid={!!errors.language}
                      />
                    );
                  })}
                </div>
              </Form.Group>
            </Form.Row>
            <hr />
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className="form-label d-block">{t("sectors")}:*</Form.Label>
                {selectedUserSectors.length ? (
                  <div className="table-responsive">
                    <table className="table table-bordered table-hover">
                      <thead>
                        <tr>
                          <th>{t("sector")}</th>
                          <th>{t("sub_sector")}</th>
                          <th className="text-center">{t("experience")}</th>
                          <th className="text-center">{t("actions")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedUserSectors.map((item, key) => {
                          return (
                            <tr key={key}>
                              <td>{item.sector.label}</td>
                              <td>
                                {item.sub_sector.length ? (
                                  <>
                                    {item.sub_sector.map((sub, key) => {
                                      return (
                                        <div key={key} className="d-block mb-2">
                                          - {sub.label}
                                        </div>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <>-</>
                                )}
                              </td>
                              <td className="text-center">
                                {item.sector_experience} {t("year")}
                              </td>
                              <td className="text-center">
                                <Button
                                  variant="outline-danger"
                                  size={"sm"}
                                  className="btn-icon"
                                  title={t("delete")}
                                  onClick={() => handleDeleteUserSector(item)}
                                >
                                  <RiDeleteBin6Line />
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="d-block border p-3 mb-3">{t("sector_not_selected")}</div>
                )}
                {/* //-- */}
                <div className="text-left">
                  {selectedUserSectors.length === 0 && (
                    <small className="text-danger mr-2">
                      {t("validation.you_must_choose", { name: t("sectors") })}
                    </small>
                  )}
                </div>
                <div className="text-right">
                  <Button
                    variant="outline-success"
                    size="sm"
                    className="with-icon"
                    title={t("add")}
                    onClick={() => setSectorSelectorModal({ show: true, type: "user" })}
                  >
                    <RiAddBoxLine />
                    <span>{t("add")}</span>
                  </Button>
                </div>
              </Form.Group>
            </Form.Row>
            <hr />
            <h6 className="mb-4">{t("business_information")}:</h6>
            <Form.Row>
              <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-business-type">
                <Form.Label>{t("business_type")}:*</Form.Label>
                <Form.Control
                  as="select"
                  custom
                  name="business_type"
                  ref={register({
                    required: {
                      value: true,
                      message: t("validation.you_must_choose", { name: t("business_type") }),
                    },
                  })}
                  defaultValue=""
                  isInvalid={!!errors.business_type}
                >
                  <option value="">{t("business_type")}</option>
                  {Object.values(BusinessTypes).map((type, key) => {
                    return (
                      <option value={type} key={key}>
                        {t(`business_types.${type}`)}
                      </option>
                    );
                  })}
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.business_type?.message}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-business-group">
                <Form.Label>{t("business_group")}:</Form.Label>
                <Form.Control
                  type="text"
                  name="business_group"
                  ref={register({
                    required: {
                      value: false,
                      message: t("validation.you_must_enter", { name: t("business_group") }),
                    },
                  })}
                  placeholder={t("business_group")}
                  defaultValue=""
                  isInvalid={!!errors.business_group}
                />
                <Form.Control.Feedback type="invalid">{errors.business_group?.message}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-business-name">
                <Form.Label>{t("business_name")}:*</Form.Label>
                <Form.Control
                  type="text"
                  name="business_name"
                  ref={register({
                    required: {
                      value: true,
                      message: t("validation.you_must_enter", { name: t("business_name") }),
                    },
                  })}
                  placeholder={t("business_name")}
                  defaultValue=""
                  isInvalid={!!errors.business_name}
                />
                <Form.Control.Feedback type="invalid">{errors.business_name?.message}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-business-role">
                <Form.Label>{t("business_role")}:*</Form.Label>
                <Form.Control
                  as="select"
                  custom
                  name="business_role"
                  ref={register({
                    required: {
                      value: true,
                      message: t("validation.you_must_choose", { name: t("business_role") }),
                    },
                  })}
                  defaultValue=""
                  isInvalid={!!errors.business_role}
                >
                  <option value="">{t("select_role")}</option>
                  <option value="MANAGER">{t("business_roles.MANAGER")}</option>
                  <option value="USER">{t("business_roles.USER")}</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.business_role?.message}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-business-working-time">
                <Form.Label>{t("business_working_time")}:</Form.Label>
                <Form.Control
                  type="number"
                  name="business_working_time"
                  ref={register({
                    required: {
                      value: false,
                      message: t("validation.you_must_enter", { name: t("business_working_time") }),
                    },
                  })}
                  placeholder={t("business_working_time")}
                  defaultValue=""
                  isInvalid={!!errors.business_working_time}
                />
                <Form.Control.Feedback type="invalid">{errors.business_working_time?.message}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-business-website">
                <Form.Label>{t("business_website")}:</Form.Label>
                <Form.Control
                  type="text"
                  name="business_website"
                  ref={register({
                    required: {
                      value: false,
                      message: t("validation.you_must_enter", { name: t("business_website") }),
                    },
                  })}
                  placeholder={t("business_website")}
                  defaultValue=""
                  isInvalid={!!errors.business_website}
                />
                <Form.Control.Feedback type="invalid">{errors.business_website?.message}</Form.Control.Feedback>
              </Form.Group>
              {citiesResponse && (
                <Form.Group as={Col} md="3" controlId="user-business-city">
                  <Form.Label>{t("business_city")}:*</Form.Label>
                  <Form.Control
                    as="select"
                    custom
                    name="business_city"
                    ref={register({
                      required: {
                        value: true,
                        message: t("validation.you_must_choose", { name: t("business_city") }),
                      },
                    })}
                    defaultValue=""
                    isInvalid={!!errors.business_city}
                  >
                    <option value="">{t("select_city")}</option>
                    {citiesResponse?.data.items.map((city, key) => {
                      return (
                        <option value={city.id} key={key}>
                          {city.name}
                        </option>
                      );
                    })}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.city?.message}</Form.Control.Feedback>
                </Form.Group>
              )}
              <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-business-address">
                <Form.Label>{t("business_address")}:</Form.Label>
                <Form.Control
                  as="textarea"
                  name="business_adress"
                  rows={1}
                  ref={register({
                    required: {
                      value: false,
                      message: t("validation.you_must_enter", { name: t("business_address") }),
                    },
                  })}
                  placeholder={t("business_address")}
                  defaultValue=""
                  isInvalid={!!errors.business_adress}
                />
                <Form.Control.Feedback type="invalid">{errors.business_adress?.message}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-business-phone">
                <Form.Label>{t("business_phone")}:</Form.Label>
                <Form.Control
                  type="text"
                  name="business_phone"
                  ref={register({
                    required: {
                      value: false,
                      message: t("validation.you_must_enter", { name: t("business_phone") }),
                    },
                  })}
                  placeholder={t("business_phone")}
                  defaultValue=""
                  isInvalid={!!errors.business_phone}
                />
                <Form.Control.Feedback type="invalid">{errors.business_phone?.message}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-business-general">
                <Form.Label>{t("business_general")}:</Form.Label>
                <Form.Control
                  type="text"
                  name="business_general"
                  ref={register({
                    required: {
                      value: false,
                      message: t("validation.you_must_enter", { name: t("business_general") }),
                    },
                  })}
                  placeholder={t("business_general")}
                  defaultValue=""
                  isInvalid={!!errors.business_general}
                />
                <Form.Control.Feedback type="invalid">{errors.business_general?.message}</Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} sm="12">
                <Form.Label className="form-label d-block">{t("business_sectors")}:</Form.Label>
                {selectedBusinessSectors.length ? (
                  <div className="table-responsive">
                    <table className="table table-bordered table-hover">
                      <thead>
                        <tr>
                          <th>{t("sector")}</th>
                          <th>{t("sub_sector")}</th>
                          <th className="text-center">{t("actions")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedBusinessSectors.map((item, key) => {
                          return (
                            <tr key={key}>
                              <td>{item.sector.label}</td>
                              <td>
                                {item.sub_sector.length ? (
                                  <>
                                    {item.sub_sector.map((sub, key) => {
                                      return (
                                        <div key={key} className="d-block mb-2">
                                          - {sub.label}
                                        </div>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <>-</>
                                )}
                              </td>
                              <td className="text-center">
                                <Button
                                  variant="outline-danger"
                                  size={"sm"}
                                  className="btn-icon"
                                  title={t("delete")}
                                  onClick={() => handleDeleteBusinessSector(item)}
                                >
                                  <RiDeleteBin6Line />
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="d-block border p-3 mb-3">{t("sector_not_selected")}</div>
                )}

                <div className="text-left">
                  {selectedBusinessSectors.length === 0 && (
                    <small className="text-danger mr-2">
                      {t("validation.you_must_choose", { name: t("business_sectors") })}
                    </small>
                  )}
                </div>
                <div className="text-right">
                  <Button
                    variant="outline-success"
                    size="sm"
                    className="with-icon"
                    title={t("add")}
                    onClick={() => setSectorSelectorModal({ show: true, type: "business" })}
                  >
                    <RiAddBoxLine />
                    <span>{t("add")}</span>
                  </Button>
                </div>
              </Form.Group>
            </Form.Row>
          </Form>
        </Card.Body>
        <Card.Footer>
          <Row>
            <Col xs="6" className="text-left">
              <Button as={NavLink} to="/users" variant="secondary" size="sm" className="with-icon">
                <RiArrowLeftSLine />
                <span>{t("list")}</span>
              </Button>
            </Col>
            <Col xs="6" className="text-right">
              <Button variant="primary" size="sm" className="with-icon" onClick={handleSubmit(onSubmit)}>
                <RiCheckFill />
                <span>{t("save")}</span>
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </MainLayout>
  );
};

export default CreateUserUser;
