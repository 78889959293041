import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CategoryType } from "../../../store/types";

export type TeamDetailModalType = {
  show: boolean;
  data: any;
  type: "clients" | "expertises";
};

export const TeamDetailModalDefaultState: TeamDetailModalType = {
  show: false,
  data: [],
  type: "clients",
};

type TeamDetailModalPropsType = {
  modal: TeamDetailModalType;
};

export type clientsType = {
  id: string;
  email: string;
  name: string;
  lastname: string;
  categories: CategoryType[];
};

export type expertisesType = {
  id: string;
  title: string;
};

export const TeamDetailModal = (props: TeamDetailModalPropsType) => {
  const { t } = useTranslation();
  const [show, setShow] = useState<boolean>(false);

  const handleClose = (): void => {
    props.modal.show = false;
    setShow(props.modal.show);
  };

  useEffect((): void => {
    setShow(props.modal.show);
  }, [props.modal.show]);

  return (
    <Modal centered show={show} onHide={handleClose} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title as="h6">{props.modal.type}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul>
          {props.modal.data.map((d: clientsType) => (
            <li>
              {d.name} {d.lastname} - ({d.email})
              <ul>
                {d.categories.map((category: CategoryType) => (
                  <li>{category.title}</li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleClose}>
          {t("close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TeamDetailModal;
