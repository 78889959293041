import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MainLayout } from "../../layouts";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import { RootState } from "../../../store/reducers";
import { FeedbackState, FeedbackType, IDefaultState, IFeedbackState } from "../../../store/types";
import { DeleteConfirmModal, LoadingIndicator } from "../../partials";
import Moment from "react-moment";
import actions from "../../../store/actions";
import { IMAGE_ERROR_SOURCE } from "../../../types";
import { MdLocationDisabled, MdLocationSearching, RiArrowLeftSLine, RiDeleteBin6Line } from "react-icons/all";
import { useToasts } from "react-toast-notifications";

type RouterParamsType = {
  id: string;
};

const Show = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { id } = useParams<RouterParamsType>();

  const { isLoading, response, error } = useSelector<RootState, FeedbackState>((state: RootState) => state.feedback);

  const { isLoading: updating, response: updateResponse } = useSelector<RootState, IFeedbackState>(
    (state: RootState) => state.updateFeedback
  );

  const {
    isLoading: deleteIsLoading,
    response: deleteResponse,
    error: deleteError,
  } = useSelector<RootState, IDefaultState>((state: RootState) => state.deleteFaq);

  const [deleteItem, setDeleteItem] = useState<string | null>(null);
  const onDeleteConfirm = async () => {
    if (deleteItem) {
      dispatch(await actions.deleteFeedback(deleteItem));
    }
  };

  useEffect(() => {
    if (deleteError) {
      addToast(t("unknown_error"), {
        appearance: "error",
        autoDismiss: true,
      });
    }

    if (deleteResponse) {
      addToast(t("deleted_with_param", { param: t("feedback") }), {
        appearance: "success",
        autoDismiss: true,
      });

      dispatch({ type: "DELETE_FEEDBACK_RESET" });

      history.push("/feedbacks");
    }
  }, [deleteResponse, deleteError, addToast, t, dispatch, history]);

  useEffect(() => {
    if (error?.response) {
      if (error?.response.status === 400 || error?.response.status === 404) {
        dispatch({ type: "FEEDBACK_RESET" });
        history.push("/404");
      }
    } else {
      dispatch(actions.feedback(id));
    }
  }, [dispatch, id, history, error]);

  const errorHandler = (e: any) => (e.target.src = IMAGE_ERROR_SOURCE);

  const handleChangeStatus = async (feedback: FeedbackType) => {
    dispatch(
      await actions.updateFeedback(feedback.id, {
        name: feedback.name,
        position: feedback.position,
        description: feedback.description,
        is_active: !feedback.is_active,
      })
    );
  };

  useEffect(() => {
    if (updateResponse) {
      dispatch({ type: "UPDATE_FEEDBACK_RESET" });
      dispatch(actions.feedback(id));
    }
  }, [updateResponse, dispatch, id]);

  return (
    <MainLayout>
      <LoadingIndicator show={isLoading} />
      {response && (
        <Card className={deleteIsLoading || updating ? "loading-block" : ""}>
          <Card.Header as="h6">{t("show")}</Card.Header>
          <Card.Body>
            <div className="content-view">
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("id")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.id}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("created_at")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  <Moment format="DD/MM/YYYY HH:mm:ss">{response.data.created_at}</Moment>
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("full_name")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.name}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("work_title")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.position}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("content")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.description}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("pictures")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  <Container>
                    <Row>
                      <Col xs={6} sm={4} md={4} lg={4} xl={4}>
                        <Image
                          onError={errorHandler}
                          src={`${process.env.REACT_APP_STORAGE_URL}/${response.data.picture}`}
                          alt=""
                        />
                      </Col>
                    </Row>
                  </Container>
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("status")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.is_active ? t("active") : t("passive")}</dd>
              </dl>
            </div>
          </Card.Body>
          <Card.Footer>
            <Row>
              <Col xs="6" className="text-left">
                <Button as={NavLink} to="/feedbacks" variant="secondary" size="sm" className="with-icon">
                  <RiArrowLeftSLine />
                  <span>{t("list")}</span>
                </Button>
              </Col>
              <Col xs="6" className="text-right">
                <Button
                  variant={`outline-${response.data.is_active ? "success" : "danger"}`}
                  size="sm"
                  className="btn-icon mr-2"
                  title={t("change_status")}
                  onClick={() => handleChangeStatus(response.data)}
                >
                  {response.data.is_active ? <MdLocationSearching /> : <MdLocationDisabled />}
                </Button>
                <DeleteConfirmModal
                  show={deleteItem !== null}
                  onClose={() => setDeleteItem(null)}
                  onConfirm={onDeleteConfirm}
                />
                <Button variant="danger" size="sm" className="with-icon" onClick={() => setDeleteItem(id)}>
                  <RiDeleteBin6Line />
                  <span>{t("delete")}</span>
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      )}
    </MainLayout>
  );
};

export default Show;
