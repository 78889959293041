import { Button, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type DeleteConfirmModalPropsType = {
  show: boolean;
  onClose: Function;
  onConfirm: Function;
};

export const DeleteConfirmModal = (props: DeleteConfirmModalPropsType) => {
  const [show, setShow] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleHide = () => {
    setShow(false);
  };

  const handleClose = () => {
    handleHide();
    props.onClose();
  };

  const handleConfirm = () => {
    handleClose();
    props.onConfirm();
  };

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  return (
    <Modal centered show={show} onHide={handleClose} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title as="h6">{t("confirmation_required")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{t("delete_confirmation_message")}</Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" autoFocus onClick={handleClose}>
          {t("no")}
        </Button>
        <Button variant="outline-success" onClick={handleConfirm}>
          {t("yes")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
