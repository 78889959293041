import React, { useState } from 'react';

type LoadingIndicatorPropsType = {
  show: boolean;
  absolute?: boolean;
};

const defaultProps: LoadingIndicatorPropsType = {
  show: false,
  absolute: false,
};

export const LoadingIndicator = (props: LoadingIndicatorPropsType) => {
  const { absolute } = { ...defaultProps, ...props };
  const [show] = useState<boolean>(false);
  return <>{show && <div className={"loading-indicator" + (absolute ? " absolute" : "")} {...props} />}</>;
};
