import { Dispatch } from "redux";
import { AdminApiClient } from "../../utils/http-client";
import { ICategoryParams, IOrderParams, IPaginationParams } from "../types";

const adminApiClient = AdminApiClient.getInstance();

export const categories = (queryParams?: IPaginationParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "CATEGORIES_START" });
  await adminApiClient
    .categories(queryParams)
    .then((response) => {
      dispatch({ type: "CATEGORIES_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "CATEGORIES_FAILURE", payload: error }));
};

export const orderCategory = (params: IOrderParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "ORDER_CATEGORY_START" });
  await adminApiClient
    .orderCategory(params)
    .then((response) => {
      dispatch({ type: "ORDER_CATEGORY_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "ORDER_CATEGORY_FAILURE", payload: error }));
};

export const category = (id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: "CATEGORY_START" });
  await adminApiClient
    .category(id)
    .then((response) => {
      dispatch({ type: "CATEGORY_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "CATEGORY_FAILURE", payload: error }));
};

export const createCategory = (params: ICategoryParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "CREATE_CATEGORY_START" });
  await adminApiClient
    .createCategory(params)
    .then((response) => {
      dispatch({ type: "CREATE_CATEGORY_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "CREATE_CATEGORY_FAILURE", payload: error }));
};

export const updateCategory = (id: string, params: ICategoryParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "UPDATE_CATEGORY_START" });
  await adminApiClient
    .updateCategory(id, params)
    .then((response) => {
      dispatch({ type: "UPDATE_CATEGORY_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "UPDATE_CATEGORY_FAILURE", payload: error }));
};

export const deleteCategory = (id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: "DELETE_CATEGORY_START" });
  await adminApiClient
    .deleteCategory(id)
    .then((response) => {
      dispatch({ type: "DELETE_CATEGORY_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "DELETE_CATEGORY_FAILURE", payload: error }));
};
