import { Dispatch } from "redux";
import { PublicApiClient } from "../../utils/http-client";

const publicApiClient = PublicApiClient.getInstance();

export const cities = () => async (dispatch: Dispatch) => {
  dispatch({ type: "CITIES_START" });
  await publicApiClient
    .cities()
    .then((response) => {
      dispatch({ type: "CITIES_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "CITIES_FAILURE", payload: error }));
};

export const districts = (cityId: string) => async (dispatch: Dispatch) => {
  dispatch({ type: "DISTRICTS_START" });
  if (cityId === "") {
    dispatch({ type: "DISTRICTS_SUCCESS", payload: { data: null } });
  } else {
    await publicApiClient
      .districts(cityId)
      .then((response) => {
        dispatch({ type: "DISTRICTS_SUCCESS", payload: response });
      })
      .catch((error) => dispatch({ type: "DISTRICTS_FAILURE", payload: error }));
  }
};
