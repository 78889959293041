import i18n from "../utils/i18n";
import { IconType } from "react-icons/lib";
import {
  RiBookOpenLine,
  RiDashboard3Line,
  RiFileShield2Line,
  RiFileTextLine,
  RiFoldersLine,
  RiHotelLine,
  RiNewspaperLine,
  RiQuestionLine,
  RiStarLine,
  RiUserStarLine,
  RiUserSettingsFill,
  RiMapPinLine,
  RiCheckboxMultipleBlankLine,
  RiEyeLine,
  RiVidicon2Line,
  RiDatabaseFill,
  RiContactsBookLine,
  RiQuestionAnswerLine,
} from "react-icons/ri";

export type NavigationItem = {
  title: string;
  to: string;
  icon?: IconType;
  exact?: boolean;
  children?: Array<NavigationItem>;
};

export const navigation: Array<NavigationItem> = [
  {
    title: i18n.t("dashboard"),
    to: "/",
    icon: RiDashboard3Line,
    exact: true,
  },
  {
    title: i18n.t("pages"),
    to: "/pages",
    icon: RiFileTextLine,
  },
  {
    title: i18n.t("news"),
    to: "/news",
    icon: RiNewspaperLine,
  },
  {
    title: i18n.t("blog_posts"),
    to: "/blog-posts",
    icon: RiBookOpenLine,
  },
  {
    title: i18n.t("classification"),
    to: "/sector-management",
    icon: RiHotelLine,
    children: [
      {
        title: i18n.t("sectors"),
        to: "/sectors",
      },
      {
        title: i18n.t("sub_sectors"),
        to: "/sub-sectors",
      },
      {
        title: i18n.t("expertise"),
        to: "/categories",
        icon: RiFoldersLine,
      },
    ],
  },
  {
    title: i18n.t("contracts"),
    to: "/contracts",
    icon: RiFileShield2Line,
  },
  {
    title: i18n.t("faqs"),
    to: "/faqs",
    icon: RiQuestionLine,
  },
  {
    title: i18n.t("contact_information"),
    to: "/contact",
    icon: RiMapPinLine,
  },
 
  {
    title: i18n.t("applications"),
    to: "/applications",
    icon: RiCheckboxMultipleBlankLine,
    children: [
      {
        title: i18n.t("mentor_applications"),
        to: "/mentor",
        icon: RiStarLine,
      },
      {
        title: i18n.t("user_applications"),
        to: "/user",
        icon: RiUserStarLine,
      },
    ],
  },
  {
    title: i18n.t("users"),
    to: "/users",
    icon: RiUserSettingsFill,
  },
  {
    title: i18n.t("feedbacks"),
    to: "/feedbacks",
    icon: RiQuestionAnswerLine,
  },
  {
    title: i18n.t("logs"),
    to: "/logs",
    icon: RiDatabaseFill,
  },
  {
    title: i18n.t("support"),
    to: "/support",
    icon: RiContactsBookLine,
  },
  {
    title: i18n.t("mentoring_procedures"),
    to:"",
    icon: RiCheckboxMultipleBlankLine,
    children: [
      {
        title: i18n.t("mentoring_programs"),
        to: "/mentoring_programs",
        icon: RiFileTextLine,
      },
      {
        title: i18n.t("mentor_teams"),
        to: "/teams",
        icon: RiUserSettingsFill,
      },
      {
        title: i18n.t("featured_mentors"),
        to: "/featured-mentors",
        icon: RiEyeLine,
      },
      {
        title: i18n.t("matches"),
        to: "/matches",
        icon: RiUserSettingsFill,
      },
      {
        title: i18n.t("appointments"),
        to: "/appointments",
        icon: RiVidicon2Line,
      },
    ],
},

];

export default navigation;
