import { Reducer } from "react";
import {
  ILoginState,
  IRefreshState,
  IPasswordState,
  LoginActionType,
  RefreshActionType,
  PasswordActionType,
  IUpdatePasswordState,
  UpdatePasswordActionType,
} from "../types";
import AppStorage from "../../utils/storage";

// Login
const loginInitialState: ILoginState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const loginReducer: Reducer<ILoginState, LoginActionType> = (
  state: ILoginState = loginInitialState,
  action: LoginActionType
): ILoginState => {
  switch (action.type) {
    case "LOGIN_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "LOGIN_SUCCESS":
      AppStorage.setAccessToken(action.payload.data.token);
      if (action.payload.data.refresh_token) {
        AppStorage.setRefreshToken(action.payload.data.refresh_token);
      }
      return { ...state, isLoading: false, response: action.payload.data };
    case "LOGIN_UPDATE_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "LOGIN_UPDATE_SUCCESS":
      return { ...state, isLoading: false, response: action.payload };
    case "LOGIN_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "LOGIN_RESET":
      return { ...state, isLoading: false, response: null, error: null };
    default:
      return state;
  }
};

// Refresh
const refreshInitialState: IRefreshState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const refreshReducer: Reducer<IRefreshState, RefreshActionType> = (
  state: IRefreshState = refreshInitialState,
  action: RefreshActionType
): ILoginState => {
  switch (action.type) {
    case "REFRESH_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "REFRESH_SUCCESS":
      AppStorage.setAccessToken(action.payload.data.access_token);
      return { ...state, isLoading: false, response: action.payload.data };
    case "REFRESH_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    default:
      return state;
  }
};

// Password
const passwordInitialState: IPasswordState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const passwordReducer: Reducer<IPasswordState, PasswordActionType> = (
  state = passwordInitialState,
  action
): IPasswordState => {
  switch (action.type) {
    case "PASSWORD_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "PASSWORD_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "PASSWORD_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "PASSWORD_RESET":
      return passwordInitialState;
    default:
      return state;
  }
};

// Update password
const updatePasswordInitialState: IUpdatePasswordState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const updatePasswordReducer: Reducer<IUpdatePasswordState, UpdatePasswordActionType> = (
  state = updatePasswordInitialState,
  action
): IUpdatePasswordState => {
  switch (action.type) {
    case "UPDATE_PASSWORD_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "UPDATE_PASSWORD_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "UPDATE_PASSWORD_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "UPDATE_PASSWORD_RESET":
      return updatePasswordInitialState;
    default:
      return state;
  }
};
