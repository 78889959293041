import React, { FormEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { MainLayout } from "../../layouts";
import { Button, ButtonGroup, Card, Col, Form, Row, Table } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { RiEyeLine, SiMicrosoftexcel } from "react-icons/all";
import moment from "moment";
import Moment from "react-moment";
import qs from "query-string";
import { RootState } from "../../../store/reducers";
import { IAppointmentsPaginationParams, IAppointmentsState, IAppointmentsExportState } from "../../../store/types";
import { LoadingIndicator, Pagination, RecordNotFound } from "../../partials";
import actions from "../../../store/actions";
import { AppointmentTypes, AppointmentStatusFilterTypes, DEFAULT_PER_PAGE } from "../../../types";
import { useToasts } from "react-toast-notifications";
import { saveAs } from "file-saver";

export const List = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const filterTypeRef = useRef<HTMLSelectElement>(null);
  const filterStatusRef = useRef<HTMLSelectElement>(null);
  const filterStartDateRef = useRef<HTMLInputElement>(null);
  const filterEndDateRef = useRef<HTMLInputElement>(null);
  const filterRecordedRef = useRef<HTMLInputElement>(null);
  const filterQRef = useRef<HTMLInputElement>(null);
  const [filterDateRangeDefaultValue, setFilterDateRangeDefaultValue] = useState<string>("");
  const [isExporting, setIsExporting] = useState<boolean>(false);

  const { isLoading, response, error } = useSelector<RootState, IAppointmentsState>(
    (state: RootState) => state.appointments
  );

  const {
    isLoading: appointmentsExportIsLoading,
    response: appointmentsExportResponse,
    error: appointmentsExportError,
  } = useSelector<RootState, IAppointmentsExportState>((state: RootState) => state.appointmentsExport);

  const getAppointments = useCallback(
    (queryParams?) => {
      dispatch(actions.appointments(queryParams));
    },
    [dispatch]
  );

  const queryString = new URLSearchParams(useLocation().search);
  const page: number = queryString.get("page") ? Number(queryString.get("page")) : 1;
  const status: string | null = queryString.get("status") ? queryString.get("status") : null;
  const type: string | null = queryString.get("type") ? queryString.get("type") : null;
  const startDateQ: string | null = queryString.get("start_date") ? queryString.get("start_date") : null;
  const endDateQ: string | null = queryString.get("end_date") ? queryString.get("end_date") : null;
  const [defaultStartDate, setDefaultStartDate] = useState<string | null>(null);
  const [defaultEndDate, setDefaultEndDate] = useState<string | null>(null);
  const recorded: string | null = queryString.get("recorded") ? queryString.get("recorded") : null;
  const q: string | null = queryString.get("q") ? queryString.get("q") : null;

  const [queryParams, setQueryParams] = useState<IAppointmentsPaginationParams>({
    page,
    per: DEFAULT_PER_PAGE,
  });
  const qParams = useMemo<IAppointmentsPaginationParams>(() => qs.parse(search), [search]);

  useEffect(() => {
    const params = qParams;
    params.per = DEFAULT_PER_PAGE;
    setQueryParams(params);
    getAppointments(params);
  }, [getAppointments, qParams]);

  useEffect(() => {
    setDefaultStartDate(startDateQ);
    setDefaultEndDate(endDateQ);
  }, [startDateQ, endDateQ]);

  const handleFilterDateRangeApply = (event: any, picker: any) => {
    filterStartDateRef.current!.value = picker.startDate.format("YYYY-MM-DD");
    filterEndDateRef.current!.value = picker.endDate.format("YYYY-MM-DD");

    picker.element.val(`${picker.startDate.format("DD/MM/YYYY")} - ${picker.endDate.format("DD/MM/YYYY")}`);
  };

  const handleFilterDateRangeCancel = (event: any, picker: any) => {
    picker.element.val("");
  };

  const handleFilterDateRangeCallback = (start: any, end: any) => {
    filterStartDateRef.current!.value = start.format("YYYY-MM-DD");
    filterEndDateRef.current!.value = end.format("YYYY-MM-DD");
  };

  const handleFilterDateRangeValueChange = (e: FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.value === "") {
      setFilterDateRangeDefaultValue("");
      setDefaultStartDate(null);
      setDefaultEndDate(null);
      filterStartDateRef.current!.value = "";
      filterEndDateRef.current!.value = "";
    }
  };

  const handleSubmitFilter = () => {
    let url = `/appointments?page=1`;
    const searchParams: Array<string> = [];
    if (filterTypeRef.current!.value) {
      searchParams.push(`type=${filterTypeRef.current!.value}`);
      queryParams.type = filterTypeRef.current!.value;
    }

    if (filterStatusRef.current!.value) {
      searchParams.push(`status=${filterStatusRef.current!.value}`);
      queryParams.status = filterStatusRef.current!.value;
    }

    if (filterStartDateRef.current!.value && filterEndDateRef.current!.value) {
      searchParams.push(`start_date=${filterStartDateRef.current!.value}`);
      queryParams.start_date = filterStartDateRef.current!.value;

      searchParams.push(`end_date=${filterEndDateRef.current!.value}`);
      queryParams.end_date = filterEndDateRef.current!.value;
    }

    if (filterRecordedRef.current!.checked) {
      searchParams.push(`recorded=true`);
      queryParams.recorded = "true";
    }

    if (filterQRef.current!.value) {
      searchParams.push(`q=${filterQRef.current!.value}`);
      queryParams.q = filterQRef.current!.value;
    }

    if (searchParams.length) {
      url += "&" + searchParams.join("&");
    }

    getAppointments(queryParams);

    history.push(url);
  };

  useEffect(() => {
    if (error?.response) {
      dispatch({ type: "APPOINTMENTS_RESET" });

      if (error.response.status >= 400) {
        history.push("/appointments");
      }
    }
  }, [error, dispatch, history]);

  useEffect(() => {
    if (!response && !isLoading) {
      getAppointments({
        page,
        per: DEFAULT_PER_PAGE,
        type,
        status,
        start_date: startDateQ,
        end_date: endDateQ,
        recorded,
        q,
      });
    }
  }, [response, isLoading, getAppointments, page, type, status, startDateQ, endDateQ, recorded, q]);

  useEffect(() => {
    if (startDateQ && endDateQ && filterStartDateRef) {
      const startDate = moment(startDateQ, "YYYY-MM-DD");
      const endDate = moment(endDateQ, "YYYY-MM-DD");

      setFilterDateRangeDefaultValue(`${startDate.format("DD/MM/YYYY")} - ${endDate.format("DD/MM/YYYY")}`);
    }
  }, [startDateQ, endDateQ]);

  const handleExport = () => {
    const exportParams: any = queryParams;
    delete exportParams.page;
    delete exportParams.per;
    exportParams["export"] = "excel";

    dispatch(actions.appointmentsExport(exportParams));
  };

  useEffect(() => {
    setIsExporting(appointmentsExportIsLoading);
  }, [appointmentsExportIsLoading]);

  useEffect(() => {
    if (appointmentsExportError) {
      addToast(t("unknown_error"), {
        appearance: "error",
        autoDismiss: true,
      });

      dispatch({ type: "APPOINTMENTS_EXPORT_RESET" });
    }
  }, [appointmentsExportError, addToast, t, dispatch]);

  useEffect(() => {
    if (appointmentsExportResponse) {
      saveAs(new Blob([appointmentsExportResponse]), `Gorusme-Listesi-${moment().format("YYYY-MM-DD-HH-mm-ss")}.xlsx`);
      dispatch({ type: "APPOINTMENTS_EXPORT_RESET" });
    }
  }, [appointmentsExportResponse, dispatch]);

  return (
    <MainLayout>
      <LoadingIndicator show={isLoading} />
      {response && (
        <Row>
          <Col md="9" className="order-last order-md-first">
            <Card>
              <Card.Header>
                <Row>
                  <Col as="h6" xs="8" className="mb-0">
                    {t("appointments")}
                  </Col>
                  <Col xs="4" className="mb-0 text-right">
                    <strong className="mr-1">{t("total")}:</strong>
                    <span>{response.data.pagination.total}</span>
                  </Col>
                </Row>
              </Card.Header>

              <Card.Body className="p-0 m-0">
                {response.data.items.length > 0 ? (
                  <>
                    <Table responsive striped hover className="table-list m-0">
                      <thead>
                        <tr>
                          <th>{t("role_names.MENTOR")}</th>
                          <th>{t("role_names.USER")}</th>
                          <th className="text-center">{t("type")}</th>
                          <th className="text-center">{t("record_count")}</th>
                          <th className="date">{t("start_date")}</th>
                          <th className="date">{t("end_date")}</th>
                          <th className="actions">{t("actions")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {response.data.items.map((item, key) => {
                          return (
                            <tr key={key}>
                              <td>{`${item.mentor.name} ${item.mentor.lastname}`}</td>
                              <td>{`${item.user.name} ${item.user.lastname}`}</td>
                              <td className="text-center">{t(`appointment_content_types.${item.type}`)}</td>
                              <td className="text-center">{item.records.length}</td>
                              <td className="date">
                                <Moment format="DD/MM/YYYY HH:mm">{item.start_date}</Moment>
                              </td>
                              <td className="date">
                                <Moment format="DD/MM/YYYY HH:mm">{item.end_date}</Moment>
                              </td>
                              <td className="actions">
                                <ButtonGroup>
                                  <Button
                                    as={NavLink}
                                    to={`/appointments/${item.id}`}
                                    variant="outline-info"
                                    size={"sm"}
                                    className="btn-icon"
                                    title={t("show")}
                                  >
                                    <RiEyeLine />
                                  </Button>
                                </ButtonGroup>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </>
                ) : (
                  <RecordNotFound />
                )}
              </Card.Body>

              <Card.Footer>
                <Row>
                  <Col xs="8">
                    <Pagination
                      pagination={response.data.pagination}
                      extraParams={["type", "status", "start_date", "end_date", "recorded", "q"]}
                    />
                  </Col>
                  <Col xs="4" className="text-right">
                    <Button
                      variant="info"
                      size="sm"
                      className={`with-icon${isExporting ? " process" : ""}`}
                      title={t("export_as_excel")}
                      onClick={handleExport}
                      disabled={isExporting}
                    >
                      <SiMicrosoftexcel />
                      <span>{t("export")}</span>
                    </Button>
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Col>
          <Col md="3" className="order-first order-md-last">
            <Card>
              <Card.Body>
                <Form onSubmit={handleSubmitFilter}>
                  <Form.Group controlId="filter-type">
                    <Form.Label>{t("type")}</Form.Label>
                    <Form.Control as="select" custom ref={filterTypeRef} defaultValue={type ? type : ""}>
                      <option value="">{t("all")}</option>
                      {Object.values(AppointmentTypes).map((item) => {
                        return <option value={item}>{t(`appointment_content_types.${item}`)}</option>;
                      })}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="filter-status">
                    <Form.Label>{t("status")}</Form.Label>
                    <Form.Control as="select" custom ref={filterStatusRef} defaultValue={status ? status : ""}>
                      <option value="">{t("all")}</option>
                      {Object.values(AppointmentStatusFilterTypes).map((item) => {
                        return <option value={item}>{t(`appointment_status_types.${item}`)}</option>;
                      })}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="filter-date-range">
                    <Form.Label>{t("date_range")}</Form.Label>
                    <DateRangePicker
                      onApply={handleFilterDateRangeApply}
                      onCancel={handleFilterDateRangeCancel}
                      onCallback={handleFilterDateRangeCallback}
                      initialSettings={{
                        showDropdowns: true,
                        autoUpdateInput: false,
                        autoApply: true,
                        opens: "left",
                        locale: {
                          format: "DD/MM/YYYY",
                        },
                      }}
                    >
                      <input
                        type="text"
                        id="filter-date-range"
                        placeholder={t("date_range")}
                        className="form-control"
                        defaultValue={filterDateRangeDefaultValue}
                        onChange={handleFilterDateRangeValueChange}
                      />
                    </DateRangePicker>
                    <input
                      type="hidden"
                      ref={filterStartDateRef}
                      defaultValue={defaultStartDate ? defaultStartDate : ""}
                    />
                    <input type="hidden" ref={filterEndDateRef} defaultValue={defaultEndDate ? defaultEndDate : ""} />
                  </Form.Group>
                  <Form.Group controlId="filter-q">
                    <Form.Label>{t("keywords")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="q"
                      ref={filterQRef}
                      placeholder={t("keywords")}
                      onKeyPress={(e: any) => e.code === "Enter" && handleSubmitFilter()}
                      defaultValue={q ? q : ""}
                    />
                  </Form.Group>
                  <Form.Group controlId="filter-recorded">
                    <Form.Check
                      type="switch"
                      ref={filterRecordedRef}
                      defaultChecked={recorded === "true"}
                      label={t("only_recorded")}
                    />
                  </Form.Group>
                  <hr />
                  <Button variant="outline-secondary" block onClick={handleSubmitFilter}>
                    {t("filter")}
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </MainLayout>
  );
};

export default List;
