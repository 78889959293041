import { Reducer } from "react";
import {
  IDefaultState,
  IUserApplicationsState,
  UserApplicationsActionType,
  IUserApplicationState,
  UserApplicationActionType,
  EvaluateUserApplicationActionType,
  DeleteUserApplicationActionType,
} from "../types";

// UserApplications
const userApplicationsInitialState: IUserApplicationsState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const userApplicationsReducer: Reducer<IUserApplicationsState, UserApplicationsActionType> = (
  state = userApplicationsInitialState,
  action
): IUserApplicationsState => {
  switch (action.type) {
    case "USER_APPLICATIONS_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "USER_APPLICATIONS_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "USER_APPLICATIONS_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "USER_APPLICATIONS_RESET":
      return userApplicationsInitialState;
    default:
      return state;
  }
};

// UserApplication
const userApplicationInitialState: IUserApplicationState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const userApplicationReducer: Reducer<IUserApplicationState, UserApplicationActionType> = (
  state = userApplicationInitialState,
  action
): IUserApplicationState => {
  switch (action.type) {
    case "USER_APPLICATION_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "USER_APPLICATION_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "USER_APPLICATION_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "USER_APPLICATION_RESET":
      return userApplicationInitialState;
    default:
      return state;
  }
};

/**
 * @param state
 * @param action
 */
export const evaluateUserApplicationReducer: Reducer<IUserApplicationState, EvaluateUserApplicationActionType> = (
  state = userApplicationInitialState,
  action
): IUserApplicationState => {
  switch (action.type) {
    case "EVALUATE_USER_APPLICATION_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "EVALUATE_USER_APPLICATION_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "EVALUATE_USER_APPLICATION_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "EVALUATE_USER_APPLICATION_RESET":
      return userApplicationInitialState;
    default:
      return state;
  }
};

const faqDeleteInitialState: IDefaultState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const deleteUserApplicationReducer: Reducer<IDefaultState, DeleteUserApplicationActionType> = (
  state = faqDeleteInitialState,
  action
): IUserApplicationState => {
  switch (action.type) {
    case "DELETE_USER_APPLICATION_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "DELETE_USER_APPLICATION_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "DELETE_USER_APPLICATION_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "DELETE_USER_APPLICATION_RESET":
      return faqDeleteInitialState;
    default:
      return state;
  }
};
