import IStorage from "./IStorage";
import TokenType from "./tokenType";

export default class LocalStorage implements IStorage {
  /**
   * @private classInstance
   */
  private static classInstance?: LocalStorage;

  /**
   * Token key.
   * @private
   */
  private ACCESS_TOKEN_KEY = "token";

  /**
   * Refresh token key.
   * @private
   */
  private REFRESH_TOKEN_KEY = "refresh_token";

  /**
   * @public getInstance
   */
  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new this();
    }

    return this.classInstance;
  }

  /**
   * Get localStorage item.
   * @param key
   */
  private _getItem = (key: string): TokenType => {
    return localStorage.getItem(key);
  };

  /**
   * Remove localStorage item.
   * @param key
   * @private
   */
  private _removeItem = (key: string): void => {
    localStorage.removeItem(key);
  };

  /**
   * Set access token.
   */
  public getAccessToken = (): TokenType => {
    return this._getItem(this.ACCESS_TOKEN_KEY);
  };

  /**
   * Get access token.
   * @param value
   */
  public setAccessToken = (value: string): void => {
    localStorage.setItem(this.ACCESS_TOKEN_KEY, value);
  };

  /**
   * Remove access token.
   */
  public removeAccessToken = (): void => {
    this._removeItem(this.ACCESS_TOKEN_KEY);
  };

  /**
   * Set refresh token.
   */
  public getRefreshToken = (): TokenType => {
    return this._getItem(this.REFRESH_TOKEN_KEY);
  };

  /**
   * Get refresh token.
   * @param value
   */
  public setRefreshToken = (value: string): void => {
    localStorage.setItem(this.REFRESH_TOKEN_KEY, value);
  };

  /**
   * Remove refresh token.
   */
  public removeRefreshToken = (): void => {
    this._removeItem(this.REFRESH_TOKEN_KEY);
  };

  /**
   * Remove tokens.
   */
  public removeTokens = (): void => {
    this.removeAccessToken();
    this.removeRefreshToken();
  };
}
