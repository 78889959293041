import { Dispatch } from "redux";
import { AdminApiClient } from "../../utils/http-client";
import { IDeletePictureParams } from "../types";

const adminApiClient = AdminApiClient.getInstance();

export const deletePicture = (params: IDeletePictureParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "DELETE_PICTURE_START" });
  await adminApiClient
    .deletePicture(params)
    .then((response) => {
      dispatch({ type: "DELETE_PICTURE_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "DELETE_PICTURE_FAILURE", payload: error }));
};
