import { Reducer } from "react";
import { IDashboardState, DashboardActionType } from "../types";

// Dashboard
const dashboardInitialState: IDashboardState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const dashboardReducer: Reducer<IDashboardState, DashboardActionType> = (
  state: IDashboardState = dashboardInitialState,
  action: DashboardActionType
): IDashboardState => {
  switch (action.type) {
    case "DASHBOARD_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "DASHBOARD_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "DASHBOARD_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "DASHBOARD_RESET":
      return { ...state, isLoading: false, response: null, error: action.payload };
    default:
      return state;
  }
};
