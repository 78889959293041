import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { MainLayout } from "../../layouts";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Select from "react-select";
import { RiAddBoxLine, RiArrowLeftSLine, RiCheckFill } from "react-icons/all";
import { Controller, useForm } from "react-hook-form";
import { RootState } from "../../../store/reducers";
import { WysiwygEditor } from "../../partials";
import { UserRoleTypes } from "../../../types";
import {
  ITeamCreateParams,
  IUsersState,
  IUsersPaginationParams,
  ICategoriesState,
  ITeamState,
  UserType,
} from "../../../store/types";
import { AlertDefaultState, AlertStateType } from "../../../types";
import { FormAlert } from "../../partials";
import actions from "../../../store/actions";
import history from "../../../utils/history";

import UserSelectorModal, {
  UserSelectedType,
  UserSelectorModalDefaultState,
  UserSelectorModalType,
} from "../users/CreateUserModal";

export type OptionType = {
  value: string;
  label: string | null;
};

export const Create = () => {
  const dispatch = useDispatch();

  const [queryForUser] = useState<IUsersPaginationParams>({
    page: 1,
    per: 9999,
  });
  const [queryForCategories] = useState<IUsersPaginationParams>({
    page: 1,
    per: 9999,
  });

  const [mentors, setMentors] = useState<Array<OptionType>>([]);
  const [categories, setCategories] = useState<Array<OptionType>>([]);
  const { response: userResponse } = useSelector<RootState, IUsersState>((state: RootState) => state.users);
  const { response: categoryResponse } = useSelector<RootState, ICategoriesState>(
    (state: RootState) => state.categories
  );
  const [sectorSelectorModal, setUserSelectorModal] = useState<UserSelectorModalType>(UserSelectorModalDefaultState);
  const [, setSelectedUserUsers] = useState<Array<UserSelectedType>>([]);
  const onHandleUserSelect = (data: UserSelectedType): void =>
    setSelectedUserUsers((selectedUserUsers) => [...selectedUserUsers, data]);

  // Resetting the pagination to previous states so that it can be disabled
  useEffect(() => {
    dispatch({ type: 'USERS_RESET' })
    dispatch({ type: 'CATEGORIES_RESET' })
  }, [dispatch]);

  useEffect((): void => {
    if (categoryResponse) {
      if (categoryResponse.data.items.length > 0) {
        const categoryOptions: Array<OptionType> = categoryResponse.data.items.map((item) => {
          return {
            value: item.id,
            label: item.title,
          };
        });
        setCategories(categoryOptions);
      }
    } else {
      dispatch(actions.categories(queryForCategories));
    }
  }, [categoryResponse, dispatch, queryForCategories]);

  useEffect((): void => {
    if (userResponse) {

      const mentorOptionsTypeArray: Array<OptionType> = [];
      userResponse.data.items.forEach((item: UserType): void => {
        if (item.roles.includes(UserRoleTypes.mentor) || item.roles.includes(UserRoleTypes.admin))
          mentorOptionsTypeArray.push({
            value: item.id,
            label: `${item.name} ${item.lastname} (${item.email}) ${item.categories.map((category) => category.title).join(", ")}`,
          });
      });
      setMentors(mentorOptionsTypeArray);
    } else {
      dispatch(actions.users(queryForUser));
    }
  }, [userResponse, dispatch, queryForUser]);

  const [selectedCategoryOptions, setSelectedOptions] = React.useState([]);

  const [formDisable, setFormDisable] = useState<boolean>(false);
  const [formAlert, setFormAlert] = useState<AlertStateType>(AlertDefaultState);

  const { t } = useTranslation();
  const wysiwygRef = useRef();
  const { addToast } = useToasts();

  const { register, control, handleSubmit, errors, reset } = useForm<ITeamCreateParams>();
  const onSubmit = async (data: any) => {
    data.process_manager = data.process_manager ? data.process_manager.value : null;
    data.project_managers = data.project_managers.map((client: any) => client.value);
    if (data.process_manager == null && data.project_managers == null) {
      if (data.process_manager == null) {
        addToast(t("you_should_process_manager_or_interns"), {
          appearance: "error",
          autoDismiss: true,
        });
      } else {
        addToast(t("unknown_error"), {
          appearance: "error",
          autoDismiss: true,
        });
      }
      return;
    }
    data.team_lead = data.team_lead ? data.team_lead.value : null;
    data.mentors = data.mentors.map((client: any) => client.value);
    data.categories = selectedCategoryOptions.map((category: any) => category.value);
    const formattedData: ITeamCreateParams = data;
    dispatch(await actions.createTeam(formattedData));
  };

  const { isLoading, response, error } = useSelector<RootState, ITeamState>((state: RootState) => state.createTeam);

  useEffect((): void => {
    setFormDisable(isLoading);

    if (error !== null) {
      if (error.response.status === 404) {
        setFormAlert({
          variant: "danger",
          show: true,
          messages: error.response.data.messages,
        });
      } else {
        addToast(t("unknown_error"), {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }
    if (errors !== null) {
      console.log(errors);
    }

    if (response) {
      addToast(t("created_with_param", { param: t("user") }), {
        appearance: "success",
        autoDismiss: true,
      });

      reset();

      dispatch({ type: "CREATE_TEAM_RESET" });
      history.push(`/teams/${response.data.id}`);
    }
  }, [isLoading, response, error, addToast, reset, t, dispatch, errors]);

  return (
    <MainLayout>
      <FormAlert variant={formAlert.variant} show={formAlert.show} to={formAlert.to}>
        {formAlert.messages.map((message, key: number) => (
          <p key={key}>{message}</p>
        ))}
      </FormAlert>

      <Card className={formDisable ? "loading-block" : ""} style={{ marginBottom: 180 }}>
        <Card.Header as="h6">{t("create")}</Card.Header>
        <Card.Body>
          <UserSelectorModal
            modal={sectorSelectorModal}
            onClose={() => setUserSelectorModal(UserSelectorModalDefaultState)}
            onSubmit={onHandleUserSelect}
          />
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
              <Form.Group as={Col} md="8" controlId="team-name">
                <Form.Label>{t("team_name")}:</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  ref={register({
                    required: {
                      value: true,
                      message: t("validation.you_must_enter", { name: t("name") }),
                    },
                  })}
                  placeholder={t("name")}
                  defaultValue=""
                  autoFocus
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <hr />
            <Form.Row>
              <Form.Group as={Col} md="12" controlId="team-description">
                <Form.Label>{t("description")}:</Form.Label>
                <Controller
                  control={control}
                  name="description"
                  ref={register({
                    required: {
                      value: true,
                      message: t("validation.you_must_enter", { name: t("description") }),
                    },
                  })}
                  placeholder={t("description")}
                  defaultValue=""
                  render={({ onChange }) => (
                    <WysiwygEditor defaultValue="" onChange={(content: string) => onChange(content)} ref={wysiwygRef} />
                  )}
                  isInvalid={!!errors.description}
                />

                <Form.Control.Feedback type="invalid">{errors.description?.message}</Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <hr />
            <Form.Row>
              <Form.Group as={Col} md="12">
                <Form.Label htmlFor="selector-team-lead">
                  {t("team_lead")}
                  <Button
                    variant="outline-success"
                    size="sm"
                    className="with-icon ml-1"
                    title={t("add")}
                    onClick={() => setUserSelectorModal({ show: true, type: "mentor", clients: mentors })}
                  >
                    <RiAddBoxLine />
                  </Button>
                </Form.Label>

                <Controller
                  control={control}
                  name="team_lead"
                  defaultValue={null}
                  render={({ onChange, value, ref }) => (
                    <>
                      <Select
                        isClearable
                        inputRef={ref}
                        placeholder={t("team_lead")}
                        noOptionsMessage={() => t("not_found")}
                        options={mentors.filter((mentor) => !mentor.label?.includes("Stajyer"))}
                        value={mentors.find((mentor) => mentor.value === value)}
                        onChange={(value: any) => onChange(value)}
                        className={errors.team_lead ? "is-invalid" : ""}
                      />
                    </>
                  )}
                  rules={{ required: t("validation.you_must_choose", { name: t("team_lead") }) as string }}
                  isInvalid={!!errors.team_lead}
                />
                <Form.Control.Feedback type="invalid">{errors.team_lead?.message}</Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <hr />
            <Form.Row>
              <Form.Group as={Col} md="12">
                <Form.Label htmlFor="selector-project_manager">
                  {t("interns")}
                  <Button
                    variant="outline-success"
                    size="sm"
                    className="with-icon ml-1"
                    title={t("add")}
                    onClick={() => setUserSelectorModal({ show: true, type: "mentor", clients: mentors })}
                  >
                    <RiAddBoxLine />
                  </Button>
                </Form.Label>

                <Controller
                  control={control}
                  name="project_managers"
                  defaultValue={[]}
                  render={({ onChange, value, ref }) => (
                    <Select
                      isMulti
                      isClearable
                      inputRef={ref}
                      placeholder={t("select_interns")}
                      noOptionsMessage={() => t("not_found")}
                      options={mentors.filter((mentor) => mentor.label?.includes("Stajyer"))}
                      value={mentors.find((client) => client.value === value)}
                      onChange={(value: any) => onChange(value)}
                    />
                  )}
                  ref={register}
                />
              </Form.Group>
            </Form.Row>
            <hr />
            <Form.Row>
              <Form.Group as={Col} md="12">
                <Form.Label htmlFor="selector-process-manager">
                  {t("process_manager")}
                  <Button
                    variant="outline-success"
                    size="sm"
                    className="with-icon ml-1"
                    title={t("add")}
                    onClick={() => setUserSelectorModal({ show: true, type: "mentor", clients: mentors })}
                  >
                    <RiAddBoxLine />
                  </Button>
                </Form.Label>

                <Controller
                  control={control}
                  name="process_manager"
                  defaultValue={[]}
                  render={({ onChange, value, ref }) => (
                    <Select
                      isClearable
                      inputRef={ref}
                      placeholder={t("process_manager")}
                      noOptionsMessage={() => t("not_found")}
                      options={mentors.filter((mentor) => !mentor.label?.includes("Stajyer"))}
                      value={mentors.find((client) => client.value === value)}
                      onChange={(value: any) => onChange(value)}
                    />
                  )}
                  ref={register}
                />
              </Form.Group>
            </Form.Row>
            <hr />

            <hr />
            <Form.Row>
              <Form.Group as={Col} md="12">
                <Form.Label htmlFor="selector-mentors">
                  {t("mentors")}
                  <Button
                    variant="outline-success"
                    size="sm"
                    className="with-icon ml-1"
                    title={t("add")}
                    onClick={() => setUserSelectorModal({ show: true, type: "mentor", clients: mentors })}
                  >
                    <RiAddBoxLine />
                  </Button>
                </Form.Label>

                <Controller
                  control={control}
                  name="mentors"
                  defaultValue={[]}
                  render={({ onChange, value, ref }) => (
                    <Select
                      isMulti
                      isClearable
                      inputRef={ref}
                      placeholder={t("mentors")}
                      noOptionsMessage={() => t("not_found")}
                      options={mentors.filter((mentor) => !mentor.label?.includes("Stajyer"))}
                      value={mentors.find((client) => client.value === value)}
                      onChange={(value: any) => onChange(value)}
                    />
                  )}
                  ref={register}
                />
              </Form.Group>
            </Form.Row>
            <hr />
            <Form.Row>
              <Form.Group as={Col} md="12">
                <Form.Label htmlFor="selector-categories">{t("categories")}</Form.Label>

                <Controller
                  control={control}
                  name="categories"
                  defaultValue={[]}
                  render={({ onChange, value, ref }) => (
                    <Select
                      isMulti
                      isClearable
                      inputRef={ref}
                      placeholder={t("categories")}
                      noOptionsMessage={() => t("not_found")}
                      isOptionDisabled={(): boolean => selectedCategoryOptions.length >= 3}
                      options={categories}
                      value={categories.find((category: OptionType) => category.value === value)}
                      onChange={(value: any) => setSelectedOptions(value)}
                    />
                  )}
                  ref={register}
                />
              </Form.Group>
            </Form.Row>
          </Form>
        </Card.Body>
        <Card.Footer>
          <Row>
            <Col xs="6" className="text-left">
              <Button as={NavLink} to="/teams" variant="secondary" size="sm" className="with-icon">
                <RiArrowLeftSLine />
                <span>{t("list")}</span>
              </Button>
            </Col>
            <Col xs="6" className="text-right">
              <Button variant="primary" size="sm" className="with-icon" onClick={handleSubmit(onSubmit)}>
                <RiCheckFill />
                <span>{t("save")}</span>
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </MainLayout>
  );
};

export default Create;
