import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { MainLayout } from "../../layouts";
import { Button, Card, Col, Row, ButtonGroup } from "react-bootstrap";
import { RiArrowLeftSLine, RiDeleteBin6Line, RiEditBoxLine, RiEyeLine } from "react-icons/all";
import { RootState } from "../../../store/reducers";
import { IMentoringProgramState, IDefaultState, IFollowedTaskState } from "../../../store/types";
import { LoadingIndicator, DeleteConfirmModal } from "../../partials";
import Moment from "react-moment";
import actions from "../../../store/actions";
import { SessionDetailModalType, SessionModalDefaultState, SessionListModal } from "../sessions/SessionListModal";
import { SessionUpdateDetailModalType, SessionUpdateModalDefaultState, SessionUpdate } from "../sessions/sessionUpdateModal";

type RouterParamsType = {
    id: string;
};

export const ShowPrograms = () => {
    const [sessionModal, setSessionModal] = useState<SessionDetailModalType>(SessionModalDefaultState);
    const [sessionUpdateModal, setSessionUpdateModal] = useState<SessionUpdateDetailModalType>(SessionUpdateModalDefaultState);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { addToast } = useToasts();
    const { id } = useParams<RouterParamsType>();

    const { isLoading, response, error } = useSelector<RootState, IMentoringProgramState>((state: RootState) => state.mentoringProgram);
    const { response: responseCreateFollowedTask, error: errorCreateFollowedTask } = useSelector<RootState, IFollowedTaskState>((state: RootState) => state.createFollowedTask);
    const { response: responseDeleteFollowedTask, error: errorDeleteFollowedTask } = useSelector<RootState, IFollowedTaskState>((state: RootState) => state.deleteFollowedTask);
    const { response: responseUpdateFollowedTask, error: errorUpdateFollowedTask } = useSelector<RootState, IFollowedTaskState>((state: RootState) => state.updateFollowedTask);
    const {
        isLoading: deleteIsLoading,
        response: deleteResponse,
        error: deleteError,
    } = useSelector<RootState, IDefaultState>((state: RootState) => state.deleteSession);

    type IDandSID = { mentorID: string, sessionID: string };
    const [deleteSession, setDeleteSession] = useState<IDandSID | null>(null);
    const [deleteItem, setDeleteItem] = useState<string | null>(null);

    const onDeleteConfirm = async () => {
        if (deleteItem) {
            await dispatch(actions.deleteMentoringProgram(deleteItem));
            setDeleteItem(null);
            history.push("/mentoring_programs");
        }
        if (deleteSession) {
            await dispatch(actions.deleteSession(deleteSession.mentorID, deleteSession.sessionID));
            setDeleteSession(null);
            dispatch(actions.mentoringProgram(id));
            addToast(t("deleted_with_param", { param: t("session") }), {
                appearance: "success",
                autoDismiss: true,
            });
        }
    };

    const getProgram = useCallback(
        (id: string) => {
            dispatch(actions.mentoringProgram(id));
        },
        [dispatch]
    );

    useEffect(() => {
        if (error?.response) {
            if (error.response.status === 400 || error.response.status === 404) {
                dispatch({ type: "MENTORING_PROGRAM_RESET" });
                history.push("/404");
            }
        } else {
            dispatch(actions.mentoringProgram(id));
        }
    }, [dispatch, id, error, history]);
    useEffect(() => {
        if (error) {
            addToast(t("unknown_error"), {
                appearance: "error",
                autoDismiss: true,
            });
        }
    }, [error, addToast, t]);
    useEffect(() => {
        if (deleteError) {
            addToast(t("unknown_error"), {
                appearance: "error",
                autoDismiss: true,
            });
        }

        if (deleteResponse) {
            addToast(`${t("deleted_with_param")} ${t("mentoring_programs")}`, {
                appearance: "success",
                autoDismiss: true,
            });
            dispatch({ type: "DELETE_MENTORING_PROGRAM_RESET" });
            history.push("/mentoring_programs");
        }
    }, [deleteError, deleteResponse, addToast, t, dispatch, history]);
    useEffect(() => {
        if (errorCreateFollowedTask) {
            addToast(t("unknown_error"), {
                appearance: "error",
                autoDismiss: true,
            });
        }

        if (responseCreateFollowedTask) {
            dispatch(actions.mentoringProgram(id));
            dispatch({ type: "CREATE_FOLLOWED_TASK_RESET" });
            dispatch({ type: "MENTORING_PROGRAM_RESET" });
        }
    }, [errorCreateFollowedTask, responseCreateFollowedTask, addToast, t, dispatch, history, id, response?.data?.sessions]);

    useEffect(() => {
        if (errorUpdateFollowedTask) {
            addToast(t("unknown_error"), {
                appearance: "error",
                autoDismiss: true,
            });
        }

        if (responseUpdateFollowedTask) {
            dispatch(actions.mentoringProgram(id));
            dispatch({ type: "UPDATE_FOLLOWED_TASK_RESET" });
            dispatch({ type: "MENTORING_PROGRAM_RESET" });
        }
    }, [errorUpdateFollowedTask, responseUpdateFollowedTask, addToast, t, dispatch, history, id]);

    useEffect(() => {
        if (errorDeleteFollowedTask) {
            addToast(t("unknown_error"), {
                appearance: "error",
                autoDismiss: true,
            });
        }
        if (responseDeleteFollowedTask) {
            dispatch(actions.mentoringProgram(id));
            dispatch({ type: "DELETE_FOLLOWED_TASK_RESET" });
            dispatch({ type: "MENTORING_PROGRAM_RESET" });
        }
    }, [errorDeleteFollowedTask, responseDeleteFollowedTask, addToast, t, dispatch, history, id]);

    const UpdateSessionModal = (sessionID: any) => {
        setSessionUpdateModal({ show: true, session: sessionID });
    };
    const openSessionModal = (session: any) => {
        setSessionModal({ show: true, data: session });
    };
    const closeSessionModal = () => {
        setSessionModal({ show: false, data: null });
    };
    const [sessionUpdateKey, setSessionUpdateKey] = useState<number>(0);

    const closeSessionUpdateModal = () => {
        setSessionUpdateModal({ show: false, session: null });
        setSessionUpdateKey((prevKey) => prevKey + 1);
    };

    useEffect(() => {
        getProgram(id);
    }, [id, sessionUpdateKey, getProgram]);

    return (
        <MainLayout>
            <LoadingIndicator show={isLoading} />
            {response && (
                <Card className={deleteIsLoading ? "loading-block" : ""}>
                    <Card.Header as="h6">{t("show")}</Card.Header>
                    <Card.Body>
                        <DeleteConfirmModal
                            show={!!deleteItem || !!deleteSession}
                            onConfirm={onDeleteConfirm}
                            onClose={() => {
                                setDeleteItem(null);
                                setDeleteSession(null);
                            }}
                        />

                        <div className="content-view">
                            <dl className="row">
                                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("title")}:</dt>
                                <dd className="col-12 col-sm-9 col-md-10">{response.data.title}</dd>
                            </dl>
                            <hr />
                            <dl className="row">
                                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("sessions_title_and_sessions_topics")}:</dt>
                                <dd className="col-12 col-sm-9 col-md-10">
                                    {response.data.sessions.map((session, index) => {
                                        const titleParts = session.title.split("-");
                                        const title = titleParts.length > 1 ? titleParts[1].trim() : session.title;
                                        return (
                                            <React.Fragment key={index}>
                                                {index > 0 && <br />}
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <button
                                                        style={{ color: '#007bff', textDecoration: 'none', cursor: 'pointer', border: 'none', background: 'none', padding: '0' }}
                                                        onClick={() => openSessionModal(session)}
                                                    >
                                                        {title}
                                                    </button>
                                                    <ButtonGroup>
                                                        <Button
                                                            onClick={() => openSessionModal(session)}
                                                            variant="outline-primary"
                                                            size="sm"
                                                            title={t("show")}
                                                        >
                                                            <RiEyeLine />
                                                        </Button>

                                                        <Button
                                                            onClick={() => UpdateSessionModal(session.id)}
                                                            variant="outline-primary"
                                                            size="sm"
                                                            title={t("edit")}
                                                        >
                                                            <RiEditBoxLine />
                                                        </Button>
                                                        <Button
                                                            variant="danger"
                                                            size="sm"
                                                            className="with-icon"
                                                            title={t("delete")}
                                                            onClick={() => {
                                                                setDeleteSession({ mentorID: response.data.id, sessionID: session.id });
                                                            }}
                                                        >
                                                            <RiDeleteBin6Line />
                                                        </Button>

                                                    </ButtonGroup>
                                                </div>
                                            </React.Fragment>
                                        );
                                    })}
                                </dd>
                            </dl>

                            <hr />
                            <dl className="row">
                                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("created_at")}:</dt>
                                <dd className="col-12 col-sm-9 col-md-10">
                                    <Moment format="DD/MM/YYYY">{response.data.created_at}</Moment>
                                </dd>
                            </dl>
                        </div>
                    </Card.Body>
                    <Card.Footer>
                        <Row>
                            <Col xs="6" className="text-left">
                                <Button as={NavLink} to="/mentoring_programs" variant="secondary" size="sm" className="with-icon">
                                    <RiArrowLeftSLine />
                                    <span>{t("list")}</span>
                                </Button>
                            </Col>
                            <Col xs="6" className="text-right">
                                <Button as={NavLink} to={`/mentoring_programs/${id}/edit`} variant="warning" size="sm" className="with-icon mr-2">
                                    <RiEditBoxLine />
                                    <span>{t("create_new_session")}</span>
                                </Button>
                                <Button variant="danger" size="sm" className="with-icon" onClick={() => setDeleteItem(id)}>
                                    <RiDeleteBin6Line />
                                    <span>{t("delete")}</span>
                                </Button>
                            </Col>
                        </Row>
                    </Card.Footer>
                </Card>
            )}
            <SessionListModal modal={sessionModal} onClose={closeSessionModal} />
            <SessionUpdate modal={sessionUpdateModal} onClose={closeSessionUpdateModal} />
        </MainLayout>
    );
};

export default ShowPrograms;