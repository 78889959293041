import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useKeyPress } from "@react-typed-hooks/use-key-press";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import ILayoutProps from "../layouts/ILayoutProps";
import { Header, Footer, Aside, Breadcrumb } from "../partials";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Container } from "react-bootstrap";
import { RootState } from "../../store/reducers";
import { IMeState, ILogoutState, IMeData, IDashboardState } from "../../store/types";
import actions from "../../store/actions";
import history from "../../utils/history";
import { useLocation } from "react-router-dom";

export const MainLayout = (props: ILayoutProps) => {
  const { children } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const location = useLocation();

  const isEscapePressed = useKeyPress({ targetKey: "Escape" });
  const [asideToggle, setAsideToggle] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<IMeData | null>(null);
  const { response: meResponse } = useSelector<RootState, IMeState>((state: RootState) => state.me);
  const { response: logoutResponse } = useSelector<RootState, ILogoutState>((state: RootState) => state.logout);
  const { response: dashboardResponse } = useSelector<RootState, IDashboardState>(
    (state: RootState) => state.dashboard
  );

  const toggleAside = (toggle: boolean = true) => {
    if (toggle) {
      document.body.classList.add("aside-toggle");
      setAsideToggle(true);
    } else {
      document.body.classList.remove("aside-toggle");
      setAsideToggle(false);
    }
  };

  const handleAsideToggle = (status?: boolean) => {
    if (status !== undefined) {
      setAsideToggle(status);
      toggleAside(false);
    } else {
      if (asideToggle) {
        toggleAside(false);
      } else {
        toggleAside(true);
      }
    }
  };

  useEffect(() => {
    if (isEscapePressed && asideToggle) {
      toggleAside(false);
    }
  }, [isEscapePressed, asideToggle]);

  useEffect(() => {
    if (meResponse === null) {
      dispatch(actions.me());
    } else {
      if (meResponse.data.roles.indexOf("ADMIN") === -1) {
        addToast(t("not_authorized_this_area"), {
          appearance: "error",
          autoDismiss: true,
        });

        dispatch(actions.logout());
      } else {
        setCurrentUser(meResponse.data);
      }
    }
  }, [meResponse, addToast, t, dispatch]);

  const clearUserData = useCallback(() => {
    dispatch(actions.loginReset());
    dispatch(actions.meReset());
  }, [dispatch]);

  useEffect(() => {
    if (logoutResponse === true) {
      clearUserData();
      history.push("/auth/login");
    }
  }, [logoutResponse, clearUserData]);

  useEffect(() => {
    if (!dashboardResponse) {
      dispatch(actions.dashboard());
    }
  }, [dashboardResponse, dispatch]);
  const renderBreadcrumb = () => {
    if (location.pathname.startsWith("/matches/") && location.pathname.includes("/report")) {
      return <Breadcrumb showReportProgram />;
    }
    if (location.pathname.startsWith("/matches/") && location.pathname.includes("/edit")) {
      return <Breadcrumb showEdit />;
    }
    if (location.pathname.startsWith("/mentoring_programs/") && location.pathname.includes("/match")) {
      return <Breadcrumb showMatch />;
    }
    return <Breadcrumb />;
  };
  return (
    <>
      {currentUser && (
        <>
          <Header asideToggleHandler={handleAsideToggle} />
          <Aside asideToggleHandler={handleAsideToggle} />
          <main>
          {renderBreadcrumb()}
            <PerfectScrollbar>
              <Container fluid={true} className="content-container">
                {children}
              </Container>
            </PerfectScrollbar>
          </main>
          <Footer />
        </>
      )}
    </>
  );
};

export default MainLayout;
