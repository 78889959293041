import { Reducer } from "react";
import { ICitiesState, CitiesActionType, DistrictsActionType, IDistrictsState } from "../types";

// Cities
const citiesInitialState: ICitiesState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const citiesReducer: Reducer<ICitiesState, CitiesActionType> = (
  state: ICitiesState = citiesInitialState,
  action: CitiesActionType
): ICitiesState => {
  switch (action.type) {
    case "CITIES_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "CITIES_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "CITIES_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "CITIES_RESET":
      return citiesInitialState;
    default:
      return state;
  }
};

// Districts
const districtsInitialState: IDistrictsState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const districtsReducer: Reducer<IDistrictsState, DistrictsActionType> = (
  state: IDistrictsState = districtsInitialState,
  action: DistrictsActionType
): IDistrictsState => {
  switch (action.type) {
    case "DISTRICTS_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "DISTRICTS_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "DISTRICTS_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "DISTRICTS_RESET":
      return districtsInitialState;
    default:
      return state;
  }
};
