import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MainLayout } from "../../layouts";
import { Container, Card, Col, Row, Image, Button } from "react-bootstrap";
import { RiArrowLeftSLine } from "react-icons/all";
import { RootState } from "../../../store/reducers";
import { ISupportState } from "../../../store/types";
import { LoadingIndicator } from "../../partials";
import Moment from "react-moment";
import actions from "../../../store/actions";
import { IMAGE_ERROR_SOURCE } from "../../../types";

type RouterParamsType = {
  id: string;
};

const Show = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<RouterParamsType>();
  const { isLoading, response, error } = useSelector<RootState, ISupportState>((state: RootState) => state.support);

  useEffect(() => {
    if (error?.response) {
      if (error?.response.status === 400 || error?.response.status === 404) {
        dispatch({ type: "SUPPORT_RESET" });
        history.push("/404");
      }
    } else {
      dispatch(actions.support(id));
    }
  }, [dispatch, id, history, error]);

  const errorHandler = (e: any) => (e.target.src = IMAGE_ERROR_SOURCE);

  return (
    <MainLayout>
      <LoadingIndicator show={isLoading} />
      {response && (
        <Card>
          <Card.Header as="h6">{t("show")}</Card.Header>
          <Card.Body>
            <div className="content-view">
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("id")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.id}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("created_at")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  <Moment format="DD/MM/YYYY HH:mm:ss">{response.data.created_at}</Moment>
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("sender")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{`${response.data.sender?.name} ${response.data.sender?.lastname}`}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("title")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.title}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("content")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.content}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("pictures")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  <Container>
                    <Row>
                      {response.data.pictures.length > 0
                        ? response.data.pictures.map((p, k) => (
                            <Col key={k} xs={6} sm={4} md={4} lg={4} xl={4}>
                              <Image onError={errorHandler} src={`${process.env.REACT_APP_STORAGE_URL}/${p}`} alt="" />
                            </Col>
                          ))
                        : "-"}
                    </Row>
                  </Container>
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("is_read_status")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{t(`support_is_read.${response.data.is_read}`)}</dd>
              </dl>
            </div>
          </Card.Body>
          <Card.Footer>
            <Row>
              <Col xs="12" className="text-left">
                <Button as={NavLink} to="/support" variant="secondary" size="sm" className="with-icon">
                  <RiArrowLeftSLine />
                  <span>{t("list")}</span>
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      )}
    </MainLayout>
  );
};

export default Show;
