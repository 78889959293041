import { MainLayout } from "../../layouts";
import { useTranslation } from "react-i18next";
import { Button, Card, Col, Image, Row } from "react-bootstrap";
import { RiEditBoxLine } from "react-icons/all";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/reducers";
import { IMeState } from "../../../store/types";
import { LoadingIndicator } from "../../partials";
import { NavLink } from "react-router-dom";
import { pictureUrl } from "../../../utils/mixins";
import Moment from "react-moment";

export const ShowProfile = () => {
  const { t } = useTranslation();

  const { isLoading, response } = useSelector<RootState, IMeState>((state: RootState) => state.me);

  return (
    <MainLayout>
      <LoadingIndicator show={isLoading} />
      {response && (
        <Card>
          <Card.Header as="h6">{t("profile")}</Card.Header>
          <Card.Body>
            <div className="content-view">
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("name")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.name}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("lastname")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.lastname}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("phone")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.phone}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("city")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.city ? response.data.city.name : "-"}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("district")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {response.data.district ? response.data.district.name : "-"}
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("email")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  <a href={"mailto:" + response.data.email} className="text-muted">
                    {response.data.email}
                  </a>
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("avatar")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {response.data.avatar ? (
                    <div className="profile-picture">
                      <Image src={pictureUrl(response.data.avatar)} rounded thumbnail />
                    </div>
                  ) : (
                    "-"
                  )}
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("created_at")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  <Moment format="DD/MM/YYYY HH:mm:ss">{response.data.created_at}</Moment>
                </dd>
              </dl>
              {response.data.updated_at && (
                <>
                  <hr />
                  <dl className="row">
                    <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("updated_at")}:</dt>
                    <dd className="col-12 col-sm-9 col-md-10">
                      <Moment format="DD/MM/YYYY HH:mm:ss">{response.data.updated_at}</Moment>
                    </dd>
                  </dl>
                </>
              )}
            </div>
          </Card.Body>
          <Card.Footer>
            <Row>
              <Col xs="12" className="text-right">
                <Button as={NavLink} to="/profile/edit" variant="warning" size="sm" className="with-icon">
                  <RiEditBoxLine />
                  <span>{t("update_my_profile")}</span>
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      )}
    </MainLayout>
  );
};

export default ShowProfile;
