import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { MainLayout } from "../../layouts";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { NavLink, useParams } from "react-router-dom";
import { RiAddBoxLine, RiArrowLeftSLine, RiCheckFill } from "react-icons/all";
import { Controller, useForm } from "react-hook-form";
import { RootState } from "../../../store/reducers";
import { WysiwygEditor } from "../../partials";
import { AlertDefaultState, AlertStateType } from "../../../types";
import { FormAlert, LoadingIndicator } from "../../partials";
import actions from "../../../store/actions";
import history from "../../../utils/history";
import Select from "react-select";

import UserSelectorModal, {
  UserSelectedType,
  UserSelectorModalDefaultState,
  UserSelectorModalType,
} from "../users/CreateUserModal";
import {
  IUsersState,
  IUsersPaginationParams,
  ICategoriesState,
  ITeamState,
  ITeamParams,
  UserType,
} from "../../../store/types";
import { UserRoleTypes } from "../../../types";

type RouterParamsType = {
  id: string;
};

export type OptionType = {
  value: string;
  label: string | null;
};

export const Edit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { id } = useParams<RouterParamsType>();
  const wysiwygRef = useRef();
  const [clients, setClients] = useState<Array<OptionType>>([]);
  const [mentors, setMentors] = useState<Array<OptionType>>([]);
  const [categories, setCategories] = useState<Array<OptionType>>([]);

  const [sectorSelectorModal, setUserSelectorModal] = useState<UserSelectorModalType>(UserSelectorModalDefaultState);

  const [queryForUser] = useState<IUsersPaginationParams>({ page: 1, per: 9999 });

  const [queryForCategories] = useState<IUsersPaginationParams>({ page: 1, per: 9999 });

  const { register, control, handleSubmit, errors, reset } = useForm<ITeamParams>();

  const { isLoading, response, error } = useSelector<RootState, ITeamState>((state: RootState) => state.updateTeam);

  const [formDisable, setFormDisable] = useState<boolean>(false);
  const [formAlert, setFormAlert] = useState<AlertStateType>(AlertDefaultState);
  const [def_name, setName] = useState<string>("");
  const [def_description, setDescription] = useState<string>("");
  const [def_team_lead, setTeamLead] = useState<string>("");
  const [def_project_managers, setProjectManager] = useState<Array<OptionType>>([]);
  const [def_process_manager, setProcessManager] = useState<string>("");
  const [def_mentors, setDefMentors] = useState<Array<OptionType>>([]);
  const [def_categories, setDefCategories] = useState<Array<OptionType>>([]);
  const onSubmit = async (data: ITeamParams) => {
    data.name = def_name;
    data.categories = def_categories.map((c) => c.value);
    data.mentors = def_mentors.map((c) => c.value);
    data.team_lead = def_team_lead;
    data.process_manager = def_process_manager;
    data.project_managers = def_project_managers.map((client: any) => client.value);
    dispatch(await actions.updateTeam(id, data));
  };
  const { response: userResponse } = useSelector<RootState, IUsersState>((state: RootState) => state.users);
  const { response: createUserResponse } = useSelector<RootState, IUsersState>((state: RootState) => state.createUser);
  const { response: categoryResponse } = useSelector<RootState, ICategoriesState>(
    (state: RootState) => state.categories
  );
  const {
    isLoading: teamIsLoading,
    response: teamResponse,
    error: teamError,
  } = useSelector<RootState, ITeamState>((state: RootState) => state.team);

  const [, setSelectedUserUsers] = useState<Array<UserSelectedType>>([]);
  const onHandleUserSelect = (data: UserSelectedType): void => {
    setSelectedUserUsers((selectedUserUsers) => [...selectedUserUsers, data]);
  };

  useEffect(() => {
    dispatch({ type: 'USERS_RESET' })
    dispatch({ type: 'CATEGORIES_RESET' })
  }, [dispatch]);

  useEffect(() => {
    if (createUserResponse) {
      dispatch(actions.users(queryForUser));
    }
  }, [createUserResponse, dispatch, queryForUser]);

  useEffect((): void => {
    if (teamResponse) {
      setName(teamResponse.data.name);
      setDescription(teamResponse.data.description);
      setTeamLead(teamResponse.data.team_lead?.id);
      setProcessManager(teamResponse.data.process_manager?.id);
      setDefMentors(
        teamResponse.data.mentors.length > 0
          ? teamResponse.data.mentors.map((id: any) => {
              return mentors.find((mntr) => mntr.value === String(id.id));
            }).filter((mentor): mentor is OptionType => mentor !== undefined) // Filter out undefined values
          : []
      );
      
      setDefCategories(
        teamResponse.data.categories.length > 0
          ? teamResponse.data.categories.map((id: any) => {
            return categories.find((expr) => expr.value === String(id.id));
          })
          : []
      );
      setProjectManager(
        teamResponse.data.project_managers.length > 0
          ? teamResponse.data.project_managers.map((id: any) => {
            return mentors.find((mntr) => mntr.value === String(id.id));
          })
          : []
      );
    }
  }, [teamResponse, dispatch, clients, mentors, categories]);


  useEffect((): void => {
    if (categoryResponse) {
      if (categoryResponse.data.items.length > 0) {
        const categoryOptions: Array<OptionType> = categoryResponse.data.items.map((item) => {
          return {
            value: item.id,
            label: item.title,
          };
        });
        setCategories(categoryOptions);
      }
    } else {
      dispatch(actions.categories(queryForCategories));
    }
  }, [categoryResponse, dispatch, queryForCategories]);

  useEffect((): void => {
    if (teamError?.response) {
      if (teamError.response.status === 400 || teamError.response.status === 404) {
        dispatch({ type: "TEAM_RESET" });
        history.push("/404");
      }
    } else {
      dispatch(actions.team(id));
    }
  }, [dispatch, id, teamError]);

  useEffect((): void => {
    setFormDisable(isLoading);

    if (error !== null) {
      if (error.response.status === 400) {
        setFormAlert({
          variant: "danger",
          show: true,
          messages: error.response.data.messages,
        });
      } else {
        addToast(t("unknown_error"), {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }

    if (response) {
      addToast(t("updated_with_param", { param: t("mentor_team") }), {
        appearance: "success",
        autoDismiss: true,
      });

      dispatch({ type: "UPDATE_TEAM_RESET" });
      history.push(`/teams/${id}`);
    }
  }, [isLoading, response, addToast, t, id, error, reset, dispatch]);

  useEffect((): void => {
    if (userResponse) {
      const clientOptionsTypeArray: Array<OptionType> = [];
      userResponse.data.items.forEach((item: UserType): void => {
        if (item.roles.includes(UserRoleTypes.user)) {
          clientOptionsTypeArray.push({
            value: item.id,
            label: `${item.name} ${item.lastname} (${item.email})`,
          });
        }
      });
      setClients(clientOptionsTypeArray);

      const mentorOptionsTypeArray: Array<OptionType> = [];
      userResponse.data.items.forEach((item: UserType): void => {
        if (item.roles.includes(UserRoleTypes.mentor) || item.roles.includes(UserRoleTypes.admin))
          mentorOptionsTypeArray.push({
            value: item.id,
            label: `${item.name} ${item.lastname} (${item.email})  ${item.categories.map((category) => category.title).join(", ")}`,
          });
      });
      setMentors(mentorOptionsTypeArray);
    } else {
      dispatch(actions.users(queryForUser));
    }
  }, [userResponse, dispatch, queryForUser]);


  return (
    <MainLayout>
      <LoadingIndicator show={teamIsLoading} />

      {def_name && (
        <>
          <FormAlert variant={formAlert.variant} show={formAlert.show} to={formAlert.to}>
            {formAlert.messages.map((message, key) => (
              <p key={key}>{message}</p>
            ))}
          </FormAlert>

          <Card className={formDisable ? "loading-block" : ""} style={{ marginBottom: 180 }}>
            <Card.Header as="h6">{t("edit")}</Card.Header>
            <Card.Body>
              <UserSelectorModal
                modal={sectorSelectorModal}
                onClose={() => setUserSelectorModal(UserSelectorModalDefaultState)}
                onSubmit={onHandleUserSelect}
              />
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Row>
                  <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-name">
                    <Form.Label>{t("team_name")}:</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      ref={register({
                        required: {
                          value: true,
                          message: t("validation.you_must_enter", { name: t("name") }),
                        },
                      })}
                      placeholder={t("name")}
                      defaultValue={def_name}
                      autoFocus
                      isInvalid={!!errors.name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <hr />
                <hr />
                <Form.Row>
                  <Form.Group as={Col} md="12" controlId="contract-description">
                    <Form.Label>{t("description")}:</Form.Label>
                    <Controller
                      control={control}
                      name="description"
                      ref={register({
                        required: {
                          value: true,
                          message: t("validation.you_must_enter", { name: t("description") }),
                        },
                      })}
                      placeholder={t("description")}
                      defaultValue={def_description}
                      render={({ onChange }) => {
                        return (
                          <WysiwygEditor
                            defaultValue={def_description}
                            onChange={(content: string) => onChange(content)}
                            ref={wysiwygRef}
                          />
                        );
                      }}
                      isInvalid={!!errors.description}
                    />

                    <Form.Control.Feedback type="invalid">{errors.description?.message}</Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>

                <hr />
                <Form.Row>
                  <Form.Group as={Col} md="12">
                    <Form.Label htmlFor="selector-team-lead">
                      {t("team_lead")}
                      <Button
                        variant="outline-success"
                        size="sm"
                        className="with-icon ml-1"
                        title={t("add")}
                        onClick={() => setUserSelectorModal({ show: true, type: "mentor", clients: mentors })}
                      >
                        <RiAddBoxLine />
                      </Button>
                    </Form.Label>
                    <Controller
                      control={control}
                      name="team_lead"
                      defaultValue={def_team_lead}
                      render={({ onChange, value, ref }) => (
                        <>
                          <Select
                            isClearable
                            inputRef={ref}
                            placeholder={t("team_lead")}
                            noOptionsMessage={() => t("not_found")}
                            options={mentors.filter((mentor) => !mentor.label?.includes("Stajyer"))}
                            value={mentors.find((mentor: OptionType): boolean => mentor.value === value)}
                            onChange={(value: any): void => {
                              onChange(value);
                              setTeamLead(value.value);
                            }}
                            className={errors.team_lead ? "is-invalid" : ""}
                          />
                        </>
                      )}
                      rules={{ required: t("validation.you_must_choose", { name: t("team_lead") }) as string }}
                      isInvalid={!!errors.team_lead}
                    />
                    <Form.Control.Feedback type="invalid">{errors.team_lead?.message}</Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <hr />
                <Form.Row>
                  <Form.Group as={Col} md="12">
                    <Form.Label htmlFor="selector-project_managers">
                      {t("interns")}
                      <Button
                        variant="outline-success"
                        size="sm"
                        className="with-icon ml-1"
                        title={t("add")}
                        onClick={() => setUserSelectorModal({ show: true, type: "mentor", clients: mentors })}
                      >
                        <RiAddBoxLine />
                      </Button>
                    </Form.Label>

                    <Controller
                      control={control}
                      name="project_managers"
                      defaultValue={def_project_managers}
                      render={({ onChange, value, ref }) => (
                        <Select
                          isClearable
                          isMulti
                          inputRef={ref}
                          placeholder={t("select_interns")}
                          noOptionsMessage={() => t("not_found")}
                          options={mentors.filter((mentor) => mentor.label?.includes("Stajyer"))}
                          value={def_project_managers}
                          onChange={(value: any): void => setProjectManager(value)}
                        />
                      )}
                      ref={register}
                    />
                  </Form.Group>
                </Form.Row>
                <hr />
                <Form.Row>
                  <Form.Group as={Col} md="12">
                    <Form.Label htmlFor="selector-process-manager">
                      {t("process_manager")}
                      <Button
                        variant="outline-success"
                        size="sm"
                        className="with-icon ml-1"
                        title={t("add")}
                        onClick={() => setUserSelectorModal({ show: true, type: "mentor", clients: mentors })}
                      >
                        <RiAddBoxLine />
                      </Button>
                    </Form.Label>

                    <Controller
                      control={control}
                      name="process_manager"
                      defaultValue={def_process_manager}
                      render={({ onChange, value, ref }) => (
                        <Select
                          isClearable
                          inputRef={ref}
                          placeholder={t("process_manager")}
                          noOptionsMessage={() => t("not_found")}
                          options={mentors.filter((mentor) => !mentor.label?.includes("Stajyer"))}
                          value={mentors.find((client) => client.value === value)}
                          onChange={(value: any) => {
                            onChange(value);
                            setProcessManager(value.value);
                          }}
                        />
                      )}
                      ref={register}
                    />
                  </Form.Group>
                </Form.Row>

                <hr />
                <Form.Row>
                  <Form.Group as={Col} md="12">
                    <Form.Label htmlFor="selector-mentors">
                      {t("mentors")}
                      <Button
                        variant="outline-success"
                        size="sm"
                        className="with-icon ml-1"
                        title={t("add")}
                        onClick={() => setUserSelectorModal({ show: true, type: "mentor", clients: mentors })}
                      >
                        <RiAddBoxLine />
                      </Button>
                    </Form.Label>

                    <Controller
                      control={control}
                      name="mentors"
                      defaultValue={def_mentors}
                      render={({ onChange, value, ref }) => (
                        <Select
                          isMulti
                          isClearable
                          inputRef={ref}
                          placeholder={t("mentors_count")}
                          noOptionsMessage={() => t("not_found")}
                          options={mentors.filter((mentor) => !mentor.label?.includes("Stajyer"))}
                          value={def_mentors}
                          onChange={(value: any): void => setDefMentors(value)}
                        />
                      )}
                      ref={register}
                    />
                  </Form.Group>
                </Form.Row>
                <hr />
                <Form.Row>
                  <Form.Group as={Col} md="12">
                    <Form.Label htmlFor="selector-categories">{t("categories")}</Form.Label>
                    <Controller
                      control={control}
                      name="categories"
                      defaultValue={def_categories.map((id) => ({ value: id }))}
                      render={({ onChange, value, ref }) => (
                        <Select
                          isMulti
                          isClearable
                          inputRef={ref}
                          placeholder={t("categories")}
                          noOptionsMessage={() => t("not_found")}
                          isOptionDisabled={(): boolean => def_categories.length >= 3}
                          options={categories}
                          value={def_categories}
                          onChange={(value: any): void => setDefCategories(value)}
                        />
                      )}
                      ref={register}
                    />
                  </Form.Group>
                </Form.Row>
              </Form>
            </Card.Body>
            <Card.Footer>
              <Row>
                <Col xs="6" className="text-left">
                  <Button as={NavLink} to="/teams" variant="secondary" size="sm" className="with-icon">
                    <RiArrowLeftSLine />
                    <span>{t("list")}</span>
                  </Button>
                </Col>
                <Col xs="6" className="text-right">
                  <Button variant="primary" size="sm" className="with-icon" onClick={handleSubmit(onSubmit)}>
                    <RiCheckFill />
                    <span>{t("save")}</span>
                  </Button>
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        </>
      )}
    </MainLayout>
  );
};

export default Edit;
