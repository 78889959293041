import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { MainLayout } from "../../../layouts";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { RiArrowLeftSLine, RiCheckFill } from "react-icons/all";
import { useForm } from "react-hook-form";
import { RootState } from "../../../../store/reducers";
import { ISectorsState, ISubSectorParams, ISubSectorState } from "../../../../store/types";
import { AlertDefaultState, AlertStateType } from "../../../../types";
import { FormAlert } from "../../../partials";
import actions from "../../../../store/actions";
import history from "../../../../utils/history";

export const Create = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const { register, handleSubmit, errors, reset } = useForm<ISubSectorParams>();
  const onSubmit = async (data: ISubSectorParams) => {
    dispatch(await actions.createSubSector(data));
  };

  const { isLoading, response, error } = useSelector<RootState, ISubSectorState>(
    (state: RootState) => state.createSubSector
  );

  const { response: sectorsResponse } = useSelector<RootState, ISectorsState>((state: RootState) => state.sectors);

  const [formDisable, setFormDisable] = useState<boolean>(false);
  const [formAlert, setFormAlert] = useState<AlertStateType>(AlertDefaultState);

  useEffect(() => {
    if (!sectorsResponse) {
      dispatch(actions.sectors());
    }
  }, [sectorsResponse, dispatch]);

  useEffect(() => {
    setFormDisable(isLoading);

    if (error !== null) {
      if (error.response.status === 400) {
        setFormAlert({
          variant: "danger",
          show: true,
          messages: error.response.data.messages,
        });
      } else {
        addToast(t("unknown_error"), {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }

    if (response) {
      addToast(t("created_with_param", { param: t("sub_sector") }), {
        appearance: "success",
        autoDismiss: true,
      });

      reset();

      dispatch({ type: "CREATE_SUB_SECTOR_RESET" });
      history.push(`/sector-management/sub-sectors/${response.data.id}`);
    }
  }, [isLoading, response, addToast, t, error, reset, dispatch]);

  return (
    <MainLayout>
      <FormAlert variant={formAlert.variant} show={formAlert.show} to={formAlert.to}>
        {formAlert.messages.map((message, key) => {
          return <p key={key}>{message}</p>;
        })}
      </FormAlert>

      <Card className={formDisable ? "loading-block" : ""}>
        <Card.Header as="h6">{t("create")}</Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
              <Form.Group as={Col} md="6" controlId="sub-sector-sector">
                <Form.Label>{t("sector")}:</Form.Label>
                <Form.Control
                  as="select"
                  name="sector"
                  custom
                  ref={register({
                    required: {
                      value: true,
                      message: t("validation.you_must_choose", { name: t("sector") }),
                    },
                  })}
                  placeholder={t("sector")}
                  defaultValue=""
                  autoFocus
                  isInvalid={!!errors.sector}
                >
                  <option value="">{t("select_sector")}</option>
                  {sectorsResponse && sectorsResponse.data.items.length && (
                    <>
                      {sectorsResponse.data.items.map((item) => {
                        return <option value={item.id} key={item.id}>{item.title}</option>;
                      })}
                    </>
                  )}
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.sector?.message}</Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <hr />
            <Form.Row>
              <Form.Group as={Col} md="8" controlId="sub-sector-name">
                <Form.Label>{t("name")}:</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  ref={register({
                    required: {
                      value: true,
                      message: t("validation.you_must_enter", { name: t("name") }),
                    },
                  })}
                  placeholder={t("name")}
                  defaultValue=""
                  autoFocus
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="sub-sector-code">
                <Form.Label>{t("code")}:</Form.Label>
                <Form.Control
                  type="text"
                  name="code"
                  ref={register({
                    required: {
                      value: true,
                      message: t("validation.you_must_enter", { name: t("code") }),
                    },
                  })}
                  placeholder={t("code")}
                  defaultValue=""
                  autoFocus
                  isInvalid={!!errors.code}
                />
                <Form.Control.Feedback type="invalid">{errors.code?.message}</Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
          </Form>
        </Card.Body>
        <Card.Footer>
          <Row>
            <Col xs="6" className="text-left">
              <Button
                as={NavLink}
                to="/sector-management/sub-sectors"
                variant="secondary"
                size="sm"
                className="with-icon"
              >
                <RiArrowLeftSLine />
                <span>{t("list")}</span>
              </Button>
            </Col>
            <Col xs="6" className="text-right">
              <Button variant="primary" size="sm" className="with-icon" onClick={handleSubmit(onSubmit)}>
                <RiCheckFill />
                <span>{t("save")}</span>
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </MainLayout>
  );
};

export default Create;
