import i18n from "../utils/i18n";
import { Dashboard } from "../components/views/dashboard";
import { EditProfile, ShowProfile } from "../components/views/profile";
import { ListPages, CreatePage, ShowPage, EditPage } from "../components/views/pages";
import { ListCategories, CreateCategory, ShowCategory, EditCategory } from "../components/views/categories";
import {
  SectorManagement,
  ListSectors,
  CreateSector,
  ShowSector,
  EditSector,
  ListSubSectors,
  CreateSubSector,
  ShowSubSector,
  EditSubSector,
} from "../components/views/sector-management";
import { ListBlogPosts, CreateBlogPost, ShowBlogPost, EditBlogPost } from "../components/views/blog-posts";
import { ListNews, CreateNews, ShowNews, EditNews } from "../components/views/news";
import { ListContracts, CreateContract, ShowContract, EditContract } from "../components/views/contracts";
import { ListFaqs, CreateFaq, ShowFaq, EditFaq } from "../components/views/faqs";
import { ShowContact, EditContact } from "../components/views/contact";
import { ListPrograms, CreatePrograms, ShowPrograms, UpdateMentoringProgram,MatchProgramShow } from "../components/views/mentor-programs";
import { MatchesList,EditMatch, CreateMatch,MatchReportProgram } from "../components/views/matches";
import { List } from "../components/views/logs";
import { ListSupport, ShowSupport } from "../components/views/supports";
import {
  Applications,
  ListMentorApplications,
  ShowMentorApplication,
  ListUserApplications,
  ShowUserApplication,
} from "../components/views/applications";
import { FeaturedMentors } from "../components/views/featured-mentors";
import { CreateFeedback, ListFeedbacks, ShowFeedback } from "../components/views/feedbacks";
import { ListAppointments, ShowAppointment } from "../components/views/appointments";
import { ListUsers, CreateUser, CreateMentorUser, CreateUserUser, ShowUser, EditUser } from "../components/views/users";
import { ListTeams, CreateTeam, ShowTeam, EditTeam } from "../components/views/teams";
import { AuthLogin, AuthPassword, AuthUpdatePassword } from "../components/views/auth";
import { Error404 } from "../components/views/errors";
import UpdateMatchMentoringProgram from "../components/views/mentor-programs/MatchEdit";

export const routes = [
  {
    path: "/",
    component: Dashboard,
    authRequired: true,
    breadcrumb: i18n.t("dashboard"),
  },
  {
    path: "/profile",
    component: ShowProfile,
    authRequired: true,
    breadcrumb: i18n.t("profile"),
    routes: [
      {
        path: "/edit",
        component: EditProfile,
        authRequired: true,
        breadcrumb: i18n.t("edit"),
      },
    ],
  },
  {
    path: "/pages",
    component: ListPages,
    authRequired: true,
    breadcrumb: i18n.t("pages"),
    routes: [
      {
        path: "/create",
        component: CreatePage,
        authRequired: true,
        breadcrumb: i18n.t("create"),
      },
      {
        path: "/:id",
        component: ShowPage,
        authRequired: true,
        breadcrumb: i18n.t("show"),
      },
      {
        path: "/:id/edit",
        component: EditPage,
        authRequired: true,
        breadcrumb: i18n.t("edit"),
      },
    ],
  },
  {
    path: "/sector-management",
    authRequired: true,
    component: SectorManagement,
    breadcrumb: i18n.t("sector_management"),
    routes: [
      {
        path: "/sectors",
        component: ListSectors,
        authRequired: true,
        breadcrumb: i18n.t("sectors"),
        routes: [
          {
            path: "/create",
            component: CreateSector,
            authRequired: true,
            breadcrumb: i18n.t("create"),
          },
          {
            path: "/:id",
            component: ShowSector,
            authRequired: true,
            breadcrumb: i18n.t("show"),
          },
          {
            path: "/:id/edit",
            component: EditSector,
            authRequired: true,
            breadcrumb: i18n.t("edit"),
          },
        ],
      },
      {
        path: "/sub-sectors",
        component: ListSubSectors,
        authRequired: true,
        breadcrumb: i18n.t("sub_sectors"),
        routes: [
          {
            path: "/create",
            component: CreateSubSector,
            authRequired: true,
            breadcrumb: i18n.t("create"),
          },
          {
            path: "/:id",
            component: ShowSubSector,
            authRequired: true,
            breadcrumb: i18n.t("show"),
          },
          {
            path: "/:id/edit",
            component: EditSubSector,
            authRequired: true,
            breadcrumb: i18n.t("edit"),
          },
        ],
      },
      {
        path: "/categories",
        component: ListCategories,
        authRequired: true,
        breadcrumb: i18n.t("categories"),
        routes: [
          {
            path: "/create",
            component: CreateCategory,
            authRequired: true,
            breadcrumb: i18n.t("create"),
          },
          {
            path: "/:id",
            component: ShowCategory,
            authRequired: true,
            breadcrumb: i18n.t("show"),
          },
          {
            path: "/:id/edit",
            component: EditCategory,
            authRequired: true,
            breadcrumb: i18n.t("edit"),
          },
        ],
      },
    ],
  },
  {
    path: "/blog-posts",
    component: ListBlogPosts,
    authRequired: true,
    breadcrumb: i18n.t("blog_posts"),
    routes: [
      {
        path: "/create",
        component: CreateBlogPost,
        authRequired: true,
        breadcrumb: i18n.t("create"),
      },
      {
        path: "/:id",
        component: ShowBlogPost,
        authRequired: true,
        breadcrumb: i18n.t("show"),
      },
      {
        path: "/:id/edit",
        component: EditBlogPost,
        authRequired: true,
        breadcrumb: i18n.t("edit"),
      },
    ],
  },
  {
    path: "/news",
    component: ListNews,
    authRequired: true,
    breadcrumb: i18n.t("news"),
    routes: [
      {
        path: "/create",
        component: CreateNews,
        authRequired: true,
        breadcrumb: i18n.t("create"),
      },
      {
        path: "/:id",
        component: ShowNews,
        authRequired: true,
        breadcrumb: i18n.t("show"),
      },
      {
        path: "/:id/edit",
        component: EditNews,
        authRequired: true,
        breadcrumb: i18n.t("edit"),
      },
    ],
  },
  {
    path: "/contracts",
    component: ListContracts,
    authRequired: true,
    breadcrumb: i18n.t("contracts"),
    routes: [
      {
        path: "/create",
        component: CreateContract,
        authRequired: true,
        breadcrumb: i18n.t("create"),
      },
      {
        path: "/:id",
        component: ShowContract,
        authRequired: true,
        breadcrumb: i18n.t("show"),
      },
      {
        path: "/:id/edit",
        component: EditContract,
        authRequired: true,
        breadcrumb: i18n.t("edit"),
      },
    ],
  },
  {
    path: "/faqs",
    component: ListFaqs,
    authRequired: true,
    breadcrumb: i18n.t("faqs"),
    routes: [
      {
        path: "/create",
        component: CreateFaq,
        authRequired: true,
        breadcrumb: i18n.t("create"),
      },
      {
        path: "/:id",
        component: ShowFaq,
        authRequired: true,
        breadcrumb: i18n.t("show"),
      },
      {
        path: "/:id/edit",
        component: EditFaq,
        authRequired: true,
        breadcrumb: i18n.t("edit"),
      },
    ],
  },
  {
    path: "/contact",
    component: ShowContact,
    authRequired: true,
    breadcrumb: i18n.t("contact_information"),
    routes: [
      {
        path: "/edit",
        component: EditContact,
        authRequired: true,
        breadcrumb: i18n.t("edit"),
      },
    ],
  },
  {
    path: "/applications",
    authRequired: true,
    component: Applications,
    breadcrumb: i18n.t("applications"),
    routes: [
      {
        path: "/mentor",
        component: ListMentorApplications,
        authRequired: true,
        breadcrumb: i18n.t("mentor_applications"),
        routes: [
          {
            path: "/:id",
            component: ShowMentorApplication,
            authRequired: true,
            breadcrumb: i18n.t("show"),
          },
        ],
      },
      {
        path: "/user",
        component: ListUserApplications,
        authRequired: true,
        breadcrumb: i18n.t("user_applications"),
        routes: [
          {
            path: "/:id",
            component: ShowUserApplication,
            authRequired: true,
            breadcrumb: i18n.t("show"),
          },
        ],
      },
    ],
  },
  {
    path: "/featured-mentors",
    component: FeaturedMentors,
    authRequired: true,
    breadcrumb: i18n.t("featured"),
  },
  {
    path: "/feedbacks",
    breadcrumb: i18n.t("feedbacks"),
    authRequired: true,
    component: ListFeedbacks,
    routes: [
      {
        path: "/create",
        component: CreateFeedback,
        authRequired: true,
        breadcrumb: i18n.t("create"),
      },
      {
        path: "/:id",
        component: ShowFeedback,
        authRequired: true,
        breadcrumb: i18n.t("show"),
      },
    ],
  },
  {
    path: "/appointments",
    component: ListAppointments,
    authRequired: true,
    breadcrumb: i18n.t("appointments"),
    routes: [
      {
        path: "/:id",
        component: ShowAppointment,
        authRequired: true,
        breadcrumb: i18n.t("show"),
      },
    ],
  },
  {
    path: "/users",
    component: ListUsers,
    authRequired: true,
    breadcrumb: i18n.t("users"),
    routes: [
      {
        path: "/create-mentor",
        component: CreateMentorUser,
        authRequired: true,
        breadcrumb: i18n.t("create_mentor"),
      },
      {
        path: "/create-user",
        component: CreateUserUser,
        authRequired: true,
        breadcrumb: i18n.t("create_user"),
      },
      {
        path: "/create",
        component: CreateUser,
        authRequired: true,
        breadcrumb: i18n.t("create"),
      },
      {
        path: "/:id",
        component: ShowUser,
        authRequired: true,
        breadcrumb: i18n.t("show"),
      },
      {
        path: "/:id/edit",
        component: EditUser,
        authRequired: true,
        breadcrumb: i18n.t("edit"),
      },
    ],
  },
  {
    path: "/teams",
    component: ListTeams,
    authRequired: true,
    breadcrumb: i18n.t("mentor_teams"),
    routes: [
      {
        path: "/create",
        component: CreateTeam,
        authRequired: true,
        breadcrumb: i18n.t("create"),
      },
      {
        path: "/:id",
        component: ShowTeam,
        authRequired: true,
        breadcrumb: i18n.t("show"),
      },
      {
        path: "/:id/edit",
        component: EditTeam,
        authRequired: true,
        breadcrumb: i18n.t("edit"),
      },
    ],
  },
  {
    path :"/mentoring_programs",
    component: ListPrograms,
    authRequired: true,
    breadcrumb: i18n.t("Rehberlik Programları"),
    routes: [
      {
        path: "/create",
        component: CreatePrograms,
        authRequired: true,
        breadcrumb: i18n.t("create"),
      },
      {
        path: "/:id",
        component: ShowPrograms,
        authRequired: true,
        breadcrumb: i18n.t("show"),
      },
      {
        path: "/:id/edit",
        component: UpdateMentoringProgram,
        authRequired: true,
        breadcrumb: i18n.t("edit"),
      },
      {
        path: "/:id/match",
        component: MatchProgramShow,
        authRequired: true,
        breadcrumb: i18n.t("matches"),
      },
      {
        path: "/:id/match/edit",
        component: UpdateMatchMentoringProgram,
        authRequired: true,
        breadcrumb: i18n.t("edit"),
      }
    
    ],

  },
  {
    path:"/matches",
    component: MatchesList,
    authRequired: true,
    breadcrumb: i18n.t("matches"),
    routes: [
      {
        path: "/:id/edit",
        component: EditMatch,
        authRequired: true,
        breadcrumb: i18n.t("edit"),
      },
      {
        path: "/create",
        component: CreateMatch,
        authRequired: true,
        breadcrumb: i18n.t("create"),
      },
      {
        path: "/:id/program/:cid/report",
        component: MatchReportProgram,
        authRequired: true,
        breadcrumb: i18n.t("create"),
      }
    ],
  },
  {
    path: "/logs",
    breadcrumb: i18n.t("logs"),
    authRequired: true,
    component: List,
  },
  {
    path: "/support",
    breadcrumb: i18n.t("support"),
    authRequired: true,
    component: ListSupport,
    routes: [
      {
        path: "/:id",
        component: ShowSupport,
        authRequired: true,
        breadcrumb: i18n.t("show"),
      },
    ],
  },
  {
    path: "/auth",
    breadcrumb: "Auth",
    authRequired: false,
    routes: [
      {
        path: "/login",
        component: AuthLogin,
        authRequired: false,
        breadcrumb: i18n.t("login"),
      },
      {
        path: "/password",
        component: AuthPassword,
        authRequired: false,
        breadcrumb: i18n.t("lost_password"),
      },
      {
        path: "/password/:token",
        component: AuthUpdatePassword,
        authRequired: false,
        breadcrumb: i18n.t("lost_password_update"),
      },
    ],
  },
  {
    path: "*",
    component: Error404,
  },
];

export default routes;
