import { Reducer } from "react";
import {
  IFeaturedMentorsState,
  FeaturedMentorsActionType,
  IFeaturedMentorState,
  UpdateFeaturedMentorActionType,
} from "../types";

// Featured users
const featuredMentorsInitialState: IFeaturedMentorsState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const featuredMentorsReducer: Reducer<IFeaturedMentorsState, FeaturedMentorsActionType> = (
  state = featuredMentorsInitialState,
  action
): IFeaturedMentorsState => {
  switch (action.type) {
    case "FEATURED_MENTORS_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "FEATURED_MENTORS_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "FEATURED_MENTORS_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "FEATURED_MENTORS_RESET":
      return featuredMentorsInitialState;
    default:
      return state;
  }
};

const featuredMentorInitialState: IFeaturedMentorState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const updateFeaturedMentorReducer: Reducer<IFeaturedMentorState, UpdateFeaturedMentorActionType> = (
  state = featuredMentorInitialState,
  action
): IFeaturedMentorState => {
  switch (action.type) {
    case "UPDATE_FEATURED_MENTOR_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "UPDATE_FEATURED_MENTOR_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "UPDATE_FEATURED_MENTOR_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "UPDATE_FEATURED_MENTOR_RESET":
      return featuredMentorInitialState;
    default:
      return state;
  }
};
