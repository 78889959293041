import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { MainLayout } from "../../layouts";
import moment from "moment";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { NavLink, useParams, useLocation } from "react-router-dom";
import { RiArrowLeftSLine, RiCheckFill } from "react-icons/all";
import { Controller, useForm } from "react-hook-form";
import { RootState } from "../../../store/reducers";
import { AlertDefaultState, AlertStateType } from "../../../types";
import { FormAlert, LoadingIndicator } from "../../partials";
import actions from "../../../store/actions";
import history from "../../../utils/history";
import Select from "react-select";
import { IMatchState, IMatchParams, IMentoringProgramsState, IUsersState, UserType, IUsersPaginationParams } from "../../../store/types";
import { UserRoleTypes } from "../../../types";

type RouterParamsType = {
    tid: string;
};
export type OptionType = {
    value: string;
    label: string | null;
};

export const EditMatch = () => {
    const location = useLocation();
    const pairId = (location.state as { pairId: string }).pairId;
    const itemId = (location.state as { itemId: string }).itemId;
    const [start_date, setStartDate] = useState<string>("");
    const [end_date, setEndDate] = useState<string>("");
    const [queryForUser] = useState<IUsersPaginationParams>({ page: 1, per: 9999 });
    const [clients, setclients] = useState<Array<OptionType>>([]);
    const [mentoring_program_title, setProgram] = useState<Array<OptionType>>([]);
    const [programs, setPrograms] = useState<Array<OptionType>>([]);
    const [filteredPrograms, setFilteredPrograms] = useState<Array<OptionType>>([]);
    const [def_clients, setDefclients] = useState<Array<OptionType>>([]);
    const { response: programsResponse } = useSelector<RootState, IMentoringProgramsState>((state: RootState) => state.mentoringPrograms);
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const { response: userResponse } = useSelector<RootState, IUsersState>((state: RootState) => state.users);
    const { isLoading, response, error } = useSelector<RootState, IMatchState>(state => state.updateMatch);
    const dispatch = useDispatch();
    const { handleSubmit, control, reset } = useForm<IMatchParams>();
    const [formAlert, setFormAlert] = useState<AlertStateType>(AlertDefaultState);
    const [hasDispatched, setHasDispatched] = useState(false);
    const [formDisable, setFormDisable] = useState<boolean>(false);
    const {
        isLoading: matchIsLoading,
        response: matchResponse,
        error: matchError,
    } = useSelector<RootState, IMatchState>((state: RootState) => state.match);
    const onSubmit = (data: IMatchParams) => {
        data.mentoring_programs = mentoring_program_title.map((program) => program.value);
        data.start_at = start_date;
        data.end_at = end_date;
        data.clients = def_clients.map((client) => client.value);
        dispatch(actions.updateMatch(itemId, pairId, data));
        dispatch({ type: "UPDATE_MATCH_RESET" });
    };
    useEffect(() => {
        setFormDisable(isLoading);
        if (error !== null) {
            if (error.response.status === 400) {
                setFormAlert({
                    variant: "danger",
                    show: true,
                    messages: error.response.data.messages,
                });
            } else {
                addToast(t("unknown_error"), {
                    appearance: "error",
                    autoDismiss: true,
                });
            }
        }
        if (response) {
            addToast(t("updated_with_param", { param: t("match") }), {
                appearance: "success",
                autoDismiss: true,
            });

            dispatch({ type: "UPDATE_MATCH_RESET" });
            history.push(`/matches`);
        }
    }, [isLoading, addToast, t, response, error, reset, dispatch]);
    useEffect((): void => {
        if (programsResponse) {
            if (programsResponse.data.items.length > 10) {
                if(!hasDispatched) {
                    dispatch(actions.mentoringPrograms({page: 1, per: programsResponse.data.items.length}));
                    setHasDispatched(true);
                }
                const programOptions: Array<OptionType> = programsResponse.data.items.map((item) => ({
                    value: item.id,
                    label: item.title,
                }));
                setPrograms(programOptions);
            }
        } else {
            dispatch(actions.mentoringPrograms({ page: 1, per: 999 }));
            setHasDispatched(true);
        }
    }
        , [programsResponse, dispatch, hasDispatched]);


    useEffect(() => {
        dispatch(actions.Match(itemId, pairId));
    }, [dispatch]);
    useEffect(() => {
        if (matchResponse && matchResponse.data) {
            const startDateString = moment(matchResponse.data.start_at).format('YYYY-MM-DDTHH:mm');
            setStartDate(startDateString);
            const endDateString = moment(matchResponse.data.end_at).format('YYYY-MM-DDTHH:mm');
            setEndDate(endDateString);
            setDefclients(
                matchResponse.data.clients.map((client: UserType) => ({
                    value: client.id,
                    label: `${client.name} ${client.lastname} (${client.email})`,
                }))
            );
            if (Array.isArray(matchResponse.data.mentoring_programs) && matchResponse.data.mentoring_programs.length > 0) {
                const programsData = matchResponse.data.mentoring_programs.map(program => ({
                    value: program.id,
                    label: program.title
                }));
                setProgram(programsData);
            } else {
                setProgram([]);
            }
        }
    }, [matchResponse]);
    useEffect(() => {
        dispatch({ type: 'USERS_RESET' });
    }, [dispatch]);

    useEffect(() => {
        const filtered = programs.filter(program =>
            !mentoring_program_title.some(selectedProgram => selectedProgram.label === program.label)
        );
        setFilteredPrograms(filtered);
    }, [mentoring_program_title, programs]);


    useEffect((): void => {
        if (userResponse) {
            const clientOptionsTypeArray: Array<OptionType> = [];
            userResponse.data.items.forEach((item: UserType): void => {
                if (item.roles.includes(UserRoleTypes.user)) {
                    
                    clientOptionsTypeArray.push({
                        value: item.id,
                        label: `${item.name} ${item.lastname} (${item.email})`,
                    });
                }
            });
            setclients(clientOptionsTypeArray);
        }
        else {
            dispatch(actions.users(queryForUser));
        }
    }, [userResponse]);
        
    return (
        <MainLayout>
            <LoadingIndicator show={isLoading} />
            <Card className={formDisable ? "loading-block" : ""} style={{ marginBottom: 180 }}>
                <Card.Header as="h6" >{t("edit")}</Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Row >
                            <Form.Group as={Col} md="8">
                                <Form.Label>
                                    {t("mentoring_program")}
                                </Form.Label>
                                <Controller
                                    name="mentoring_programs"
                                    control={control}
                                    defaultValue={mentoring_program_title}
                                    render={({ onChange, value, ref }) => (
                                        <Select
                                            isMulti
                                            isClearable
                                            inputRef={ref}
                                            noOptionsMessage={() => t("not_found")}
                                            options={filteredPrograms}
                                            value={mentoring_program_title}
                                            onChange={(selectedOptions: any): void => {
                                                const updatedPrograms = selectedOptions ? selectedOptions : [];
                                                const mentoring_program_title_labels = mentoring_program_title.map((program) => program.label);
                                                if (selectedOptions) {
                                                    selectedOptions.forEach((selectedProgram: any) => {
                                                        if (!mentoring_program_title_labels.includes(selectedProgram.label)) {
                                                            mentoring_program_title.push(selectedProgram);
                                                        }
                                                    });
                                                }
                                                setProgram(updatedPrograms);
                                                onChange(updatedPrograms);
                                            }}
                                        />
                                    )}
                                />
                            </Form.Group>

                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="8">
                                <Form.Label>
                                    {t("start_date")}
                                </Form.Label>
                                <Form.Control
                                    type="datetime-local"
                                    value={start_date}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="8">
                                <Form.Label>
                                    {t("end_date")}
                                </Form.Label>
                                <Form.Control
                                    type="datetime-local"
                                    value={end_date}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="8">
                                <Form.Label htmlFor="selector-clients">
                                    {t("client_s")}
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="clients"
                                    defaultValue={def_clients}
                                    render={({ onChange, value, ref }) => (
                                        <Select
                                            isMulti
                                            isClearable
                                            inputRef={ref}
                                            placeholder={t("select_client")}
                                            noOptionsMessage={() => t("not_found")}
                                            options={clients}
                                            value={def_clients}
                                            onChange={(value: any): void => setDefclients(value)}
                                        />
                                    )}
                                />
                            </Form.Group>
                        </Form.Row>
                    </Form>
                </Card.Body>
                <Card.Footer>
                    <Row>
                        <Col xs="6" className="text-left">
                            <Button as={NavLink} to="/matches" variant="secondary" size="sm" className="with-icon">
                                <RiArrowLeftSLine />
                                <span>{t("list")}</span>
                            </Button>
                        </Col>
                        <Col xs="6" className="text-right">
                            <Button variant="primary" size="sm" className="with-icon" onClick={handleSubmit(onSubmit)}>
                                <RiCheckFill />
                                <span>{t("save")}</span>
                            </Button>
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
        </MainLayout>
    )
}
export default EditMatch;