import React from "react";
import { AppRouter } from "../router";
import { routes } from "../config";
import history from "../utils/history";

const App = () => {
  return <AppRouter routes={routes} history={history} />;
};

export default App;
