import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { MainLayout } from "../../layouts";
import { Button, ButtonGroup, Card, Col, Form, Row, Table } from "react-bootstrap";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { RiAddBoxLine, RiDeleteBin6Line, RiEditBoxLine, RiEyeLine } from "react-icons/all";
import Moment from "react-moment";
import { RootState } from "../../../store/reducers";
import { ICategoriesPaginationParams, ICategoriesState, ICategoryState } from "../../../store/types";
import { DeleteConfirmModal, LoadingIndicator, Pagination, RecordNotFound } from "../../partials";
import actions from "../../../store/actions";
import { DEFAULT_PER_PAGE } from "../../../types";
import qs from "query-string";

export const List = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const filterQRef = useRef<HTMLInputElement>(null);
  const [isOrdering, setIsOrdering] = useState<boolean>(false);

  const { isLoading, response, error } = useSelector<RootState, ICategoriesState>(
    (state: RootState) => state.categories
  );

  const { response: deleteResponse } = useSelector<RootState, ICategoriesState>(
    (state: RootState) => state.deleteCategory
  );

  const { response: orderResponse, error: orderError } = useSelector<RootState, ICategoryState>(
    (state: RootState) => state.orderCategory
  );

  const getCategories = useCallback(
    (queryParams?) => {
      dispatch(actions.categories(queryParams));
    },
    [dispatch]
  );

  const queryString = new URLSearchParams(useLocation().search);
  const page: number = queryString.get("page") ? Number(queryString.get("page")) : 1;
  const q: string | null = queryString.get("q") ? queryString.get("q") : null;

  const [queryParams, setQueryParams] = useState<ICategoriesPaginationParams>({ page: page, per: DEFAULT_PER_PAGE });
  const qParams = useMemo<ICategoriesPaginationParams>(() => qs.parse(search), [search]);
  useEffect(() => {
    const params = qParams;
    params.per = DEFAULT_PER_PAGE;
    setQueryParams(params);
    getCategories(params);
  }, [getCategories, qParams]);

  const handleSubmitFilter = () => {
    let url = `/sector-management/categories?page=1`;
    const searchParams: Array<string> = [];
    if (filterQRef.current!.value) {
      searchParams.push(`q=${filterQRef.current!.value}`);
      queryParams.q = filterQRef.current!.value;
    }

    if (searchParams.length) {
      url += "&" + searchParams.join("&");
    }

    getCategories(queryParams);

    history.push(url);
  };

  const [deleteItem, setDeleteItem] = useState<string | null>(null);
  const onDeleteConfirm = async () => {
    if (deleteItem) {
      dispatch(await actions.deleteCategory(deleteItem));
      setDeleteItem(null);
    }
  };

  useEffect(() => {
    if (deleteResponse) {
      addToast(t("deleted_with_param", { param: t("category") }), {
        appearance: "success",
        autoDismiss: true,
      });

      getCategories({ page, per: DEFAULT_PER_PAGE, q });
      dispatch({ type: "DELETE_CATEGORY_RESET" });
    }
  }, [deleteResponse, addToast, t, getCategories, page, q, dispatch]);

  useEffect(() => {
    if (error?.response) {
      dispatch({ type: "CATEGORIES_RESET" });

      if (error.response.status >= 400) {
        history.push("/sector-management/categories");
      }
    } else {
      getCategories({ page, per: DEFAULT_PER_PAGE, q });
    }
  }, [getCategories, page, q, error, history, dispatch]);

  useEffect(() => {
    if (orderResponse || orderError) {
      setIsOrdering(false);
      dispatch({ type: "ORDER_CATEGORY_RESET" });
      getCategories(queryParams);
    }
  }, [orderResponse, orderError, queryParams, dispatch, getCategories]);

  return (
    <MainLayout>
      <LoadingIndicator show={isLoading || isOrdering} />
      {response && !isOrdering && (
        <Row>
          <Col md="9" className="order-last order-md-first">
            <Card>
              <Button
                as={NavLink}
                to="/sector-management/categories/create"
                variant="success"
                size="sm"
                className="with-icon"
              >
                <RiAddBoxLine />
                <span>{t("create")}</span>
              </Button>
              <Card.Header>
                <Row>
                  <Col as="h6" xs="8" className="mb-0">
                    {t("categories")}
                  </Col>
                  <Col xs="4" className="mb-0 text-right">
                    <strong className="mr-1">{t("total")}:</strong>
                    <span>{response.data.pagination.total}</span>
                  </Col>
                </Row>
              </Card.Header>

              <Card.Body className="p-0 m-0">
                {response.data.items.length > 0 ? (
                  <>
                    <DeleteConfirmModal
                      show={deleteItem !== null}
                      onClose={() => setDeleteItem(null)}
                      onConfirm={onDeleteConfirm}
                    />
                    <Table responsive striped hover className="table-list m-0">
                      <thead>
                        <tr>
                          <th>{t("title")}</th>
                          {/* <th className="text-center">{t('order')}</th> */}
                          <th className="text-center">{t("     ")}</th>
                          <th className="date">{t("created_at")}</th>
                          <th className="actions">{t("actions")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {response.data.items.map((item, key) => {
                          return (
                            <tr key={key}>
                              <td>{item.title}</td>
                              <td className="text-center">
                                {/* <ButtonGroup aria-label={t('order')} size="sm">
                                  <Button variant="outline-secondary"
                                    onClick={() => handleOrder({ id: item.id, sort: 'up' })}>
                                    <RiArrowUpFill />
                                  </Button>
                                  <Button variant="outline-secondary"
                                    onClick={() => handleOrder({ id: item.id, sort: 'down' })}>
                                    <RiArrowDownFill />
                                  </Button>
                                </ButtonGroup> */}
                              </td>
                              <td className="date">
                                <Moment format="DD/MM/YYYY HH:mm:ss">{item.created_at}</Moment>
                              </td>
                              <td className="actions">
                                <ButtonGroup>
                                  <Button
                                    as={NavLink}
                                    to={`/sector-management/categories/${item.id}`}
                                    variant="outline-info"
                                    size={"sm"}
                                    className="btn-icon"
                                    title={t("show")}
                                  >
                                    <RiEyeLine />
                                  </Button>
                                  <Button
                                    as={NavLink}
                                    to={`/sector-management/categories/${item.id}/edit`}
                                    variant="outline-secondary"
                                    size={"sm"}
                                    className="btn-icon"
                                    title={t("edit")}
                                  >
                                    <RiEditBoxLine />
                                  </Button>
                                  <Button
                                    variant="outline-danger"
                                    size={"sm"}
                                    className="btn-icon"
                                    title={t("delete")}
                                    onClick={() => setDeleteItem(item.id)}
                                  >
                                    <RiDeleteBin6Line />
                                  </Button>
                                </ButtonGroup>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </>
                ) : (
                  <RecordNotFound />
                )}
              </Card.Body>

              <Card.Footer>
                <Row>
                  <Col xs="6">
                    <Pagination pagination={response.data.pagination} extraParams={["q"]} />
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Col>
          <Col md="3" className="order-first order-md-last">
            <Card>
              <Card.Body>
                <Form onSubmit={handleSubmitFilter}>
                  <Form.Group controlId="filter-q">
                    <Form.Label>{t("keywords")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="q"
                      ref={filterQRef}
                      placeholder={t("keywords")}
                      defaultValue={q ? q : ""}
                    />
                  </Form.Group>
                  <hr />
                  <Button variant="outline-secondary" block onClick={handleSubmitFilter}>
                    {t("filter")}
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </MainLayout>
  );
};

export default List;
