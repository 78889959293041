import React, { useCallback, useEffect, useState, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { MainLayout } from "../../layouts";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import qs from "query-string";
import { Button, ButtonGroup, Card, Col, Form, Row, Table } from "react-bootstrap";
import { RootState } from "../../../store/reducers";
import { ISupportListState, ISupportListPaginationParams } from "../../../store/types";
import { LoadingIndicator, Pagination, RecordNotFound } from "../../partials";
import { RiEyeLine } from "react-icons/all";
import Moment from "react-moment";
import actions from "../../../store/actions";
import { DEFAULT_PER_PAGE, SupportReadTypes } from "../../../types";

const List = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const filterSenderRef = useRef<HTMLInputElement>(null);
  const filterIsReadRef = useRef<HTMLSelectElement>(null);
  const filterQRef = useRef<HTMLInputElement>(null);

  const { isLoading, response, error } = useSelector<RootState, ISupportListState>(
    (state: RootState) => state.supports
  );

  const queryString = new URLSearchParams(useLocation().search);
  const page: number = queryString.get("page") ? Number(queryString.get("page")) : 1;
  const sender: string | null = queryString.get("sender") ? queryString.get("sender") : null;
  const is_read: string | null = queryString.get("is_read") ? queryString.get("is_read") : null;
  const q: string | null = queryString.get("q") ? queryString.get("q") : null;

  const [queryParams, setQueryParams] = useState<ISupportListPaginationParams>({
    page: page,
    per: DEFAULT_PER_PAGE,
  });
  const qParams = useMemo<ISupportListPaginationParams>(() => qs.parse(search), [search]);

  const getSupport = useCallback(
    (queryParams?) => {
      dispatch(actions.supportList(queryParams));
    },
    [dispatch]
  );

  useEffect(() => {
    const params = qParams;
    params.per = DEFAULT_PER_PAGE;
    setQueryParams(params);
    getSupport(params);
  }, [getSupport, qParams]);

  const handleSubmitFilter = () => {
    let url = `/support?page=1`;
    if (filterSenderRef.current!.value) {
      url += `&sender=${filterSenderRef.current!.value}`;
      queryParams.sender = filterSenderRef.current!.value;
    }
    if (filterIsReadRef.current!.value) {
      url += `&is_read=${filterIsReadRef.current!.value}`;
      queryParams.is_read = filterIsReadRef.current!.value;
    }
    if (filterQRef.current!.value) {
      url += `&q=${filterQRef.current!.value}`;
      queryParams.q = filterQRef.current!.value;
    }
    getSupport(queryParams);

    history.push(url);
  };

  useEffect(() => {
    if (error?.response) {
      dispatch({ type: "SUPPORT_LIST_RESET" });

      if (error.response.status >= 400) {
        history.push("/support");
      }
    }

    if (!response && !isLoading) {
      getSupport(queryParams);
    }
  }, [getSupport, response, isLoading, queryParams, page, is_read, sender, error, history, dispatch]);

  return (
    <MainLayout>
      <LoadingIndicator show={isLoading} />
      {response && (
        <Row>
          <Col md="9" className="order-last order-md-first">
            <Card>
              <Card.Header>
                <Row>
                  <Col as="h6" xs="8" className="mb-0">
                    {t("support")}
                  </Col>
                  <Col xs="4" className="mb-0 text-right">
                    <strong className="mr-1">{t("total")}:</strong>
                    <span>{response.data.pagination.total}</span>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="p-0 m-0">
                {response.data.items.length > 0 ? (
                  <Table responsive striped hover className="table-list m-0">
                    <thead>
                      <tr>
                        <th>{t("title")}</th>
                        <th>{t("content")}</th>
                        <th>{t("sender")}</th>
                        <th className="text-center">{t("is_read_status")}</th>
                        <th className="date">{t("created_at")}</th>
                        <th className="actions">{t("actions")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {response.data.items.map((item, key) => {
                        return (
                          <tr key={key}>
                            <td>{item.title}</td>
                            <td>{item.content}</td>
                            <td>{`${item.sender?.name} ${item.sender?.lastname}`}</td>
                            <td className="text-center">{t(`support_is_read.${item.is_read}`)}</td>
                            <td className="date">
                              <Moment format="DD/MM/YYYY HH:mm:ss">{item.created_at}</Moment>
                            </td>
                            <td className="actions">
                              <ButtonGroup>
                                <Button
                                  as={NavLink}
                                  to={`/support/${item.id}`}
                                  variant="outline-info"
                                  size={"sm"}
                                  className="btn-icon"
                                  title={t("show")}
                                >
                                  <RiEyeLine />
                                </Button>
                              </ButtonGroup>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                ) : (
                  <RecordNotFound />
                )}
              </Card.Body>
              <Card.Footer>
                <Row>
                  <Col xs="6">
                    <Pagination pagination={response.data.pagination} extraParams={["is_read", "sender", "q"]} />
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Col>
          <Col md="3" className="order-last order-md-last">
            <Card>
              <Card.Body>
                <Form onSubmit={handleSubmitFilter}>
                  <Form.Group controlId="method-type">
                    <Form.Label>{t("type")}</Form.Label>
                    <Form.Control as="select" custom ref={filterIsReadRef} defaultValue={is_read ? is_read : ""}>
                      <option value="">{t("all")}</option>
                      {Object.keys(SupportReadTypes).map((key) => {
                        return (
                          <option value={key} key={key}>
                            {t("support_is_read." + key)}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="email-type">
                    <Form.Label> {t("sender")} </Form.Label>
                    <Form.Control
                      as="input"
                      onKeyPress={(e: any) => e.code === "Enter" && handleSubmitFilter()}
                      type="text"
                      placeholder={t("enter_name")}
                      ref={filterSenderRef}
                      defaultValue={sender ? sender : ""}
                    />
                  </Form.Group>
                  <Form.Group controlId="filter-q">
                    <Form.Label>{t("keywords")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="q"
                      ref={filterQRef}
                      onKeyPress={(e: any) => e.code === "Enter" && handleSubmitFilter()}
                      placeholder={t("keywords")}
                      defaultValue={q ? q : ""}
                    />
                  </Form.Group>
                  <hr />
                  <Button onClick={handleSubmitFilter} variant="outline-secondary" block>
                    {t("filter")}
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </MainLayout>
  );
};

export default List;
