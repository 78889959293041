import { Dispatch } from "redux";
import { AdminApiClient } from "../../utils/http-client";
import { ISupportListPaginationParams } from "../types";

const adminApiClient = AdminApiClient.getInstance();

export const supportList = (queryParams?: ISupportListPaginationParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "SUPPORT_LIST_START" });
  await adminApiClient
    .supportList(queryParams)
    .then((response) => {
      dispatch({ type: "SUPPORT_LIST_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "SUPPORT_LIST_FAILURE", payload: error }));
};

export const support = (id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: "SUPPORT_START" });
  await adminApiClient
    .support(id)
    .then((response) => {
      dispatch({ type: "SUPPORT_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "SUPPORT_FAILURE", payload: error }));
};
