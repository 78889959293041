import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FollowedTaskType } from "../../../store/types";

type FollowedTaskDetailModalPropsType = {
    modal: FollowedTaskDetailModalType;
    onClose: () => void;
};

export type FollowedTaskDetailModalType = {
    show: boolean;
    session: FollowedTaskType | null;
};

export const FollowedTaskModalDefaultState: FollowedTaskDetailModalType = {
    show: false,
    session: null,
};

export const FollowedTaskModal = (props: FollowedTaskDetailModalPropsType) => {
    const { t } = useTranslation();
    const [show, setShow] = useState<boolean>(props.modal.show);

    useEffect(() => {
        setShow(props.modal.show);
    }, [props.modal.show]);

    const handleClose = (): void => {
        props.onClose();
    };

    return (
        <Modal centered
            show={show}
            onHide={handleClose}
            animation={false}
        >

            <Modal.Header closeButton>
                <Modal.Title as="h6">{props.modal.session?.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <h6>{t("title")}</h6>
                    <p>{props.modal.session?.title}</p>
                </div>
                <div>
                    <h6>{t("task_order")}:</h6>
                    <p>{props.modal.session?.steps}</p>
                </div>
                <hr />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t("Close")}
                </Button>
            </Modal.Footer>
        </Modal>
    )
};