import { Reducer } from "react";
import { CreateMatchActionType, DeleteMatchActionType, IMatchState, MatchesActionType, UpdateMatchActionType } from "../types";
export const matchesInitialState:IMatchState = {
    isLoading: false,
    response: null,
    error: null,
};

/**
 * @param state
 * @param action
 */

export const matchReducer: Reducer<IMatchState, MatchesActionType> = (
    state = matchesInitialState, 
    action
): IMatchState => {
    switch (action.type) {
        case "MATCH_START":
            return { ...state, isLoading: true, response: null, error: null };        
        case "MATCH_SUCCESS":
            return { ...state, isLoading: false, response: action.payload.data };
        case "MATCH_FAILURE":
            return { ...state, isLoading: false, error: action.payload };
        case "MATCH_RESET":
            return matchesInitialState;
        default:
            return state;
    }
};

export const updateMatchReducer: Reducer<IMatchState, UpdateMatchActionType> = (
    state = matchesInitialState, 
    action
): IMatchState => {
    switch (action.type) {
        case "UPDATE_MATCH_START":
            return { ...state, isLoading: true, response: null, error: null };
        case "UPDATE_MATCH_SUCCESS":
            return { ...state, isLoading: false, response: action.payload.data };
        case "UPDATE_MATCH_FAILURE":
            return { ...state, isLoading: false, error: action.payload };
        case "UPDATE_MATCH_RESET":
            return matchesInitialState;
        default:
            return state;
    }
};

export const createMatchReducer: Reducer<IMatchState, CreateMatchActionType> = (
    state = matchesInitialState, 
    action
): IMatchState => {
    switch (action.type) {
        case "CREATE_MATCH_START":
            return { ...state, isLoading: true, response: null, error: null };
        case "CREATE_MATCH_SUCCESS":
            return { ...state, isLoading: false, response: action.payload.data };
        case "CREATE_MATCH_FAILURE":
            return { ...state, isLoading: false, error: action.payload };
        case "CREATE_MATCH_RESET":
            return matchesInitialState;
        default:
            return state;
    }
};

export const deleteMatchReducer: Reducer<IMatchState, DeleteMatchActionType> = (
    state = matchesInitialState, 
    action
): IMatchState => {
    switch (action.type) {
        case "DELETE_MATCH_START":
            return { ...state, isLoading: true, response: null, error: null };
        case "DELETE_MATCH_SUCCESS":
            return { ...state, isLoading: false, response: action.payload.data };
        case "DELETE_MATCH_FAILURE":
            return { ...state, isLoading: false, error: action.payload };
        case "DELETE_MATCH_RESET":
            return matchesInitialState;
        default:
            return state;
    }
};