import axios, { AxiosInstance, AxiosResponse, AxiosError } from "axios";
import AppStorage from "../storage";
import store from "../../store";
import actions from "../../store/actions";
import history from "../history";

declare module "axios" {
  /**
   * @interface AxiosResponse
   */
  interface AxiosResponse<T = any> extends Promise<T> {}
}

export abstract class AbstractHttpClient {
  /**
   * @readonly instance
   * @protected instance
   */
  protected readonly instance: AxiosInstance;

  /**
   * @param baseURL
   * @protected constructor
   */
  protected constructor(baseURL: string) {
    this.instance = axios.create({
      baseURL,
    });

    this._initializeResponseInterceptor();
  }

  /**
   * @private _initializeResponseInterceptor
   */
  protected _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(this._handleResponse, this._handleError);
  };

  /**
   * @private _handleResponse
   * @param response
   */
  protected _handleResponse = (response: AxiosResponse) => {
    return response;
  };

  /**
   * @protected _handleError
   * @param error
   */
  protected _handleError = (error: AxiosError) => {
    if (!error.response) {
      console.log("Please check your internet connection.");
    }

    return Promise.reject(error);
  };

  /**
   * Remove access token from storage.
   */
  protected _removeAccessTokenFromStorage = (): void => {
    AppStorage.removeAccessToken();
  };

  /**
   * @protected _handleError
   * @param error
   */
  protected _authenticatedHandleError = async (error: AxiosError) => {
    const statusCode = Number(error.response?.status);

    if (401 === statusCode || 422 === statusCode) {
      if (AppStorage.getRefreshToken() !== null) {
        await store.dispatch(actions.refresh());
      } else {
        this._removeAccessTokenFromStorage();
        await store.dispatch(actions.loginReset());
        history.push("/auth/login");
        window.location.href = "/auth/login";
      }
    }

    return Promise.reject(error);
  };
}
