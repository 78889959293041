import { Reducer } from "react";
import {
  ISectorsState,
  SectorsActionType,
  ISectorState,
  SectorActionType,
  CreateSectorActionType,
  UpdateSectorActionType,
  DeleteSectorActionType,
  IDefaultState,
} from "../types";

// Sectors
const sectorsInitialState: ISectorsState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const sectorsReducer: Reducer<ISectorsState, SectorsActionType> = (
  state = sectorsInitialState,
  action
): ISectorsState => {
  switch (action.type) {
    case "SECTORS_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "SECTORS_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "SECTORS_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "SECTORS_RESET":
      return sectorsInitialState;
    default:
      return state;
  }
};

// Sector
const sectorInitialState: ISectorState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const sectorReducer: Reducer<ISectorState, SectorActionType> = (
  state = sectorInitialState,
  action
): ISectorState => {
  switch (action.type) {
    case "SECTOR_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "SECTOR_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "SECTOR_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "SECTOR_RESET":
      return sectorInitialState;
    default:
      return state;
  }
};

/**
 * @param state
 * @param action
 */
export const createSectorReducer: Reducer<ISectorState, CreateSectorActionType> = (
  state = sectorInitialState,
  action
): ISectorState => {
  switch (action.type) {
    case "CREATE_SECTOR_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "CREATE_SECTOR_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "CREATE_SECTOR_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "CREATE_SECTOR_RESET":
      return sectorInitialState;
    default:
      return state;
  }
};

/**
 * @param state
 * @param action
 */
export const updateSectorReducer: Reducer<ISectorState, UpdateSectorActionType> = (
  state = sectorInitialState,
  action
): ISectorState => {
  switch (action.type) {
    case "UPDATE_SECTOR_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "UPDATE_SECTOR_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "UPDATE_SECTOR_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "UPDATE_SECTOR_RESET":
      return sectorInitialState;
    default:
      return state;
  }
};

const sectorDeleteInitialState: IDefaultState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const deleteSectorReducer: Reducer<IDefaultState, DeleteSectorActionType> = (
  state = sectorDeleteInitialState,
  action
): ISectorState => {
  switch (action.type) {
    case "DELETE_SECTOR_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "DELETE_SECTOR_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "DELETE_SECTOR_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "DELETE_SECTOR_RESET":
      return sectorDeleteInitialState;
    default:
      return state;
  }
};
