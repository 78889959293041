import React from "react";
import SunEditor from "suneditor-react";

type ComponentPropsType = {
  defaultValue: string | null;
  onChange: Function;
};

export const WysiwygEditor = React.forwardRef(({ defaultValue, onChange }: ComponentPropsType, ref: any) => {
  const options = {
    height: "auto",
    minHeight: 300,
    buttonList: [
      ["bold", "italic", "underline", "italic", "strike", "subscript", "superscript"],
      ["font", "fontSize", "fontColor", "hiliteColor", "removeFormat"],
      ["outdent", "indent", "align", "list", "lineHeight"],
      ["table", "link", "image", "video", "audio"],
      ["fullScreen", "showBlocks", "codeView"],
    ],
  };

  const handleOnChange = (content: string) => {
    onChange(content);
  };

  return (
    <div ref={ref}>
      <SunEditor
        setOptions={options}
        defaultValue={defaultValue ? defaultValue : ""}
        onChange={(content: string) => handleOnChange(content)}
        placeholder="test"
      />
    </div>
  );
});
