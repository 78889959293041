import React, { ReactNode } from "react";
import { RouteProps, Route, Redirect } from "react-router-dom";

interface IPrivateRouteProps extends RouteProps {
  component?: any;
  children?: ReactNode;
  isSignedIn: boolean;
}

export const PrivateRoute = (props: IPrivateRouteProps) => {
  const { component: Component, children, isSignedIn, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        isSignedIn ? (
          Component ? (
            <Component {...routeProps} />
          ) : (
            children
          )
        ) : (
          <Redirect
            exact
            to={{
              pathname: "/auth/login",
              state: { from: routeProps.location },
            }}
          />
        )
      }
    />
  );
};
