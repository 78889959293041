import { AbstractHttpClient } from "./AbstractHttpClient";
import { app as config } from "../../config/app";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import {
  IDeletePictureParams,
  IPaginationParams,
  IPageParams,
  ICategoryParams,
  IContractParams,
  INewsParams,
  IBlogPostParams,
  IFaqParams,
  IUpdateContactParams,
  IMentorApplicationEvaluateParams,
  IUserApplicationEvaluateParams,
  IUserParams,
  IUserCreateParams,
  IUsersExportParams,
  ITeamParams,
  ITeamCreateParams,
  ITeamsExportParams,
  IFeaturedMentorParams,
  ISectorParams,
  ISectorsParams,
  ISubSectorParams,
  ISubSectorsPaginationParams,
  ISectorQueryParams,
  IOrderParams,
  IFeedbackParams,
  IAppointmentsExportParams,
  IMentoringProgramParams,
  IMentoringProgramCreateParams,
  IMentoringProgramsExportParams,
  ISessionUpdateParams,
  ISessionCreateParams,
  IFollowedTaskCreateParams,
  IFollowedTaskUpdateParams,
  IMatchParams,
  IMatchesCreateParams,
  IMatchCreateSessionParams,
} from "../../store/types";
import { UserRoleTypes } from "../../types";

export default class AdminApiClient extends AbstractHttpClient {
  /**
   * @private classInstance
   */
  private static classInstance?: AdminApiClient;

  /**
   * @private constructor
   */
  private constructor() {
    super(config.API_URL.replace(/^\/|\/$/g, "").concat("/admin"));

    this._initializeRequestInterceptor();
    this._initializeResponseInterceptor();
  }

  /**
   * @private _initializeRequestInterceptor
   */
  private _initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(this._setAccessToken);
  };

  /**
   * @param config
   */
  private _setAccessToken = (config: AxiosRequestConfig) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  };

  /**
   * @private _initializeResponseInterceptor
   */
  protected _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(this._handleResponse, this._authenticatedHandleError);
  };

  /**
   * @private _handleResponse
   * @param response
   */
  protected _handleResponse = (response: AxiosResponse) => {
    return response;
  };

  /**
   * @public getInstance
   */
  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new this();
    }

    return this.classInstance;
  }

  /**
   * Dashboard.
   */
  public dashboard = async () => await this.instance.get("/dashboard");

  /**
   * Delete picture.
   */
  public deletePicture = async (params: IDeletePictureParams) => {
    return await this.instance.delete("/delete-picture", {
      data: params,
    });
  };

  /**
   * Categories.
   */
  public categories = async (queryParams?: IPaginationParams) =>
    await this.instance.get("/categories", {
      params: queryParams,
    });

  /**
   * Order category.
   * @param params
   */
  public orderCategory = async (params: IOrderParams) => await this.instance.post("/category/order", params);

  /**
   * Category.
   */
  public category = async (id: string) => await this.instance.get(`/category/${id}`);

  /**
   * Create category.
   */
  public createCategory = async (params: ICategoryParams) => await this.instance.put("/categories", params);

  /**
   * Update category.
   */
  public updateCategory = async (id: string, params: ICategoryParams) => {
    return await this.instance.patch(`/category/${id}`, params);
  };

  /**
   * Delete category.
   */
  public deleteCategory = async (id: string) => await this.instance.delete(`/category/${id}`);

  /**
   * Sectors.
   */
  public sectors = async (queryParams?: ISectorsParams) =>
    await this.instance.get("/sectors", {
      params: queryParams,
    });

  /**
   * Sector.
   */
  public sector = async (id: string, queryParams?: ISectorQueryParams) => {
    return await this.instance.get(`/sector/${id}`, {
      params: queryParams,
    });
  };

  /**
   * Create sector.
   */
  public createSector = async (params: ISectorParams) => await this.instance.put("/sectors", params);

  /**
   * Update sector.
   */
  public updateSector = async (id: string, params: ISectorParams) => {
    return await this.instance.patch(`/sector/${id}`, params);
  };

  /**
   * Delete sector.
   */
  public deleteSector = async (id: string) => await this.instance.delete(`/sector/${id}`);

  /**
   * Sub sectors.
   */
  public subSectors = async (queryParams?: ISubSectorsPaginationParams) =>
    await this.instance.get("/subsectors", {
      params: queryParams,
    });

  /**
   * Sub sector.
   */
  public subSector = async (id: string) => {
    return await this.instance.get(`/subsector/${id}`);
  };

  /**
   * Create sub sector.
   */
  public createSubSector = async (params: ISubSectorParams) => await this.instance.put("/subsectors", params);

  /**
   * Update sub sector.
   */
  public updateSubSector = async (id: string, params: ISubSectorParams) => {
    return await this.instance.patch(`/subsector/${id}`, params);
  };

  /**
   * Delete sub sector.
   */
  public deleteSubSector = async (id: string) => await this.instance.delete(`/subsector/${id}`);

  /**
   * Pages.
   */
  public pages = async (queryParams?: IPaginationParams) =>
    await this.instance.get("/pages", {
      params: queryParams,
    });

  /**
   * Order page.
   * @param params
   */
  public orderPage = async (params: IOrderParams) => await this.instance.post("/page/order", params);

  /**
   * Page.
   */
  public page = async (id: string) => await this.instance.get(`/page/${id}`);

  /**
   * Create page.
   */
  public createPage = async (params: ICategoryParams) => await this.instance.put("/pages", params);

  /**
   * Update page.
   */
  public updatePage = async (id: string, params: IPageParams) => {
    return await this.instance.patch(`/page/${id}`, params);
  };

  /**
   * Delete page.
   */
  public deletePage = async (id: string) => await this.instance.delete(`/page/${id}`);

  /**
   * Contracts.
   */
  public contracts = async (queryParams?: IPaginationParams) =>
    await this.instance.get("/contracts", {
      params: queryParams,
    });

  /**
   * Contract.
   */
  public contract = async (id: string) => await this.instance.get(`/contract/${id}`);

  /**
   * Create contract.
   */
  public createContract = async (params: IContractParams) => await this.instance.put("/contracts", params);

  /**
   * Update contract.
   */
  public updateContract = async (id: string, params: IContractParams) => {
    return await this.instance.patch(`/contract/${id}`, params);
  };

  /**
   * Delete contract.
   */
  public deleteContract = async (id: string) => await this.instance.delete(`/contract/${id}`);

  /**
   * News list.
   */
  public newsList = async (queryParams?: IPaginationParams) =>
    await this.instance.get("/news", {
      params: queryParams,
    });

  /**
   * News.
   */
  public news = async (id: string) => await this.instance.get(`/news/${id}`);

  /**
   * Create news.
   */
  public createNews = async (params: INewsParams) => {
    return await this.instance.put("/news", params);
  };

  /**
   * Update news.
   */
  public updateNews = async (id: string, params: INewsParams) => {
    return await this.instance.patch(`/news/${id}`, params);
  };

  /**
   * Delete news.
   */
  public deleteNews = async (id: string) => await this.instance.delete(`/news/${id}`);

  /**
   * Blog posts.
   */
  public blogPosts = async (queryParams?: IPaginationParams) =>
    await this.instance.get("/blogs", {
      params: queryParams,
    });

  /**
   * Blog post.
   */
  public blogPost = async (id: string) => await this.instance.get(`/blog/${id}`);

  /**
   * Create blog post.
   */
  public createBlogPost = async (params: IBlogPostParams) => await this.instance.put("/blogs", params);

  /**
   * Update blog post.
   */
  public updateBlogPost = async (id: string, params: IBlogPostParams) => {
    return await this.instance.patch(`/blog/${id}`, params);
  };

  /**
   * Delete blog post.
   */
  public deleteBlogPost = async (id: string) => await this.instance.delete(`/blog/${id}`);

  /**
   * Faqs.
   */
  public faqs = async (queryParams?: IPaginationParams) =>
    await this.instance.get("/faqs", {
      params: queryParams,
    });

  /**
   * Faq.
   */
  public faq = async (id: string) => await this.instance.get(`/faq/${id}`);

  /**
   * Create faq.
   */
  public createFaq = async (params: IFaqParams) => await this.instance.put("/faqs", params);

  /**
   * Update faq.
   */
  public updateFaq = async (id: string, params: IFaqParams) => {
    return await this.instance.patch(`/faq/${id}`, params);
  };

  /**
   * Delete faq.
   */
  public deleteFaq = async (id: string) => await this.instance.delete(`/faq/${id}`);

  /**
   * Contact.
   */
  public contact = async () => await this.instance.get("/contact");

  /**
   * Update contact.
   */
  public updateContact = async (params: IUpdateContactParams) => {
    return await this.instance.patch("/contact", params);
  };

  /**
   * Mentor applications.
   */
  public mentorApplications = async (queryParams?: IPaginationParams) => {
    return await this.instance.get("/mentor-applications", {
      params: queryParams,
    });
  };

  /**
   * Mentor application.
   */
  public mentorApplication = async (id: string) => await this.instance.get(`/mentor-application/${id}`);

  /**
   * Evaluate mentor application.
   */
  public evaluateMentorApplication = async (id: string, params: IMentorApplicationEvaluateParams) => {
    return await this.instance.patch(`/mentor-application/${id}`, params);
  };

  /**
   * Delete mentor application.
   */
  public deleteMentorApplication = async (id: string) => await this.instance.delete(`/mentor-application/${id}`);

  /**
   * User applications.
   */
  public userApplications = async (queryParams?: IPaginationParams) => {
    return await this.instance.get("/user-applications", {
      params: queryParams,
    });
  };

  /**
   * User application.
   */
  public userApplication = async (id: string) => await this.instance.get(`/user-application/${id}`);

  /**
   * Evaluate user application.
   */
  public evaluateUserApplication = async (id: string, params: IUserApplicationEvaluateParams) => {
    return await this.instance.patch(`/user-application/${id}`, params);
  };

  /**
   * Delete user application.
   */
  public deleteUserApplication = async (id: string) => await this.instance.delete(`/user-application/${id}`);

  /**
   * Teams.
   */
  public teams = async (queryParams?: IPaginationParams) =>
    await this.instance.get("/teams?clients=true", {
      params: queryParams,
    });

  /**
   * Teams export.
   */
  public teamsExport = async (queryParams?: ITeamsExportParams) =>
    await this.instance.get("/teams", {
      params: queryParams,
      responseType: "blob",
    });

  /**
   * Team.
   */
  public team = async (id: string) => await this.instance.get(`/teams/${id}`);

  /**
   * Create team.
   */
  public createTeam = async (params: ITeamCreateParams) => await this.instance.put("/teams", params);

  /**
   * Update team.
   */
  public updateTeam = async (id: string, params: ITeamParams) => {
    return await this.instance.patch(`/teams/${id}`, params);
  };

  /**
   * Delete team.
   */
  public deleteTeam = async (id: string) => await this.instance.delete(`/teams/${id}`);

  /**
   * Users.
   */
  public users = async (queryParams?: IPaginationParams) =>
    await this.instance.get("/users", {
      params: queryParams,
    });

  /**
   * Users export.
   */
  public usersExport = async (queryParams?: IUsersExportParams) =>
    await this.instance.get("/users", {
      params: queryParams,
      responseType: "blob",
    });

  /**
   * User.
   */
  public user = async (id: string) => await this.instance.get(`/user/${id}`);

  /**
   * Create user.
   */
  public createUser = async (params: IUserCreateParams) => await this.instance.put("/users", params);

  /**
   * Create user.
   */
  public createFastUser = async (params: IUserCreateParams) => {
    const headers = {
      "X-USER-TYPE": "fast",
    };
    return await this.instance.put("/users", params, { headers });
  };

  /**
   * Update user.
   */
  public updateUser = async (id: string, params: IUserParams) => {
    return await this.instance.patch(`/user/${id}`, params);
  };

  /**
   * Delete user.
   */
  public deleteUser = async (id: string) => await this.instance.delete(`/user/${id}`);

  /**
   * Featured mentors.
   */
  public featuredMentors = async (queryParams?: IPaginationParams) => {
    return await this.instance.get(`/users?role=${UserRoleTypes.mentor}`, {
      params: queryParams,
    });
  };

  /**
   * Update featured-mentors mentor.
   */
  public updateFeaturedMentor = async (id: string, params: IFeaturedMentorParams) => {
    return await this.instance.post(`/user/${id}/featured`, params);
  };

  /**
   * Appointments.
   */
  public appointments = async (queryParams?: IPaginationParams) => {
    return await this.instance.get("/appointments", { params: queryParams });
  };

  /**
   * Appointments export.
   */
  public appointmentsExport = async (queryParams?: IAppointmentsExportParams) => {
    return await this.instance.get("/appointments", {
      params: queryParams,
      responseType: "blob",
    });
  };

  /**
   * Appointment.
   */
  public appointment = async (id: string) => await this.instance.get(`/appointment/${id}`);

  /**
   * Appointment records.
   */
  public appointmentRecords = async (queryParams?: IPaginationParams) => {
    return await this.instance.get("/appointment-records", { params: queryParams });
  };

  /**
   * Appointment record.
   */
  public appointmentRecord = async (id: string) => await this.instance.get(`/appointment-records/${id}`);

  /**
   * Logs List.
   */
  public logsList = async (queryParams?: IPaginationParams) =>
    await this.instance.get("/activity", {
      params: queryParams,
    });

  /**
   * Support List.
   * @param queryParams
   */
  public supportList = async (queryParams?: IPaginationParams) =>
    await this.instance.get("/support", {
      params: queryParams,
    });

  /**
   * Support.
   * @param id
   */
  public support = async (id: string) => await this.instance.get(`/support/${id}`);

  /**
   * Feedbacks.
   * @param queryParams
   */
  public feedbacks = async (queryParams?: IPaginationParams) =>
    await this.instance.get("/feedbacks", {
      params: queryParams,
    });

  /**
   * Feedback.
   * @param id
   */
  public feedback = async (id: string) => await this.instance.get(`/feedbacks/${id}`);

  /**
   * Create feedback.
   * @param params
   */
  public createFeedback = async (params: IFeedbackParams) => {
    return await this.instance.put("/feedbacks", params);
  };

  /**
   * Update feedback.
   */
  public updateFeedback = async (id: string, params: IFeedbackParams) => {
    return await this.instance.patch(`/feedbacks/${id}`, params);
  };

  /**
   * Delete feedback.
   * @param id
   */
  public deleteFeedback = async (id: string) => await this.instance.delete(`/feedbacks/${id}`);

  /**
   * Mentoring Programs.
   */
  public mentoringPrograms = async (queryParams?: IPaginationParams) =>
    await this.instance.get("/mentoring_programs", {
      params: queryParams,
    });
  /**
   * Mentoring Programs Export.
   */
  public mentoringProgramsExport = async (queryParams?: IMentoringProgramsExportParams) =>
    await this.instance.get("/mentoring_programs", {
      params: queryParams,
      responseType: "blob",
    });
  /**
   * Mentoring Program.
   */
  public mentoringProgram = async (id: string) => await this.instance.get(`/mentoring_programs/${id}`);

  /**
   * Create Mentoring Program.
   */
  public createMentoringProgram = async (params: IMentoringProgramCreateParams) => await this.instance.put("/mentoring_programs", params);
  
  /**
   * Update Mentoring Program.
   */

  public updateMentoringProgram = async (id: string, params: IMentoringProgramParams) => {
    return await this.instance.patch(`/mentoring_programs/${id}`, params);
  }
  /**
   * Delete Mentoring Program.
   */
  public deleteMentoringProgram = async (id: string) => await this.instance.delete(`/mentoring_programs/${id}`);
   /**
   * Create Sessions.
   */
  public createSession = async (id:string,params: ISessionCreateParams) => {
    return await this.instance.put(`/mentoring_programs/${id}/sessions`,params);
  }

  public createMatchSession = async (id:string,params: IMatchCreateSessionParams) => {
    return await this.instance.put(`/mentoring_programs/${id}/sessions`,params);
  }
  /**
   * Update Sessions.
   */
  public updateSession = async (id: string,sid:string, params: ISessionUpdateParams) => {
    return await this.instance.patch(`/mentoring_programs/${id}/sessions/${sid}`, params);
  }
  /**
   * delete Sessions.
   */
  public deleteSession = async (id: string, sid: string) => {
    await this.instance.delete(`/mentoring_programs/${id}/sessions/${sid}`);
  }

  /**
   * Create Followed Task.
   */
  public createFollowedTask = async (id:string,sid:string,params: IFollowedTaskCreateParams) => {
    return await this.instance.put(`/mentoring_programs/${id}/sessions/${sid}/followed_task`,params);
  }
  /**
   * Update Followed Task.
   */
  public updateFollowedTask = async (id: string,sid:string,fid:string, params: IFollowedTaskUpdateParams) => {
    return await this.instance.patch(`/mentoring_programs/${id}/sessions/${sid}/followed_task/${fid}`, params);
  }
  /**
   * delete Followed Task.
   */
  public deleteFollowedTask = async (id: string,sid:string, fid: string) => {
    return await this.instance.delete(`/mentoring_programs/${id}/sessions/${sid}/followed_task/${fid}`);
  };
  /**
   * Matches Export.
   */

  public match = async (tid:string, cid:string, queryParams?: IPaginationParams) =>
    await this.instance.get(`/teams/${tid}/program/${cid}`, {
      params: queryParams,
    });

  public updateMatch = async (tid:string,cid:string,params: IMatchParams ) => {
    return await this.instance.patch(`/teams/${tid}/program/${cid}`, params);
  }
  public deleteMatch = async (tid:string,cid:string) => {
    return await this.instance.delete(`/teams/${tid}/program/${cid}`);
  }
  public createMatch = async (tid:string,params: IMatchesCreateParams) => {
    return await this.instance.put(`teams/${tid}/program`, params);
  }
}