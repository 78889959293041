import { Dispatch } from "redux";
import { AdminApiClient } from "../../utils/http-client";
import { IFollowedTaskUpdateParams,IFollowedTaskCreateParams } from "../types";

const adminApiClient = AdminApiClient.getInstance();


export const createFollowedTask = (id:string,sid:string,params: IFollowedTaskCreateParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "CREATE_FOLLOWED_TASK_START" });
    await adminApiClient
        .createFollowedTask(id,sid,params)
        .then((response) => {
        dispatch({ type: "CREATE_FOLLOWED_TASK_SUCCESS", payload: response,console:response });
        })
        .catch((error) => dispatch({ type: "CREATE_FOLLOWED_TASK_FAILURE", payload: error }));
};

export const updateFollowedTask = (id: string,sid:string,fid:string, params: IFollowedTaskUpdateParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "UPDATE_FOLLOWED_TASK_START" });
    await adminApiClient
        .updateFollowedTask(id,sid,fid, params)
        .then((response) => {
        dispatch({ type: "UPDATE_FOLLOWED_TASK_SUCCESS", payload: response });
        })
        .catch((error) => dispatch({ type: "UPDATE_FOLLOWED_TASK_FAILURE", payload: error }));
};

export const deleteFollowedTask = (id: string, sid: string,fid:string) => async (dispatch: Dispatch) => {
  dispatch({ type: "DELETE_FOLLOWED_TASK_START" });
  await adminApiClient
    .deleteFollowedTask(id, sid,fid)
    .then((response) => {
      dispatch({ type: "DELETE_FOLLOWED_TASK_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "DELETE_FOLLOWED_TASK_FAILURE", payload: error }));
};

