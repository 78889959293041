import { Dispatch } from "redux";
import { AdminApiClient } from "../../utils/http-client";
import { IContractParams, IPaginationParams } from "../types";

const adminApiClient = AdminApiClient.getInstance();

export const contracts = (queryParams?: IPaginationParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "CONTRACTS_START" });
  await adminApiClient
    .contracts(queryParams)
    .then((response) => {
      dispatch({ type: "CONTRACTS_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "CONTRACTS_FAILURE", payload: error }));
};

export const contract = (id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: "CONTRACT_START" });
  await adminApiClient
    .contract(id)
    .then((response) => {
      dispatch({ type: "CONTRACT_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "CONTRACT_FAILURE", payload: error }));
};

export const createContract = (params: IContractParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "CREATE_CONTRACT_START" });
  await adminApiClient
    .createContract(params)
    .then((response) => {
      dispatch({ type: "CREATE_CONTRACT_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "CREATE_CONTRACT_FAILURE", payload: error }));
};

export const updateContract = (id: string, params: IContractParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "UPDATE_CONTRACT_START" });
  await adminApiClient
    .updateContract(id, params)
    .then((response) => {
      dispatch({ type: "UPDATE_CONTRACT_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "UPDATE_CONTRACT_FAILURE", payload: error }));
};

export const deleteContract = (id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: "DELETE_CONTRACT_START" });
  await adminApiClient
    .deleteContract(id)
    .then((response) => {
      dispatch({ type: "DELETE_CONTRACT_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "DELETE_CONTRACT_FAILURE", payload: error }));
};
