import { UserSectorsType } from "../../store/types";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { Button } from "react-bootstrap";

const ToggleSector = (props: UserSectorsType) => {
  const { t } = useTranslation();
  const [toggle, setToggle] = useState(false);
  const { sector, sub_sector } = props;
  const experience = props.sector_experience ? `${props.sector_experience} - ${t("year")}` : null;

  return (
    <>
      <Button size="sm" as="div" variant="outline-secondary" onClick={() => setToggle(!toggle)}>
        {" "}
        {sector.title}/{sector.nace}/{sector.code} {experience}{" "}
      </Button>
      <div>
        {toggle &&
          sub_sector.map(({ name }, index) => (
            <Button as="div" size="sm" className="my-2 mx-1" key={index}>
              {name}
            </Button>
          ))}
      </div>
      <hr />
    </>
  );
};

export default ToggleSector;
