import React, { useEffect, useState } from 'react';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image, Font } from '@react-pdf/renderer';
import { MainLayout } from '../../layouts';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/reducers';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { IMatchState, IUsersPaginationParams, IUsersState, IUserState, MatchType, MentoringProgramMatchType, MatchSessionType, TeamType, UserType } from '../../../store/types';
import ICMPD from "../../../assets/img/logo/ICMPD_logo_transparent_v2.jpg";
import ExampleSTB from "../../../assets/img/logo/STB-ÖrnekLogo.png";
import EUTR from "../../../assets/img/logo/EU_TR flag.png";
import YDKM from "../../../assets/img/logo/YDKM_TR Yatay Renkli.png";
import uretken from "../../../assets/img/logo/uretkeniz biz LOGO FINAL_LOGO VE LOGOTYPE beyaz-turkuvaz.png";
import { useParams } from "react-router-dom";
import actions from '../../../store/actions';

Font.register({
  family: 'Ubuntu',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
    },
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
      fontWeight: 'bold',
    },
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
      fontWeight: 'normal',
      fontStyle: 'italic',
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    paddingTop: "20px",
    flexDirection: 'column',
    backgroundColor: 'white',
    fontFamily: 'Ubuntu',
    paddingHorizontal: 30,
  },
  box: {
    border: "1px solid black",
    padding: "5px",
    marginBottom: "10px",
    lineHeight: "1.5",
    width: "90%",

  },
  mainContent: {
    margin: 20,
    flexDirection: 'column',
    padding: 10,
    flexGrow: 1,
  },
  section: {
    margin: 20,
    flexGrow: 1,
    flexDirection: 'column',
    width: '90%',
    marginLeft: "20px",
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    left: 0,
    right: 0,
    padding: 10,
    zIndex: 1,
    marginBottom: 5,
    marginLeft: "30px"
  },
  mentorTitle: {
    textAlign: 'center',
    fontSize: 20,
    color: 'green',
    marginBottom: 0,
    marginTop: "20px"
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
    fontSize: 12,
    color: 'grey',
  },
  image: {
    padding: '2px 0px',
    height: 45,
    width: 45,
    marginTop: 20,
  },
  hrGreen: {
    marginVertical: 2,
    height: 1,
    backgroundColor: 'green',
  },
  hrBlue: {
    marginVertical: 2,
    height: 1,
    backgroundColor: 'blue',
  },
  hrRed: {
    marginVertical: 2,
    height: 1,
    backgroundColor: 'red',
  },
  hrBlack: {
    marginVertical: 1,
    height: 3,
    borderRadius: 5,
    backgroundColor: 'black',
  },
  table: {
    marginLeft: '-4px',
    display: "flex",
    width: "92%",
    backgroundColor: "white",
    borderStyle: "solid",
    borderColor: "black",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol10: {
    width: "10%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },

  tableCol1: {
    width: "15%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol2: {
    width: "20%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol25: {
    width: "25%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
    padding: 2,
  },
  followedTask: {
    backgroundColor: "white",
    padding: "5px",
    marginBottom: "10px",
  },
});

type RouterParamsType = {
  cid: string;
  id: string;
};

const MatchReportProgram = () => {
  const { id, cid } = useParams<RouterParamsType>();
  const dispatch = useDispatch();
  const history = useHistory();
  const { response: userResponse } = useSelector<RootState, IUsersState>(state => state.users);
  const { isLoading, response, error } = useSelector<RootState, IMatchState>(state => state.match);
  const [userMap, setUserMap] = useState<Map<string, string>>(new Map());
  const [queryForUser] = useState<IUsersPaginationParams>({ page: 1, per: 9999 });
  useEffect(() => {
    dispatch(actions.Match(id, cid));
  }, [dispatch, id, cid]);
  useEffect(() => {
    if (userResponse) {
      const map = new Map<string, string>();
      userResponse.data.items.forEach((user: UserType) => {
        map.set(user.id, `${user.name} ${user.lastname}`);
      });
      setUserMap(map);
    }
  }, [userResponse]);
  useEffect(() => {
    dispatch(actions.users(queryForUser));
  }
    , [dispatch]);

    const formatDate = (date:any) => {
      return date ? moment(date).format('DD/MM/YYYY') : '-';
    };

    useEffect(() => {
      const back = () => {
          window.removeEventListener('popstate', back);
          console.log('-BACK-');
          history.push('/matches');
      };
      window.addEventListener('popstate', back);
    }, []);

  const MyDocument = ({ match }: { match: MatchType }) => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={{ flexDirection: "row", justifyContent: "space-between", width: "500px" }}>
            <View style={{ flexDirection: "column" }}>
              <Image src={ICMPD} style={{ padding: '2px 0px', objectFit: 'contain', height: 41, marginTop: 20 }} />
              <Image src={uretken} style={{ padding: '2px 0px', objectFit: 'contain', height: 45, width: 45, marginTop: 20 }} />
            </View>
            <View style={{ alignItems: "center", justifyContent: "center" }}>
              <Image src={EUTR} style={{ padding: '2px 0px', objectFit: 'contain', height: 90 }} />
            </View>
            <View style={{ flexDirection: "column" }}>
              <Image src={ExampleSTB} style={{ padding: '2px 0px', objectFit: 'contain', height: 45, marginTop: 20 }} />
              <Image src={YDKM} style={{ padding: '2px 0px', objectFit: 'contain', height: 45, marginTop: 20 }} />
            </View>
          </View>
        </View>
        <View style={styles.hrGreen} />
        <View style={styles.hrBlue} />
        <View style={styles.hrRed} />
        {match.mentoring_programs?.map((program: MentoringProgramMatchType, programIndex: number) => (
          <View wrap={true} break={programIndex > 0}>
            
            <Text style={styles.mentorTitle}> Rehberlik Programı: {program.title}</Text>
            <Text style={{ textAlign: 'left', fontSize: 15, color: 'green', marginTop: 10,marginLeft:60 }}>Takım Adı : {response?.data.team?.name}</Text>
            <View key={programIndex} style={styles.mainContent}>

              <View style={{ width: "500px", fontSize: "12px", paddingHorizontal: "10px", marginLeft: "20px" }}>
                <View style={styles.box}>
                  <Text style={{ color: 'green', fontSize: 12, fontStyle: "italic" }}>Danışanlar: </Text>
                  {match.clients && match.clients.map((client: UserType, index: number) => (
                    <Text key={index} style={{ color: 'gray', fontSize: 12, fontStyle: "italic", marginRight: 10 }}>
                      &#8226; {client.name} {client.lastname}
                    </Text>

                  ))}
                </View>
                <View style={styles.box}>
                  <Text style={{ color: 'green', fontSize: 12, fontStyle: "italic" }}>Takım Rehberleri: </Text>
                  {match.team.mentors && match.team.mentors.map((mentor: UserType, index: number) => (
                    <Text key={index} style={{ color: 'gray', fontSize: 12, fontStyle: "italic", marginRight: 10 }}>
                      &#8226; {mentor.name} {mentor.lastname}
                    </Text>
                  ))}
                </View>
                <View style={styles.box}>
                  <Text style={{ color: 'green', fontSize: 12, fontStyle: "italic" }}>Takım Stajyerleri: </Text>
                  {match.team.project_managers && match.team.project_managers.map((intern: UserType, index: number) => (
                    <Text key={index} style={{ color: 'gray', fontSize: 12, fontStyle: "italic", marginRight: 10 }}>
                      &#8226; {intern.name} {intern.lastname}
                    </Text>
                  ))}
                </View>
              </View>
              {program.sessions.map((session: MatchSessionType, sessionIndex: number) => (
                <View key={sessionIndex} style={styles.section} break={sessionIndex > -1}>
                  <View style={{ marginBottom: "10px", width: "350px", marginLeft: "50px" }}>
                    <View style={{ flexDirection: "column", justifyContent: "center", alignItems: "center", display: "flex", marginBottom: "20px" }}>
                      <View style={{ flexDirection: "row" }}>
                        <Text style={{ color: "green", fontSize: 16 }}>Oturum No {sessionIndex + 1} : </Text>
                        <Text style={{ fontSize: 16, color: "gray" }}>{session.title}</Text>
                      </View>
                    </View>
                  </View>
                  <View style={{ width: "500px", fontSize: "12px", paddingHorizontal: "10px" }}>
                    <View style={styles.box} wrap={false}>
                      <Text style={{ color: "red", fontSize: 16 }}>Ön Hazırlık:</Text>
                      <Text>{session.preparations}</Text>
                    </View>
                    <View style={styles.box} wrap={false}>
                      <Text style={{ color: "red", fontSize: 16 }}>Hedefler:</Text>
                      <Text>{session.goals}</Text>
                    </View>
                    <View style={styles.box} wrap={false}>
                      <Text style={{ color: "red", fontSize: 16 }}>Tarih:</Text>
                      <Text>{formatDate(session.date)}</Text>
                    </View>
                    <View style={styles.box} wrap={false}>
                      <Text style={{ color: "red", fontSize: 16 }}>Lokasyon:</Text>
                      <Text>{session.location}</Text>
                    </View>
                    <View style={styles.box} wrap={false}>
                      <Text style={{ color: "red", fontSize: 16 }}>Gündem:</Text>
                      <Text>{session.agenda}</Text>
                    </View>
                    <View style={styles.box} wrap={false}>
                      <Text style={{ color: "red", fontSize: 16 }}>Konular:</Text>
                      <Text>{session.topics}</Text>
                    </View>
                    <View style={styles.box} wrap={false}>
                      <Text style={{ color: "red", fontSize: 16 }}>Kararlar:</Text>
                      <Text>{session.decisions}</Text>
                    </View>
                    <View style={styles.box} wrap={false}>
                      <Text style={{ color: "red", fontSize: 16 }}>Dökümanlar:</Text>
                      <Text>{session.documents}</Text>
                    </View>
                    <View style={styles.followedTask} wrap={false}>
                      <Text style={{ color: "red", fontSize: 16 }}>Takip Edilen Görevler:</Text>
                      <View style={styles.table}>
                        <View style={styles.tableRow}>
                          <View style={styles.tableCol10}>
                            <Text style={[styles.tableCell, { color: "red" }]}>No</Text>
                          </View>
                          <View style={styles.tableCol25}>
                            <Text style={[styles.tableCell, { color: "red" }]}>Başlık</Text>
                          </View>
                          <View style={styles.tableCol2}>
                            <Text style={[styles.tableCell, { color: "red" }]}>Yetkili Kişi</Text>
                          </View>
                          <View style={styles.tableCol1}>
                            <Text style={[styles.tableCell, { color: "red" }]}>Durum</Text>
                          </View>
                          <View style={styles.tableCol1}>
                            <Text style={[styles.tableCell, { color: "red" }]}>Başlangıç Tarihi</Text>
                          </View>
                          <View style={styles.tableCol1}>
                            <Text style={[styles.tableCell, { color: "red" }]}>Bitiş Tarihi</Text>
                          </View>



                        </View>
                        {session.followed_tasks?.map((task: any, taskIndex: number) => (
                          <View key={taskIndex} style={styles.tableRow}>
                            <View style={styles.tableCol10}>
                              <Text style={styles.tableCell}>{task.steps ? task.steps : '-'}</Text>
                            </View>
                            <View style={styles.tableCol25}>
                              <Text style={styles.tableCell}>{task.title ? task.title : '-'}</Text>
                            </View>
                            <View style={styles.tableCol2}>
                              {task.authorized_person && task.authorized_person.length > 0 ? (
                                task.authorized_person.split(',').map((id: string, index: number) => {
                                  const trimmedId = id.trim();
                                  return (
                                    <Text key={index} style={styles.tableCell}>
                                      &#8226; {userMap.get(trimmedId) || trimmedId}
                                    </Text>
                                  );
                                })
                              ) : (
                                <Text style={styles.tableCell}>-</Text>
                              )}
                            </View>
                            <View style={styles.tableCol1}>
                              <Text style={styles.tableCell}>{task.status ? task.status : '-'}</Text>
                            </View>
                            <View style={styles.tableCol1}>
                              <Text style={styles.tableCell}>{formatDate(task.start_date)}</Text>
                            </View>
                            <View style={styles.tableCol1}>
                              <Text style={styles.tableCell}>{formatDate(task.completion_date)}</Text>
                            </View>



                          </View>
                        ))}
                      </View>
                    </View>
                  </View>
                </View>
              ))}

            </View>
          </View>

        ))}
        <View style={styles.footer} fixed={false}>
          <Text style={{ backgroundColor: 'rgb(218, 41, 28)', display: 'flex', alignItems: 'center', padding: '5px', color: 'white', justifyContent: 'center', fontSize: '8px' }}>
            Bu Hibe Programı, Avrupa Birliği'nin “Türkiye'deki Mülteciler için Mali Yardım
            Programı (FRIT)” tarafından
            fonlanan ve Uluslararası Göç Politikaları Geliştirme Merkezi (ICMPD) tarafından uygulanan “Sürdürülebilir
            Sosyo-Ekonomik Uyum için Girişimcilik Kapasitelerinin Geliştirilmesi
            (ENHANCER)” (IPA/ 2019/411-568) projesi kapsamında finanse edilmektedir.</Text>
        </View>
      </Page>
    </Document>
  );
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (response && response.data) {
    const match: MatchType = response.data;
    return (
      <MainLayout >
        <div className="container">
          <div className="row" style={{overflow:"scroll",marginBottom:"50px"}}>
            <div className="col-12">
              <h1>Report Program</h1>
              <PDFViewer width="100%" height="600">
                <MyDocument match={match} />
              </PDFViewer>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }

  return <div>No data available</div>;
};

export default MatchReportProgram;
