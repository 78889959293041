import { Reducer } from "react";
import {
  IDefaultState,
  INewsListState,
  INewsState,
  NewsListActionType,
  NewsActionType,
  CreateNewsActionType,
  UpdateNewsActionType,
  DeleteNewsActionType,
} from "../types";

// News list
const newsListInitialState: INewsListState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const newsListReducer: Reducer<INewsListState, NewsListActionType> = (
  state = newsListInitialState,
  action
): INewsListState => {
  switch (action.type) {
    case "NEWS_LIST_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "NEWS_LIST_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "NEWS_LIST_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "NEWS_LIST_RESET":
      return newsListInitialState;
    default:
      return state;
  }
};

// News
const newsInitialState: INewsState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const newsReducer: Reducer<INewsState, NewsActionType> = (state = newsInitialState, action): INewsState => {
  switch (action.type) {
    case "NEWS_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "NEWS_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "NEWS_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "NEWS_RESET":
      return newsInitialState;
    default:
      return state;
  }
};

/**
 * @param state
 * @param action
 */
export const createNewsReducer: Reducer<INewsState, CreateNewsActionType> = (
  state = newsInitialState,
  action
): INewsState => {
  switch (action.type) {
    case "CREATE_NEWS_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "CREATE_NEWS_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "CREATE_NEWS_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "CREATE_NEWS_RESET":
      return newsInitialState;
    default:
      return state;
  }
};

/**
 * @param state
 * @param action
 */
export const updateNewsReducer: Reducer<INewsState, UpdateNewsActionType> = (
  state = newsInitialState,
  action
): INewsState => {
  switch (action.type) {
    case "UPDATE_NEWS_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "UPDATE_NEWS_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "UPDATE_NEWS_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "UPDATE_NEWS_RESET":
      return newsInitialState;
    default:
      return state;
  }
};

const newsDeleteInitialState: IDefaultState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const deleteNewsReducer: Reducer<IDefaultState, DeleteNewsActionType> = (
  state = newsDeleteInitialState,
  action
): INewsState => {
  switch (action.type) {
    case "DELETE_NEWS_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "DELETE_NEWS_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "DELETE_NEWS_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "DELETE_NEWS_RESET":
      return newsDeleteInitialState;
    default:
      return state;
  }
};
