import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { MainLayout } from "../../layouts";
import { Button, ButtonGroup, Card, Col, Form, Row, Table } from "react-bootstrap";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import {
  MdLocationDisabled,
  MdLocationSearching,
  RiAddBoxLine,
  RiDeleteBin6Line,
  RiEditBoxLine,
  RiEyeLine,
} from "react-icons/all";
import { RootState } from "../../../store/reducers";
import {
  BlogPostType,
  IBlogPostsPaginationParams,
  IBlogPostsState,
  IBlogPostState,
  ICategoriesState,
} from "../../../store/types";
import { DeleteConfirmModal, LoadingIndicator, Pagination, RecordNotFound } from "../../partials";
import Moment from "react-moment";
import actions from "../../../store/actions";
import { DEFAULT_PER_PAGE } from "../../../types";
import qs from "query-string";

export const List = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const filterCategoryRef = useRef<HTMLSelectElement>(null);
  const filterQRef = useRef<HTMLInputElement>(null);

  const { isLoading, response, error } = useSelector<RootState, IBlogPostsState>((state: RootState) => state.blogPosts);

  const { isLoading: updating, response: updateResponse } = useSelector<RootState, IBlogPostState>(
    (state: RootState) => state.updateBlogPost
  );

  const { response: deleteResponse } = useSelector<RootState, IBlogPostsState>(
    (state: RootState) => state.deleteBlogPost
  );

  const { response: categoriesResponse } = useSelector<RootState, ICategoriesState>(
    (state: RootState) => state.categories
  );

  const getBlogPosts = useCallback(
    (queryParams?) => {
      dispatch(actions.blogPosts(queryParams));
    },
    [dispatch]
  );

  const queryString = new URLSearchParams(useLocation().search);
  const page: number = queryString.get("page") ? Number(queryString.get("page")) : 1;
  const category: string | null = queryString.get("category") ? queryString.get("category") : null;
  const q: string | null = queryString.get("q") ? queryString.get("q") : null;

  const [queryParams, setQueryParams] = useState<IBlogPostsPaginationParams>({ page: page, per: DEFAULT_PER_PAGE });
  const qParams = useMemo<IBlogPostsPaginationParams>(() => qs.parse(search), [search]);
  useEffect(() => {
    const params = qParams;
    params.per = DEFAULT_PER_PAGE;
    setQueryParams(params);
    getBlogPosts(params);
  }, [getBlogPosts, qParams]);

  const [deleteItem, setDeleteItem] = useState<string | null>(null);
  const onDeleteConfirm = async () => {
    if (deleteItem) {
      dispatch(await actions.deleteBlogPost(deleteItem));
      setDeleteItem(null);
    }
  };

  const handleSubmitFilter = () => {
    let url = `/blog-posts?page=1`;
    const searchParams: Array<string> = [];
    if (filterCategoryRef.current!.value) {
      searchParams.push(`category=${filterCategoryRef.current!.value}`);
      queryParams.category = filterCategoryRef.current!.value;
    }

    if (filterQRef.current!.value) {
      searchParams.push(`q=${filterQRef.current!.value}`);
      queryParams.q = filterQRef.current!.value;
    }

    if (searchParams.length) {
      url += "&" + searchParams.join("&");
    }

    getBlogPosts(queryParams);

    history.push(url);
  };

  useEffect(() => {
    if (!categoriesResponse) {
      dispatch(actions.categories({ per: 1000 }));
    }
  }, [categoriesResponse, dispatch]);

  useEffect(() => {
    if (deleteResponse) {
      addToast(t("deleted_with_param", { param: t("blog_post") }), {
        appearance: "success",
        autoDismiss: true,
      });

      getBlogPosts({ page, per: DEFAULT_PER_PAGE, category, q });
      dispatch({ type: "DELETE_BLOG_POST_RESET" });
    }
  }, [deleteResponse, addToast, t, getBlogPosts, page, category, q, dispatch]);

  useEffect(() => {
    if (error?.response) {
      dispatch({ type: "BLOG_POSTS_RESET" });

      if (error.response.status >= 400) {
        history.push("/blog-posts");
      }
    } else {
      getBlogPosts({ page, per: DEFAULT_PER_PAGE, category, q });
    }
  }, [getBlogPosts, page, category, q, error, history, dispatch]);

  const handleChangeStatus = async (blogPost: BlogPostType) => {
    const categories: Array<string> = blogPost.categories.map((category) => {
      return category.id;
    });

    dispatch(
      await actions.updateBlogPost(blogPost.id, {
        title: blogPost.title,
        summary: blogPost.summary,
        content: blogPost.content,
        categories,
        pictures: [],
        is_active: !blogPost.is_active,
      })
    );
  };

  useEffect(() => {
    if (updateResponse) {
      getBlogPosts(queryParams);
      dispatch({ type: "UPDATE_BLOG_POST_RESET" });
    }
  }, [updateResponse, getBlogPosts, queryParams, dispatch]);

  return (
    <MainLayout>
      <LoadingIndicator show={isLoading || updating} />
      {response && !updating && (
        <Row>
          <Col md="9" className="order-last order-md-first">
            <Card>
              <Card.Header>
                <Row>
                  <Col as="h6" xs="8" className="mb-0">
                    {t("blog_posts")}
                  </Col>
                  <Col xs="4" className="mb-0 text-right">
                    <strong className="mr-1">{t("total")}:</strong>
                    <span>{response.data.pagination.total}</span>
                  </Col>
                </Row>
              </Card.Header>

              <Card.Body className="p-0 m-0">
                {response.data.items.length > 0 ? (
                  <>
                    <DeleteConfirmModal
                      show={deleteItem !== null}
                      onClose={() => setDeleteItem(null)}
                      onConfirm={onDeleteConfirm}
                    />
                    <Table responsive striped hover className="table-list m-0">
                      <thead>
                        <tr>
                          <th>{t("title")}</th>
                          <th className="date">{t("created_at")}</th>
                          <th className="actions">{t("actions")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {response.data.items.map((item, key) => {
                          return (
                            <tr key={key}>
                              <td>{item.title}</td>
                              <td className="date">
                                <Moment format="DD/MM/YYYY HH:mm:ss">{item.created_at}</Moment>
                              </td>
                              <td className="actions">
                                <ButtonGroup>
                                  <Button
                                    as={NavLink}
                                    to={`/blog-posts/${item.id}`}
                                    variant="outline-info"
                                    size={"sm"}
                                    className="btn-icon"
                                    title={t("show")}
                                  >
                                    <RiEyeLine />
                                  </Button>
                                  <Button
                                    variant={`outline-${item.is_active ? "success" : "danger"}`}
                                    size="sm"
                                    className="btn-icon"
                                    title={t("change_status")}
                                    onClick={() => handleChangeStatus(item)}
                                  >
                                    {item.is_active ? <MdLocationSearching /> : <MdLocationDisabled />}
                                  </Button>
                                  <Button
                                    as={NavLink}
                                    to={`/blog-posts/${item.id}/edit`}
                                    variant="outline-secondary"
                                    size={"sm"}
                                    className="btn-icon"
                                    title={t("edit")}
                                  >
                                    <RiEditBoxLine />
                                  </Button>
                                  <Button
                                    variant="outline-danger"
                                    size={"sm"}
                                    className="btn-icon"
                                    title={t("delete")}
                                    onClick={() => setDeleteItem(item.id)}
                                  >
                                    <RiDeleteBin6Line />
                                  </Button>
                                </ButtonGroup>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </>
                ) : (
                  <RecordNotFound />
                )}
              </Card.Body>

              <Card.Footer>
                <Row>
                  <Col xs="6">
                    <Pagination pagination={response.data.pagination} extraParams={["category", "q"]} />
                  </Col>
                  <Col xs="6" className="text-right">
                    <Button as={NavLink} to="/blog-posts/create" variant="success" size="sm" className="with-icon">
                      <RiAddBoxLine />
                      <span>{t("create")}</span>
                    </Button>
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Col>
          <Col md="3" className="order-first order-md-last">
            <Card>
              <Card.Body>
                <Form onSubmit={handleSubmitFilter}>
                  <Form.Group controlId="filter-category">
                    <Form.Label>{t("category")}</Form.Label>
                    <Form.Control as="select" custom ref={filterCategoryRef} defaultValue={category ? category : ""}>
                      <option value="">{t("all")}</option>
                      {categoriesResponse && categoriesResponse.data.items.length && (
                        <>
                          {categoriesResponse.data.items.map((item) => {
                            return <option value={item.id}>{item.title}</option>;
                          })}
                        </>
                      )}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="filter-q">
                    <Form.Label>{t("keywords")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="q"
                      ref={filterQRef}
                      placeholder={t("keywords")}
                      defaultValue={q ? q : ""}
                    />
                  </Form.Group>
                  <hr />
                  <Button variant="outline-secondary" block onClick={handleSubmitFilter}>
                    {t("filter")}
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </MainLayout>
  );
};

export default List;
