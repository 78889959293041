import { Dispatch } from "redux";
import { AuthApiClient } from "../../utils/http-client";
import { ILoginParams, ILoginUpdateParams, IPasswordParams, IUpdatePasswordParams } from "../types";
import store from "../index";
import actions from "./index";

const authApiClient = AuthApiClient.getInstance();

// Login
export const login = (params: ILoginParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "LOGIN_START" });
  await authApiClient
    .login(params)
    .then((response) => {
      dispatch({ type: "LOGIN_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "LOGIN_FAILURE", payload: error }));
};

export const loginUpdate = (params: ILoginUpdateParams | null) => (dispatch: Dispatch) => {
  dispatch({ type: "LOGIN_UPDATE_START" });
  dispatch({ type: "LOGIN_UPDATE_SUCCESS", payload: params });
};

export const loginReset = () => (dispatch: Dispatch) => {
  dispatch({ type: "LOGIN_RESET", payload: null });
};

// Refresh
export const refresh = () => async (dispatch: Dispatch) => {
  dispatch({ type: "REFRESH_START" });
  await authApiClient
    .refresh()
    .then(async (response) => {
      store.dispatch(await actions.me());
      dispatch({ type: "REFRESH_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "REFRESH_FAILURE", payload: error }));
};

// Password
export const password = (params: IPasswordParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "PASSWORD_START" });
  await authApiClient
    .password(params)
    .then((response) => {
      dispatch({ type: "PASSWORD_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "PASSWORD_FAILURE", payload: error }));
};

// Update password
export const updatePassword = (token: string, params: IUpdatePasswordParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "UPDATE_PASSWORD_START" });
  await authApiClient
    .updatePassword(token, params)
    .then((response) => {
      dispatch({ type: "UPDATE_PASSWORD_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "UPDATE_PASSWORD_FAILURE", payload: error }));
};
