import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { FollowedTaskType, IUsersPaginationParams, MatchFollowedTaskType, UserType } from "../../../store/types";
import { RootState } from "../../../store/reducers";
import { IUsersState } from "../../../store/types";
import actions from "../../../store/actions";
import moment from "moment";

type MatchFollowedTaskDetailModalPropsType = {
    modal: MatchFollowedTaskDetailModalType;
    onClose: () => void;
};

export type OptionType = {
    value: string;
    label: string | null;
};

export type MatchFollowedTaskDetailModalType = {
    show: boolean;
    session: MatchFollowedTaskType | null;
};

export const MatchFollowedTaskModalDefaultState: MatchFollowedTaskDetailModalType = {
    show: false,
    session: null,
};

export const MatchFollowedTaskModal = (props: MatchFollowedTaskDetailModalPropsType) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [queryForUser] = useState<IUsersPaginationParams>({ page: 1, per: 9999 });
    const [show, setShow] = useState<boolean>(props.modal.show);
    const [authorizedPersonsNames, setAuthorizedPersonsNames] = useState<string[]>([]);
    const { response: userResponse } = useSelector<RootState, IUsersState>((state: RootState) => state.users);

    useEffect(() => {
        setShow(props.modal.show);
        actions.users(queryForUser);
    }, [props.modal.show, dispatch]);

    useEffect(() => {
        if (userResponse) {
            const authorizedPersonsStr = props.modal.session?.authorized_person || "";
            const authorized_persons: string[] = authorizedPersonsStr ? authorizedPersonsStr.split(",") : [];
            const names: string[] = [];
            authorized_persons.forEach(id => {
                const user = userResponse.data.items.find((item: UserType) => item.id === id.trim());
                if (user) {
                    names.push(`${user.name} ${user.lastname}`);
                }
            });
            setAuthorizedPersonsNames(names);
        } else {
            dispatch(actions.users(queryForUser));
        }
    }, [userResponse, props.modal.session, dispatch]);
    
    const handleClose = (): void => {
        props.onClose();
    };

    return (
        <Modal centered
            show={show}
            onHide={handleClose}
            animation={false}
        >
            <Modal.Header closeButton>
                <Modal.Title as="h6">{props.modal.session?.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <h6>{t("title")}</h6>
                    <p>{props.modal.session?.title}</p>
                </div>
                <div>
                    <h6>{t("task_order")}:</h6>
                    <p>{props.modal.session?.steps}</p>
                </div>
                <div>
                    <h6>{t("followed_task_status")}:</h6>
                    <p>{props.modal.session?.status}</p>
                </div>
                <div>
                    <h6>{t("followed_task_authorized_person")}:</h6>
                    <p>{authorizedPersonsNames.join(", ")}</p>
                </div>
                <div>
                    <h6>{t("followed_task_start_date")}:</h6>
                    {props.modal.session?.start_date ? (
                        <p>{moment(props.modal.session?.start_date).format("DD-MM-YYYY")}</p>
                    ) : 
                        ""
                    }
                </div>
                <div>
                    <h6>{t("followed_task_completion_date")}:</h6>
                    {props.modal.session?.completion_date ? (
                        <p>{moment(props.modal.session?.completion_date).format("DD-MM-YYYY")}</p>
                    ) : 
                        ""
                    }
                </div>
                <hr />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t("Close")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
