import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import { NavLink, useParams } from "react-router-dom";
import { AuthLayout } from "../../layouts";
import { RootState } from "../../../store/reducers";
import { Alert, Button, Form } from "react-bootstrap";
import { FormAlert, LoadingIndicator } from "../../partials";
import { IUpdatePasswordParams, IUpdatePasswordState } from "../../../store/types";
import { AlertDefaultState, AlertStateType } from "../../../types";
import actions from "../../../store/actions";
import history from "../../../utils/history";

type RouterParamsType = {
  token: string;
};

type FormDataType = {
  password: string;
  password_confirmation: string;
};

export const UpdatePassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { token } = useParams<RouterParamsType>();

  const [updateSuccess, setUpdateSuccess] = useState<boolean>(false);

  const { isLoading, response, error } = useSelector<RootState, IUpdatePasswordState>(
    (state: RootState) => state.updatePassword
  );

  const [formAlert, setFormAlert] = useState<AlertStateType>(AlertDefaultState);

  const { register, handleSubmit, watch, reset, errors } = useForm<FormDataType>();
  const onSubmit = async (data: FormDataType) => {
    const params: IUpdatePasswordParams = {
      password: data.password,
      password_confirmation: data.password_confirmation,
    };

    setFormAlert(AlertDefaultState);
    dispatch(await actions.updatePassword(token, params));
  };

  useEffect(() => {
    if (error !== null) {
      if (error.response.status > 400) {
        addToast(t("lost_password_update_invalid_request"), {
          appearance: "error",
          autoDismiss: true,
        });
        dispatch({ type: "UPDATE_PASSWORD_RESET" });
        history.push("/auth/login");
      } else {
        addToast(t("unknown_error"), {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }

    if (response !== null) {
      setUpdateSuccess(true);
    }
  }, [isLoading, response, error, addToast, t, dispatch]);

  useEffect(() => {
    if (updateSuccess) {
      reset();

      addToast(t("login_with_new_password"), {
        appearance: "success",
        autoDismiss: true,
      });

      dispatch({ type: "UPDATE_PASSWORD_RESET" });

      history.push("/auth/login");
    }
  }, [updateSuccess, reset, addToast, dispatch, t]);

  return (
    <>
      <AuthLayout>
        <Alert variant="secondary">{t("lost_password_update_notice")}</Alert>

        <FormAlert variant={formAlert.variant} show={formAlert.show}>
          {formAlert.messages.map((message, key) => {
            return <p key={key}>{message}</p>;
          })}
        </FormAlert>

        <LoadingIndicator show={isLoading} absolute={true} />

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group controlId="update-password">
            <Form.Label>{t("password")}:</Form.Label>
            <Form.Control
              type="password"
              name="password"
              ref={register({
                minLength: {
                  value: 6,
                  message: t("validation.min", { min: 6 }),
                },
                maxLength: {
                  value: 100,
                  message: t("validation.max", { max: 100 }),
                },
              })}
              placeholder={t("password")}
              isInvalid={!!errors.password}
            />
            <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="update-password-confirmation">
            <Form.Label>{t("password_confirmation")}:</Form.Label>
            <Form.Control
              type="password"
              name="password_confirmation"
              ref={register({
                validate: (value) => value === watch("password") || t("validation.passwords_must_match").toString(),
              })}
              placeholder={t("password_confirmation")}
              isInvalid={!!errors.password_confirmation}
            />
            <Form.Control.Feedback type="invalid">{errors.password_confirmation?.message}</Form.Control.Feedback>
          </Form.Group>

          <Button type="submit" variant="secondary" block disabled={!watch("password")}>
            {t("send")}
          </Button>

          <hr />

          <div className="text-center">
            <NavLink to="/auth/login" className="text-muted">
              {t("login")}
            </NavLink>
          </div>
        </Form>
      </AuthLayout>
    </>
  );
};

export default UpdatePassword;
