import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconType } from "react-icons/lib";
import { RiEmotionUnhappyLine } from "react-icons/all";

type ComponentsPropType = {
  icon?: IconType;
  message?: string;
};

export const NotFound = (props: ComponentsPropType) => {
  const { t } = useTranslation();

  const [icon, setIcon] = useState<IconType>(RiEmotionUnhappyLine);
  const [message, setMessage] = useState<string>(t("not_found"));

  useEffect(() => {
    if (props.icon) {
      setIcon(props.icon);
    }

    if (props.message) {
      setMessage(props.message);
    }
  }, [props]);

  return (
    <div className="not-found-block">
      {icon}
      <span>{message}</span>
    </div>
  );
};

export const ContentNotFound = (props: ComponentsPropType) => {
  const { t } = useTranslation();

  return <NotFound message={t("content_not_found")} {...props} />;
};

export const RecordNotFound = (props: ComponentsPropType) => {
  const { t } = useTranslation();

  return <NotFound message={t("record_not_found")} {...props} />;
};
