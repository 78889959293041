import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";

type ComponentPropType = {
  name: string;
  multiple?: boolean;
  dropzone?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  value?: string | string[] | number;
  accept?: string;
  isInvalid?: boolean;
  reset?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
};

export const CustomFileInput = React.forwardRef(
  (
    { name, multiple, dropzone, readOnly, disabled, value, accept, isInvalid, reset, onChange }: ComponentPropType,
    ref: any
  ) => {
    const { t } = useTranslation();
    const [label, setLabel] = useState<string>(t("select_file"));
    const [selectedFiles, setSelectedFiles] = useState<Array<string>>([]);

    /**
     * @param event
     */
    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setLabel(t("select_file"));
      setSelectedFiles([]);

      if (event.target.files instanceof FileList) {
        const fileSources: Array<string> = [];

        const filenames = Array.from(event.target.files).map((file: File) => {
          fileSources.push(URL.createObjectURL(file));
          return file.name;
        });

        setSelectedFiles(fileSources);

        setLabel(filenames.join(", "));
      }
    };

    useEffect(() => {
      if (reset) {
        setSelectedFiles([]);
      }
    }, [reset]);

    return (
      <>
        <div className={"custom-file-input-container" + (dropzone ? " dropzone-container" : "")}>
          {dropzone && selectedFiles.length > 0 && (
            <div className="file-list">
              {selectedFiles.map((source, key) => {
                return <div className="item" key={key} style={{ backgroundImage: `url('${source}')` }} />;
              })}
            </div>
          )}
          <Form.File
            name={name}
            multiple={multiple}
            readOnly={readOnly}
            disabled={disabled}
            value={value}
            accept={accept}
            isInvalid={isInvalid}
            label={dropzone ? "" : label}
            data-browse={dropzone ? t("browse_or_drop") : t("browse")}
            defaultValue=""
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (onChange) {
                onChange(event);
              }

              handleOnChange(event);
            }}
            ref={ref}
            className={dropzone ? "dropzone" : ""}
            custom
          />
        </div>
      </>
    );
  }
);
