import { Dispatch } from "redux";
import { AdminApiClient } from "../../utils/http-client";
import { IMentoringProgramParams, IMentoringProgramCreateParams,IPaginationParams, IMentoringProgramsExportParams } from "../types";

const adminApiClient = AdminApiClient.getInstance();

export const mentoringPrograms = (queryParams?: IPaginationParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "MENTORING_PROGRAMS_START" });
  await adminApiClient
    .mentoringPrograms(queryParams)
    .then((response) => {
      dispatch({ type: "MENTORING_PROGRAMS_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "MENTORING_PROGRAMS_FAILURE", payload: error }));
};

export const mentoringProgramsExport = (queryParams?: IMentoringProgramsExportParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "MENTORING_PROGRAMS_EXPORT_START" });
  await adminApiClient
    .mentoringProgramsExport(queryParams)
    .then((response) => {
      dispatch({ type: "MENTORING_PROGRAMS_EXPORT_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "MENTORING_PROGRAMS_EXPORT_FAILURE", payload: error }));
};

export const mentoringProgram = (id: string) => async (dispatch: Dispatch) => {
    dispatch({ type: "MENTORING_PROGRAM_START" });
    await adminApiClient
        .mentoringProgram(id)
        .then((response) => {
        dispatch({ type: "MENTORING_PROGRAM_SUCCESS", payload: response });
        })
        .catch((error) => dispatch({ type: "MENTORING_PROGRAM_FAILURE", payload: error }));
    };

export const createMentoringProgram = (params: IMentoringProgramCreateParams) => async (dispatch: Dispatch) => {
    dispatch({ type: "CREATE_MENTORING_PROGRAM_START" });
    await adminApiClient
        .createMentoringProgram(params)
        .then((response) => {
        dispatch({ type: "CREATE_MENTORING_PROGRAM_SUCCESS", payload: response });
        })
        .catch((error) => dispatch({ type: "CREATE_MENTORING_PROGRAM_FAILURE", payload: error }));
    };

export const updateMentoringProgram = (id: string, params: IMentoringProgramParams) => async (dispatch: Dispatch) => {
    dispatch({ type: "UPDATE_MENTORING_PROGRAM_START" });
    await adminApiClient
        .updateMentoringProgram(id, params)
        .then((response) => {
        dispatch({ type: "UPDATE_MENTORING_PROGRAM_SUCCESS", payload: response });
        })
        .catch((error) => dispatch({ type: "UPDATE_MENTORING_PROGRAM_FAILURE", payload: error }));
    };

export const deleteMentoringProgram = (id: string) => async (dispatch: Dispatch) => {
    dispatch({ type: "DELETE_MENTORING_PROGRAM_START" });
    await adminApiClient
        .deleteMentoringProgram(id)
        .then((response) => {
        dispatch({ type: "DELETE_MENTORING_PROGRAM_SUCCESS", payload: response });
        })
        .catch((error) => dispatch({ type: "DELETE_MENTORING_PROGRAM_FAILURE", payload: error }));
    };
