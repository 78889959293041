import { Dispatch } from "redux";
import { AdminApiClient } from "../../utils/http-client";
import { IAppointmentsPaginationParams, IAppointmentsExportParams } from "../types";

const adminApiClient = AdminApiClient.getInstance();

export const appointments = (queryParams?: IAppointmentsPaginationParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "APPOINTMENTS_START" });
  await adminApiClient
    .appointments(queryParams)
    .then((response) => {
      dispatch({ type: "APPOINTMENTS_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "APPOINTMENTS_FAILURE", payload: error }));
};

export const appointmentsExport = (queryParams?: IAppointmentsExportParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "APPOINTMENTS_EXPORT_START" });
  await adminApiClient
    .appointmentsExport(queryParams)
    .then((response) => {
      dispatch({ type: "APPOINTMENTS_EXPORT_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "APPOINTMENTS_EXPORT_FAILURE", payload: error }));
};

export const appointment = (id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: "APPOINTMENT_START" });
  await adminApiClient
    .appointment(id)
    .then((response) => {
      dispatch({ type: "APPOINTMENT_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "APPOINTMENT_FAILURE", payload: error }));
};
