import React from "react";
import { Breadcrumb as BsBreadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import { RiDashboard3Line } from "react-icons/ri";
import useReactRouterBreadcrumbs from "use-react-router-breadcrumbs";
import { flatRoutes } from "../../router";
import { routes } from "../../config";

interface BreadcrumbProps {
  showReportProgram?: boolean;
  showEdit?: boolean;
  showMatch?: boolean;
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ showReportProgram, showEdit,showMatch }) => {
  const breadcrumbs = useReactRouterBreadcrumbs(flatRoutes(routes));

  return (
    <BsBreadcrumb className="app-breadcrumb">
      {showReportProgram ? (
        <>
          <BsBreadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
            Başlangıç
          </BsBreadcrumb.Item>
          <BsBreadcrumb.Item linkAs={Link} linkProps={{ to: "/matches" }}>
            Eşleştirme İşlemleri
          </BsBreadcrumb.Item>
          <BsBreadcrumb.Item active>Report Program</BsBreadcrumb.Item>
        </>
      ) : showEdit ? (
        <>
        <BsBreadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
          Başlangıç
        </BsBreadcrumb.Item>
        <BsBreadcrumb.Item linkAs={Link} linkProps={{ to: "/matches" }}>
          Eşleştirme İşlemleri
        </BsBreadcrumb.Item>
        <BsBreadcrumb.Item active>
          Düzenle
        </BsBreadcrumb.Item>
        </>
      ) : showMatch ? (
        <>
        <BsBreadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
          Başlangıç
        </BsBreadcrumb.Item>
        <BsBreadcrumb.Item linkAs={Link} linkProps={{ to: "/matches" }}>
          Eşleştirme İşlemleri
        </BsBreadcrumb.Item>
        <BsBreadcrumb.Item active>
          Eşleştirme
        </BsBreadcrumb.Item>
        </>
      )
      :
      
      (
        breadcrumbs.map(({ match, breadcrumb }, key) => (
          <BsBreadcrumb.Item linkAs={Link} linkProps={{ to: match.url }} key={key}>
            {breadcrumb}
          </BsBreadcrumb.Item>
        ))
      )}
    </BsBreadcrumb>
  );
};
