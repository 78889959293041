import { app as config } from "../config/app";
import history from "./history";
import { ILocationStateType, ApplicationStatusTypes } from "../types";
import { MatchType, MentorApplicationType, UserApplicationType } from "../store/types";
import * as moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";

momentDurationFormatSetup(moment);

/**
 * Storage URL generator.
 * @param path
 */
export const storageURL = (path: string) => {
  return config.STORAGE_URL.replace(/^\/|\/$/g, "").concat("/", path.replace(/^\/|\/$/g, ""));
};

/**
 * Picture storage URL generator.
 * @param path
 */
export const pictureUrl = (path: string) => {
  return storageURL("/picture/" + path + "?" + Math.random());
};

/**
 * New line to br.
 * @param content
 */
export const nl2br = (content: string): string => {
  return content.replace(/\r\n|\r|\n/g, "<br>");
};

/**
 * File object to base64 string.
 * @param file
 * @param onlyContent
 */
export const fileObjectToBase64 = async (file: File, onlyContent: boolean = true): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    if (file instanceof File) {
      reader.onloadend = (event: any) => {
        const result = onlyContent ? String(event.target.result).split(",")[1] : event.target.result;

        return resolve(result);
      };

      reader.onerror = (error) => {
        return reject(error);
      };

      reader.readAsDataURL(file);
    } else {
      return reject("Incorrect file object!");
    }
  });
};

/**
 * Get router flash state.
 */
export const getRouterFlashState = () => {
  const state = history.location.state as ILocationStateType | null;

  if (state && state.flash !== undefined) {
    return state.flash;
  }

  return null;
};

/**
 * Clear router flash state.
 */
export const clearRouterFlashState = () => {
  const state = history.location.state as ILocationStateType | null;

  if (state) {
    history.replace({
      state: {
        flash: undefined,
      },
    });
  }
};

/**
 * Get application status.
 * @param application
 */
export const getApplicationStatus = (application: MentorApplicationType | UserApplicationType): string => {
  let status = ApplicationStatusTypes.waiting;

  if (application.approved_at) {
    status = ApplicationStatusTypes.approved;
  } else if (application.rejected_at) {
    status = ApplicationStatusTypes.rejected;
  }

  return status;
};

/**
 * Get application status table row class name.
 * @param application
 */
export const getApplicationStatusClassName = (application: MentorApplicationType | UserApplicationType): string => {
  let className = "warning";

  if (application.approved_at) {
    className = "success";
  } else if (application.rejected_at) {
    className = "danger";
  }

  return className;
};

export const getMatchStatusClassName = (match: MatchType): string => {
  const now = new Date();
  const startDate = new Date(match.start_at);
  const endDate = new Date(match.end_at);

  let className: string = "";

  if (startDate <= now && endDate >= now) {
    className = "green";
  } else if (endDate < now) {
    className = "red";
  } else {
    className = "orange";
  }

  return className;
};
/**
 * Get formatted duration.
 * @param duration
 */
export const getFormattedDuration = (duration: string | null): string => {
  if (!duration) {
    return "-";
  }

  return moment.duration(Number(duration), "seconds").format("hh:mm:ss", {trim: false});
};

/**
 * Generate random password.
 * @param length
 */
export const generateRandomPassword = (length: number = 8) => {
  const Allowed = {
    Chars: 'qwertyuiopasdfghjklzxcvbnm',
    Numbers: '1234567890',
    Symbols: '!@#$%^&*.?'
  };

  const randomIntFromInterval = (min: number, max: number) => { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  const getRandomCharFromString = (str: string) => str.charAt(Math.floor(randomIntFromInterval(0, str.length)))

  let pwd = '';
  pwd += getRandomCharFromString(Allowed.Chars.toUpperCase());
  pwd += getRandomCharFromString(Allowed.Chars.toLowerCase());
  pwd += getRandomCharFromString(Allowed.Numbers);
  pwd += getRandomCharFromString(Allowed.Symbols);
  for (let i = pwd.length; i < length; i++) {
    pwd += getRandomCharFromString(Object.values(Allowed).join(''));
  }

  return pwd;
};
