import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { MainLayout } from "../../layouts";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { RiArrowLeftSLine, RiCheckFill } from "react-icons/all";
import { Controller, useForm } from "react-hook-form";
import { RootState } from "../../../store/reducers";
import { IBlogPostParams, IBlogPostState, ICategoriesState } from "../../../store/types";
import { AlertDefaultState, AlertStateType } from "../../../types";
import { CustomFileInput, FormAlert, WysiwygEditor } from "../../partials";
import Select from "react-select";
import actions from "../../../store/actions";
import { fileObjectToBase64 } from "../../../utils/mixins";
import history from "../../../utils/history";

export type CategorySelectOptionType = {
  value: string;
  label: string;
};

type FormDataType = {
  title: string;
  summary: string;
  content: string;
  pictures: FileList;
  categories: Array<CategorySelectOptionType>;
  status: string;
  description?: string | null;
  keywords?: string | null;
};

export const Create = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const [categories, setCategories] = useState<Array<CategorySelectOptionType>>([]);

  const { response: categoriesResponse } = useSelector<RootState, ICategoriesState>(
    (state: RootState) => state.categories
  );

  useEffect(() => {
    if (categoriesResponse) {
      if (categoriesResponse.data.items.length > 0) {
        const categoryOptions: Array<CategorySelectOptionType> = categoriesResponse.data.items.map((category) => {
          return {
            value: category.id,
            label: category.title,
          };
        });
        setCategories(categoryOptions);
      }
    } else {
      dispatch(actions.categories({ page: 1, per: 9999 }));
    }
  }, [categoriesResponse, dispatch]);

  const { register, control, handleSubmit, errors, reset } = useForm<FormDataType>();
  const onSubmit = async (data: FormDataType) => {
    setFormDisable(true);
    setFormAlert(AlertDefaultState);

    const params: IBlogPostParams = {
      title: data.title,
      summary: data.summary,
      content: data.content,
      pictures: [],
      categories: [],
      is_active: data.status === "active",
      description: data.description,
      keywords: data.keywords,
    };

    let pictures: Promise<string>[] = [];
    if (data.pictures.length > 0) {
      pictures = Array.from(data.pictures).map(async (picture) => {
        return await fileObjectToBase64(picture);
      });
    }

    if (data.categories.length > 0) {
      params.categories = data.categories.map((category) => {
        return category.value;
      });
    }

    Promise.all(pictures).then(async (values) => {
      params.pictures = values;
      dispatch(await actions.createBlogPost(params));
    });
  };

  const { isLoading, response, error } = useSelector<RootState, IBlogPostState>(
    (state: RootState) => state.createBlogPost
  );

  const [formDisable, setFormDisable] = useState<boolean>(false);
  const [formAlert, setFormAlert] = useState<AlertStateType>(AlertDefaultState);

  useEffect(() => {
    setFormDisable(isLoading);

    if (error !== null) {
      if (error.response.status === 400) {
        setFormAlert({
          variant: "danger",
          show: true,
          messages: error.response.data.messages,
        });
      } else {
        addToast(t("unknown_error"), {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }

    if (response) {
      addToast(t("created_with_param", { param: t("blog_post") }), {
        appearance: "success",
        autoDismiss: true,
      });

      reset();

      dispatch({ type: "CREATE_BLOG_POST_RESET" });
      history.push(`/blog-posts/${response.data.id}`);
    }
  }, [isLoading, response, error, addToast, reset, t, dispatch]);

  return (
    <MainLayout>
      <FormAlert variant={formAlert.variant} show={formAlert.show} to={formAlert.to}>
        {formAlert.messages.map((message, key) => {
          return <p key={key}>{message}</p>;
        })}
      </FormAlert>

      <Card className={formDisable ? "loading-block" : ""}>
        <Card.Header as="h6">{t("create")}</Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
              <Form.Group as={Col} md="12" controlId="blog-post-categories">
                <Form.Label>{t("categories")}:</Form.Label>
                <Controller
                  control={control}
                  name="categories"
                  defaultValue={[]}
                  render={({ onChange, value, ref }) => (
                    <Select
                      isMulti
                      isClearable
                      inputRef={ref}
                      placeholder={t("select_category")}
                      noOptionsMessage={() => t("not_found")}
                      options={categories}
                      value={categories.find((category) => category.value === value)}
                      onChange={(value: any) => onChange(value)}
                    />
                  )}
                  ref={register}
                  rules={{
                    required: {
                      value: true,
                      message: t("validation.you_must_choose", { name: t("category") }),
                    },
                  }}
                  isInvalid={!!errors.categories}
                />
              </Form.Group>
              <Form.Control.Feedback type="invalid">
                {errors.categories ? t("validation.you_must_choose", { name: t("category") }) : ""}
              </Form.Control.Feedback>
            </Form.Row>
            <hr />
            <Form.Row>
              <Form.Group as={Col} md="12" controlId="blog-post-title">
                <Form.Label>{t("title")}:</Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  ref={register({
                    required: {
                      value: true,
                      message: t("validation.you_must_enter", { name: t("title") }),
                    },
                  })}
                  placeholder={t("title")}
                  defaultValue=""
                  autoFocus
                  isInvalid={!!errors.title}
                />
                <Form.Control.Feedback type="invalid">{errors.title?.message}</Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <hr />
            <Form.Row>
              <Form.Group as={Col} md="12" controlId="news-summary">
                <Form.Label>{t("summary")}:</Form.Label>
                <Form.Control
                  type="text"
                  as="textarea"
                  name="summary"
                  ref={register({
                    required: {
                      value: true,
                      message: t("validation.you_must_enter", { name: t("summary") }),
                    },
                  })}
                  placeholder={t("summary")}
                  defaultValue=""
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="12" controlId="blog-post-content">
                <Form.Label>{t("content")}:</Form.Label>
                <Controller
                  control={control}
                  name="content"
                  ref={register({
                    required: {
                      value: true,
                      message: t("validation.you_must_enter", { name: t("content") }),
                    },
                  })}
                  placeholder={t("content")}
                  defaultValue=""
                  render={({ onChange, ref }) => {
                    return (
                      <WysiwygEditor defaultValue="" onChange={(content: string) => onChange(content)} ref={ref} />
                    );
                  }}
                  isInvalid={!!errors.content}
                />
                <Form.Control.Feedback type="invalid">{errors.content?.message}</Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <hr />
            <Form.Row>
              <Form.Group as={Col} md="12" controlId="blog-post-content">
                <Form.Label>{t("pictures")}:</Form.Label>
                <CustomFileInput
                  name="pictures"
                  accept="image/jpeg,image/jpg,image/png"
                  multiple
                  dropzone
                  ref={register}
                  isInvalid={!!errors.pictures}
                />
                <Form.Control.Feedback type="invalid">{errors.pictures?.message}</Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <hr />
            <hr />
            <Form.Row>
              <Form.Group as={Col} md="4" controlId="blog-post-description">
                <Form.Label>{t("description")}:</Form.Label>
                <Form.Control
                  type="text"
                  as="textarea"
                  name="description"
                  ref={register}
                  placeholder={t("description")}
                  defaultValue=""
                />
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="blog-post-keywords">
                <Form.Label>{t("keywords")}:</Form.Label>
                <Form.Control
                  type="text"
                  as="textarea"
                  name="keywords"
                  ref={register}
                  placeholder={t("keywords")}
                  defaultValue=""
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="3" controlId="blogPost-status">
                <Form.Label>{t("status")}</Form.Label>
                <Form.Control
                  as="select"
                  custom
                  name="status"
                  ref={register({
                    required: {
                      value: true,
                      message: t("validation.you_must_choose", { name: t("status") }),
                    },
                  })}
                  placeholder={t("status")}
                  isInvalid={!!errors.status}
                >
                  <option value="">{t("select_status")}</option>
                  <option value="active">{t("active")}</option>
                  <option value="passive">{t("passive")}</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.status?.message}</Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
          </Form>
        </Card.Body>
        <Card.Footer>
          <Row>
            <Col xs="6" className="text-left">
              <Button as={NavLink} to="/blog-posts" variant="secondary" size="sm" className="with-icon">
                <RiArrowLeftSLine />
                <span>{t("list")}</span>
              </Button>
            </Col>
            <Col xs="6" className="text-right">
              <Button variant="primary" size="sm" className="with-icon" onClick={handleSubmit(onSubmit)}>
                <RiCheckFill />
                <span>{t("save")}</span>
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </MainLayout>
  );
};

export default Create;
