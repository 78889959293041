import { combineReducers } from "redux";
import { loginReducer, refreshReducer, passwordReducer, updatePasswordReducer } from "./auth";
import { meReducer, updateProfileReducer, logoutReducer } from "./userOperations";
import { deletePictureReducer } from "./general";
import { dashboardReducer } from "./dashboard";
import { citiesReducer, districtsReducer } from "./cityAndDistricts";
import {
  categoriesReducer,
  orderCategoryReducer,
  categoryReducer,
  createCategoryReducer,
  updateCategoryReducer,
  deleteCategoryReducer,
} from "./categories";
import {
  sectorsReducer,
  sectorReducer,
  createSectorReducer,
  updateSectorReducer,
  deleteSectorReducer,
} from "./sectors";
import {
  subSectorsReducer,
  subSectorReducer,
  createSubSectorReducer,
  updateSubSectorReducer,
  deleteSubSectorReducer,
} from "./subSectors";
import {
  pagesReducer,
  orderPageReducer,
  pageReducer,
  createPageReducer,
  updatePageReducer,
  deletePageReducer,
} from "./pages";
import {
  contractsReducer,
  contractReducer,
  createContractReducer,
  updateContractReducer,
  deleteContractReducer,
} from "./contracts";
import { newsListReducer, newsReducer, createNewsReducer, updateNewsReducer, deleteNewsReducer } from "./news";
import {
  blogPostsReducer,
  blogPostReducer,
  createBlogPostReducer,
  updateBlogPostReducer,
  deleteBlogPostReducer,
} from "./blogPosts";
import { faqsReducer, faqReducer, createFaqReducer, updateFaqReducer, deleteFaqReducer } from "./faqs";
import { contactReducer, updateContactReducer } from "./contact";
import {
  usersReducer,
  usersExportReducer,
  userReducer,
  createUserReducer,
  updateUserReducer,
  deleteUserReducer,
} from "./users";
import { matchReducer,updateMatchReducer,createMatchReducer,deleteMatchReducer } from "./matches";
import { teamsReducer, teamReducer, createTeamReducer, updateTeamReducer, deleteTeamReducer } from "./teams";
import { mentoringProgramsReducer, mentoringProgramReducer, createMentoringProgramReducer, updateMentoringProgramReducer, deleteMentoringProgramReducer } from "./mentoringPrograms";
import { sessionsReducer, sessionReducer, createSessionReducer, updateSessionReducer, deleteSessionReducer } from "./sessions";
import { featuredMentorsReducer, updateFeaturedMentorReducer } from "./featured";
import {
  feedbacksReducer,
  createFeedbackReducer,
  feedbackReducer,
  updateFeedbackReducer,
  deleteFeedbacksReducer,
} from "./feedbacks";
import {
  mentorApplicationsReducer,
  mentorApplicationReducer,
  evaluateMentorApplicationReducer,
  deleteMentorApplicationReducer,
} from "./mentorApplications";
import {
  userApplicationsReducer,
  userApplicationReducer,
  evaluateUserApplicationReducer,
  deleteUserApplicationReducer,
} from "./userApplications";
import { appointmentsReducer, appointmentsExportReducer, appointmentReducer } from "./appointments";
import { appointmentRecordsReducer, appointmentRecordReducer } from "./appointmentRecords";
import { logsListReducer } from "./logs";
import { supportListReducer, supportReducer } from "./support";
import { createFollowedTaskReducer, deleteFollowedTaskReducer,updateFollowedTaskReducer } from "./followedTasks";

const reducers = combineReducers({
  login: loginReducer,
  refresh: refreshReducer,
  password: passwordReducer,
  updatePassword: updatePasswordReducer,
  me: meReducer,
  updateProfile: updateProfileReducer,
  logout: logoutReducer,
  dashboard: dashboardReducer,
  deletePicture: deletePictureReducer,
  cities: citiesReducer,
  districts: districtsReducer,
  categories: categoriesReducer,
  orderCategory: orderCategoryReducer,
  category: categoryReducer,
  createCategory: createCategoryReducer,
  updateCategory: updateCategoryReducer,
  deleteCategory: deleteCategoryReducer,
  sectors: sectorsReducer,
  sector: sectorReducer,
  createSector: createSectorReducer,
  updateSector: updateSectorReducer,
  deleteSector: deleteSectorReducer,
  subSectors: subSectorsReducer,
  subSector: subSectorReducer,
  createSubSector: createSubSectorReducer,
  updateSubSector: updateSubSectorReducer,
  deleteSubSector: deleteSubSectorReducer,
  pages: pagesReducer,
  orderPage: orderPageReducer,
  page: pageReducer,
  createPage: createPageReducer,
  updatePage: updatePageReducer,
  deletePage: deletePageReducer,
  contracts: contractsReducer,
  contract: contractReducer,
  createContract: createContractReducer,
  updateContract: updateContractReducer,
  deleteContract: deleteContractReducer,
  newsList: newsListReducer,
  news: newsReducer,
  createNews: createNewsReducer,
  updateNews: updateNewsReducer,
  deleteNews: deleteNewsReducer,
  blogPosts: blogPostsReducer,
  blogPost: blogPostReducer,
  createBlogPost: createBlogPostReducer,
  updateBlogPost: updateBlogPostReducer,
  deleteBlogPost: deleteBlogPostReducer,
  faqs: faqsReducer,
  faq: faqReducer,
  createFaq: createFaqReducer,
  updateFaq: updateFaqReducer,
  deleteFaq: deleteFaqReducer,
  contact: contactReducer,
  updateContact: updateContactReducer,
  mentorApplications: mentorApplicationsReducer,
  mentorApplication: mentorApplicationReducer,
  evaluateMentorApplication: evaluateMentorApplicationReducer,
  deleteMentorApplication: deleteMentorApplicationReducer,
  userApplications: userApplicationsReducer,
  userApplication: userApplicationReducer,
  evaluateUserApplication: evaluateUserApplicationReducer,
  deleteUserApplication: deleteUserApplicationReducer,
  users: usersReducer,
  usersExport: usersExportReducer,
  user: userReducer,
  createUser: createUserReducer,
  updateUser: updateUserReducer,
  deleteUser: deleteUserReducer,
  featuredUsers: featuredMentorsReducer,
  teams: teamsReducer,
  team: teamReducer,
  createTeam: createTeamReducer,
  updateTeam: updateTeamReducer,
  deleteTeam: deleteTeamReducer,
  mentoringPrograms:mentoringProgramsReducer,
  mentoringProgram: mentoringProgramReducer,
  createMentoringProgram: createMentoringProgramReducer,
  updateMentoringProgram: updateMentoringProgramReducer,
  deleteMentoringProgram: deleteMentoringProgramReducer,
  match: matchReducer,
  updateMatch: updateMatchReducer,
  createMatch: createMatchReducer,
  deleteMatch: deleteMatchReducer,
  sessions:sessionsReducer,
  session:sessionReducer,
  createSession:createSessionReducer,
  updateSession:updateSessionReducer,
  deleteSession:deleteSessionReducer,
  createFollowedTask: createFollowedTaskReducer,
  updateFollowedTask: updateFollowedTaskReducer,
  deleteFollowedTask: deleteFollowedTaskReducer,
  feedbacks: feedbacksReducer,
  createFeedback: createFeedbackReducer,
  feedback: feedbackReducer,
  updateFeedback: updateFeedbackReducer,
  deleteFeedback: deleteFeedbacksReducer,
  updateFeaturedUser: updateFeaturedMentorReducer,
  appointments: appointmentsReducer,
  appointmentsExport: appointmentsExportReducer,
  appointment: appointmentReducer,
  appointmentRecords: appointmentRecordsReducer,
  appointmentRecord: appointmentRecordReducer,
  logs: logsListReducer,
  supports: supportListReducer,
  support: supportReducer,
});

export type RootState = ReturnType<typeof reducers>;
export default reducers;
