import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import qs from "query-string";
import { useToasts } from "react-toast-notifications";
import { MainLayout } from "../../../layouts";
import { Button, ButtonGroup, Card, Col, Form, Row, Table } from "react-bootstrap";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { RiAddBoxLine, RiDeleteBin6Line, RiEditBoxLine, RiEyeLine } from "react-icons/all";
import { RootState } from "../../../../store/reducers";
import { ISectorsState, ISubSectorsPaginationParams, ISubSectorsState } from "../../../../store/types";
import { DeleteConfirmModal, LoadingIndicator, Pagination, RecordNotFound } from "../../../partials";
import actions from "../../../../store/actions";
import { DEFAULT_PER_PAGE } from "../../../../types";

export const List = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const filterSectorRef = useRef<HTMLSelectElement>(null);
  const filterQRef = useRef<HTMLInputElement>(null);

  const { isLoading, response, error } = useSelector<RootState, ISubSectorsState>(
    (state: RootState) => state.subSectors
  );

  const { response: sectorsResponse } = useSelector<RootState, ISectorsState>((state: RootState) => state.sectors);

  const { response: deleteResponse } = useSelector<RootState, ISubSectorsState>(
    (state: RootState) => state.deleteSubSector
  );

  const getSubSectors = useCallback(
    (queryParams?) => {
      dispatch(actions.subSectors(queryParams));
    },
    [dispatch]
  );

  const queryString = new URLSearchParams(useLocation().search);
  const page: number = queryString.get("page") ? Number(queryString.get("page")) : 1;
  const sector: string | null = queryString.get("sector") ? queryString.get("sector") : null;
  const q: string | null = queryString.get("q") ? queryString.get("q") : null;

  const [queryParams, setQueryParams] = useState<ISubSectorsPaginationParams>({ page: page, per: DEFAULT_PER_PAGE });
  const qParams = useMemo<ISubSectorsPaginationParams>(() => qs.parse(search), [search]);
  useEffect(() => {
    const params = qParams;
    params.per = DEFAULT_PER_PAGE;
    setQueryParams(params);
    getSubSectors(params);
  }, [getSubSectors, qParams]);

  const handleSubmitFilter = () => {
    let url = `/sector-management/sub-sectors?page=1`;
    const searchParams: Array<string> = [];

    if (filterSectorRef.current!.value) {
      searchParams.push(`sector=${filterSectorRef.current!.value}`);
      queryParams.sector = filterSectorRef.current!.value;
    }

    if (filterQRef.current!.value) {
      searchParams.push(`q=${filterQRef.current!.value}`);
      queryParams.q = filterQRef.current!.value;
    }

    if (searchParams.length) {
      url += "&" + searchParams.join("&");
    }

    getSubSectors(queryParams);

    history.push(url);
  };

  useEffect(() => {
    dispatch(actions.sectors());
  }, [dispatch]);

  const [deleteItem, setDeleteItem] = useState<string | null>(null);
  const onDeleteConfirm = async () => {
    if (deleteItem) {
      dispatch(await actions.deleteSubSector(deleteItem));
      setDeleteItem(null);
    }
  };

  useEffect(() => {
    if (deleteResponse) {
      addToast(t("deleted_with_param", { param: t("sub_sector") }), {
        appearance: "success",
        autoDismiss: true,
      });

      getSubSectors({ page, per: DEFAULT_PER_PAGE, sector, q });
      dispatch({ type: "DELETE_SUB_SECTOR_RESET" });
    }
  }, [deleteResponse, addToast, t, getSubSectors, page, sector, q, dispatch]);

  useEffect(() => {
    if (error?.response) {
      dispatch({ type: "SUB_SECTORS_RESET" });

      if (error.response.status >= 400) {
        history.push("/sector-management/sub-sectors");
      }
    } else {
      getSubSectors({ page, per: DEFAULT_PER_PAGE, sector, q });
    }
  }, [getSubSectors, page, sector, q, error, history, dispatch]);

  return (
    <MainLayout>
      <LoadingIndicator show={isLoading} />
      {response && (
        <Row>
          <Col md="9" className="order-last order-md-first">
            <Card>
              <Button
                as={NavLink}
                to="/sector-management/sub-sectors/create"
                variant="success"
                size="sm"
                className="with-icon"
              >
                <RiAddBoxLine />
                <span>{t("create")}</span>
              </Button>
              <Card.Header>
                <Row>
                  <Col as="h6" xs="8" className="mb-0">
                    {t("sub_sectors")}
                  </Col>
                  <Col xs="4" className="mb-0 text-right">
                    <strong className="mr-1">{t("total")}:</strong>
                    <span>{response.data.pagination.total}</span>
                  </Col>
                </Row>
              </Card.Header>

              <Card.Body className="p-0 m-0">
                {response.data.items.length > 0 ? (
                  <>
                    <DeleteConfirmModal
                      show={deleteItem !== null}
                      onClose={() => setDeleteItem(null)}
                      onConfirm={onDeleteConfirm}
                    />
                    <Table responsive striped hover className="table-list m-0">
                      <thead>
                        <tr>
                          <th>{t("name")}</th>
                          <th>{t("code")}</th>
                          <th>{t("sector")}</th>
                          <th className="actions">{t("actions")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {response.data.items.map((item, key) => {
                          return (
                            <tr key={key}>
                              <td>{item.name}</td>
                              <td>{item.code}</td>
                              <td>{item.sector.title}</td>
                              <td className="actions">
                                <ButtonGroup>
                                  <Button
                                    as={NavLink}
                                    to={`/sector-management/sub-sectors/${item.id}`}
                                    variant="outline-info"
                                    size={"sm"}
                                    className="btn-icon"
                                    title={t("show")}
                                  >
                                    <RiEyeLine />
                                  </Button>
                                  <Button
                                    as={NavLink}
                                    to={`/sector-management/sub-sectors/${item.id}/edit`}
                                    variant="outline-secondary"
                                    size={"sm"}
                                    className="btn-icon"
                                    title={t("edit")}
                                  >
                                    <RiEditBoxLine />
                                  </Button>
                                  <Button
                                    variant="outline-danger"
                                    size={"sm"}
                                    className="btn-icon"
                                    title={t("delete")}
                                    onClick={() => setDeleteItem(item.id)}
                                  >
                                    <RiDeleteBin6Line />
                                  </Button>
                                </ButtonGroup>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </>
                ) : (
                  <RecordNotFound />
                )}
              </Card.Body>

              <Card.Footer>
                <Row>
                  <Col xs="6">
                    <Pagination pagination={response.data.pagination} extraParams={["sector", "q"]} />
                  </Col>
                  <Col xs="6" className="text-right">
                    {/* <Button as={NavLink} to="/sector-management/sub-sectors/create" variant="success" size="sm" className="with-icon">
                    <RiAddBoxLine/>
                    <span>{t('create')}</span>
                  </Button> */}
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Col>
          <Col md="3" className="order-first order-md-last">
            <Card>
              <Card.Body>
                <Form onSubmit={handleSubmitFilter}>
                  <Form.Group controlId="filter-sector">
                    <Form.Label>{t("sector")}</Form.Label>
                    <Form.Control as="select" custom ref={filterSectorRef} defaultValue={sector ? sector : ""}>
                      <option value="">{t("all")}</option>
                      {sectorsResponse && sectorsResponse.data.items.length && (
                        <>
                          {sectorsResponse.data.items.map((item) => {
                            return <option value={item.id}>{item.title}</option>;
                          })}
                        </>
                      )}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="filter-q">
                    <Form.Label>{t("keywords")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="q"
                      ref={filterQRef}
                      placeholder={t("keywords")}
                      defaultValue={q ? q : ""}
                    />
                  </Form.Group>
                  <hr />
                  <Button variant="outline-secondary" block onClick={handleSubmitFilter}>
                    {t("filter")}
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </MainLayout>
  );
};

export default List;
