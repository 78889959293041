  import { Dispatch } from "redux";
  import { AdminApiClient } from "../../utils/http-client";
  import { IMatchParams, IMatchesCreateParams} from "../types";
  const adminApiClient = AdminApiClient.getInstance();

  export const Match = (tid: string,cid:any) => async (dispatch: Dispatch) => {
    dispatch({ type: "MATCH_START" });
    await adminApiClient
      .match(tid,cid)
      .then((response) => {
        dispatch({ type: "MATCH_SUCCESS", payload: response });
      })
      .catch((error) => dispatch({ type: "MATCH_FAILURE", payload: error }));
}


  export const updateMatch =(tid:string,cid:any,params:IMatchParams) => async (dispatch: Dispatch) => {
    dispatch({ type: "UPDATE_MATCH_START" });
      await adminApiClient
          .updateMatch(tid,cid,params)
          .then((response) => {
          dispatch({ type: "UPDATE_MATCH_SUCCESS", payload: response });
          })
          .catch((error) => dispatch({ type: "UPDATE_MATCH_FAILURE", payload: error }));
  };

  
  export const deleteMatch = (tid:string,cid:string) => async (dispatch: Dispatch) => {
    dispatch({ type: "DELETE_MATCH_START" });
    await adminApiClient
      .deleteMatch(tid,cid)
      .then((response) => {
        dispatch({ type: "DELETE_MATCH_SUCCESS", payload: response });
      })
      .catch((error) => dispatch({ type: "DELETE_MATCH_FAILURE", payload: error }));
    };

  export const createMatch = (tid:string,params:IMatchesCreateParams) => async (dispatch: Dispatch) => {
    dispatch({ type: "CREATE_MATCH_START" });
    await adminApiClient
      .createMatch(tid,params)
      .then((response) => {
        dispatch({ type: "CREATE_MATCH_SUCCESS", payload: response });
      })
      .catch((error) => dispatch({ type: "CREATE_MATCH_FAILURE", payload: error }));
  }