import React, { useCallback, useEffect, useState, useRef } from "react";
import { Modal, Button, Form, InputGroup, Col, Accordion, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { RootState } from "../../../store/reducers";

import {
  SectorType,
  IDistrictsState,
  IUserParams,
  ICitiesState,
  ISectorsState,
  IUserState,
  IUserSpeedCreateParams,
  ICategoriesState,
} from "../../../store/types";
import actions from "../../../store/actions";
import Select from "react-select";
import {
  AlertDefaultState,
  AlertStateType,
  EMAIL_REGEX_PATTERN,
  GenderTypes,
  BusinessTypes,
  EducationStatusTypes,
} from "../../../types";
import { FormAlert } from "../../partials";
import { Controller, useForm } from "react-hook-form";
import { RiAddBoxLine, RiDeleteBin6Line, RiCheckFill } from "react-icons/all";

export type OptionType = {
  value: string;
  label: string | null;
};
export type UserSelectorModalType = {
  show: boolean;
  clients: Array<OptionType>;
  type: "mentor" | "user";
};

export type SectorSelectOptionType = {
  value: string;
  label: string;
};

export const UserSelectorModalDefaultState: UserSelectorModalType = {
  show: false,
  type: "user",
  clients: [],
};

type UserSelectorModalPropsType = {
  modal: UserSelectorModalType;
  onClose: Function;
  onSubmit: Function;
};

export type UserSelectOptionType = {
  value: string;
  label: string;
};

export type UserSelectedType = {
  sector: UserSelectOptionType;
  sub_sector: Array<UserSelectOptionType>;
  sector_experience?: number;
};
export type SectorSelectedType = {
  sector: SectorSelectOptionType;
  sub_sector: Array<SectorSelectOptionType>;
  sector_experience?: number;
};

type CategorySelectOptionType = {
  value: string;
  label: string;
};

export const UserSelectorModal = (props: UserSelectorModalPropsType) => {
  const { addToast } = useToasts();
  const [isOpenUserSectorAccordion, setIsOpenUserSector] = useState(false);
  const [categories, setCategories] = useState<Array<CategorySelectOptionType>>([]);
  const [isOpenBusinessSectorAccordion, setIsOpenBusinessSector] = useState(false);
  const toggleBusinessSectorAccordion = () => {
    setIsOpenBusinessSector(!isOpenBusinessSectorAccordion);
  };
  const dispatch = useDispatch();
  const [selectedUserSectors, setSelectedUserSectors] = useState<Array<SectorSelectedType>>([]);
  const [selectedBusinessSectors, setSelectedBusinessSectors] = useState<Array<SectorSelectedType>>([]);
  const [sectors, setSectors] = useState<Array<SectorType>>([]);
  const [subSectorOptions, setSubSectorOptions] = useState<Array<SectorSelectOptionType>>([]);
  const [subSectorOptionsBusiness, setSubSectorOptionsBusiness] = useState<Array<SectorSelectOptionType>>([]);
  const sectorRef = useRef<HTMLSelectElement>(null);
  const subSectorRef = useRef<any>(null);
  const [selectedSubSectors, setSelectedSubSectors] = useState<Array<UserSelectOptionType>>([]);
  const [selectedSubSectorsBusiness, setSelectedSubSectorsBusiness] = useState<Array<UserSelectOptionType>>([]);

  const { response: sectorsResponse } = useSelector<RootState, ISectorsState>((state: RootState) => state.sectors);
  const handleDeleteBusinessSector = (data: SectorSelectedType) => {
    setSelectedBusinessSectors(selectedBusinessSectors.filter((item) => item.sector.value !== data.sector.value));
  };

  const { response: categoriesResponse } = useSelector<RootState, ICategoriesState>(
    (state: RootState) => state.categories
  );

  useEffect(() => {
    dispatch(actions.categories({ page: 1, per: 9999 }));
  }, [dispatch]);

  useEffect(() => {
    if (categoriesResponse) {
      if (categoriesResponse.data.items.length > 0) {
        const categoryOptions: Array<CategorySelectOptionType> = categoriesResponse.data.items.map((category) => {
          return {
            value: category.id,
            label: category.title,
          };
        });
        setCategories(categoryOptions);
      }
    } else {
      dispatch(actions.categories({ page: 1, per: 9999 }));
    }
  }, [categoriesResponse, dispatch]);

  useEffect(() => {
    if (!sectorsResponse) {
      dispatch(actions.sectors({ subsector: true }));
    } else {
      setSectors(sectorsResponse.data.items);
    }
  }, [sectorsResponse, dispatch]);

  const handleBusinessSectorChange = () => {
    if (!sectorRef.current!.value) {
      setSelectedBusinessSector(null);
      setSelectedSubSectorsBusiness([]);
      return;
    }

    const selectedBusinessSectorMatched = sectors.filter((sector) => sector.id === sectorRef.current!.value);
    if (selectedBusinessSectorMatched.length) {
      if (selectedBusinessSectorMatched[0].subsector) {
        setSelectedBusinessSector({
          label: `${selectedBusinessSectorMatched[0].title} / ${selectedBusinessSectorMatched[0].nace}`,
          value: selectedBusinessSectorMatched[0].id,
        });

        const subSectorsBag: Array<SectorSelectOptionType> = [];

        selectedBusinessSectorMatched[0].subsector.forEach((subSector) => {
          subSectorsBag.push({
            value: subSector.id,
            label: subSector.name + " / " + subSector.code,
          });
        });

        setSubSectorOptionsBusiness(subSectorsBag);
      }
    } else {
      setSelectedBusinessSector(null);
      setSelectedSubSectorsBusiness([]);
    }

    setSelectedSubSectorsBusiness([]);
  };
  const handleSectorChange = () => {
    if (!sectorRef.current!.value) {
      setSelectedSector(null);
      setSelectedSubSectors([]);
      return;
    }

    const selectedSectorMatched = sectors.filter((sector) => sector.id === sectorRef.current!.value);
    if (selectedSectorMatched.length) {
      if (selectedSectorMatched[0].subsector) {
        setSelectedSector({
          label: `${selectedSectorMatched[0].title} / ${selectedSectorMatched[0].nace}`,
          value: selectedSectorMatched[0].id,
        });

        const subSectorsBag: Array<SectorSelectOptionType> = [];

        selectedSectorMatched[0].subsector.forEach((subSector) => {
          subSectorsBag.push({
            value: subSector.id,
            label: subSector.name + " / " + subSector.code,
          });
        });

        setSubSectorOptions(subSectorsBag);
      }
    } else {
      setSelectedSector(null);
      setSelectedSubSectors([]);
    }

    setSelectedSubSectors([]);
  };
  const handleSubSectorChange = (options: any) => {
    const subSectorsBag: Array<SectorSelectOptionType> = [];
    if (options.length) {
      options.forEach((option: SectorSelectOptionType) => {
        subSectorsBag.push(option);
      });
    }

    setSelectedSubSectors(subSectorsBag);
  };
  const handleSubSectorBusinessChange = (options: any) => {
    const businessSubSectorsBag: Array<SectorSelectOptionType> = [];
    if (options.length) {
      options.forEach((option: SectorSelectOptionType) => {
        businessSubSectorsBag.push(option);
      });
    }

    setSelectedSubSectorsBusiness(businessSubSectorsBag);
  };

  const { t } = useTranslation();
  const [show, setShow] = useState<boolean>(false);
  const [formDisable, setFormDisable] = useState<boolean>(true);

  const { response: citiesResponse } = useSelector<RootState, ICitiesState>((state: RootState) => state.cities);
  const experienceRef = useRef<HTMLInputElement>(null);

  const getCityDistricts = useCallback(
    (cityId: string) => {
      dispatch(actions.districts(cityId));
    },
    [dispatch]
  );
  useEffect(() => {
    if (citiesResponse === null) {
      dispatch(actions.cities());
    }
  }, [citiesResponse, dispatch]);

  const handleClose = useCallback(() => {
    setFormAlert({
      variant: "",
      show: false,
      messages: [],
    });
    setShow(false);
    dispatch({ type: "CREATE_USER_RESET" });
    setIsOpenUserSector(false);
    setSelectedUserSectors([]);
    props.onClose();
  }, [dispatch, props, setShow, setIsOpenUserSector, setSelectedUserSectors]);
  
  useEffect(() => {
    setShow(props.modal.show);
  }, [props.modal.show]);

  const { register, control, handleSubmit, errors, reset } = useForm<IUserParams>();
  const onSubmit = async (data: any) => {
    if (props.modal.type === "mentor") data.roles = ["MENTOR"];
    else data.roles = ["USER"];

    data.user_sectors = [];
    if (selectedUserSectors.length) {
      selectedUserSectors.forEach((item) => {
        const userSubSectorBag: Array<string> = [];
        if (item.sub_sector.length) {
          item.sub_sector.forEach((sub) => {
            userSubSectorBag.push(sub.value);
          });
        }

        data.user_sectors.push({
          sector: item.sector.value,
          sub_sector: userSubSectorBag,
          sector_experience: item.sector_experience,
        });
      });
    }
    const businessData: any = {
      business_name: data.business_name,
      business_sectors: [],
      business_type: data.business_type,
      business_role: data.business_role,
    };
    if (selectedBusinessSectors.length) {
      selectedBusinessSectors.forEach((item) => {
        const businessSubSectorBag: Array<string> = [];
        if (item.sub_sector.length) {
          item.sub_sector.forEach((sub) => {
            businessSubSectorBag.push(sub.value);
          });
        }

        businessData.business_sectors.push({
          sector: item.sector.value,
          sub_sector: businessSubSectorBag,
        });
      });
    }
    delete data.business_name;
    delete data.business_sectors;
    delete data.business_type;
    delete data.business_working_time;
    delete data.business_website;
    delete data.business_group;
    delete data.business_city;
    delete data.business_adress;
    delete data.business_phone;
    delete data.business_general;
    delete data.business_role;
    data.email = data.email.toLowerCase();

    const formattedData: IUserSpeedCreateParams = data;
    formattedData.business = businessData;
    formattedData.categories = data.categories.map((category: { value: string; }) => category.value)
    dispatch(await actions.createFastUser(formattedData));
  };
  const {
    isLoading: createUserisLoading,
    response: createUser_response,
    error: createUser_error,
  } = useSelector<RootState, IUserState>((state: RootState) => state.createUser);

  useEffect(() => {
    setFormDisable(createUserisLoading);

    if (createUser_error !== null) {
      if (createUser_error.response.status === 400) {
        setFormAlert({
          variant: "danger",
          show: true,
          messages: createUser_error.response.data.messages,
        });
      } else {
        setFormAlert({
          variant: "danger",
          show: true,
          messages: t("unknown_createUser_error"),
        });
      }
    }

    if (createUser_response) {
      handleClose();

      props.modal.clients.push({
        value: createUser_response.data.id,
        label:
          createUser_response.data.name +
          " " +
          createUser_response.data.lastname +
          " (" +
          createUser_response.data.email +
          ")",
      });
      addToast(t("created_with_param", { param: t("user") }), {
        appearance: "success",
        autoDismiss: true,
      });

      dispatch({ type: "CREATE_FAST_USER_RESET" });
      setSelectedUserSectors([]);
      setIsOpenUserSector(false);
      dispatch({ type: "CREATE_USER_RESET" });
    }
  }, [createUserisLoading, createUser_response, createUser_error, addToast, reset, t, dispatch, handleClose, props.modal.clients]);

  const { response: districtsResponse } = useSelector<RootState, IDistrictsState>(
    (state: RootState) => state.districts
  );
  const [formAlert, setFormAlert] = useState<AlertStateType>(AlertDefaultState);

  const [selectedSector, setSelectedSector] = useState<SectorSelectOptionType | null>(null);
  const [selectedBusinessSector, setSelectedBusinessSector] = useState<SectorSelectOptionType | null>(null);

  const appendBusinessSector = () => {
    const errors: Array<string> = [];

    if (!selectedBusinessSector) {
      errors.push(t("validation.you_must_choose", { name: t("sector") }));
    }

    if (!selectedSubSectorsBusiness.length) {
      errors.push(t("validation.you_must_choose", { name: t("sub_sector") }));
    }

    if (selectedSubSectorsBusiness.length > 3) {
      errors.push(t("validation.max_choose", { name: t("sub_sector"), max: 3 }));
    }

    if (errors.length) {
      setFormAlert({
        variant: "danger",
        show: true,
        messages: errors,
      });
    } else {
      const data = {
        sector: selectedBusinessSector,
        sub_sector: selectedSubSectorsBusiness,
      };

      setFormAlert(AlertDefaultState);
      sectorRef.current!.value = "";
      setSelectedBusinessSector(null);
      setSelectedSubSectorsBusiness([]);
      selectedBusinessSectors.push(data as SectorSelectedType);
    }
  };
  const appendUserSector = () => {
    const errors: Array<string> = [];

    if (!selectedSector) {
      errors.push(t("validation.you_must_choose", { name: t("sector") }));
    }

    if (!selectedSubSectors.length) {
      errors.push(t("validation.you_must_choose", { name: t("sub_sector") }));
    }

    if (selectedSubSectors.length > 3) {
      errors.push(t("validation.max_choose", { name: t("sub_sector"), max: 3 }));
    }

    if (errors.length) {
      setFormAlert({
        variant: "danger",
        show: true,
        messages: errors,
      });
    } else {
      const data = {
        sector: selectedSector,
        sub_sector: selectedSubSectors,
        sector_experience: experienceRef.current!.value ? Number(experienceRef.current!.value) : null,
      };

      setFormAlert(AlertDefaultState);
      sectorRef.current!.value = "";
      setSelectedSector(null);
      setSelectedSubSectors([]);
      experienceRef.current!.value = "";
      selectedUserSectors.push(data as SectorSelectedType);
    }
  };
  const handleDeleteUserSector = (data: SectorSelectedType) => {
    setSelectedUserSectors(selectedUserSectors.filter((item) => item.sector.value !== data.sector.value));
  };

  return (
    <Modal
      centered
      show={show}
      onHide={handleClose}
      animation={false}
      className={formDisable ? "loading-block m80dal" : "m80dal"}
      dialogClassName="ydc"
    >
      <Modal.Header closeButton>
        <Modal.Title as="h6">{t("add_user_modal_title_" + props.modal.type)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormAlert variant={formAlert.variant} show={formAlert.show} to={formAlert.to}>
            {formAlert.messages.map((message, key) => {
              return <p key={key}>{message}</p>;
            })}
          </FormAlert>
          <Form.Row>
            <Form.Group as={Col} sm="6" md="2" lg="2" controlId="user-name">
              <Form.Label>{t("name")}:</Form.Label>
              <Form.Control
                type="text"
                name="name"
                ref={register({
                  required: {
                    value: true,
                    message: t("validation.you_must_enter", { name: t("name") }),
                  },
                })}
                placeholder={t("name")}
                defaultValue=""
                autoFocus
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} sm="6" md="2" lg="2" controlId="user-lastname">
              <Form.Label>{t("lastname")}:</Form.Label>
              <Form.Control
                type="text"
                name="lastname"
                ref={register({
                  required: {
                    value: true,
                    message: t("validation.you_must_enter", { name: t("lastname") }),
                  },
                })}
                placeholder={t("lastname")}
                defaultValue=""
                isInvalid={!!errors.lastname}
              />
              <Form.Control.Feedback type="invalid">{errors.lastname?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} sm="6" md="2" lg="2" controlId="user-email">
              <Form.Label>{t("email")}:</Form.Label>
              <Form.Control
                type="text"
                name="email"
                ref={register({
                  required: {
                    value: true,
                    message: t("validation.you_must_enter", { name: t("email") }),
                  },
                  pattern: {
                    value: EMAIL_REGEX_PATTERN,
                    message: t("validation.invalid", { name: t("email") }),
                  },
                })}
                placeholder={t("email")}
                defaultValue=""
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} sm="6" md="2" lg="2" controlId="user-phone">
              <Form.Label>{t("phone")}:</Form.Label>
              <Form.Control
                type="number"
                name="phone"
                ref={register({
                  required: {
                    value: true,
                    message: t("validation.you_must_enter", { name: t("phone") }),
                  },
                  min: {
                    value: 11,
                    message: t("validation.min", { min: 11 }),
                  },
                })}
                maxLength={11}
                placeholder={t("phone")}
                defaultValue=""
                isInvalid={!!errors.phone}
              />
              <Form.Control.Feedback type="invalid">{errors.phone?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} sm="6" md="2" lg="2" controlId="user-education">
              <Form.Label>{t("education_status")}:</Form.Label>
              <Form.Control
                as="select"
                custom
                name="education"
                ref={register({
                  required: {
                    value: true,
                    message: t("validation.you_must_choose", { name: t("education_status") }),
                  },
                })}
                defaultValue=""
                isInvalid={!!errors.education}
              >
                <option value="">{t("select_education_status")}</option>
                {Object.values(EducationStatusTypes).map((education, key) => {
                  return (
                    <option value={education} key={key}>
                      {t(`education_items.${education}`)}
                    </option>
                  );
                })}
              </Form.Control>
              <Form.Control.Feedback type="invalid">{errors.education?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} sm="6" md="2" lg="2" controlId="user-education-detail">
              <Form.Label>{t("education_detail")}:</Form.Label>
              <Form.Control
                type="text"
                name="education_detail"
                ref={register({
                  required: {
                    value: true,
                    message: t("validation.you_must_enter", { name: t("education_detail") }),
                  },
                })}
                placeholder={t("education_detail")}
                defaultValue=""
                isInvalid={!!errors.education_detail}
              />
              <Form.Control.Feedback type="invalid">{errors.education_detail?.message}</Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <hr />
          <Form.Row>
            <Form.Group as={Col} sm="6" md="2" lg="2" controlId="user-gender">
              <Form.Label>{t("gender")}:</Form.Label>
              <Form.Control
                as="select"
                custom
                name="gender"
                ref={register({
                  required: {
                    value: true,
                    message: t("validation.you_must_choose", { name: t("gender") }),
                  },
                })}
                defaultValue=""
                isInvalid={!!errors.gender}
              >
                <option value="">{t("select_gender")}</option>
                {[GenderTypes.male, GenderTypes.female].map((gender, key) => {
                  return (
                    <option value={gender} key={key}>
                      {t(`genders.${gender}`)}
                    </option>
                  );
                })}
              </Form.Control>
              <Form.Control.Feedback type="invalid">{errors.gender?.message}</Form.Control.Feedback>
            </Form.Group>
            {citiesResponse && (
              <Form.Group as={Col} sm="6" md="2" lg="2" controlId="user-city">
                <Form.Label>{t("city")}:</Form.Label>
                <Form.Control
                  as="select"
                  custom
                  name="city"
                  ref={register({
                    required: {
                      value: true,
                      message: t("validation.you_must_choose", { name: t("city") }),
                    },
                  })}
                  defaultValue=""
                  onChange={(event) => getCityDistricts(event.target.value)}
                  isInvalid={!!errors.city}
                >
                  <option value="">{t("select_city")}</option>
                  {citiesResponse?.data.items.map((city, key) => {
                    return (
                      <option value={city.id} key={key}>
                        {city.name}
                      </option>
                    );
                  })}
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.city?.message}</Form.Control.Feedback>
              </Form.Group>
            )}
            {districtsResponse && (
              <Form.Group as={Col} sm="6" md="2" lg="2" controlId="user-district">
                <Form.Label>{t("district")}:</Form.Label>
                <Form.Control
                  as="select"
                  custom
                  name="district"
                  ref={register({
                    required: {
                      value: true,
                      message: t("validation.you_must_choose", { name: t("district") }),
                    },
                  })}
                  defaultValue=""
                  isInvalid={!!errors.district}
                >
                  <option value="">{t("select_district")}</option>
                  {districtsResponse?.data.items.map((district, key) => {
                    return (
                      <option value={district.id} key={key}>
                        {district.name}
                      </option>
                    );
                  })}
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.district?.message}</Form.Control.Feedback>
              </Form.Group>
            )}
            <Form.Group as={Col} sm="6" md="2" lg="2" controlId="user-address">
              <Form.Label>{t("address")}:</Form.Label>
              <Form.Control
                as="textarea"
                name="address"
                rows={1}
                ref={register({
                  required: {
                    value: true,
                    message: t("validation.you_must_enter", { name: t("address") }),
                  },
                })}
                placeholder={t("address")}
                defaultValue=""
                isInvalid={!!errors.address}
              />
              <Form.Control.Feedback type="invalid">{errors.address?.message}</Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label className="form-label d-block">{t("sectors")}:</Form.Label>
              {selectedUserSectors.length ? (
                <div className="table-responsive">
                  <table className="table table-bordered table-hover">
                    <thead>
                      <tr>
                        <th>{t("sector")}</th>
                        <th>{t("sub_sector")}</th>
                        <th className="text-center">{t("experience")}</th>
                        <th className="text-center">{t("actions")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedUserSectors.map((item, key) => {
                        return (
                          <tr key={key}>
                            <td>{item.sector.label}</td>
                            <td>
                              {item.sub_sector.length ? (
                                <>
                                  {item.sub_sector.map((sub, key) => {
                                    return (
                                      <div key={key} className="d-block mb-2">
                                        - {sub.label}
                                      </div>
                                    );
                                  })}
                                </>
                              ) : (
                                <>-</>
                              )}
                            </td>
                            <td className="text-center">
                              {item.sector_experience} {t("year")}
                            </td>
                            <td className="text-center">
                              <Button
                                variant="outline-danger"
                                size={"sm"}
                                className="btn-icon"
                                title={t("delete")}
                                onClick={() => handleDeleteUserSector(item)}
                              >
                                <RiDeleteBin6Line />
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="d-block border p-3 mb-3">{t("sector_not_selected")}</div>
              )}
              <div className="text-right">
                <Button
                  variant="outline-success"
                  size="sm"
                  className="with-icon"
                  title={t("add")}
                  onClick={()=> setIsOpenUserSector(!isOpenUserSectorAccordion)}
                >
                  <RiAddBoxLine />
                  <span>{t("add")}</span>
                </Button>
              </div>
            </Form.Group>
          </Form.Row>
          {isOpenUserSectorAccordion && (
            <Accordion defaultActiveKey="0">
              <Card as={Col} sm="12" md="12" lg="12">
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  {t("select_sector")}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <Form.Group>
                      <Form.Label htmlFor="selector-sector">{t("sector")}</Form.Label>
                      <Form.Control
                        as="select"
                        custom
                        id="selector-sector"
                        autoFocus
                        ref={sectorRef}
                        onChange={handleSectorChange}
                      >
                        <option value="">{t("select_sector")}</option>
                        {sectors.length > 0 && (
                          <>
                            {sectors.map((sector, key) => {
                              return (
                                <option key={key} value={sector.id}>
                                  {sector.title} / {sector.nace}
                                </option>
                              );
                            })}
                          </>
                        )}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label htmlFor="selector-subsector">{t("sub_sector")}</Form.Label>
                      <Select
                        isMulti
                        isClearable
                        id="selector-subsector"
                        inputRef={subSectorRef}
                        placeholder={t("select_sub_sector")}
                        noOptionsMessage={() => t("not_found")}
                        defaultValue={selectedSubSectors}
                        value={selectedSubSectors}
                        options={subSectorOptions}
                        onChange={(option) => handleSubSectorChange(option)}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label htmlFor="selector-experience">{t("experience")}</Form.Label>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          name="experience"
                          id="selector-experience"
                          min="1"
                          aria-describedby="experience-addon"
                          placeholder={t("experience")}
                          defaultValue=""
                          ref={experienceRef}
                        />
                        <InputGroup.Text id="experience-addon">{t("year")}</InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group>
                      <Button variant="success" onClick={appendUserSector}>
                        {t("add")}
                      </Button>
                    </Form.Group>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          )}

          <Form.Row>
            <Form.Group as={Col} controlId="user-categories" style={{ zIndex: 2 }}>
              <Form.Label>{t("categories")}:</Form.Label>
              <Controller
                  control={control}
                  name="categories"
                  defaultValue={[]}
                  render={({ onChange, value, ref }) => (
                      <Select
                          isMulti
                          isClearable
                          inputRef={ref}
                          placeholder={t("select_category")}
                          noOptionsMessage={() => t("not_found")}
                          options={categories}
                          value={categories.find((category) => category.value === value)}
                          onChange={(value: any) => onChange(value)}
                      />
                  )}
                  ref={register}
                  rules={{
                    required: {
                      value: true,
                      message: t("validation.you_must_choose", { name: t("category") }),
                    },
                  }}
                  isInvalid={!!errors.categories}
              />
            </Form.Group>
          </Form.Row>
          <hr />
          <Form.Row>
            <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-business-type">
              <Form.Label>{t("business_type")}:</Form.Label>
              <Form.Control
                as="select"
                custom
                name="business_type"
                ref={register({
                  required: {
                    value: false,
                    message: t("validation.you_must_choose", { name: t("business_type") }),
                  },
                })}
                defaultValue=""
                isInvalid={!!errors.business_type}
              >
                <option value="">{t("business_type")}</option>
                {Object.values(BusinessTypes).map((type, key) => {
                  return (
                    <option value={type} key={key}>
                      {t(`business_types.${type}`)}
                    </option>
                  );
                })}
              </Form.Control>
              <Form.Control.Feedback type="invalid">{errors.business_type?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-business-name">
              <Form.Label>{t("business_name")}:</Form.Label>
              <Form.Control
                type="text"
                name="business_name"
                ref={register({
                  required: {
                    value: false,
                    message: t("validation.you_must_enter", { name: t("business_name") }),
                  },
                })}
                placeholder={t("business_name")}
                defaultValue=""
                isInvalid={!!errors.business_name}
              />
              <Form.Control.Feedback type="invalid">{errors.business_name?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} sm="6" md="4" lg="3" controlId="user-business-role">
              <Form.Label>{t("business_role")}:</Form.Label>
              <Form.Control
                as="select"
                custom
                name="business_role"
                ref={register({
                  required: {
                    value: false,
                    message: t("validation.you_must_choose", { name: t("business_role") }),
                  },
                })}
                defaultValue=""
                isInvalid={!!errors.business_role}
              >
                <option value="">{t("select_role")}</option>
                <option value="MANAGER">{t("business_roles.MANAGER")}</option>
                <option value="USER">{t("business_roles.USER")}</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">{errors.business_role?.message}</Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} sm="12">
              <Form.Label className="form-label d-block">{t("business_sectors")}:</Form.Label>
              {selectedBusinessSectors.length ? (
                <div className="table-responsive">
                  <table className="table table-bordered table-hover">
                    <thead>
                      <tr>
                        <th>{t("sector")}</th>
                        <th>{t("sub_sector")}</th>
                        <th className="text-center">{t("actions")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedBusinessSectors.map((item, key) => {
                        return (
                          <tr key={key}>
                            <td>{item.sector.label}</td>
                            <td>
                              {item.sub_sector.length ? (
                                <>
                                  {item.sub_sector.map((sub, key) => {
                                    return (
                                      <div key={key} className="d-block mb-2">
                                        - {sub.label}
                                      </div>
                                    );
                                  })}
                                </>
                              ) : (
                                <>-</>
                              )}
                            </td>
                            <td className="text-center">
                              <Button
                                variant="outline-danger"
                                size={"sm"}
                                className="btn-icon"
                                title={t("delete")}
                                onClick={() => handleDeleteBusinessSector(item)}
                              >
                                <RiDeleteBin6Line />
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="d-block border p-3 mb-3">{t("sector_not_selected")}</div>
              )}
              <div className="text-right">
                <div className="text-right">
                  <Button
                    variant="outline-success"
                    size="sm"
                    className="with-icon"
                    title={t("add")}
                    onClick={toggleBusinessSectorAccordion}
                  >
                    <RiAddBoxLine />
                    <span>{t("add")}</span>
                  </Button>
                </div>
              </div>
            </Form.Group>
          </Form.Row>

          <hr />
          {isOpenBusinessSectorAccordion && (
            <Accordion defaultActiveKey="0">
              <Card as={Col} sm="12" md="12" lg="12">
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  {t("select_sector")}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <Form.Group>
                      <Form.Label htmlFor="selector-sector">{t("sector")}</Form.Label>
                      <Form.Control
                        as="select"
                        custom
                        id="selector-sector"
                        autoFocus
                        ref={sectorRef}
                        onChange={handleBusinessSectorChange}
                      >
                        <option value="">{t("select_sector")}</option>
                        {sectors.length > 0 && (
                          <>
                            {sectors.map((sector, key) => {
                              return (
                                <option key={key} value={sector.id}>
                                  {sector.title} / {sector.nace}
                                </option>
                              );
                            })}
                          </>
                        )}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label htmlFor="selector-subsector">{t("sub_sector")}</Form.Label>
                      <Select
                        isMulti
                        isClearable
                        id="selector-subsector"
                        inputRef={subSectorRef}
                        placeholder={t("select_sub_sector")}
                        noOptionsMessage={() => t("not_found")}
                        defaultValue={selectedSubSectorsBusiness}
                        value={selectedSubSectorsBusiness}
                        options={subSectorOptionsBusiness}
                        onChange={(option) => handleSubSectorBusinessChange(option)}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Button variant="success" onClick={appendBusinessSector}>
                        {t("add")}
                      </Button>
                    </Form.Group>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" size="sm" className="with-icon" onClick={handleSubmit(onSubmit)}>
          <RiCheckFill />
          <span>{t("save")}</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserSelectorModal;
