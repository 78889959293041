import { Reducer } from "react";
import {
  IDefaultState,
  IBlogPostsState,
  IBlogPostState,
  BlogPostsActionType,
  BlogPostActionType,
  CreateBlogPostActionType,
  UpdateBlogPostActionType,
  DeleteBlogPostActionType,
} from "../types";

// Blog posts
const blogPostsInitialState: IBlogPostsState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const blogPostsReducer: Reducer<IBlogPostsState, BlogPostsActionType> = (
  state = blogPostsInitialState,
  action
): IBlogPostsState => {
  switch (action.type) {
    case "BLOG_POSTS_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "BLOG_POSTS_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "BLOG_POSTS_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "BLOG_POSTS_RESET":
      return blogPostsInitialState;
    default:
      return state;
  }
};

// Blog post
const blogPostInitialState: IBlogPostState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const blogPostReducer: Reducer<IBlogPostState, BlogPostActionType> = (
  state = blogPostInitialState,
  action
): IBlogPostState => {
  switch (action.type) {
    case "BLOG_POST_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "BLOG_POST_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "BLOG_POST_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "BLOG_POST_RESET":
      return blogPostInitialState;
    default:
      return state;
  }
};

/**
 * @param state
 * @param action
 */
export const createBlogPostReducer: Reducer<IBlogPostState, CreateBlogPostActionType> = (
  state = blogPostInitialState,
  action
): IBlogPostState => {
  switch (action.type) {
    case "CREATE_BLOG_POST_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "CREATE_BLOG_POST_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "CREATE_BLOG_POST_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "CREATE_BLOG_POST_RESET":
      return blogPostInitialState;
    default:
      return state;
  }
};

/**
 * @param state
 * @param action
 */
export const updateBlogPostReducer: Reducer<IBlogPostState, UpdateBlogPostActionType> = (
  state = blogPostInitialState,
  action
): IBlogPostState => {
  switch (action.type) {
    case "UPDATE_BLOG_POST_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "UPDATE_BLOG_POST_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "UPDATE_BLOG_POST_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "UPDATE_BLOG_POST_RESET":
      return blogPostInitialState;
    default:
      return state;
  }
};

const blogPostDeleteInitialState: IDefaultState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const deleteBlogPostReducer: Reducer<IDefaultState, DeleteBlogPostActionType> = (
  state = blogPostDeleteInitialState,
  action
): IBlogPostState => {
  switch (action.type) {
    case "DELETE_BLOG_POST_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "DELETE_BLOG_POST_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "DELETE_BLOG_POST_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "DELETE_BLOG_POST_RESET":
      return blogPostDeleteInitialState;
    default:
      return state;
  }
};
