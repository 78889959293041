import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { MainLayout } from "../../../layouts";
import { Button, Card, Col, Image, Row } from "react-bootstrap";
import { RiArrowLeftSLine, RiDeleteBin6Line } from "react-icons/all";
import { RootState } from "../../../../store/reducers";
import { IDefaultState, IUserApplicationEvaluateParams, IUserApplicationState } from "../../../../store/types";
import { LoadingIndicator, DeleteConfirmModal } from "../../../partials";
import Moment from "react-moment";
import actions from "../../../../store/actions";
import { getApplicationStatus, getApplicationStatusClassName, nl2br, pictureUrl } from "../../../../utils/mixins";
import { ApplicationStatusTypes } from "../../../../types";
import EvaluateModal from "../EvaluateModal";
import ToggleSector from "../../../partials/ToggleSector";

type RouterParamsType = {
  id: string;
};

export const Show = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { addToast } = useToasts();
  const { id } = useParams<RouterParamsType>();
  const [showEvaluateModal, setShowEvaluateModal] = useState<boolean>(false);

  const { isLoading, response, error } = useSelector<RootState, IUserApplicationState>(
    (state: RootState) => state.userApplication
  );

  const {
    isLoading: evaluateIsLoading,
    response: evaluateResponse,
    error: evaluateError,
  } = useSelector<RootState, IDefaultState>((state: RootState) => state.evaluateUserApplication);

  const {
    isLoading: deleteIsLoading,
    response: deleteResponse,
    error: deleteError,
  } = useSelector<RootState, IDefaultState>((state: RootState) => state.deleteUserApplication);

  const [deleteItem, setDeleteItem] = useState<string | null>(null);
  const onDeleteConfirm = async () => {
    if (deleteItem) {
      dispatch(await actions.deleteUserApplication(deleteItem));
    }
  };

  const handleEvaluate = (id: string, data: IUserApplicationEvaluateParams) => {
    setShowEvaluateModal(false);
    dispatch(actions.evaluateUserApplication(id, data));
  };

  useEffect(() => {
    if (evaluateResponse) {
      dispatch(actions.userApplication(id));
      dispatch({ type: "EVALUATE_USER_APPLICATION_RESET" });
      dispatch(actions.dashboard());

      addToast(t("evaluate_success"), {
        appearance: "success",
        autoDismiss: true,
      });
    }

    if (evaluateError) {
      addToast(t("unknown_error"), {
        appearance: "error",
        autoDismiss: true,
      });

      dispatch({ type: "EVALUATE_USER_APPLICATION_RESET" });
    }
  }, [evaluateResponse, evaluateError, addToast, dispatch, id, t]);

  useEffect(() => {
    if (error?.response) {
      if (error.response.status === 400 || error.response.status === 404) {
        dispatch({ type: "FAQ_RESET" });
        history.push("/404");
      }
    } else {
      dispatch(actions.userApplication(id));
    }
  }, [dispatch, id, error, history]);

  useEffect(() => {
    if (deleteError) {
      if (deleteError.response.status === 400) {
        addToast(deleteError.response.data.messages.join(" "), {
          appearance: "error",
          autoDismiss: true,
        });
      } else {
        addToast(t("unknown_error"), {
          appearance: "error",
          autoDismiss: true,
        });
      }

      dispatch({ type: "DELETE_USER_APPLICATION_RESET" });
    }

    if (deleteResponse) {
      addToast(t("deleted_with_param", { param: t("userApplication") }), {
        appearance: "success",
        autoDismiss: true,
      });

      dispatch({ type: "DELETE_USER_APPLICATION_RESET" });
      dispatch(actions.dashboard());

      history.push("/applications/user");
    }
  }, [deleteResponse, deleteError, addToast, t, dispatch, history]);

  return (
    <MainLayout>
      <LoadingIndicator show={isLoading} />
      {response && (
        <Card className={deleteIsLoading ? "loading-block" : ""}>
          <Card.Header as="h6">{t("show")}</Card.Header>
          <Card.Body>
            <div className="content-view">
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("id")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.id}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("status")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {evaluateIsLoading ? (
                    <div style={{ width: "50px" }}>
                      <LoadingIndicator show={true} />
                    </div>
                  ) : (
                    <>
                      {getApplicationStatus(response.data) === ApplicationStatusTypes.waiting ? (
                        <>
                          {response.data.verification_token ? (
                            <div className="d-block text-warning">{t("email_address_is_not_valid")}!</div>
                          ) : (
                            <>
                              <Button variant="info" size="sm" onClick={() => setShowEvaluateModal(true)}>
                                {t("evaluate")}
                              </Button>
                              <EvaluateModal
                                show={showEvaluateModal}
                                onClose={() => setShowEvaluateModal(false)}
                                onSubmit={(data: IUserApplicationEvaluateParams) =>
                                  handleEvaluate(response.data.id, data)
                                }
                              />
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <div className={`d-block text-${getApplicationStatusClassName(response.data)}`}>
                            {t("application_status_type." + getApplicationStatus(response.data))}
                          </div>
                          {getApplicationStatus(response.data) === ApplicationStatusTypes.approved ? (
                            <>
                              {response.data.approved_at && (
                                <div className="d-block mt-2">
                                  <strong className="mr-2">{t("approved_at")}:</strong>
                                  <Moment format="DD/MM/YYYY HH:mm:ss" className="d-block">
                                    {response.data.approved_at}
                                  </Moment>
                                </div>
                              )}
                              {response.data.user_id && (
                                <div className="d-block mt-2">
                                  <Button
                                    variant="outline-secondary"
                                    as={NavLink}
                                    to={`/applications/${response.data.user_id}`}
                                  >
                                    {t("user_detail")}
                                  </Button>
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              {response.data.rejected_at && (
                                <div className="d-block mt-2">
                                  <strong className="mr-2">{t("rejected_at")}:</strong>
                                  <Moment format="DD/MM/YYYY HH:mm:ss" className="d-block">
                                    {response.data.rejected_at}
                                  </Moment>
                                </div>
                              )}
                              {response.data.reject_description && (
                                <div className="d-block mt-2">
                                  <strong className="mr-2">{t("reject_description")}:</strong>
                                  <div className="d-block">{nl2br(response.data.reject_description)}</div>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("name")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.name}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("lastname")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.lastname}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("email")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  <a href={"mailto:" + response.data.email} className="text-muted">
                    {response.data.email}
                  </a>
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("phone")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  <a href={"tel:" + response.data.phone} className="text-muted">
                    {response.data.phone}
                  </a>
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("city")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.city ? response.data.city.name : "-"}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("district")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {response.data.district ? response.data.district.name : "-"}
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("address")}:</dt>
                <dd
                  className="col-12 col-sm-9 col-md-10"
                  dangerouslySetInnerHTML={{ __html: response.data.address ? nl2br(response.data.address) : "-" }}
                />
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">LinkedIn:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {response.data.linkedin ? (
                    <a href={response.data.linkedin} target="_blank" rel="noreferrer" className="text-muted">
                      {response.data.linkedin}
                    </a>
                  ) : (
                    <>-</>
                  )}
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("avatar")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {response.data.avatar ? (
                    <div className="profile-picture">
                      <Image src={pictureUrl(response.data.avatar)} rounded thumbnail />
                    </div>
                  ) : (
                    "-"
                  )}
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("gender")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{t(`genders.${response.data.gender}`)}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("disability")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{t(`disabilities.${response.data.disability}`)}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("about")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.about ? nl2br(response.data.about) : "-"}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("sectors")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {response.data.user_sectors.length > 0 ? (
                    <>
                      {response.data.user_sectors.map((item, key) => (
                        <>
                          <ToggleSector {...item} key={key} />
                        </>
                      ))}
                    </>
                  ) : (
                    "-"
                  )}
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("education_status")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {response.data.education ? <>{t(`education_items.${response.data.education}`)}</> : <>-</>}
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("business_type")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{t(`business_types.${response.data.business_type}`)}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("business_name")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.business_name}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("business_role")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.business_role}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("business_sectors")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {response.data.business_sectors.length > 0 ? (
                    <>
                      {response.data.business_sectors.map((item, key) => (
                        <ToggleSector {...item} key={key} />
                      ))}
                    </>
                  ) : (
                    "-"
                  )}
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("business_working_time")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {t("experience_years", { param: response.data.business_working_time })}
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("created_at")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  <Moment format="DD/MM/YYYY HH:mm:ss">{response.data.created_at}</Moment>
                </dd>
              </dl>
              {response.data.updated_at && (
                <>
                  <hr />
                  <dl className="row">
                    <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("updated_at")}:</dt>
                    <dd className="col-12 col-sm-9 col-md-10">
                      <Moment format="DD/MM/YYYY HH:mm:ss">{response.data.updated_at}</Moment>
                    </dd>
                  </dl>
                </>
              )}
              {response.data.activated_at && (
                <>
                  <hr />
                  <dl className="row">
                    <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("activated_at")}:</dt>
                    <dd className="col-12 col-sm-9 col-md-10">
                      <Moment format="DD/MM/YYYY HH:mm:ss">{response.data.activated_at}</Moment>
                    </dd>
                  </dl>
                </>
              )}
            </div>
          </Card.Body>
          <Card.Footer>
            <Row>
              <Col xs="6" className="text-left">
                <Button as={NavLink} to="/applications/user" variant="secondary" size="sm" className="with-icon">
                  <RiArrowLeftSLine />
                  <span>{t("list")}</span>
                </Button>
              </Col>
              <Col xs="6" className="text-right">
                <DeleteConfirmModal
                  show={deleteItem !== null}
                  onClose={() => setDeleteItem(null)}
                  onConfirm={onDeleteConfirm}
                />
                <Button variant="danger" size="sm" className="with-icon" onClick={() => setDeleteItem(id)}>
                  <RiDeleteBin6Line />
                  <span>{t("delete")}</span>
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      )}
    </MainLayout>
  );
};

export default Show;
