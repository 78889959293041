import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ApplicationStatusTypes } from "../../../types";
import { IMentorApplicationEvaluateParams } from "../../../store/types";

type EvaluateModalPropsType = {
  show: boolean;
  onClose: Function;
  onSubmit: Function;
};

export const EvaluateModal = (props: EvaluateModalPropsType) => {
  const { t } = useTranslation();
  const [show, setShow] = useState<boolean>(false);
  const [ready, setReady] = useState<boolean>(false);
  const statusRef = useRef<HTMLSelectElement>(null);
  const rejectDescriptionRef = useRef<HTMLTextAreaElement>(null);
  const [status, setStatus] = useState<string>("");

  const handleHide = () => {
    setShow(false);
  };

  const handleClose = () => {
    handleHide();
    props.onClose();
  };

  const handleStatusChange = () => {
    setStatus(statusRef.current!.value);

    if ([ApplicationStatusTypes.approved, ApplicationStatusTypes.rejected].includes(statusRef.current!.value)) {
      if (statusRef.current!.value === ApplicationStatusTypes.rejected) {
        setReady(false);

        setTimeout(() => {
          rejectDescriptionRef.current?.focus();
        }, 200);
      } else {
        setReady(true);
      }
    } else {
      setReady(false);
    }
  };

  const handleRejectDescriptionChange = () => {
    if (rejectDescriptionRef.current?.value) {
      setReady(true);
    } else {
      setReady(false);
    }
  };

  const handleSubmit = () => {
    const data: IMentorApplicationEvaluateParams = { status: statusRef.current?.value as string };
    if (rejectDescriptionRef.current?.value) {
      data.reject_description = rejectDescriptionRef.current?.value as string;
    }

    props.onSubmit(data);
  };

  useEffect(() => {
    setShow(props.show);
    setReady(false);
  }, [props.show]);

  return (
    <Modal centered show={show} onHide={handleClose} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title as="h6">{t("evaluate")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label htmlFor="evaluate-status">{t("status")}</Form.Label>
            <Form.Control
              as="select"
              custom
              id="evaluate-status"
              autoFocus
              ref={statusRef}
              onChange={handleStatusChange}
            >
              <option value="">{t("select_status")}</option>
              <option value="approved">{t("application_status_type.approved")}</option>
              <option value="rejected">{t("application_status_type.rejected")}</option>
            </Form.Control>
          </Form.Group>
          {status === "rejected" && (
            <Form.Group>
              <Form.Label htmlFor="evaluate-reject-description">{t("reject_description")}</Form.Label>
              <Form.Control
                as="textarea"
                id="evaluate-reject-description"
                placeholder={t("reject_description")}
                ref={rejectDescriptionRef}
                onChange={handleRejectDescriptionChange}
              />
            </Form.Group>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleSubmit} disabled={!ready}>
          {t("ok")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EvaluateModal;
