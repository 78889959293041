import { Reducer } from "react";
import {
  IFaqsState,
  FaqsActionType,
  IFaqState,
  FaqActionType,
  CreateFaqActionType,
  UpdateFaqActionType,
  DeleteFaqActionType,
  IDefaultState,
} from "../types";

// Faqs
const faqsInitialState: IFaqsState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const faqsReducer: Reducer<IFaqsState, FaqsActionType> = (state = faqsInitialState, action): IFaqsState => {
  switch (action.type) {
    case "FAQS_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "FAQS_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "FAQS_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "FAQS_RESET":
      return faqsInitialState;
    default:
      return state;
  }
};

// Faq
const faqInitialState: IFaqState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const faqReducer: Reducer<IFaqState, FaqActionType> = (state = faqInitialState, action): IFaqState => {
  switch (action.type) {
    case "FAQ_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "FAQ_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "FAQ_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "FAQ_RESET":
      return faqInitialState;
    default:
      return state;
  }
};

/**
 * @param state
 * @param action
 */
export const createFaqReducer: Reducer<IFaqState, CreateFaqActionType> = (
  state = faqInitialState,
  action
): IFaqState => {
  switch (action.type) {
    case "CREATE_FAQ_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "CREATE_FAQ_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "CREATE_FAQ_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "CREATE_FAQ_RESET":
      return faqInitialState;
    default:
      return state;
  }
};

/**
 * @param state
 * @param action
 */
export const updateFaqReducer: Reducer<IFaqState, UpdateFaqActionType> = (
  state = faqInitialState,
  action
): IFaqState => {
  switch (action.type) {
    case "UPDATE_FAQ_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "UPDATE_FAQ_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "UPDATE_FAQ_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "UPDATE_FAQ_RESET":
      return faqInitialState;
    default:
      return state;
  }
};

const faqDeleteInitialState: IDefaultState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const deleteFaqReducer: Reducer<IDefaultState, DeleteFaqActionType> = (
  state = faqDeleteInitialState,
  action
): IFaqState => {
  switch (action.type) {
    case "DELETE_FAQ_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "DELETE_FAQ_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "DELETE_FAQ_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "DELETE_FAQ_RESET":
      return faqDeleteInitialState;
    default:
      return state;
  }
};
