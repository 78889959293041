import { useCallback, useEffect, useState, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { MainLayout } from "../../layouts";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import { RootState } from "../../../store/reducers";
import { ILogsListState, ILogsPaginationParams } from "../../../store/types";
import { LoadingIndicator, Pagination, RecordNotFound } from "../../partials";
import Moment from "react-moment";
import actions from "../../../store/actions";
import { DEFAULT_PER_PAGE, LogsMethodTypes, LogStatusTypes, LogTypes } from "../../../types";

const List = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { isLoading, response, error } = useSelector<RootState, ILogsListState>((state: RootState) => state.logs);
  const queryString = new URLSearchParams(useLocation().search);
  const page: string | number | null = queryString.get("page") ? Number(queryString.get("page")) : 1;
  const method: string | null = queryString.get("method") ? queryString.get("method") : null;
  const code: string | null = queryString.get("code") ? queryString.get("code") : null;
  const type: string | null = queryString.get("type") ? queryString.get("type") : null;
  const email: string | null = queryString.get("email") ? queryString.get("email") : null;
  const ip: string | null = queryString.get("ip") ? queryString.get("ip") : null;
  const endpoint: string | null = queryString.get("endpoint") ? queryString.get("endpoint") : null;

  const [queryParams, setQueryParams] = useState<ILogsPaginationParams>({ page: page, per: DEFAULT_PER_PAGE });
  const qParams = useMemo<ILogsPaginationParams>(() => qs.parse(search), [search]);

  const getLogs = useCallback(
    (queryParams?) => {
      dispatch(actions.logs(queryParams));
    },
    [dispatch]
  );

  const methodRef = useRef<HTMLSelectElement>(null);
  const codeRef = useRef<HTMLSelectElement>(null);
  const typeRef = useRef<HTMLSelectElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const ipRef = useRef<HTMLInputElement>(null);
  const endpointRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const params = qParams;
    params.per = DEFAULT_PER_PAGE;
    setQueryParams(params);
    getLogs(params);
  }, [getLogs, qParams]);

  const handleSubmitFilter = () => {
    let url = `/logs?page=1`;
    if (methodRef.current!.value) {
      url += `&method=${methodRef.current!.value}`;
      queryParams.method = methodRef.current!.value;
    }
    if (codeRef.current!.value) {
      url += `&code=${codeRef.current!.value}`;
      queryParams.code = codeRef.current!.value;
    }
    if (typeRef.current!.value) {
      url += `&type=${typeRef.current!.value}`;
      queryParams.type = typeRef.current!.value;
    }
    if (emailRef.current!.value) {
      url += `&email=${emailRef.current!.value}`;
      queryParams.email = emailRef.current!.value;
    }
    if (ipRef.current!.value) {
      url += `&ip=${ipRef.current!.value}`;
      queryParams.ip = ipRef.current!.value;
    }
    if (endpointRef.current!.value) {
      url += `&endpoint=${endpointRef.current!.value}`;
      queryParams.ip = endpointRef.current!.value;
    }
    getLogs(queryParams);

    history.push(url);
  };

  useEffect(() => {
    if (error?.response) {
      dispatch({ type: "LOGS_RESET" });

      if (error.response.status >= 400) {
        history.push("/logs");
      }
    }

    if (!response && !isLoading) {
      getLogs(queryParams);
    }
  }, [
    getLogs,
    response,
    isLoading,
    queryParams,
    page,
    method,
    code,
    type,
    email,
    ip,
    endpoint,
    error,
    history,
    dispatch,
  ]);

  return (
    <MainLayout>
      <LoadingIndicator show={isLoading} />
      {response && (
        <Row>
          <Col md="9" className="order-last order-md-first">
            <Card>
              <Card.Header>
                <Row>
                  <Col as="h6" xs="8" className="mb-0">
                    {t("logs")}
                  </Col>
                  <Col xs="4" className="mb-0 text-right">
                    <strong className="mr-1">{t("total")}:</strong>
                    <span>{response.data.pagination.total}</span>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="p-0 m-0">
                {response.data.items.length > 0 ? (
                  <Table responsive striped hover className="table-list m-0">
                    <thead>
                      <tr>
                        <th>{t("email")}</th>
                        <th>{t("ip")}</th>
                        <th>{t("service")}</th>
                        <th>{t("endpoint")}</th>
                        <th>{t("method")}</th>
                        <th>{t("type")}</th>
                        <th>{t("code")}</th>
                        <th className="date">{t("created_at")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {response.data.items.map((item, key) => {
                        return (
                          <tr key={key}>
                            <td>{item.user?.email || "-"}</td>
                            <td>{item.ip}</td>
                            <td>{item.service}</td>
                            <td>{item.endpoint}</td>
                            <td>{item.method}</td>
                            <td>{item.type}</td>
                            <td>{item.code}</td>
                            <td className="date">
                              <Moment format="DD/MM/YYYY HH:mm:ss">{item.created_at}</Moment>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                ) : (
                  <RecordNotFound />
                )}
              </Card.Body>
              <Card.Footer>
                <Row>
                  <Col xs="6">
                    <Pagination pagination={response.data.pagination} extraParams={["type"]} />
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Col>
          <Col md="3" className="order-last order-md-last">
            <Card>
              <Card.Body>
                <Form onSubmit={handleSubmitFilter}>
                  <Form.Group controlId="method-type">
                    <Form.Label>{t("method")}</Form.Label>
                    <Form.Control as="select" custom ref={methodRef} defaultValue={method ? method : ""}>
                      <option value="">{t("all")}</option>
                      {Object.keys(LogsMethodTypes).map((key) => {
                        return (
                          <option value={key} key={key}>
                            {t("methods_type." + key)}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="code-type">
                    <Form.Label>{t("code")}</Form.Label>
                    <Form.Control as="select" custom ref={codeRef} defaultValue={code ? code : ""}>
                      <option value="">{t("all")}</option>
                      {Object.keys(LogStatusTypes).map((key) => {
                        return (
                          <option value={key} key={key}>
                            {key}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="type">
                    <Form.Label>{t("type")}</Form.Label>
                    <Form.Control as="select" custom ref={typeRef} defaultValue={type ? type : ""}>
                      <option value="">{t("all")}</option>
                      {Object.keys(LogTypes).map((key) => {
                        return (
                          <option value={key} key={key}>
                            {t("logs_types." + key)}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="email-type">
                    <Form.Label> {t("email")} </Form.Label>
                    <Form.Control
                      as="input"
                      onKeyPress={(e: any) => e.code === "Enter" && handleSubmitFilter()}
                      type="email"
                      placeholder="Email giriniz.."
                      ref={emailRef}
                      defaultValue={email ? email : ""}
                    />
                  </Form.Group>
                  <Form.Group controlId="ip-type">
                    <Form.Label> {t("ip")} </Form.Label>
                    <Form.Control
                      as="input"
                      onKeyPress={(e: any) => e.code === "Enter" && handleSubmitFilter()}
                      placeholder="Ip giriniz.."
                      ref={ipRef}
                      defaultValue={ip ? ip : ""}
                    />
                  </Form.Group>
                  <Form.Group controlId="endpoint-type">
                    <Form.Label> {t("endpoint")} </Form.Label>
                    <Form.Control
                      as="input"
                      type="text"
                      onKeyPress={(e: any) => e.code === "Enter" && handleSubmitFilter()}
                      placeholder="Endpoint giriniz.."
                      ref={endpointRef}
                      defaultValue={endpoint ? endpoint : ""}
                    />
                  </Form.Group>
                  <hr />
                  <Button onClick={handleSubmitFilter} variant="outline-secondary" block>
                    {t("filter")}
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </MainLayout>
  );
};

export default List;
