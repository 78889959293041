import { RouteComponentProps } from "react-router-dom";

export type AlertStateType = {
  variant: "" | "primary" | "secondary" | "info" | "success" | "warning" | "danger" | "light" | "dark";
  show: boolean;
  dismissible?: boolean;
  messages: Array<any>;
  to?: string | null;
};

export const AlertDefaultState: AlertStateType = {
  variant: "",
  show: false,
  messages: [],
};

export interface ILocationStateType extends RouteComponentProps {
  flash?: AlertStateType;
}
