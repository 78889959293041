import { Dispatch } from "redux";
import { AdminApiClient } from "../../utils/http-client";
import { IMentorApplicationPaginationParams, IMentorApplicationEvaluateParams } from "../types";

const adminApiClient = AdminApiClient.getInstance();

export const mentorApplications = (queryParams?: IMentorApplicationPaginationParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "MENTOR_APPLICATIONS_START" });
  await adminApiClient
    .mentorApplications(queryParams)
    .then((response) => {
      dispatch({ type: "MENTOR_APPLICATIONS_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "MENTOR_APPLICATIONS_FAILURE", payload: error }));
};

export const mentorApplication = (id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: "MENTOR_APPLICATION_START" });
  await adminApiClient
    .mentorApplication(id)
    .then((response) => {
      dispatch({ type: "MENTOR_APPLICATION_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "MENTOR_APPLICATION_FAILURE", payload: error }));
};

export const evaluateMentorApplication =
  (id: string, params: IMentorApplicationEvaluateParams) => async (dispatch: Dispatch) => {
    dispatch({ type: "EVALUATE_MENTOR_APPLICATION_START" });
    await adminApiClient
      .evaluateMentorApplication(id, params)
      .then((response) => {
        dispatch({ type: "EVALUATE_MENTOR_APPLICATION_SUCCESS", payload: response });
      })
      .catch((error) => dispatch({ type: "EVALUATE_MENTOR_APPLICATION_FAILURE", payload: error }));
  };

export const deleteMentorApplication = (id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: "DELETE_MENTOR_APPLICATION_START" });
  await adminApiClient
    .deleteMentorApplication(id)
    .then((response) => {
      dispatch({ type: "DELETE_MENTOR_APPLICATION_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "DELETE_MENTOR_APPLICATION_FAILURE", payload: error }));
};
