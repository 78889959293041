import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { MainLayout } from "../../layouts";
import { Accordion, Button, Card, Col, Form, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { RiAddBoxLine, RiArrowLeftSLine, RiCheckFill, RiDeleteBin6Line } from "react-icons/all";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { RootState } from "../../../store/reducers";
import moment from 'moment';
import { useParams } from "react-router-dom";
import {
    IMentoringProgramState,
    IMatchCreateSessionParams,
    MatchSessionFormType,
    IUsersState,
    IUsersPaginationParams,
    ITeamsState
}
    from "../../../store/types";
import actions from "../../../store/actions";
import history from "../../../utils/history";
import { categories } from "../../../store/actions/categories";
type RouterParamsType = {
    id: string;
};
export type OptionType = {
    value: string;
    label: string | null;
};

const CreateMatchSession = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const { register, handleSubmit, errors, reset, control } = useForm<IMatchCreateSessionParams>();
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const { id } = useParams<RouterParamsType>();
    const [formDisable, setFormDisable] = useState<boolean>(false);
    const [authorized_person, setAuthorized_person] = useState<Array<OptionType>>([]);
    const {response:teamResponse} = useSelector<RootState, ITeamsState>((state: RootState) => state.teams);
    const [session, setSession] = useState<MatchSessionFormType>({
        title: "",
        location: "",
        link: "",
        recording_link: "",
        assessment: "",
        topics: "",
        decisions: "",
        goals: "",
        preparations: "",
        agenda: "",
        documents: "",
        date: "",
        followed_tasks: [],
    });

    useEffect(() => {
        dispatch({ type: "USERS_RESET" });
    
        if (!teamResponse) {
            dispatch(actions.teams({ page: 1, per: 9999 }));
        } else if (teamResponse.data?.items) {
            const authorizedPersonsArray: Array<{id:string, name: string, lastname: string, profession: string }> = [];
            const programId = id;
    
            teamResponse.data.items.forEach((team: any) => {
                team.pairs.forEach((pair: any) => {
                    if (pair.mentoring_programs.some((program: any) => program.id === programId)) {
    
                        if (Array.isArray(pair.clients)) {
                            authorizedPersonsArray.push(...pair.clients.map((user: any) => ({
                                id: user.id,
                                name: user.name,
                                lastname: user.lastname,
                                profession: "Eşleşme Danışanı"
                            })));
                        }
    
                        if (Array.isArray(team.mentors)) {
                            authorizedPersonsArray.push(...team.mentors.map((user: any) => ({
                                id: user.id,
                                name: user.name,
                                lastname: user.lastname,
                                profession: "Takım Rehberi"
                            })));
                        }
    
                        if (team.team_lead) {
                            authorizedPersonsArray.push({
                                id: team.team_lead.id,
                                name: team.team_lead.name,
                                lastname: team.team_lead.lastname,
                                profession: "Takım Lideri"
                            });
                        }
    
                        if (team.process_manager) {
                            authorizedPersonsArray.push({
                                id: team.process_manager.id,
                                name: team.process_manager.name,
                                lastname: team.process_manager.lastname,
                                profession: "Takım Süreç Yöneticisi"
                            });
                        }
    
                        if (Array.isArray(team.project_managers)) {
                            authorizedPersonsArray.push(...team.project_managers.map((user: any) => ({
                                id: user.id,
                                name: user.name,
                                lastname: user.lastname,
                                profession: "Takım Stajyeri"
                            })));
                        }
                    }
                });
            });
    
            const uniqueUsersMap = new Map();
            authorizedPersonsArray.forEach(user => {
                const key = `${user.name} ${user.lastname}`;
                if (uniqueUsersMap.has(key)) {
                    const existingUser = uniqueUsersMap.get(key);
                    existingUser.profession = `${existingUser.profession}/${user.profession}`;
                    uniqueUsersMap.set(key, existingUser);
                } else {
                    uniqueUsersMap.set(key, user);
                }
            });
    
            const uniqueUsersArray = Array.from(uniqueUsersMap.values());
            setAuthorized_person(uniqueUsersArray.map(user => ({
                value: `${user.id}`,
                label: `${user.name} ${user.lastname} (${user.profession})`
            })));
        }
    }, [teamResponse, id]);
    
    

    const [isAddingTask, setIsAddingTask] = useState(false);

    

    const addFollowedTaskItem = () => {
        if (!isAddingTask) {
            setIsAddingTask(true);

            const newFollowedTask = {
                title: "",
                steps: "",
                status: "",
                authorized_person: "",
                start_date: "",
                completion_date: ""
            };

            const updatedFollowedTasks = session.followed_tasks ? [...session.followed_tasks, newFollowedTask] : [newFollowedTask];

            setSession({
                ...session,
                followed_tasks: updatedFollowedTasks,
            });

            setIsAddingTask(false);
        }
    };



    const removeFollowedTaskItem = (taskIndex: number) => {
        const followedTasks = session.followed_tasks ? [...session.followed_tasks] : [];

        if (taskIndex > -1 && taskIndex < followedTasks.length) {
            followedTasks.splice(taskIndex, 1);

            setSession((prevSession) => ({
                ...prevSession,
                followed_tasks: followedTasks,
            }));
        } else {
            console.error("Invalid task index provided or followed_tasks is null.");
        }
    };

    const { isLoading, response, error } = useSelector<RootState, IMentoringProgramState>((state: RootState) => state.createSession);
    useEffect(() => {
        if (isLoading) {
            setFormDisable(true);
        } else {
            setFormDisable(false);
        }
    }
        , [isLoading]);
    useEffect(() => {
        if (response) {
            addToast(t("created_with_param", { param: t("session") }), {
                appearance: "success",
                autoDismiss: true,
            });
        }
    }
        , [response]);

    const onSubmit = async (data: IMatchCreateSessionParams): Promise<void> => {
        const newData = { ...data };
        const formattedFollowedTasks = newData.followed_tasks?.map(task => {
            const formattedTask = { ...task };

            if (task.start_date !== "") {
                formattedTask.start_date = moment(task.start_date).format("YYYY-MM-DD HH:mm:ss");
            }
            else {
                formattedTask.start_date = undefined;
            }

            if (task.completion_date !== "") {
                formattedTask.completion_date = moment(task.completion_date).format("YYYY-MM-DD HH:mm:ss");
            }
            else {
                formattedTask.completion_date = undefined;
            }

            if (task.authorized_person !== "") {
                formattedTask.authorized_person = Array.isArray(task.authorized_person)
                    ? task.authorized_person.map((item: any) => item.value).join(', ')
                    : '';
            } else {
                formattedTask.authorized_person = undefined;
            }
            
            if (task.status === "") {
                formattedTask.status = undefined;
            }

            return formattedTask;
        }) || null;
        const sessionData: IMatchCreateSessionParams = {
            title: newData.title,
            goals: newData.goals,
            location: newData.location ? newData.location : undefined,
            link: newData.link ? newData.link : undefined,
            recording_link: newData.recording_link ? newData.recording_link : undefined,
            assessment: newData.assessment ? newData.assessment : undefined,
            topics: newData.topics ? newData.topics : undefined,
            decisions: newData.decisions ? newData.decisions : undefined,
            preparations: newData.preparations ? newData.preparations : undefined,
            agenda: newData.agenda ? newData.agenda : undefined,
            date: newData.date ? moment(newData.date).format("YYYY-MM-DD HH:mm:ss") : undefined,
            documents: newData.documents ? newData.documents : undefined,
            followed_tasks: formattedFollowedTasks || undefined,
        };  
        try {
            await dispatch(actions.createMatchSession(id, sessionData));
            dispatch({ type: "CREATE_SESSION_RESET" });
            history.push(`/mentoring_programs/${id}/match`);
        } catch (error) {
            console.error("Error creating session: ", error);
        }
    };




    function isUrlValid(userInput: string): boolean {
        if (userInput.trim() === "") {
            return true;
        }
        const urls = userInput.split(' ');
        for (let url of urls) {
            const res = url.match(/^(http(s)?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/);
            if (res === null) {
                return false;
            }
        }
        return true;
    }


    return (
        <MainLayout>
            <Card className={formDisable ? "loading-block" : ""} style={{ marginBottom: 180 }}>
                <Card.Header as="h6">{t("create")}</Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Card.Body>
                            <Form.Row>
                                <Form.Group as={Col} sm="6" md="3" lg="3">
                                    <Form.Label>{t("session_title")}:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={`title`}
                                        ref={register({
                                            required: {
                                                value: true,
                                                message: t("validation.you_must_enter", { name: t("session_title") }),
                                            },
                                        })}
                                        placeholder={t("session_title")}
                                        defaultValue=""
                                        autoFocus
                                        isInvalid={errors.title !== undefined}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.title && errors.title.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} sm="6" md="3" lg="3">
                                    <Form.Label>{t("session_location")}:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={`location`}
                                        ref={register}
                                        placeholder={t("session_location")}
                                        defaultValue=""
                                    />
                                </Form.Group>
                                <Form.Group as={Col} sm="6" md="4" lg="4">
                                    <Form.Label>{t("session_documents")}:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={`documents`}
                                        ref={register({ required: false, validate: isUrlValid })}
                                        placeholder={t("You_can_enter_multiple_links_by_separating_them_with_a_space")}
                                        defaultValue=""
                                    />
                                    {errors.documents && errors.documents.type === "validate" && <Form.Text className="text-danger">{t("validation.invalid_url")}</Form.Text>}
                                </Form.Group>
                            </Form.Row>
                            <hr />
                            <Form.Row>
                                <Form.Group as={Col} sm="6" md="4" lg="4">
                                    <Form.Label>{t("session_goals")}:</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        style={{ resize: "none" }}
                                        rows={4}
                                        type="text"
                                        name={`goals`}
                                        ref={register({
                                            required: {
                                                value: true,
                                                message: t("validation.you_must_enter", { name: t("session_goal") }),
                                            },
                                        })}
                                        placeholder={t("session_goals")}
                                        defaultValue=""
                                        isInvalid={errors.goals !== undefined}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.goals && errors.goals.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} sm="6" md="4" lg="4">
                                    <Form.Label>{t("session_preparations")}:</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        style={{ resize: "none" }}
                                        rows={4}
                                        type="text"
                                        name={`preparations`}
                                        ref={register}
                                        placeholder={t("session_preparations")}
                                        defaultValue=""
                                    />
                                </Form.Group>
                                <Form.Group as={Col} sm="6" md="4" lg="4">
                                    <Form.Label>{t("session_agenda")}:</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        style={{ resize: "none" }}
                                        rows={4}
                                        type="text"
                                        name={`agenda`}
                                        ref={register}
                                        placeholder={t("session_agenda")}
                                        defaultValue=""
                                    />
                                </Form.Group>
                            </Form.Row>
                            <hr />
                            <Form.Row>
                                <Form.Group as={Col} sm="6" md="4" lg="4">
                                    <Form.Label>{t("session_link")}:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={`link`}
                                        ref={register({ required: false, validate: isUrlValid })}
                                        placeholder={t("You_can_enter_multiple_links_by_separating_them_with_a_space")}
                                        defaultValue=""
                                    />
                                    {errors.link && errors.link.type === "validate" && <Form.Text className="text-danger">{t("validation.invalid_url")}</Form.Text>}
                                </Form.Group>

                                <Form.Group as={Col} sm="6" md="4" lg="4">
                                    <Form.Label>{t("session_recording_link")}:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={`recording_link`}
                                        ref={register({ required: false, validate: isUrlValid })}
                                        placeholder={t("You_can_enter_multiple_links_by_separating_them_with_a_space")}
                                        defaultValue=""
                                    />
                                    {errors.recording_link && errors.recording_link.type === "validate" && <Form.Text className="text-danger">{t("validation.invalid_url")}</Form.Text>}
                                </Form.Group>
                                <Form.Group as={Col} sm="6" md="4" lg="4">
                                    <Form.Label>{t("session_topics")}:</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        type="text"
                                        name="topics"
                                        ref={register}
                                        placeholder={t("session_topics")}
                                        defaultValue=""
                                    />
                                </Form.Group>
                            </Form.Row>
                            <hr />
                            <Form.Row>
                                <Form.Group as={Col} sm="6" md="4" lg="4">
                                    <Form.Label>{t("session_assesments")}:</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        type="text"
                                        name="assessment"
                                        ref={register}
                                        placeholder={t("session_assesments")}
                                        defaultValue=""
                                    />
                                </Form.Group>
                                <Form.Group as={Col} sm="6" md="4" lg="4">
                                    <Form.Label>{t("session_decisions")}:</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        type="text"
                                        name="decisions"
                                        ref={register}
                                        placeholder={t("session_decisions")}
                                        defaultValue=""
                                    />
                                </Form.Group>
                                <Form.Group as={Col} sm="6" md="4" lg="4">
                                    <Form.Label>{t("session_date")}:</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="date"
                                        ref={register}
                                        placeholder={t("session_date")}
                                        defaultValue=""
                                    />
                                </Form.Group>
                            </Form.Row>
                            <hr />
                            <Form.Row>
                                <Form.Group as={Col} md="12">
                                    <Form.Label htmlFor="followedTask-selector">
                                        {t("followed_tasks")}:
                                        <Button
                                            variant="outline-success"
                                            size="sm"
                                            className="with-icon ml-1"
                                            title={t("add")}
                                            onClick={() => addFollowedTaskItem()}
                                        >
                                            <RiAddBoxLine />
                                            <span>{t("add")}</span>
                                        </Button>
                                    </Form.Label>
                                </Form.Group>
                            </Form.Row>
                            <div>
                                {session.followed_tasks && session.followed_tasks.map((followed_task, followed_task_index) => (
                                    <div key={followed_task_index}>
                                        <Accordion defaultActiveKey="0" style={{ border: '2px solid #ced4da', borderRadius: '1rem' }}>
                                            <Accordion.Toggle as={Card.Header} eventKey={followed_task_index.toString()}>
                                                Followed Task {followed_task_index + 1}
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey={followed_task_index.toString()}>
                                                <Card.Body>
                                                    <Form.Row>
                                                        <Form.Group as={Col} sm="2" md="2" lg="2">
                                                            <Form.Label>{t("task_order")}:</Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                name={`followed_tasks.${followed_task_index}.steps`}
                                                                ref={register({
                                                                    required: {
                                                                        value: true,
                                                                        message: t("validation.you_must_enter", { name: t("task_order") }),
                                                                    },
                                                                })}
                                                                placeholder={t("task_order")}
                                                                defaultValue=""
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="5" md="4" lg="4">
                                                            <Form.Label htmlFor={`title-${followed_task_index}`}>{t("followed_task_title")}:</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                id={`title-${followed_task_index}`}
                                                                name={`followed_tasks.${followed_task_index}.title`}
                                                                ref={register({
                                                                    required: {
                                                                        value: true,
                                                                        message: t("validation.you_must_enter", { name: t("title") }),
                                                                    },
                                                                })}
                                                                placeholder={t("followed_task_title")}
                                                                defaultValue="" autoFocus
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="5" md="4" lg="4">
                                                            <Form.Label htmlFor={`status-${followed_task_index}`}>{t("followed_task_status")}:</Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                id={`status-${followed_task_index}`}
                                                                name={`followed_tasks.${followed_task_index}.status`}
                                                                ref={register}
                                                                placeholder={t("followed_task_status")}
                                                                defaultValue=""
                                                            >
                                                                <option value="" disabled>{t("followed_task_status_not_choose")}</option>
                                                                <option value="Yeni eklendi">{t("Yeni eklendi")}</option>
                                                                <option value="Durduruldu">{t("Durduruldu")}</option>
                                                                <option value="Üzerinde Çalışılıyor">{t("Üzerinde Çalışılıyor")}</option>
                                                                <option value="İptal edildi">{t("İptal edildi")}</option>
                                                                <option value="Tamamlandı">{t("Tamamlandı")}</option>
                                                            </Form.Control>
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="4" md="4" lg="4">
                                                            <Form.Label htmlFor={`authorized_person-${followed_task_index}`}>{t("followed_task_authorized_person")}:</Form.Label>
                                                            <Controller
                                                                control={control}
                                                                id={`authorized_person-${followed_task_index}`}
                                                                name={`followed_tasks.${followed_task_index}.authorized_person`}
                                                                defaultValue={[]}
                                                                render={({ onChange, value, ref }) => (
                                                                    <Select
                                                                        isMulti
                                                                        isClearable
                                                                        inputRef={ref}
                                                                        noOptionsMessage={() => t("not_found")}
                                                                        options={authorized_person}
                                                                        value={value}
                                                                        onChange={(selectedOptions: any): void => {
                                                                            onChange(selectedOptions);
                                                                        }}
                                                                        placeholder={t("select_client_s")}
                                                                    />
                                                                )}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="4" md="3" lg="3">
                                                            <Form.Label htmlFor={`start_date-${followed_task_index}`}>{t("followed_task_start_date")}:</Form.Label>
                                                            <Form.Control
                                                                type="date"
                                                                id={`start_date-${followed_task_index}`}
                                                                name={`followed_tasks.${followed_task_index}.start_date`}
                                                                ref={register()}
                                                                placeholder={t("followed_task_start_date")}
                                                                defaultValue=""
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="4" md="3" lg="3">
                                                            <Form.Label htmlFor={`completion_date-${followed_task_index}`}>{t("followed_task_completion_date")}:</Form.Label>
                                                            <Form.Control
                                                                type="date"
                                                                id={`completion_date-${followed_task_index}`}
                                                                name={`followed_tasks.${followed_task_index}.completion_date`}
                                                                ref={register()}
                                                                placeholder={t("followed_task_completion_date")}
                                                                defaultValue=""
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <hr />
                                                    <Button
                                                        variant="outline-danger"
                                                        size="sm"
                                                        className="with-icon ml-1"
                                                        title={t("remove")}
                                                        onClick={() => removeFollowedTaskItem(followed_task_index)}
                                                    >
                                                        <RiDeleteBin6Line />
                                                        <span>{t("remove_followed_task")}</span>
                                                    </Button>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Accordion>
                                        <hr />
                                    </div>
                                ))}
                            </div>
                        </Card.Body>
                    </Form>
                </Card.Body>
                <Card.Footer>
                    <Row>
                        <Col xs="6" className="text-left">
                            <Button as={NavLink} to="/mentoring_programs" variant="secondary" size="sm" className="with-icon">
                                <RiArrowLeftSLine />
                                <span>{t("list")}</span>
                            </Button>
                        </Col>
                        <Col xs="6" className="text-right">
                            <Button variant="primary" size="sm" className="with-icon" onClick={handleSubmit(onSubmit)}>
                                <RiCheckFill />
                                <span>{t("save")}</span>
                            </Button>
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
        </MainLayout>
    );
});

export default CreateMatchSession;  