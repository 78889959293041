import { Dispatch } from "redux";
import { UserApiClient } from "../../utils/http-client";
import { IUpdateProfileParams } from "../types";
import store from "../index";
import actions from "./index";

const userApiClient = UserApiClient.getInstance();

export const me = () => async (dispatch: Dispatch) => {
  dispatch({ type: "ME_START" });
  await userApiClient
    .me()
    .then((response) => {
      dispatch({ type: "ME_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "ME_FAILURE", payload: error }));
};

export const meReset = () => async (dispatch: Dispatch) => {
  dispatch({ type: "ME_RESET", payload: null });
};

export const updateProfile = (params: IUpdateProfileParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "UPDATE_PROFILE_START" });
  await userApiClient
    .updateProfile(params)
    .then(async (response) => {
      await store.dispatch(actions.me());
      dispatch({ type: "UPDATE_PROFILE_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "UPDATE_PROFILE_FAILURE", payload: error }));
};

export const logout =
  (data: boolean = true) =>
  (dispatch: Dispatch) => {
    dispatch({ type: "LOGOUT_START" });
    dispatch({ type: "LOGOUT_SUCCESS", payload: { isLoading: false, response: data, error: null } });
  };
