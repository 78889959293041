import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { MainLayout } from "../../layouts";
import { Button, Card, Col, Row } from "react-bootstrap";
import {
  RiEditBoxLine,
  RiMap2Line,
  RiFacebookBoxLine,
  RiTwitterLine,
  RiInstagramLine,
  RiLinkedinLine,
  RiYoutubeLine,
} from "react-icons/all";
import { RootState } from "../../../store/reducers";
import { IContactState } from "../../../store/types";
import { LoadingIndicator } from "../../partials";
import { NavLink } from "react-router-dom";
import Moment from "react-moment";
import actions from "../../../store/actions";

export const ShowProfile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { isLoading, response } = useSelector<RootState, IContactState>((state: RootState) => state.contact);

  useEffect(() => {
    if (response === null) {
      dispatch(actions.contact());
    }
  }, [response, dispatch]);

  return (
    <MainLayout>
      <LoadingIndicator show={isLoading} />
      {response && (
        <Card>
          <Card.Header as="h6">{t("contact_information")}</Card.Header>
          <Card.Body>
            <div className="content-view">
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("address")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.address ? response.data.address : "-"}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("phone")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.phone ? response.data.phone : "-"}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("fax")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.phone ? response.data.fax : "-"}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("email")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.email ? response.data.email : "-"}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("location")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {response.data.latitude && response.data.longitude ? (
                    <a
                      href={`https://www.google.com/maps/@${response.data.latitude},${response.data.longitude},18z`}
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-sm btn-outline-secondary with-icon"
                    >
                      <RiMap2Line />
                    </a>
                  ) : (
                    "-"
                  )}
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("social_media")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {response.data.facebook && (
                    <a
                      href={response.data.facebook}
                      title={t("facebook")}
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-sm btn-outline-secondary with-icon mr-2"
                    >
                      <RiFacebookBoxLine />
                    </a>
                  )}

                  {response.data.twitter && (
                    <a
                      href={response.data.twitter}
                      title={t("twitter")}
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-sm btn-outline-secondary with-icon mr-2"
                    >
                      <RiTwitterLine />
                    </a>
                  )}

                  {response.data.instagram && (
                    <a
                      href={response.data.instagram}
                      title={t("instagram")}
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-sm btn-outline-secondary with-icon mr-2"
                    >
                      <RiInstagramLine />
                    </a>
                  )}

                  {response.data.linkedin && (
                    <a
                      href={response.data.linkedin}
                      title={t("linkedin")}
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-sm btn-outline-secondary with-icon mr-2"
                    >
                      <RiLinkedinLine />
                    </a>
                  )}

                  {response.data.youtube && (
                    <a
                      href={response.data.youtube}
                      title={t("youtube")}
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-sm btn-outline-secondary with-icon mr-2"
                    >
                      <RiYoutubeLine />
                    </a>
                  )}
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("created_at")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  <Moment format="DD/MM/YYYY HH:mm:ss">{response.data.created_at}</Moment>
                </dd>
              </dl>
              {response.data.updated_at && (
                <>
                  <hr />
                  <dl className="row">
                    <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("updated_at")}:</dt>
                    <dd className="col-12 col-sm-9 col-md-10">
                      <Moment format="DD/MM/YYYY HH:mm:ss">{response.data.updated_at}</Moment>
                    </dd>
                  </dl>
                </>
              )}
            </div>
          </Card.Body>
          <Card.Footer>
            <Row>
              <Col xs="12" className="text-right">
                <Button as={NavLink} to="/contact/edit" variant="warning" size="sm" className="with-icon">
                  <RiEditBoxLine />
                  <span>{t("edit")}</span>
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      )}
    </MainLayout>
  );
};

export default ShowProfile;
