import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MainLayout } from "../../layouts";
import { Button, Card, Col, Row } from "react-bootstrap";
import { RiArrowLeftSLine, RiFileDownloadLine } from "react-icons/all";
import { RootState } from "../../../store/reducers";
import { IAppointmentState } from "../../../store/types";
import { LoadingIndicator } from "../../partials";
import Moment from "react-moment";
import actions from "../../../store/actions";
import app from "../../../config/app";
import { getFormattedDuration } from "../../../utils/mixins";

type RouterParamsType = {
  id: string;
};

export const Show = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<RouterParamsType>();

  const { isLoading, response, error } = useSelector<RootState, IAppointmentState>(
    (state: RootState) => state.appointment
  );

  useEffect(() => {
    if (error?.response) {
      if (error.response.status === 400 || error.response.status === 404) {
        dispatch({ type: "APPOINTMENT_RESET" });
        history.push("/404");
      }
    } else {
      dispatch(actions.appointment(id));
    }
  }, [dispatch, id, error, history]);

  /**
   * Get download URL.
   * @param filename
   */
  const getDownloadUrl = (filename: string | undefined): string => {
    if (filename === undefined) {
      return "";
    }

    return `${app.WEBRTC_URL}/record/${response?.data.room_id}/${filename}`;
  };

  return (
    <MainLayout>
      <LoadingIndicator show={isLoading} />
      {response && (
        <Card>
          <Card.Header as="h6">{t("show")}</Card.Header>
          <Card.Body>
            <div className="content-view">
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("id")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.id}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("room_id")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  <a
                    href={`${app.MEETING_URL}/meet/${response.data.room_id}`}
                    target="_blank"
                    rel="noreferrer"
                    className="text-muted"
                  >
                    {response.data.room_id}
                  </a>
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("role_names.MENTOR")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  <NavLink to={`/users/${response.data.mentor.id}`} className="text-muted">
                    {`${response.data.mentor.name} ${response.data.mentor.lastname}`}
                  </NavLink>
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("role_names.USER")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  <NavLink to={`/users/${response.data.user.id}`} className="text-muted">
                    {`${response.data.user.name} ${response.data.user.lastname}`}
                  </NavLink>
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("type")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{t(`appointment_content_types.${response.data.type}`)}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("status")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{t(`appointment_status_types.${response.data.status}`)}</dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("start_date")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  <Moment format="DD/MM/YYYY HH:mm">{response.data.start_date}</Moment>
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("end_date")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  <Moment format="DD/MM/YYYY HH:mm">{response.data.end_date}</Moment>
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("event_place")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {response.data.event_place ? response.data.event_place : "-"}
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("appointment_message")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {response.data.appointment_message ? response.data.appointment_message : "-"}
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("is_appointment_completed")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">{response.data.evaluation === null ? t("no") : t("yes")}</dd>
              </dl>
              <hr />
              {response.data.evaluation !== null && (
                <>
                  <dl className="row">
                    <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("mentor_evaluation")}:</dt>
                    <dd className="col-12 col-sm-9 col-md-10">
                      {response.data.evaluation.mentor_evaluation ? response.data.evaluation.mentor_evaluation : "-"}
                    </dd>
                  </dl>
                  <hr />
                  <dl className="row">
                    <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("mentor_score")}:</dt>
                    <dd className="col-12 col-sm-9 col-md-10">
                      {response.data.evaluation.mentor_score ? `${response.data.evaluation.mentor_score}/5` : "-"}
                    </dd>
                  </dl>
                  <hr />
                  <dl className="row">
                    <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("user_evaluation")}:</dt>
                    <dd className="col-12 col-sm-9 col-md-10">
                      {response.data.evaluation.user_evaluation ? response.data.evaluation.user_evaluation : "-"}
                    </dd>
                  </dl>
                  <hr />
                  <dl className="row">
                    <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("user_score")}:</dt>
                    <dd className="col-12 col-sm-9 col-md-10">
                      {response.data.evaluation.user_score ? `${response.data.evaluation.user_score}/5` : "-"}
                    </dd>
                  </dl>
                  <hr />
                </>
              )}
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("records")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  {response.data.records.length > 0 ? (
                    <div className="table-responsive">
                      <table className="table table-striped table-bordered table-hover mb-0">
                        <thead>
                          <tr>
                            <th>{t("file")}</th>
                            <th className="text-center">{t("duration")}</th>
                            <th className="text-center">{t("download")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {response.data.records.map((record) => {
                            return (
                              <tr>
                                <td>{record.path.split(/[\\/]/).pop()}</td>
                                <td className="text-center">{getFormattedDuration(record.duration)}</td>
                                <td className="text-center">
                                  <a
                                    href={getDownloadUrl(record.path.split(/[\\/]/).pop())}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="btn btn-sm btn-success with-icon"
                                  >
                                    <RiFileDownloadLine />
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <>-</>
                  )}
                </dd>
              </dl>
              <hr />
              <dl className="row">
                <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("created_at")}:</dt>
                <dd className="col-12 col-sm-9 col-md-10">
                  <Moment format="DD/MM/YYYY HH:mm:ss">{response.data.created_at}</Moment>
                </dd>
              </dl>
              {response.data.updated_at && (
                <>
                  <hr />
                  <dl className="row">
                    <dt className="col-12 col-sm-3 col-md-2 text-left text-sm-right">{t("updated_at")}:</dt>
                    <dd className="col-12 col-sm-9 col-md-10">
                      <Moment format="DD/MM/YYYY HH:mm:ss">{response.data.updated_at}</Moment>
                    </dd>
                  </dl>
                </>
              )}
            </div>
          </Card.Body>
          <Card.Footer>
            <Row>
              <Col xs="12" className="text-left">
                <Button as={NavLink} to="/appointments" variant="secondary" size="sm" className="with-icon">
                  <RiArrowLeftSLine />
                  <span>{t("list")}</span>
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      )}
    </MainLayout>
  );
};

export default Show;
