import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { MainLayout } from "../../layouts";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import Moment from "react-moment";
import qs from "query-string";
import { Button, ButtonGroup, Card, Col, Form, Row, Table } from "react-bootstrap";
import { RootState } from "../../../store/reducers";
import { FeedbackType, IFeedbacksListState, IFeedbackState, IFeedsPaginationParams } from "../../../store/types";
import { DeleteConfirmModal, LoadingIndicator, Pagination, RecordNotFound } from "../../partials";
import { MdLocationDisabled, MdLocationSearching, RiAddBoxLine, RiDeleteBin6Line, RiEyeLine } from "react-icons/all";
import actions from "../../../store/actions";
import { DEFAULT_PER_PAGE } from "../../../types";

const List = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const filterQRef = useRef<HTMLInputElement>(null);

  const { isLoading, response, error } = useSelector<RootState, IFeedbacksListState>(
    (state: RootState) => state.feedbacks
  );

  const { isLoading: updating, response: updateResponse } = useSelector<RootState, IFeedbackState>(
    (state: RootState) => state.updateFeedback
  );

  const { response: deleteResponse } = useSelector<RootState, IFeedbacksListState>(
    (state: RootState) => state.deleteFeedback
  );

  const getFeedbacks = useCallback(
    (queryParams?) => {
      dispatch(actions.feedbacks(queryParams));
    },
    [dispatch]
  );

  const queryString = new URLSearchParams(useLocation().search);
  const page: number = queryString.get("page") ? Number(queryString.get("page")) : 1;
  const q: string | null = queryString.get("q") ? queryString.get("q") : null;

  const [queryParams, setQueryParams] = useState<IFeedsPaginationParams>({ page: page, per: DEFAULT_PER_PAGE });
  const qParams = useMemo<IFeedsPaginationParams>(() => qs.parse(search), [search]);
  useEffect(() => {
    const params = qParams;
    params.per = DEFAULT_PER_PAGE;
    setQueryParams(params);
    getFeedbacks(params);
  }, [getFeedbacks, qParams]);

  const [deleteItem, setDeleteItem] = useState<string | null>(null);
  const onDeleteConfirm = async () => {
    if (deleteItem) {
      dispatch(await actions.deleteFeedback(deleteItem));
      setDeleteItem(null);
    }
  };

  useEffect(() => {
    if (error?.response) {
      dispatch({ type: "FEEDBACK_LIST_RESET" });

      if (error.response.status >= 400) {
        history.push("/feedbacks");
      }
    }

    if (!response && !isLoading) {
      getFeedbacks(queryParams);
    }
  }, [getFeedbacks, response, isLoading, queryParams, page, error, history, dispatch]);

  useEffect(() => {
    if (deleteResponse) {
      addToast(t("deleted_with_param", { param: t("feedback") }), {
        appearance: "success",
        autoDismiss: true,
      });

      getFeedbacks({ page: page, per: DEFAULT_PER_PAGE });
      dispatch({ type: "DELETE_FEEDBACK_RESET" });
    }
  }, [deleteResponse, addToast, t, getFeedbacks, page, dispatch]);

  const handleChangeStatus = async (feedback: FeedbackType) => {
    dispatch(
      await actions.updateFeedback(feedback.id, {
        name: feedback.name,
        position: feedback.position,
        description: feedback.description,
        is_active: !feedback.is_active,
      })
    );
  };

  const handleSubmitFilter = () => {
    let url = `/feedbacks?page=1`;
    const searchParams: Array<string> = [];
    if (filterQRef.current!.value) {
      searchParams.push(`q=${filterQRef.current!.value}`);
      queryParams.q = filterQRef.current!.value;
    }

    if (searchParams.length) {
      url += "&" + searchParams.join("&");
    }

    getFeedbacks(queryParams);

    history.push(url);
  };

  useEffect(() => {
    if (updateResponse) {
      getFeedbacks(queryParams);
      dispatch({ type: "UPDATE_FEEDBACK_RESET" });
    }
  }, [updateResponse, getFeedbacks, queryParams, dispatch]);

  return (
    <MainLayout>
      <LoadingIndicator show={isLoading || updating} />
      {response && !updating && (
        <>
          <DeleteConfirmModal
            show={deleteItem !== null}
            onClose={() => setDeleteItem(null)}
            onConfirm={onDeleteConfirm}
          />
          <Row>
            <Col md="9" className="order-last order-md-first">
              <Card>
                <Card.Header>
                  <Row>
                    <Col as="h6" xs="8" className="mb-0">
                      {t("Feedbacks")}
                    </Col>
                    <Col xs="4" className="mb-0 text-right">
                      <strong className="mr-1">{t("total")}:</strong>
                      <span>{response.data.pagination.total}</span>
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body className="p-0 m-0">
                  {response.data.items.length > 0 ? (
                    <Table responsive striped hover className="table-list m-0">
                      <thead>
                        <tr>
                          <th>{t("work_title")}</th>
                          <th>{t("full_name")}</th>
                          <th>{t("content")}</th>
                          <th className="center">{t("status")}</th>
                          <th className="date">{t("created_at")}</th>
                          <th className="actions">{t("actions")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {response.data.items.map((feedback, key) => {
                          return (
                            <tr key={key}>
                              <td>{feedback.position}</td>
                              <td>{feedback.name}</td>
                              <td>{feedback.description}</td>
                              <td className="center">{feedback.is_active ? t("active") : t("passive")}</td>
                              <td>
                                <Moment format="DD/MM/YYYY HH:mm:ss">{feedback.created_at}</Moment>
                              </td>
                              <td className="actions">
                                <ButtonGroup>
                                  <Button
                                    as={NavLink}
                                    to={`/feedbacks/${feedback.id}`}
                                    variant="outline-info"
                                    size="sm"
                                    className="btn-icon"
                                    title={t("show")}
                                  >
                                    <RiEyeLine />
                                  </Button>
                                  <Button
                                    variant={`outline-${feedback.is_active ? "success" : "danger"}`}
                                    size="sm"
                                    className="btn-icon"
                                    title={t("change_status")}
                                    onClick={() => handleChangeStatus(feedback)}
                                  >
                                    {feedback.is_active ? <MdLocationSearching /> : <MdLocationDisabled />}
                                  </Button>
                                  <Button
                                    variant="outline-danger"
                                    size={"sm"}
                                    className="btn-icon"
                                    title={t("delete")}
                                    onClick={() => setDeleteItem(feedback.id)}
                                  >
                                    <RiDeleteBin6Line />
                                  </Button>
                                </ButtonGroup>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  ) : (
                    <RecordNotFound />
                  )}
                </Card.Body>
                <Card.Footer>
                  <Row>
                    <Col xs="6">
                      <Pagination pagination={response.data.pagination} extraParams={["q"]} />
                    </Col>
                    <Col xs="6" className="text-right">
                      <Button as={NavLink} to="feedbacks/create" variant="success" size="sm" className="with-icon">
                        <RiAddBoxLine />
                        <span>{t("create")}</span>
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
            <Col md="3" className="order-first order-md-last">
              <Card>
                <Card.Body>
                  <Form onSubmit={handleSubmitFilter}>
                    <Form.Group controlId="filter-q">
                      <Form.Label>{t("keywords")}</Form.Label>
                      <Form.Control
                        type="text"
                        name="q"
                        ref={filterQRef}
                        placeholder={t("keywords")}
                        defaultValue={q ? q : ""}
                      />
                    </Form.Group>
                    <hr />
                    <Button variant="outline-secondary" block onClick={handleSubmitFilter}>
                      {t("filter")}
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </MainLayout>
  );
};

export default List;
