import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { MainLayout } from "../../layouts";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { NavLink, useParams } from "react-router-dom";
import { RiArrowLeftSLine, RiCheckFill } from "react-icons/all";
import { useForm, Controller } from "react-hook-form";
import { RootState } from "../../../store/reducers";
import { INewsParams, INewsState } from "../../../store/types";
import { AlertDefaultState, AlertStateType } from "../../../types";
import { CustomFileInput, FormAlert, LoadingIndicator } from "../../partials";
import actions from "../../../store/actions";
import { WysiwygEditor } from "../../partials";
import { fileObjectToBase64 } from "../../../utils/mixins";
import history from "../../../utils/history";

type FormDataType = {
  title: string;
  summary: string;
  content: string;
  pictures: FileList;
  status: string;
  description?: string | null;
  keywords?: string | null;
};

type RouterParamsType = {
  id: string;
};

export const Edit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { id } = useParams<RouterParamsType>();

  const { register, control, handleSubmit, errors, reset } = useForm<FormDataType>();
  const onSubmit = async (data: FormDataType) => {
    setFormDisable(true);
    setFormAlert(AlertDefaultState);

    const params: INewsParams = {
      title: data.title,
      summary: data.summary,
      content: data.content,
      pictures: [],
      is_active: data.status === "active",
      description: data.description,
      keywords: data.keywords,
    };

    let pictures: Promise<string>[] = [];
    if (data.pictures.length > 0) {
      pictures = Array.from(data.pictures).map(async (picture) => {
        return await fileObjectToBase64(picture);
      });
    }

    Promise.all(pictures).then(async (values) => {
      params.pictures = values;
      dispatch(await actions.updateNews(id, params));
    });
  };

  const {
    isLoading: newsIsLoading,
    response: newsResponse,
    error: newsError,
  } = useSelector<RootState, INewsState>((state: RootState) => state.news);

  const { isLoading, response, error } = useSelector<RootState, INewsState>((state: RootState) => state.updateNews);

  const [formDisable, setFormDisable] = useState<boolean>(false);
  const [formAlert, setFormAlert] = useState<AlertStateType>(AlertDefaultState);

  const wysiwygRef = useRef();

  useEffect(() => {
    if (newsError?.response) {
      if (newsError.response.status === 400 || newsError.response.status === 404) {
        dispatch({ type: "NEWS_RESET" });
        history.push("/404");
      }
    } else {
      dispatch(actions.news(id));
    }
  }, [dispatch, id, newsError]);

  useEffect(() => {
    setFormDisable(isLoading);

    if (error !== null) {
      if (error.response.status === 400) {
        setFormAlert({
          variant: "danger",
          show: true,
          messages: error.response.data.messages,
        });
      } else {
        addToast(t("unknown_error"), {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }

    if (response) {
      addToast(t("updated_with_param", { param: t("news_singular") }), {
        appearance: "success",
        autoDismiss: true,
      });

      reset();

      dispatch({ type: "UPDATE_NEWS_RESET" });
      history.push(`/news/${response.data.id}`);
    }
  }, [isLoading, response, error, addToast, reset, t, dispatch]);

  return (
    <MainLayout>
      <LoadingIndicator show={newsIsLoading} />

      {newsResponse && (
        <>
          <FormAlert variant={formAlert.variant} show={formAlert.show} to={formAlert.to}>
            {formAlert.messages.map((message, key) => {
              return <p key={key}>{message}</p>;
            })}
          </FormAlert>

          <Card className={formDisable ? "loading-block" : ""}>
            <Card.Header as="h6">{t("edit")}</Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Row>
                  <Form.Group as={Col} md="12" controlId="news-title">
                    <Form.Label>{t("title")}:</Form.Label>
                    <Form.Control
                      type="text"
                      name="title"
                      ref={register({
                        required: {
                          value: true,
                          message: t("validation.you_must_enter", { name: t("title") }),
                        },
                      })}
                      placeholder={t("title")}
                      defaultValue={newsResponse.data.title}
                      autoFocus
                      isInvalid={!!errors.title}
                    />
                    <Form.Control.Feedback type="invalid">{errors.title?.message}</Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <hr />
                <Form.Row>
                  <Form.Group as={Col} md="12" controlId="news-summary">
                    <Form.Label>{t("summary")}:</Form.Label>
                    <Form.Control
                      type="text"
                      as="textarea"
                      name="summary"
                      ref={register({
                        required: {
                          value: true,
                          message: t("validation.you_must_enter", { name: t("summary") }),
                        },
                      })}
                      placeholder={t("summary")}
                      defaultValue={newsResponse.data.summary}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md="12" controlId="news-content">
                    <Form.Label>{t("content")}:</Form.Label>
                    <Controller
                      control={control}
                      name="content"
                      ref={register({
                        required: {
                          value: true,
                          message: t("validation.you_must_enter", { name: t("content") }),
                        },
                      })}
                      placeholder={t("content")}
                      defaultValue={newsResponse.data.content ? newsResponse.data.content : ""}
                      render={({ onChange }) => {
                        return (
                          <WysiwygEditor
                            defaultValue={newsResponse.data.content ? newsResponse.data.content : ""}
                            onChange={(content: string) => onChange(content)}
                            ref={wysiwygRef}
                          />
                        );
                      }}
                      isInvalid={!!errors.content}
                    />
                    <Form.Control.Feedback type="invalid">{errors.content?.message}</Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <hr />
                <Form.Row>
                  <Form.Group as={Col} md="12" controlId="news-content">
                    <Form.Label>{t("pictures")}:</Form.Label>
                    <span className="text-muted ml-2">{t("only_new_added_notice")}</span>
                    <CustomFileInput
                      name="pictures"
                      accept="image/jpeg,image/jpg,image/png"
                      multiple
                      dropzone
                      ref={register}
                      isInvalid={!!errors.pictures}
                    />
                    <Form.Control.Feedback type="invalid">{errors.pictures?.message}</Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <hr />
                <hr />
                <Form.Row>
                  <Form.Group as={Col} md="4" controlId="news-description">
                    <Form.Label>{t("description")}:</Form.Label>
                    <Form.Control
                      type="text"
                      as="textarea"
                      name="description"
                      ref={register}
                      placeholder={t("description")}
                      defaultValue={newsResponse.data.description ? newsResponse.data.description : ""}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="4" controlId="news-keywords">
                    <Form.Label>{t("keywords")}:</Form.Label>
                    <Form.Control
                      type="text"
                      as="textarea"
                      name="keywords"
                      ref={register}
                      placeholder={t("keywords")}
                      defaultValue={newsResponse.data.keywords ? newsResponse.data.keywords : ""}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md="3" controlId="blogPost-status">
                    <Form.Label>{t("status")}</Form.Label>
                    <Form.Control
                      as="select"
                      custom
                      name="status"
                      ref={register({
                        required: {
                          value: true,
                          message: t("validation.you_must_choose", { name: t("status") }),
                        },
                      })}
                      placeholder={t("status")}
                      defaultValue={newsResponse.data.is_active ? "active" : "passive"}
                      isInvalid={!!errors.status}
                    >
                      <option value="">{t("select_status")}</option>
                      <option value="active">{t("active")}</option>
                      <option value="passive">{t("passive")}</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">{errors.status?.message}</Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
              </Form>
            </Card.Body>
            <Card.Footer>
              <Row>
                <Col xs="6" className="text-left">
                  <Button as={NavLink} to="/news" variant="secondary" size="sm" className="with-icon">
                    <RiArrowLeftSLine />
                    <span>{t("list")}</span>
                  </Button>
                </Col>
                <Col xs="6" className="text-right">
                  <Button variant="primary" size="sm" className="with-icon" onClick={handleSubmit(onSubmit)}>
                    <RiCheckFill />
                    <span>{t("save")}</span>
                  </Button>
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        </>
      )}
    </MainLayout>
  );
};

export default Edit;
