import { Dispatch } from "redux";
import { AdminApiClient } from "../../utils/http-client";
import { ISubSectorParams, ISubSectorsPaginationParams } from "../types";

const adminApiClient = AdminApiClient.getInstance();

export const subSectors = (queryParams?: ISubSectorsPaginationParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "SUB_SECTORS_START" });
  await adminApiClient
    .subSectors(queryParams)
    .then((response) => {
      dispatch({ type: "SUB_SECTORS_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "SUB_SECTORS_FAILURE", payload: error }));
};

export const subSector = (id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: "SUB_SECTOR_START" });
  await adminApiClient
    .subSector(id)
    .then((response) => {
      dispatch({ type: "SUB_SECTOR_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "SUB_SECTOR_FAILURE", payload: error }));
};

export const createSubSector = (params: ISubSectorParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "CREATE_SUB_SECTOR_START" });
  await adminApiClient
    .createSubSector(params)
    .then((response) => {
      dispatch({ type: "CREATE_SUB_SECTOR_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "CREATE_SUB_SECTOR_FAILURE", payload: error }));
};

export const updateSubSector = (id: string, params: ISubSectorParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "UPDATE_SUB_SECTOR_START" });
  await adminApiClient
    .updateSubSector(id, params)
    .then((response) => {
      dispatch({ type: "UPDATE_SUB_SECTOR_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "UPDATE_SUB_SECTOR_FAILURE", payload: error }));
};

export const deleteSubSector = (id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: "DELETE_SUB_SECTOR_START" });
  await adminApiClient
    .deleteSubSector(id)
    .then((response) => {
      dispatch({ type: "DELETE_SUB_SECTOR_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "DELETE_SUB_SECTOR_FAILURE", payload: error }));
};
