import { MainLayout } from "../../layouts";
import { LoadingIndicator, DeleteConfirmModal, RecordNotFound } from "../../partials";
import { useEffect, useState } from "react";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/reducers";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { RiArrowLeftSLine, RiEditBoxLine, RiDeleteBin6Line, RiArrowRightSLine, RiAddBoxLine, RiFilePdfLine } from "react-icons/all";
import { IMatchState, ITeamsState } from "../../../store/types";
import actions from "../../../store/actions";
import { useTranslation } from "react-i18next";
import { Card, Row, Col, Button, InputGroup, Table, ButtonGroup, Form } from "react-bootstrap";
import { getMatchStatusClassName } from "../../../utils/mixins";

export const MatchesList = () => {
    const { isLoading, response, error } = useSelector<RootState, ITeamsState>(state => state.teams);
    const { response: deleteResponse } = useSelector<RootState, IMatchState>(state => state.deleteMatch);
    const dispatch = useDispatch();
    const [queryForTeams] = useState({ page: 1, per: 1000 });
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const history = useHistory();
    type matchIds = { teamId: string, pairId: string };
    const [deleteProgram, setDeleteProgram] = useState<matchIds | null>(null);

    const onDeleteConfirm = async () => {
        if (deleteProgram) {
            await dispatch(actions.deleteMatch(deleteProgram.teamId, deleteProgram.pairId));
            setDeleteProgram(null);
        }
    }
    useEffect(() => {
    }, [deleteProgram]);

    useEffect(() => {
        if (deleteResponse) {
            addToast(t("deleted_with_param", { param: t("match") }), {
                appearance: "success",
                autoDismiss: true,
            });
            dispatch(actions.teams(queryForTeams));
            dispatch({ type: "DELETE_MATCH_RESET" });
        }

    }, [deleteResponse, error]);

    useEffect(() => {
        dispatch(actions.teams(queryForTeams));
    }, [dispatch]);

    const pairsCounts = response?.data.items.map((team: any) => team.pairs.length) || [];
    const queryString = new URLSearchParams(useLocation().search);
    const totalPairsCount = pairsCounts?.reduce((total, count) => total + count, 0);
    const PAIRS_PER_PAGE = 10;
    const totalPages = Math.ceil(totalPairsCount / PAIRS_PER_PAGE);
    const pageString = queryString.get("page");
    const page = pageString ? parseInt(pageString) : 1;

    const startIndex = (page - 1) * PAIRS_PER_PAGE;
    const endIndex = Math.min(startIndex + PAIRS_PER_PAGE, totalPairsCount);
    const visiblePairs: { teamIndex: number; pairIndex: number; }[] = [];
    let pairCounter = 0;
    for (let i = 0; i < pairsCounts!.length; i++) {
        for (let j = 0; j < pairsCounts![i]; j++) {
            if (pairCounter >= startIndex && pairCounter < endIndex) {
                visiblePairs.push({ teamIndex: i, pairIndex: j });
            }
            pairCounter++;
        }
    }
    const handlePageChange = (pageNumber: any) => {
        history.push(`?page=${pageNumber}`);
    }

    const handleDelete = (match: matchIds) => {
        setDeleteProgram(match);
    }

    const renderPagination = () => {
        if (totalPages <= 1) return null;
    }
    return (
        <MainLayout>
            <LoadingIndicator show={isLoading} />
            {response && (
                <Row>
                    <Col md="12">
                        <Card>
                            <Card.Header>
                                <Row>
                                    <Col as="h6" xs="8" className="mb-0">
                                        {t("matches")}
                                    </Col>
                                    <Col xs="4" className="mb-0 text-right">
                                        <strong className="mr-1">{t("total")}:</strong>
                                        <span>{totalPairsCount}</span>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body className="p-0 m-0">
                                {response.data.items.length > 0 ? (
                                    <>
                                        <DeleteConfirmModal
                                            show={deleteProgram !== null}
                                            onClose={() => setDeleteProgram(null)}
                                            onConfirm={onDeleteConfirm}
                                        />
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                    <th>{t("mentor_team")}</th>
                                                    <th>{t("client_s")}</th>
                                                    <th>{t("start_date")}</th>
                                                    <th>{t("end_date")}</th>
                                                    <th >{t("mentoring_program")}</th>
                                                    <th>{t("actions")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {visiblePairs.map(({ teamIndex, pairIndex }) => {
                                                    const pair = response.data.items[teamIndex].pairs[pairIndex];
                                                    return (
                                                        <tr key={`${teamIndex}_${pairIndex}`} className={`table-${getMatchStatusClassName(pair)}`}>
                                                            <td>
                                                                <NavLink to={`/teams/${response.data.items[teamIndex].id}`}>
                                                                    {response.data.items[teamIndex].name}
                                                                </NavLink>
                                                            </td>
                                                            <td>
                                                                {pair.clients.map((client: any, index: number) => (
                                                                    <div key={index}>
                                                                        <NavLink to={`/users/${client.id}`}>
                                                                            {client.name} {client.lastname}
                                                                        </NavLink>
                                                                    </div>
                                                                ))}
                                                            </td>
                                                            <td>
                                                                {pair.start_at && (
                                                                    <Moment format="DD/MM/YYYY">
                                                                        {pair.start_at}
                                                                    </Moment>
                                                                )}
                                                            </td>
                                                            <td>
                                                                {pair.end_at && (
                                                                    <Moment format="DD/MM/YYYY">
                                                                        {pair.end_at}
                                                                    </Moment>
                                                                )}
                                                            </td>
                                                            <td>
                                                                {pair.mentoring_programs.map((program: any, index: number) => (
                                                                    <div key={index}>
                                                                        <NavLink to={`/mentoring_programs/${program.id}/match`}>
                                                                            {program.title}
                                                                        </NavLink>
                                                                    </div>
                                                                ))}
                                                            </td>
                                                            <td className="actions">
                                                                <ButtonGroup>
                                                                    <Button
                                                                        as={NavLink}
                                                                        to={{
                                                                            pathname: `/matches/${response.data.items[teamIndex].id}/edit`,
                                                                            state: { pairId: pair.id, itemId: response.data.items[teamIndex].id }
                                                                        }}
                                                                        variant="outline-secondary"
                                                                        size="sm"
                                                                        className="btn-icon"
                                                                        title={t("edit")}
                                                                    >
                                                                        <RiEditBoxLine />
                                                                    </Button>
                                                                    <Button
                                                                        variant="outline-danger"
                                                                        size="sm"
                                                                        className="btn-icon"
                                                                        title={t("delete")}
                                                                        onClick={() => {
                                                                            const teamId = response.data.items[teamIndex].id;
                                                                            const pairId = pair.id;
                                                                            handleDelete({ teamId, pairId });
                                                                        }}
                                                                    >
                                                                        <RiDeleteBin6Line />
                                                                    </Button>
                                                                    <Button
                                                                    as={NavLink}
                                                                    to={`/matches/${response.data.items[teamIndex].id}/program/${response.data.items[teamIndex].pairs[pairIndex].id}/report`}
                                                                    variant="outline-primary"
                                                                    className="btn-icon"
                                                                    size="sm"
                                                                    title={t("report")}
                                                                >
                                                                    <RiFilePdfLine />
                                                                </Button>

                                                                </ButtonGroup>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}

                                            </tbody>
                                        </Table>
                                    </>
                                ) : (
                                    <RecordNotFound />
                                )}
                            </Card.Body>
                            <Card.Footer>
                                <Row>
                                    <Col xs="6">
                                        <InputGroup className="content-pagination">
                                            <InputGroup.Prepend>
                                                <Button
                                                    variant="outline-secondary"
                                                    disabled={page === 1}
                                                    onClick={() => handlePageChange(page - 1)}
                                                    size="sm"
                                                    className="with-icon"
                                                >
                                                    <RiArrowLeftSLine />
                                                </Button>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                as="select"
                                                size="sm"
                                                custom
                                                defaultValue={page}
                                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                                    const selectedPage = event.target.value;
                                                    handlePageChange(selectedPage);
                                                }}
                                            >
                                                {[...Array(totalPages)].map((_, key) => (
                                                    <option value={key + 1} key={key}>
                                                        {key + 1}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                            <InputGroup.Append>
                                                <Button
                                                    variant="outline-secondary"
                                                    disabled={page === totalPages}
                                                    onClick={() => handlePageChange(page + 1)}
                                                    size="sm"
                                                    className="with-icon"
                                                >
                                                    <RiArrowRightSLine />
                                                </Button>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </Col>
                                    <Col xs="6" className="text-right">
                                        <Button as={NavLink} to="/matches/create" variant="success" size="sm" className="with-icon">
                                            <RiAddBoxLine />
                                            <span>{t("create")}</span>
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Footer>
                            {renderPagination()}
                        </Card>
                    </Col>
                </Row>
            )}
        </MainLayout>
    );
};

export default MatchesList;
