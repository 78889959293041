import { AbstractHttpClient } from "./AbstractHttpClient";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { app as config } from "../../config";
import AppStorage from "../storage";
import { IUpdateProfileParams } from "../../store/types";

export default class UserApiClient extends AbstractHttpClient {
  /**
   * @private classInstance
   */
  private static classInstance?: UserApiClient;

  /**
   * @private constructor
   */
  private constructor() {
    super(config.API_URL.replace(/^\/|\/$/g, ""));

    this._initializeRequestInterceptor();
    this._initializeResponseInterceptor();
  }

  /**
   * @private _initializeRequestInterceptor
   */
  private _initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(this._setAccessToken);
  };

  /**
   * @param config
   */
  private _setAccessToken = (config: AxiosRequestConfig) => {
    const token = AppStorage.getAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  };

  /**
   * @private _initializeResponseInterceptor
   */
  protected _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(this._handleResponse, this._authenticatedHandleError);
  };

  /**
   * @private _handleResponse
   * @param response
   */
  protected _handleResponse = (response: AxiosResponse) => {
    return response;
  };

  /**
   * @public getInstance
   */
  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new this();
    }

    return this.classInstance;
  }

  /**
   * Authenticated user.
   */
  public me = async () => await this.instance.get("/me");

  /**
   * Update profile.
   */
  public updateProfile = async (params: IUpdateProfileParams) => {
    return await this.instance.patch("/update-profile", params);
  };
}
