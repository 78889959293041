import { Reducer } from "react";
import {
  IPagesState,
  PagesActionType,
  IPageState,
  PageActionType,
  CreatePageActionType,
  UpdatePageActionType,
  DeletePageActionType,
  IDefaultState,
  OrderPageActionType,
} from "../types";

// Pages
const pagesInitialState: IPagesState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const pagesReducer: Reducer<IPagesState, PagesActionType> = (state = pagesInitialState, action): IPagesState => {
  switch (action.type) {
    case "PAGES_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "PAGES_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "PAGES_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "PAGES_RESET":
      return pagesInitialState;
    default:
      return state;
  }
};

// Page
const pageInitialState: IPageState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const pageReducer: Reducer<IPageState, PageActionType> = (state = pageInitialState, action): IPageState => {
  switch (action.type) {
    case "PAGE_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "PAGE_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "PAGE_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "PAGE_RESET":
      return pageInitialState;
    default:
      return state;
  }
};

/**
 * @param state
 * @param action
 */
export const orderPageReducer: Reducer<IPageState, OrderPageActionType> = (
  state = pageInitialState,
  action
): IPageState => {
  switch (action.type) {
    case "ORDER_PAGE_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "ORDER_PAGE_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "ORDER_PAGE_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "ORDER_PAGE_RESET":
      return pageInitialState;
    default:
      return state;
  }
};

/**
 * @param state
 * @param action
 */
export const createPageReducer: Reducer<IPageState, CreatePageActionType> = (
  state = pageInitialState,
  action
): IPageState => {
  switch (action.type) {
    case "CREATE_PAGE_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "CREATE_PAGE_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "CREATE_PAGE_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "CREATE_PAGE_RESET":
      return pageInitialState;
    default:
      return state;
  }
};

/**
 * @param state
 * @param action
 */
export const updatePageReducer: Reducer<IPageState, UpdatePageActionType> = (
  state = pageInitialState,
  action
): IPageState => {
  switch (action.type) {
    case "UPDATE_PAGE_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "UPDATE_PAGE_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "UPDATE_PAGE_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "UPDATE_PAGE_RESET":
      return pageInitialState;
    default:
      return state;
  }
};

const pageDeleteInitialState: IDefaultState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const deletePageReducer: Reducer<IDefaultState, DeletePageActionType> = (
  state = pageDeleteInitialState,
  action
): IPageState => {
  switch (action.type) {
    case "DELETE_PAGE_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "DELETE_PAGE_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "DELETE_PAGE_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "DELETE_PAGE_RESET":
      return pageDeleteInitialState;
    default:
      return state;
  }
};
