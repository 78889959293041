import { Dispatch } from "redux";
import { AdminApiClient } from "../../utils/http-client";
import { IFeedbackParams, IPaginationParams } from "../types";

const adminApiClient = AdminApiClient.getInstance();

export const feedbacks = (queryParams?: IPaginationParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "FEEDBACKS_LIST_START" });
  await adminApiClient
    .feedbacks(queryParams)
    .then((response) => {
      dispatch({ type: "FEEDBACKS_LIST_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "FEEDBACKS_LIST_FAILURE", payload: error }));
};

export const createFeedback = (params: IFeedbackParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "CREATE_FEEDBACK_START" });
  await adminApiClient
    .createFeedback(params)
    .then((response) => {
      dispatch({ type: "CREATE_FEEDBACK_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "CREATE_FEEDBACK_FAILURE", payload: error }));
};

export const feedback = (id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: "FEEDBACK_START" });
  await adminApiClient
    .feedback(id)
    .then((response) => {
      dispatch({ type: "FEEDBACK_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "FEEDBACK_FAILURE", payload: error }));
};

export const updateFeedback = (id: string, params: IFeedbackParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "UPDATE_FEEDBACK_START" });
  await adminApiClient
    .updateFeedback(id, params)
    .then((response) => {
      dispatch({ type: "UPDATE_FEEDBACK_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "UPDATE_FEEDBACK_FAILURE", payload: error }));
};

export const deleteFeedback = (id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: "DELETE_FEEDBACK_START" });
  await adminApiClient
    .deleteFeedback(id)
    .then((response) => {
      dispatch({ type: "DELETE_FEEDBACK_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "DELETE_FEEDBACK_FAILURE", payload: error }));
};
