import { createStore, applyMiddleware } from "redux";
import reducers from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk, { ThunkMiddleware } from "redux-thunk";
import { createLogger } from "redux-logger";
import { app as config } from "../config";

const logger = createLogger({
  predicate: () => config.ENV !== "PRODUCTION",
});

const store = createStore(reducers, composeWithDevTools(applyMiddleware(thunk as ThunkMiddleware<any, any>, logger)));

export default store;
