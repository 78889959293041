import { Reducer } from "react";
import {
  ICategoriesState,
  CategoriesActionType,
  ICategoryState,
  CategoryActionType,
  CreateCategoryActionType,
  UpdateCategoryActionType,
  DeleteCategoryActionType,
  IDefaultState,
  OrderCategoryActionType,
} from "../types";

// Categories
const categoriesInitialState: ICategoriesState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const categoriesReducer: Reducer<ICategoriesState, CategoriesActionType> = (
  state = categoriesInitialState,
  action
): ICategoriesState => {
  switch (action.type) {
    case "CATEGORIES_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "CATEGORIES_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "CATEGORIES_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "CATEGORIES_RESET":
      return categoriesInitialState;
    default:
      return state;
  }
};

// Category
const categoryInitialState: ICategoryState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const orderCategoryReducer: Reducer<ICategoryState, OrderCategoryActionType> = (
  state = categoryInitialState,
  action
): ICategoryState => {
  switch (action.type) {
    case "ORDER_CATEGORY_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "ORDER_CATEGORY_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "ORDER_CATEGORY_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "ORDER_CATEGORY_RESET":
      return categoryInitialState;
    default:
      return state;
  }
};

/**
 * @param state
 * @param action
 */
export const categoryReducer: Reducer<ICategoryState, CategoryActionType> = (
  state = categoryInitialState,
  action
): ICategoryState => {
  switch (action.type) {
    case "CATEGORY_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "CATEGORY_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "CATEGORY_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "CATEGORY_RESET":
      return categoryInitialState;
    default:
      return state;
  }
};

/**
 * @param state
 * @param action
 */
export const createCategoryReducer: Reducer<ICategoryState, CreateCategoryActionType> = (
  state = categoryInitialState,
  action
): ICategoryState => {
  switch (action.type) {
    case "CREATE_CATEGORY_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "CREATE_CATEGORY_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "CREATE_CATEGORY_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "CREATE_CATEGORY_RESET":
      return categoryInitialState;
    default:
      return state;
  }
};

/**
 * @param state
 * @param action
 */
export const updateCategoryReducer: Reducer<ICategoryState, UpdateCategoryActionType> = (
  state = categoryInitialState,
  action
): ICategoryState => {
  switch (action.type) {
    case "UPDATE_CATEGORY_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "UPDATE_CATEGORY_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "UPDATE_CATEGORY_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "UPDATE_CATEGORY_RESET":
      return categoryInitialState;
    default:
      return state;
  }
};

const categoryDeleteInitialState: IDefaultState = {
  isLoading: false,
  response: null,
  error: null,
};

/**
 * @param state
 * @param action
 */
export const deleteCategoryReducer: Reducer<IDefaultState, DeleteCategoryActionType> = (
  state = categoryDeleteInitialState,
  action
): ICategoryState => {
  switch (action.type) {
    case "DELETE_CATEGORY_START":
      return { ...state, isLoading: true, response: null, error: null };
    case "DELETE_CATEGORY_SUCCESS":
      return { ...state, isLoading: false, response: action.payload.data };
    case "DELETE_CATEGORY_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "DELETE_CATEGORY_RESET":
      return categoryDeleteInitialState;
    default:
      return state;
  }
};
