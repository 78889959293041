import { AbstractHttpClient } from "./AbstractHttpClient";
import { AxiosResponse, AxiosError } from "axios";
import { app as config } from "../../config";

export default class PublicApiClient extends AbstractHttpClient {
  /**
   * @private classInstance
   */
  private static classInstance?: PublicApiClient;

  /**
   * @private constructor
   */
  private constructor() {
    super(config.API_URL.replace(/^\/|\/$/g, ""));

    this._initializeResponseInterceptor();
  }

  /**
   * @private _initializeResponseInterceptor
   */
  protected _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(this._handleResponse, this._handleError);
  };

  /**
   * @private _handleResponse
   * @param response
   */
  protected _handleResponse = (response: AxiosResponse) => response;

  /**
   * @protected _handleError
   * @param error
   */
  protected _handleError = async (error: AxiosError) => Promise.reject(error);

  /**
   * @public getInstance
   */
  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new this();
    }

    return this.classInstance;
  }

  /**
   * Cities.
   */
  public cities = async () => await this.instance.get("/cities");

  /**
   * Districts.
   */
  public districts = async (cityId: string) => await this.instance.get(`/districts/${cityId}`);
}
