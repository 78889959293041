import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { MainLayout } from "../../layouts";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { RiArrowLeftSLine, RiCheckFill } from "react-icons/all";
import { useForm, Controller } from "react-hook-form";
import { RootState } from "../../../store/reducers";
import { IFaqParams, IFaqState } from "../../../store/types";
import { AlertDefaultState, AlertStateType, FaqTypes } from "../../../types";
import { FormAlert } from "../../partials";
import actions from "../../../store/actions";
import { WysiwygEditor } from "../../partials";
import history from "../../../utils/history";

export const Create = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const { register, control, handleSubmit, errors, reset } = useForm<IFaqParams>();
  const onSubmit = async (data: IFaqParams) => {
    dispatch(await actions.createFaq(data));
  };

  const { isLoading, response, error } = useSelector<RootState, IFaqState>((state: RootState) => state.createFaq);

  const [formDisable, setFormDisable] = useState<boolean>(false);
  const [formAlert, setFormAlert] = useState<AlertStateType>(AlertDefaultState);

  const wysiwygRef = useRef();

  useEffect(() => {
    setFormDisable(isLoading);

    if (error !== null) {
      if (error.response.status === 400) {
        setFormAlert({
          variant: "danger",
          show: true,
          messages: error.response.data.messages,
        });
      } else {
        addToast(t("unknown_error"), {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }

    if (response) {
      addToast(t("created_with_param", { param: t("faq") }), {
        appearance: "success",
        autoDismiss: true,
      });

      reset();

      dispatch({ type: "CREATE_FAQ_RESET" });
      history.push(`/faqs/${response.data.id}`);
    }
  }, [isLoading, response, error, addToast, reset, t, dispatch]);

  return (
    <MainLayout>
      <FormAlert variant={formAlert.variant} show={formAlert.show} to={formAlert.to}>
        {formAlert.messages.map((message, key) => {
          return <p key={key}>{message}</p>;
        })}
      </FormAlert>

      <Card className={formDisable ? "loading-block" : ""}>
        <Card.Header as="h6">{t("create")}</Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
              <Form.Group as={Col} md="4" controlId="faq-title">
                <Form.Label>{t("type")}:</Form.Label>
                <Form.Control
                  as="select"
                  custom
                  name="type"
                  ref={register({
                    required: {
                      value: true,
                      message: t("validation.you_must_choose", { name: t("type") }),
                    },
                  })}
                  placeholder={t("type")}
                  defaultValue=""
                  autoFocus
                  isInvalid={!!errors.type}
                >
                  <option value="">{t("select_type")}</option>
                  {Object.keys(FaqTypes).map((key) => {
                    return (
                      <option value={key} key={key}>
                        {t("faqs_type." + key)}
                      </option>
                    );
                  })}
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.type?.message}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="8" controlId="faq-title">
                <Form.Label>{t("title")}:</Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  ref={register({
                    required: {
                      value: true,
                      message: t("validation.you_must_enter", { name: t("title") }),
                    },
                  })}
                  placeholder={t("title")}
                  defaultValue=""
                  autoFocus
                  isInvalid={!!errors.title}
                />
                <Form.Control.Feedback type="invalid">{errors.title?.message}</Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <hr />
            <Form.Row>
              <Form.Group as={Col} md="12" controlId="faq-content">
                <Form.Label>{t("content")}:</Form.Label>
                <Controller
                  control={control}
                  name="content"
                  ref={register({
                    required: {
                      value: true,
                      message: t("validation.you_must_enter", { name: t("content") }),
                    },
                  })}
                  placeholder={t("content")}
                  defaultValue=""
                  render={({ onChange }) => {
                    return (
                      <WysiwygEditor
                        defaultValue=""
                        onChange={(content: string) => onChange(content)}
                        ref={wysiwygRef}
                      />
                    );
                  }}
                  isInvalid={!!errors.content}
                />
                <Form.Control.Feedback type="invalid">{errors.content?.message}</Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <hr />
            <Form.Row>
              <Form.Group as={Col} md="4" controlId="faq-description">
                <Form.Label>{t("description")}:</Form.Label>
                <Form.Control
                  type="text"
                  as="textarea"
                  name="description"
                  ref={register}
                  placeholder={t("description")}
                  defaultValue=""
                />
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="faq-keywords">
                <Form.Label>{t("keywords")}:</Form.Label>
                <Form.Control
                  type="text"
                  as="textarea"
                  name="keywords"
                  ref={register}
                  placeholder={t("keywords")}
                  defaultValue=""
                />
              </Form.Group>
            </Form.Row>
          </Form>
        </Card.Body>
        <Card.Footer>
          <Row>
            <Col xs="6" className="text-left">
              <Button as={NavLink} to="/faqs" variant="secondary" size="sm" className="with-icon">
                <RiArrowLeftSLine />
                <span>{t("list")}</span>
              </Button>
            </Col>
            <Col xs="6" className="text-right">
              <Button variant="primary" size="sm" className="with-icon" onClick={handleSubmit(onSubmit)}>
                <RiCheckFill />
                <span>{t("save")}</span>
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </MainLayout>
  );
};

export default Create;
