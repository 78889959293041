import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { MainLayout } from "../../layouts";
import { Button, ButtonGroup, Card, Col, Row, Table, Badge, Form } from "react-bootstrap";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import { RiAddBoxLine, RiDeleteBin6Line, RiEditBoxLine, RiEyeLine, SiMicrosoftexcel } from "react-icons/all";
import Moment from "react-moment";
import { RootState } from "../../../store/reducers";
import {
  ICategoriesState,
  ISectorsState,
  IUsersExportState,
  IUsersPaginationParams,
  IUsersState,
  UserFilterSelectType,
} from "../../../store/types";
import { DeleteConfirmModal, LoadingIndicator, Pagination, RecordNotFound } from "../../partials";
import actions from "../../../store/actions";
import { DEFAULT_PER_PAGE, UserRoleTypes } from "../../../types";
import qs from "query-string";
import { saveAs } from "file-saver";
import moment from "moment";

export const List = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [selectedSectors, setSelectedSectors] = useState<UserFilterSelectType[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<UserFilterSelectType[]>([]);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const filterRoleRef = useRef<HTMLSelectElement>(null);
  const filterQRef = useRef<HTMLInputElement>(null);

  const { isLoading, response, error } = useSelector<RootState, IUsersState>((state: RootState) => state.users);

  const { response: sectorsResponse } = useSelector<RootState, ISectorsState>((state: RootState) => state.sectors);

  const { response: categoriesResponse } = useSelector<RootState, ICategoriesState>(
    (state: RootState) => state.categories
  );

  const { response: deleteResponse,error:deleteError } = useSelector<RootState, IUsersState>((state: RootState) => state.deleteUser);

  const {
    isLoading: usersExportIsLoading,
    response: usersExportResponse,
    error: usersExportError,
  } = useSelector<RootState, IUsersExportState>((state: RootState) => state.usersExport);

  const queryString = new URLSearchParams(useLocation().search);
  const page: number = queryString.get("page") ? Number(queryString.get("page")) : 1;
  const role: string | null = queryString.get("role") ? queryString.get("role") : null;
  const q: string | null = queryString.get("q") ? queryString.get("q") : null;
  const sectors: string | null = queryString.get("sectors") ? queryString.get("sectors") : null;
  const categories: string | null = queryString.get("categories") ? queryString.get("categories") : null;

  const [queryParams, setQueryParams] = useState<IUsersPaginationParams>({
    page: page,
    per: DEFAULT_PER_PAGE,
    sectors: [],
    categories: [],
  });
  const qParams = useMemo<IUsersPaginationParams>(() => qs.parse(search), [search]);

  const getUsers = useCallback(
    (queryParams?) => {
      dispatch(actions.users(queryParams));
    },
    [dispatch]
  );

  const getSectors = useCallback(() => {
    dispatch(actions.sectors({ subsector: true }));
  }, [dispatch]);

  const getCategories = useCallback(() => {
    dispatch(actions.categories());
  }, [dispatch]);

  useEffect(() => {
    if (sectors && sectorsResponse) {
      const selectedSectorsBag: Array<UserFilterSelectType> = [];

      sectors.split(",").forEach((id) => {
        const find = sectorsResponse.data.items.find((sector) => sector.id === id);
        if (find) {
          selectedSectorsBag.push({
            label: find.title,
            value: find.id,
          });
        }
      });

      setSelectedSectors(selectedSectorsBag);
    } else {
      setSelectedSectors([]);
    }
  }, [sectors, sectorsResponse]);

  useEffect(() => {
    if (categories && categoriesResponse) {
      const selectedCategoriesBag: Array<UserFilterSelectType> = [];

      categories.split(",").forEach((id) => {
        const find = categoriesResponse.data.items.find((category) => category.id === id);
        if (find) {
          selectedCategoriesBag.push({
            label: find.title,
            value: find.id,
          });
        }
      });

      setSelectedCategories(selectedCategoriesBag);
    } else {
      setSelectedCategories([]);
    }
  }, [categories, categoriesResponse]);

  useEffect(() => {
    const params = qParams;
    params.per = DEFAULT_PER_PAGE;

    if (sectors) {
      params.sectors = sectors.split(",");
    }

    if (categories) {
      params.categories = categories.split(",");
    }

    setQueryParams(params);
    getUsers(params);

    if (!sectorsResponse) {
      getSectors();
    }

    if (!categoriesResponse) {
      getCategories();
    }
  }, [getUsers, getSectors, getCategories, qParams, sectors, categories, sectorsResponse, categoriesResponse]);

  const [deleteItem, setDeleteItem] = useState<string | null>(null);
  const onDeleteConfirm = async () => {
    if (deleteItem) {
      dispatch(await actions.deleteUser(deleteItem));
      setDeleteItem(null);
    }
  };

  const handleSubmitFilter = () => {
    let url = `/users?page=1`;
    const searchParams: Array<string> = [];
    if (filterRoleRef.current!.value) {
      searchParams.push(`role=${filterRoleRef.current!.value}`);
      queryParams.role = filterRoleRef.current!.value;
    }

    if (filterQRef.current!.value) {
      searchParams.push(`q=${filterQRef.current!.value}`);
      queryParams.q = filterQRef.current!.value;
    }

    if (selectedSectors.length > 0) {
      searchParams.push(`sectors=${selectedSectors.map((sector) => sector.value).join(",")}`);
      queryParams.sectors = selectedSectors.map((sector) => sector.value);
    } else {
      queryParams.sectors = [];
    }

    if (selectedCategories.length > 0) {
      searchParams.push(`categories=${selectedCategories.map((category) => category.value).join(",")}`);
      queryParams.categories = selectedCategories.map((category) => category.value);
    } else {
      queryParams.categories = [];
    }

    if (searchParams.length) {
      url += "&" + searchParams.join("&");
    }

    history.push(url);
  };

  useEffect(() => {
    if (deleteResponse) {
      addToast(t("deleted_with_param", { param: t("user") }), {
        appearance: "success",
        autoDismiss: true,
      });

      getUsers({
        page: page,
        per: DEFAULT_PER_PAGE,
        role,
        q,
        categories: selectedCategories.map((category) => category.value),
        sectors: selectedSectors.map((sector) => sector.value),
      });
      dispatch({ type: "DELETE_USER_RESET" });
    }
  }, [deleteResponse, addToast, t, getUsers, page, role, q, selectedCategories, selectedSectors, dispatch]);

  useEffect(() => {
    if (error?.response) {
      dispatch({ type: "USERS_RESET" });

      if (error.response.status >= 400) {
        history.push("/users");
      }
    } else {
      getUsers({ page, per: DEFAULT_PER_PAGE });
    }
  }, [getUsers, page, error, history, dispatch]);

  useEffect(() => {
   if(deleteError){
    console.log(deleteError.response.status ===403)
      addToast(t("This_user_belongs_to_an_active_team"), {
        appearance: "error",
        autoDismiss: true,
      });
   }
  }
  ,[deleteError,addToast,t])
  const handleExport = () => {
    const exportParams: any = queryParams;
    delete exportParams.page;
    delete exportParams.per;
    exportParams["export"] = "excel";

    dispatch(actions.usersExport(exportParams));
  };

  useEffect(() => {
    setIsExporting(usersExportIsLoading);
  }, [usersExportIsLoading]);

  useEffect(() => {
    if (usersExportError) {
      addToast(t("unknown_error"), {
        appearance: "error",
        autoDismiss: true,
      });

      dispatch({ type: "USERS_EXPORT_RESET" });
    }
  }, [usersExportError, addToast, t, dispatch]);

  useEffect(() => {
    if (usersExportResponse) {
      saveAs(new Blob([usersExportResponse]), `Kullanici-Listesi-${moment().format("YYYY-MM-DD-HH-mm-ss")}.xlsx`);
      dispatch({ type: "USERS_EXPORT_RESET" });
    }
  }, [usersExportResponse, dispatch]);

  return (
    <MainLayout>
      <LoadingIndicator show={isLoading} />
      {response && (
        <Row>
          <Col md="9" className="order-last order-md-first">
            <Card>
              <Card.Header>
                <Row>
                  <Col as="h6" xs="8" className="mb-0">
                    {t("users")}
                  </Col>
                  <Col xs="4" className="mb-0 text-right">
                    <strong className="mr-1">{t("total")}:</strong>
                    <span>{response.data.pagination.total}</span>
                  </Col>
                </Row>
              </Card.Header>

              <Card.Body className="p-0 m-0">
                {response.data.items.length > 0 ? (
                  <>
                    <DeleteConfirmModal
                      show={deleteItem !== null}
                      onClose={() => setDeleteItem(null)}
                      onConfirm={onDeleteConfirm}
                    />
                    <Table responsive striped hover className="table-list m-0">
                      <thead>
                        <tr>
                          <th>{t("name")}</th>
                          <th>{t("lastname")}</th>
                          <th>{t("email")}</th>
                          <th className="text-center">{t("roles")}</th>
                          <th className="date">{t("created_at")}</th>
                          <th className="actions">{t("actions")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {response.data.items.map((item, key) => {
                          return (
                            <tr key={key}>
                              <td>{item.name}</td>
                              <td>{item.lastname}</td>
                              <td>{item.email}</td>
                              <td className="text-center">
                                {item.roles.length > 0 ? (
                                  <>
                                    {item.roles.map((role, key) => {
                                      return (
                                        <Badge variant="secondary" key={key} className="m-1">
                                          {t(`role_names.${role}`)}
                                        </Badge>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <>-</>
                                )}
                              </td>
                              <td className="date">
                                <Moment format="DD/MM/YYYY HH:mm:ss">{item.created_at}</Moment>
                              </td>
                              <td className="actions">
                                <ButtonGroup>
                                  <Button
                                    as={NavLink}
                                    to={`/users/${item.id}`}
                                    variant="outline-info"
                                    size="sm"
                                    className="btn-icon"
                                    title={t("show")}
                                  >
                                    <RiEyeLine />
                                  </Button>
                                  <Button
                                    as={NavLink}
                                    to={`/users/${item.id}/edit`}
                                    variant="outline-secondary"
                                    size="sm"
                                    className="btn-icon"
                                    title={t("edit")}
                                  >
                                    <RiEditBoxLine />
                                  </Button>
                                  <Button
                                    variant="outline-danger"
                                    size="sm"
                                    className="btn-icon"
                                    title={t("delete")}
                                    onClick={() => setDeleteItem(item.id)}
                                  >
                                    <RiDeleteBin6Line />
                                  </Button>
                                </ButtonGroup>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </>
                ) : (
                  <RecordNotFound />
                )}
              </Card.Body>

              <Card.Footer>
                <Row className="align-items-center">
                  <Col xs="12" sm="4" className="text-left">
                    <Pagination
                      pagination={response.data.pagination}
                      extraParams={["role", "q", "sectors", "categories"]}
                    />
                  </Col>
                  <Col xs="12" sm="8" className="text-right">
                    <div className="d-inline-flex justify-content-end">
                      <Button
                        variant="info"
                        size="sm"
                        className={`with-icon mr-2${isExporting ? " process" : ""}`}
                        title={t("export_as_excel")}
                        onClick={handleExport}
                        disabled={isExporting}
                      >
                        <SiMicrosoftexcel />
                        <span className="text-truncate">{t("export")}</span>
                      </Button>
                      <Button as={NavLink} to="/users/create" variant="success" size="sm" className="with-icon mr-2">
                        <RiAddBoxLine />
                        <span className="text-truncate">{t("create")}</span>
                      </Button>
                      <Button
                        as={NavLink}
                        to="/users/create-mentor"
                        variant="outline-primary"
                        size="sm"
                        className="with-icon mr-2"
                      >
                        <RiAddBoxLine />
                        <span className="text-truncate">
                          {t("role_names.MENTOR")} {t("create")}
                        </span>
                      </Button>
                      <Button as={NavLink} to="/users/create-user" variant="outline-primary" size="sm" className="with-icon">
                        <RiAddBoxLine />
                        <span className="text-truncate">
                          {t("role_names.USER")} {t("create")}
                        </span>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Col>
          <Col md="3" className="order-first order-md-last">
            <Card>
              <Card.Body>
                <Form onSubmit={handleSubmitFilter}>
                  <Form.Group controlId="filter-role">
                    <Form.Label>{t("role")}</Form.Label>
                    <Form.Control as="select" custom ref={filterRoleRef} defaultValue={role ? role : ""}>
                      <option value="">{t("all")}</option>
                      {Object.values(UserRoleTypes).map((key) => {
                        return (
                          <option value={key} key={key}>
                            {t(`role_names.${key}`)}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="filter-sectors">
                    <Form.Label>{t("sectors")}</Form.Label>
                    {sectorsResponse && (
                      <Select
                        isMulti
                        isClearable
                        placeholder={t("select_sector")}
                        hideSelectedOptions={false}
                        onChange={(items) => setSelectedSectors([...items])}
                        defaultValue={selectedSectors}
                        options={sectorsResponse.data.items.map((sector) => ({
                          value: sector.id,
                          label: sector.title,
                        }))}
                      />
                    )}
                  </Form.Group>
                  <Form.Group controlId="filter-categories">
                    <Form.Label>{t("categories")}</Form.Label>
                    {categoriesResponse && (
                      <Select
                        isMulti
                        isClearable
                        placeholder={t("select_category")}
                        hideSelectedOptions={false}
                        onChange={(items) => setSelectedCategories([...items])}
                        defaultValue={selectedCategories}
                        options={categoriesResponse.data.items.map((category) => ({
                          value: category.id,
                          label: category.title,
                        }))}
                      />
                    )}
                  </Form.Group>
                  <Form.Group controlId="filter-q">
                    <Form.Label>{t("keywords")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="q"
                      ref={filterQRef}
                      placeholder={t("keywords")}
                      onKeyPress={(e: any) => e.code === "Enter" && handleSubmitFilter()}
                      defaultValue={q ? q : ""}
                    />
                  </Form.Group>
                  <hr />
                  <Button variant="outline-secondary" block onClick={handleSubmitFilter}>
                    {t("filter")}
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </MainLayout>
  );
};

export default List;
