import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { MainLayout } from "../../layouts";
import { Accordion, Button, Card, Col, Form, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { RiAddBoxLine, RiArrowLeftSLine, RiCheckFill, RiDeleteBin6Line } from "react-icons/all";
import { useForm } from "react-hook-form";
import { RootState } from "../../../store/reducers";
import { useParams } from "react-router-dom";
import {
    IMentoringProgramState,
    ISessionCreateParams,
    SessionFormType
}
    from "../../../store/types";
import actions from "../../../store/actions";
import history from "../../../utils/history";
type RouterParamsType = {
    id: string;
};


const CreateSesion = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();

    const { register, handleSubmit, errors, reset } = useForm<ISessionCreateParams>();
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const { id } = useParams<RouterParamsType>();
    const [formDisable, setFormDisable] = useState<boolean>(false);

    const [session, setSession] = useState<SessionFormType>({
        title: "",
        location: "",
        goals: "",
        preparations: "",
        agenda: "",
        documents: "",
        followed_tasks: [],
    });
const [isAddingTask, setIsAddingTask] = useState(false);

const addFollowedTaskItem = () => {
    setIsAddingTask((prev) => {
        if (!prev) {
            const newFollowedTask = {
                title: "",
                steps: "",
            };

            const updatedFollowedTasks = session.followed_tasks ? [...session.followed_tasks, newFollowedTask] : [newFollowedTask];

            setSession({
                ...session,
                followed_tasks: updatedFollowedTasks,
            });
        }
        return false;
    });
};




    const removeFollowedTaskItem = (taskIndex: number) => {
        const followedTasks = session.followed_tasks ? [...session.followed_tasks] : [];
    
        if (taskIndex > -1 && taskIndex < followedTasks.length) {
            followedTasks.splice(taskIndex, 1);
    
            setSession((prevSession) => ({
                ...prevSession,
                followed_tasks: followedTasks,
            }));
        } else {
            console.error("Invalid task index provided or followed_tasks is null.");
        }
    };
    
    
    
    const { isLoading, response, error } = useSelector<RootState, IMentoringProgramState>((state: RootState) => state.createSession);
useEffect(() => { 
    if (isLoading) {
        setFormDisable(true);
    } else {
        setFormDisable(false);
    }
}
, [isLoading]);
useEffect(() => {
    if (response) {
        addToast(t("created_with_param", { param: t("session") }), {
            appearance: "success",
            autoDismiss: true,
        });
    }
}   
, [response]);

const onSubmit = async (data: ISessionCreateParams): Promise<void> => {
    const newData = { ...data };
    const sessionData: ISessionCreateParams = {
        title: newData.title,
        location: newData.location,
        goals: newData.goals,
        agenda: newData.agenda,
        documents: newData.documents,
        followed_tasks: newData.followed_tasks ? [...newData.followed_tasks] : []
    };

    try {
        await dispatch(actions.createSession(id, sessionData));
        dispatch({ type: "CREATE_SESSION_RESET" });
        history.push(`/mentoring_programs/${id}`);
    } catch (error) {
        console.error("Error creating session: ", error);
    }
};

    
    return (
        <MainLayout>
            <Card className={formDisable ? "loading-block" : ""} style={{ marginBottom: 180 }}>
                <Card.Header as="h6">{t("create")}</Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Card.Body>
                            <Form.Row>
                                <Form.Group as={Col} sm="6" md="3" lg="3">
                                    <Form.Label>{t("title")}:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={`title`}
                                        ref={register({
                                            required: {
                                                value: true,
                                                message: t("validation.you_must_enter", { name: t("session_title") }),
                                            },
                                        })}
                                        placeholder={t("title")}
                                        defaultValue=""
                                        autoFocus
                                        isInvalid={errors.title !== undefined}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.title && errors.title.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} sm="6" md="3" lg="3">
                                    <Form.Label>{t("location")}:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={`location`}
                                        ref={register}
                                        placeholder={t("session_location")}
                                        defaultValue=""
                                    />
                                </Form.Group>    
                                <Form.Group as={Col} sm="6" md="4" lg="4">
                                    <Form.Label>{t("documents")}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={`documents`}
                                        ref={register}
                                        placeholder={t("session_documents")}
                                        defaultValue=""
                                    />
                                </Form.Group>                            
                            </Form.Row>
                            <hr />
                            <Form.Row>
                                <Form.Group as={Col} sm="6" md="4" lg="4">
                                    <Form.Label>{t("goals")}</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        style={{ resize: "none" }}
                                        rows={4}
                                        type="text"
                                        name={`goals`}
                                        ref={register({
                                            required: {
                                                value: true,
                                                message: t("validation.you_must_enter", { name: t("session_goal") }),
                                            },
                                        })}
                                        placeholder={t("session_goals")}
                                        defaultValue=""
                                        isInvalid={errors.goals !== undefined}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.goals && errors.goals.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} sm="6" md="4" lg="4">
                                    <Form.Label>{t("preparations")}</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        style={{ resize: "none" }}
                                        rows={4}
                                        type="text"
                                        name={`preparations`}
                                        ref={register}
                                        placeholder={t("session_preparations")}
                                        defaultValue=""
                                    />
                                </Form.Group>
                                <Form.Group as={Col} sm="6" md="4" lg="4">
                                    <Form.Label>{t("agenda")}</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        style={{ resize: "none" }}
                                        rows={4}
                                        type="text"
                                        name={`agenda`}
                                        ref={register}
                                        placeholder={t("session_agenda")}
                                        defaultValue=""
                                    />
                                </Form.Group>
                            </Form.Row>
                            <hr />
                            <Form.Row>
                                <Form.Group as={Col} md="12">
                                    <Form.Label htmlFor="followedTask-selector">
                                        {t("followed_tasks")}:
                                        <Button
                                            variant="outline-success"
                                            size="sm"
                                            className="with-icon ml-1"
                                            title={t("add")}
                                            onClick={() => addFollowedTaskItem()}
                                        >
                                            <RiAddBoxLine />
                                            <span>{t("add")}</span>
                                        </Button>
                                    </Form.Label>
                                </Form.Group>
                            </Form.Row>
                            <div>
                                {session.followed_tasks && session.followed_tasks.map((followed_task, followed_task_index) => (
                                    <div key={followed_task_index}>
                                        <Accordion defaultActiveKey="0" style={{ border: '2px solid #ced4da', borderRadius: '1rem' }}>
                                            <Accordion.Toggle as={Card.Header} eventKey={followed_task_index.toString()}>
                                                Followed Task {followed_task_index + 1}
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey={followed_task_index.toString()}>
                                                <Card.Body>
                                                    <Form.Row>
                                                    <Form.Group as={Col} sm="2" md="2" lg="2">
                                                            <Form.Label>{t("task_order")}:</Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                name={`followed_tasks.${followed_task_index}.steps`}
                                                                ref={register({
                                                                    required: {
                                                                        value: true,
                                                                        message: t("validation.you_must_enter", { name: t("task_order") }),
                                                                    },
                                                                })}
                                                                placeholder={t("followed_task_task_order")}
                                                                defaultValue=""
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="6" md="4" lg="4">
                                                            <Form.Label htmlFor={`title-${followed_task_index}`}>{t("title")}:</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                id={`title-${followed_task_index}`}
                                                                name={`followed_tasks.${followed_task_index}.title`}
                                                                ref={register({
                                                                    required: {
                                                                        value: true,
                                                                        message: t("validation.you_must_enter", { name: t("title") }),
                                                                    },
                                                                })}
                                                                placeholder={t("followed_task_title")}
                                                                defaultValue="" autoFocus
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <hr />
                                                    <Button
                                                        variant="outline-danger"
                                                        size="sm"
                                                        className="with-icon ml-1"
                                                        title={t("remove")}
                                                        onClick={() => removeFollowedTaskItem(followed_task_index)}
                                                    >
                                                        <RiDeleteBin6Line />
                                                        <span>{t("remove_followed_task")}</span>
                                                    </Button>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Accordion>
                                        <hr />
                                    </div>
                                ))}
                            </div>
                        </Card.Body>
                    </Form>
                </Card.Body>
                <Card.Footer>
                    <Row>
                        <Col xs="6" className="text-left">
                            <Button as={NavLink} to="/mentoring_programs" variant="secondary" size="sm" className="with-icon">
                                <RiArrowLeftSLine />
                                <span>{t("list")}</span>
                            </Button>
                        </Col>
                        <Col xs="6" className="text-right">
                            <Button variant="primary" size="sm" className="with-icon" onClick={handleSubmit(onSubmit)}>
                                <RiCheckFill />
                                <span>{t("save")}</span>
                            </Button>
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
        </MainLayout>
    );
});

export default CreateSesion;