import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { MainLayout } from "../../layouts";
import { RootState } from "../../../store/reducers";
import { DashboardType, IDashboardState } from "../../../store/types";
import { Col, Row } from "react-bootstrap";
import actions from "../../../store/actions";

export const Show = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [dashboard, setDashboard] = useState<DashboardType | null>(null);

  const { response: dashboardResponse } = useSelector<RootState, IDashboardState>(
    (state: RootState) => state.dashboard
  );

  useEffect(() => {
    if (dashboardResponse) {
      setDashboard(dashboardResponse.data);
    } else {
      dispatch(actions.dashboard());
    }
  }, [dashboardResponse, dispatch]);

  return (
    <MainLayout>
      {dashboard && (
        <Row className="dashboard-indicators">
          <Col className="col col-6 col-md-4 col-lg-3">
            <NavLink to="/users?role=MENTOR" className="item bg-peter-river hovered">
              <span>{t("mentors_count")}</span>
              <strong>{dashboard.users_count.mentor}</strong>
            </NavLink>
          </Col>
          <Col className="col col-6 col-md-4 col-lg-3">
            <NavLink to="/users?role=USER" className="item bg-amethyst hovered">
              <span>{t("users_count")}</span>
              <strong>{dashboard.users_count.user}</strong>
            </NavLink>
          </Col>
          <Col className="col col-6 col-md-4 col-lg-3">
            <NavLink to="/applications/mentor?status=waiting" className="item bg-green-sea hovered">
              <span>{t("mentor_applications")}</span>
              <strong>{dashboard.applications.mentor}</strong>
            </NavLink>
          </Col>
          <Col className="col col-6 col-md-4 col-lg-3">
            <NavLink to="/applications/user?status=waiting" className="item bg-pumpkin hovered">
              <span>{t("user_applications")}</span>
              <strong>{dashboard.applications.user}</strong>
            </NavLink>
          </Col>
        </Row>
      )}
    </MainLayout>
  );
};

export default Show;
