import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { MainLayout } from "../../layouts";
import moment from "moment";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { NavLink, useParams, useLocation } from "react-router-dom";
import { RiAddBoxLine, RiArrowLeftSLine, RiCheckFill } from "react-icons/all";
import { Controller, useForm } from "react-hook-form";
import { RootState } from "../../../store/reducers";
import { WysiwygEditor } from "../../partials";
import { AlertDefaultState, AlertStateType } from "../../../types";
import { FormAlert, LoadingIndicator } from "../../partials";
import actions from "../../../store/actions";
import history from "../../../utils/history";
import Select from "react-select";
import { IMatchState, IMatchParams, IMentoringProgramsState, IUsersState, UserType, IUsersPaginationParams, IMatchesCreateParams, ITeamsState } from "../../../store/types";
import { UserRoleTypes } from "../../../types";


export type OptionType = {
    value: string;
    label: string | null;
};


const CreateMatch = () => {

    const [formDisable, setFormDisable] = useState<boolean>(false);
    const { register, errors, reset, control, handleSubmit } = useForm<IMatchesCreateParams>();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [programs, setPrograms] = useState<Array<OptionType>>([]);
    const { addToast } = useToasts();
    const [teams, setTeams] = useState<Array<OptionType>>([]);
    const [clients, setclients] = useState<Array<OptionType>>([]);
    const [start_date, setStartDate] = useState<string>("");
    const [end_date, setEndDate] = useState<string>("");
    const [def_clients, setDefclients] = useState<Array<OptionType>>([]);
    const [formAlert, setFormAlert] = useState<AlertStateType>(AlertDefaultState);
    const [queryForUser] = useState<IUsersPaginationParams>({ page: 1, per: 9999 });
    const [mentoring_program_title, setProgram] = useState<Array<OptionType>>([]);
    const [hasDispatched, setHasDispatched] = useState(false);
    const [team_title, setTeam] = useState<OptionType | null>(null);
    const { response: programsResponse } = useSelector<RootState, IMentoringProgramsState>((state: RootState) => state.mentoringPrograms);
    const { response: userResponse } = useSelector<RootState, IUsersState>((state: RootState) => state.users);
    const { response: teamResponse } = useSelector<RootState, ITeamsState>((state: RootState) => state.teams);

    const onSubmit = (data: IMatchesCreateParams) => {
        if (!team_title) {
            addToast(t("validation.you_must_choose", { name: t("mentor_team") }), {
                appearance: "error",
                autoDismiss: true,
            });
            return;
        }
        const teamId = team_title?.value || "";
        data.mentoring_programs = mentoring_program_title.length > 0 ? mentoring_program_title.map((item) => item.value) : [];
        data.clients = def_clients.map((item) => item.value);
        data.start_at = moment(start_date).format("YYYY-MM-DD HH:mm:ss");
        data.end_at = moment(end_date).format("YYYY-MM-DD HH:mm:ss");
        delete (data as Partial<IMatchesCreateParams>).team;
        dispatch(actions.createMatch(teamId, data));
    };
    const { isLoading, response, error } = useSelector<RootState, IMatchState>(state => state.createMatch);
    useEffect((): void => {
        setFormDisable(isLoading);

        if (error !== null) {
            if ( error.response.status === 400) {
                setFormAlert({
                    variant: "danger",
                    show: true,
                    messages: error.response.data.messages,
                });
            } else {
                addToast(t("unknown_error"), {
                    appearance: "error",
                    autoDismiss: true,
                });
            }
        }
        if (errors !== null) {
            console.log(errors);
        }
        if (response) {
            addToast(t("created_with_param", { param: t("match") }), {
                appearance: "success",
                autoDismiss: true,
            });

            reset();

            dispatch({ type: "CREATE_MATCH_RESET" });
            history.push(`/matches`);
        }
    }, [isLoading, response, error, addToast, reset, t, dispatch, errors]);

    
    //useEffect for teams options
    useEffect((): void => {
        if (teamResponse) {
            if (teamResponse?.data.items.length! > 10) {
                if (!hasDispatched) {
                    dispatch(actions.teams({ page: 1, per: 999 }));
                    setHasDispatched(true);
                }
                const teamResponseOptionTypeArray: Array<OptionType> = [];
                teamResponse?.data.items.forEach((item) => {
                    teamResponseOptionTypeArray.push({
                        value: item.id,
                        label: item.name,
                    });
                });
                setTeams(teamResponseOptionTypeArray);
            } else {
                dispatch(actions.teams({ page: 1, per: 999 }));
                setHasDispatched(true);
            }
        }
    }, [teamResponse, dispatch, hasDispatched]);


    //useEffect for programs options
    useEffect((): void => {
        if (programsResponse) {
            if (programsResponse.data.pagination.total > 10) {
                if (!hasDispatched) {
                    dispatch(actions.mentoringPrograms({ page: 1, per: 999 }));
                    setHasDispatched(true);
                }
            }
            const programResponseOptionTypeArray: Array<OptionType> = [];
            programsResponse.data.items.forEach((item) => {
                programResponseOptionTypeArray.push({
                    value: item.id,
                    label: item.title,
                });
            });
            setPrograms(programResponseOptionTypeArray);
        } else {
            dispatch(actions.mentoringPrograms({ page: 1, per: 999 }));
            setHasDispatched(true);
        }
    }, [programsResponse, dispatch,hasDispatched]);


    //useEffect for clients options
    useEffect((): void => {
        if (userResponse) {
            const clientOptionsTypeArray: Array<OptionType> = [];
            userResponse.data.items.forEach((item: UserType): void => {
                if(item.roles.includes(UserRoleTypes.user)){   
                    clientOptionsTypeArray.push({
                        value: item.id,
                        label: `${item.name} ${item.lastname} (${item.email})`,
                    });
                }
            });
            setclients(clientOptionsTypeArray);
        }
        else {
            dispatch(actions.users(queryForUser));
        }
    }, [userResponse]);

    return (
        <MainLayout>
            <LoadingIndicator show={isLoading} />
            <FormAlert variant={formAlert.variant} show={formAlert.show} to={formAlert.to}>
                {formAlert.messages.map((message, key: number) => (
                    <p key={key}>{message}</p>
                ))}
            </FormAlert>

            <Card className={formDisable ? "loading-block" : ""} style={{ marginBottom: 180 }}>
                <Card.Header as="h6" >{t("create")}</Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Row>
                            <Form.Group as={Col} md="8">
                                <Form.Label>
                                    {t("mentor_team")}
                                </Form.Label>
                                <Controller
                                    name="team"
                                    control={control}
                                    defaultValue=" "
                                    render={({ onChange, value, ref }) => (
                                        <Select
                                            isClearable
                                            inputRef={ref}
                                            noOptionsMessage={() => t("not_found")}
                                            options={teams}
                                            value={teams.find((item) => item.value === team_title?.value) || null}
                                            onChange={(value: any): void => {
                                                setTeam(value);
                                            }}
                                            placeholder={t("select_mentor_team")}
                                        />
                                    )}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: t("validation.you_must_choose", { name: t("mentor_team") }),
                                        },
                                    }}
                                    isInvalid={!!errors.team}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.team}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row >
                            <Form.Group as={Col} md="8">
                                <Form.Label>
                                    {t("mentoring_program")}
                                </Form.Label>
                                <Controller
                                    name="mentoring_programs"
                                    control={control}
                                    defaultValue=" "
                                    render={({ onChange, value, ref }) => (
                                        <Select
                                            isMulti
                                            isClearablea
                                            inputRef={ref}
                                            noOptionsMessage={() => t("not_found")}
                                            options={programs}
                                            onChange={(value: any): void => {
                                                setProgram(value);
                                            }}
                                            placeholder={t("select_mentoring_program")}
                                        />
                                    )}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="8">
                                <Form.Label>
                                    {t("start_date")}
                                </Form.Label>
                                <Form.Control
                                    type="datetime-local"
                                    name="start_at"
                                    value={start_date}
                                    onChange={(e) => setStartDate(e.target.value)}
                                    ref={register({
                                        required: {
                                            value: true,
                                            message: t("validation.you_must_enter", { name: t("start_date") })
                                        }
                                    })}
                                    isInvalid={!!errors.start_at}
                                />
                                <Form.Control.Feedback type="invalid"> {errors.start_at && errors.start_at.message}</Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="8">
                                <Form.Label>
                                    {t("end_date")}
                                </Form.Label>
                                <Form.Control
                                    type="datetime-local"
                                    name="end_at"
                                    value={end_date}
                                    onChange={(e) => setEndDate(e.target.value)}
                                    ref={register({
                                        required: {
                                            value: true,
                                            message: t("validation.you_must_enter", { name: t("end_date") })
                                        }
                                    })}
                                    isInvalid={!!errors.end_at}
                                />
                                {errors.end_at && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.end_at.message}
                                    </Form.Control.Feedback>
                                )}

                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="8">
                                <Form.Label htmlFor="selector-clients">
                                    {t("client_s")}
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="clients"
                                    defaultValue=" "

                                    render={({ onChange, value, ref }) => (
                                        <Select
                                            isMulti
                                            isClearable
                                            inputRef={ref}
                                            noOptionsMessage={() => t("not_found")}
                                            options={clients}
                                            value={def_clients}
                                            onChange={(value: any): void => setDefclients(value)}
                                            placeholder={t("select_client_s")}
                                        />
                                    )}
                                />
                            </Form.Group>
                        </Form.Row>
                    </Form>
                </Card.Body>
                <Card.Footer>
                    <Row>
                        <Col xs="6" className="text-left">
                            <Button as={NavLink} to="/matches" variant="secondary" size="sm" className="with-icon">
                                <RiArrowLeftSLine />
                                <span>{t("list")}</span>
                            </Button>
                        </Col>
                        <Col xs="6" className="text-right">
                            <Button variant="primary" size="sm" className="with-icon" onClick={handleSubmit(onSubmit)}>
                                <RiCheckFill />
                                <span>{t("save")}</span>
                            </Button>
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
        </MainLayout>
    );
};

export default CreateMatch;
