import { Dispatch } from "redux";
import { AdminApiClient } from "../../utils/http-client";
import { ISessionUpdateParams,ISessionCreateParams,IMatchCreateSessionParams} from "../types";

const adminApiClient = AdminApiClient.getInstance();


export const createSession = (id:string,params: ISessionCreateParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "CREATE_SESSION_START" });
    await adminApiClient
        .createSession(id,params)
        .then((response) => {
        dispatch({ type: "CREATE_SESSION_SUCCESS", payload: response });
        })
        .catch((error) => dispatch({ type: "CREATE_SESSION_FAILURE", payload: error }));
};

export const createMatchSession = (id:string,params: IMatchCreateSessionParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "CREATE_SESSION_START" });
    await adminApiClient
        .createMatchSession(id,params)
        .then((response) => {
        dispatch({ type: "CREATE_SESSION_SUCCESS", payload: response });
        })
        .catch((error) => dispatch({ type: "CREATE_SESSION_FAILURE", payload: error }));
};
export const updateSession = (id: string,sid:string, params: ISessionUpdateParams) => async (dispatch: Dispatch) => {
  dispatch({ type: "UPDATE_SESSION_START" });
    await adminApiClient
        .updateSession(id,sid, params)
        .then((response) => {
        dispatch({ type: "UPDATE_SESSION_SUCCESS", payload: response });
        })
        .catch((error) => dispatch({ type: "UPDATE_SESSION_FAILURE", payload: error }));
};

export const deleteSession = (mentoringProgramId: string, sessionId: string) => async (dispatch: Dispatch) => {
  dispatch({ type: "DELETE_SESSION_START" });
  await adminApiClient
    .deleteSession(mentoringProgramId, sessionId)
    .then((response) => {
      dispatch({ type: "DELETE_SESSION_SUCCESS", payload: response });
    })
    .catch((error) => dispatch({ type: "DELETE_SESSION_FAILURE", payload: error }));
};





