import { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SessionType, FollowedTaskType } from "../../../store/types";
import { FollowedTaskModal, FollowedTaskDetailModalType, FollowedTaskModalDefaultState } from "../followed-tasks/FollowedTaskModal";
type SessionDetailModalPropsType = {
    modal: SessionDetailModalType;
    onClose: () => void;
};


export type SessionDetailModalType = {
    show: boolean;
    data: SessionType | null;
};

export const SessionModalDefaultState: SessionDetailModalType = {
    show: false,
    data: null,
};

export const SessionListModal = (props: SessionDetailModalPropsType) => {
    const { t } = useTranslation();
    const [show, setShow] = useState<boolean>(props.modal.show);
    const [followedTaskModal, setFollowedTaskModal] = useState<FollowedTaskDetailModalType>(FollowedTaskModalDefaultState);
    const handleClose = (): void => {
        props.onClose();
    };
    useEffect(() => {
        setShow(props.modal.show);
    }, [props.modal.show]);

    const openFollowedTaskModal = (followedTask: FollowedTaskType) => {
        setFollowedTaskModal({
            show: true,
            session: followedTask,
        });
    };
    const closeFollowedTaskModal = () => {
        setFollowedTaskModal({ show: false, session: null });
    };
    return (
        <Modal centered show={show} onHide={handleClose} animation={false} dialogClassName="ydc">
            <Modal.Header closeButton>
                <Modal.Title as="h6">{props.modal.data?.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <h6>{t("title")}:</h6>
                    <p>{props.modal.data?.title}</p>
                </div>
                <div>
                    <h6>{t("session_location")}:</h6>
                    <p>{props.modal.data?.location}</p>
                </div>
                <hr />
                <div>
                    <h6>{t("session_goals")}:</h6>
                    <p>{props.modal.data?.goals}</p>
                </div>
                <hr />
                <div>
                    <h6>{t("session_preparations")}:</h6>
                    <p>{props.modal.data?.preparations}</p>
                </div>
                <hr />
                <div>
                    <h6>{t("session_agenda")}:</h6>
                    <p>{props.modal.data?.agenda}</p>
                </div>
                <hr />
                <div>
                    <h6>{t("session_documents")}:</h6>
                    <p>{props.modal.data?.documents}</p>
                </div>
                <hr />
                <h6>{t("followed_tasks")}:</h6>
                {props.modal.data?.followed_tasks?.map((followedTask, index) => (
                    <div key={index} className="row mb-2">
                        <div className="col-8">
                            <button
                                style={{ color: '#007bff', textDecoration: 'underline', cursor: 'pointer', border: 'none', background: 'none', padding: '0' }}
                                onClick={() => openFollowedTaskModal(followedTask)}
                            >
                                {followedTask.title}
                            </button>

                        </div>
                    </div>
                ))}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t("Close")}
                </Button>
            </Modal.Footer>
            <FollowedTaskModal modal={followedTaskModal} onClose={closeFollowedTaskModal} />
        </Modal>
    );
};
