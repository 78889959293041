import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import ILayoutPartialPropType from "./ILayoutPartialPropType";
import PerfectScrollbar from "react-perfect-scrollbar";
import { NavigationItem, navigation } from "../../config";
import { RiArrowDownSLine, RiMistLine, RiLoader3Fill } from "react-icons/ri";

export const Aside = ({ asideToggleHandler }: ILayoutPartialPropType) => {
  const history = useHistory();

  /**
   * Get parent menu class name.
   * @param item
   */
  const getParentMenuActiveClassName = (item: NavigationItem): string => {
    const classNames = [];

    if (hasParentMenu(item)) {
      classNames.push("has-children");

      const match =
        item.to.replace(/^\/|\/$/g, "") === history.location.pathname.split("/").filter((item) => item !== "")[0];

      if (match) {
        classNames.push("open");
        classNames.push("active");
      }
    }

    return classNames
      .filter((value: string, index: number, self: Array<string>) => {
        return self.indexOf(value) === index;
      })
      .join(" ");
  };

  /**
   * @param item
   */
  const hasParentMenu = (item: NavigationItem): boolean => {
    return (item.children && item.children?.length > 0) === true;
  };

  /**
   * @param e
   */
  const parentMenuToggleClassName = (e: any) => {
    if (e.currentTarget.parentNode.classList.value.split(" ").find((item: string) => item === "open")) {
      e.currentTarget.parentNode.classList.remove("open");
    } else {
      e.currentTarget.parentNode.classList.add("open");
    }
  };

  /**
   * @param e
   * @param item
   */
  const handleParentMenuLinkOnClick = (e: any, item: NavigationItem) => {
    if (hasParentMenu(item)) {
      e.preventDefault();

      parentMenuToggleClassName(e);
    }
  };

  /**
   * @param e
   */
  const handleParentMenuOpenerOnClick = (e: any) => {
    e.preventDefault();

    parentMenuToggleClassName(e);
  };

  return (
    <aside className="bg-light border-right">
      <PerfectScrollbar>
        <ul className="menu">
          {navigation.map((item, key) => {
            return (
              <li
                key={key}
                className={getParentMenuActiveClassName(item)}
                onClick={() => (!(item.children && item.children?.length) ? asideToggleHandler(false) : null)}
              >
                <NavLink
                  to={item.to}
                  activeClassName={"active"}
                  exact={item.exact}
                  onClick={(e) => handleParentMenuLinkOnClick(e, item)}
                >
                  {item.icon ? <item.icon /> : <RiMistLine />}
                  <span>{item.title}</span>
                </NavLink>
                {item.children && item.children?.length > 0 && (
                  <>
                    <span className="submenu-opener" onClick={(e: any) => handleParentMenuOpenerOnClick(e)}>
                      <RiArrowDownSLine />
                    </span>
                    <ul>
                      {item.children.map((subItem, subKey) => {
                        return (
                          <li key={`${key}-${subKey}`} onClick={() => asideToggleHandler(false)}>
                            <NavLink
                              to={item.to + subItem.to}
                              className={"item-link"}
                              activeClassName={"active"}
                              exact={subItem.exact}
                            >
                              {subItem.icon ? <subItem.icon /> : <RiLoader3Fill />}
                              <span>{subItem.title}</span>
                            </NavLink>
                          </li>
                        );
                      })}
                    </ul>
                  </>
                )}
              </li>
            );
          })}
        </ul>
      </PerfectScrollbar>
      <div className="overlay" onClick={() => asideToggleHandler(false)} />
    </aside>
  );
};
